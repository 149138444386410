import React from "react";
import { useSnackbar } from 'notistack';
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from '@material-ui/core'
// custom components
import { EnhancedTable, useApi, useWindowDimensions } from 'components';
import { SearchContext, DataSourceContext, GridContext } from 'context';
import { Topbar } from 'Topbar';
import { AdvancedSearchForm, Search, ActionCell } from './Components'
//import CreateCollection from 'views/WorkbookCollections/CreateCollection';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.contentBackground,
    padding: '0px',
    margin: '0px'
  }
}));
//lastViewDate
const columns = [{ field: 'folderName', type: 'string', label: 'Folder Name' },
{ field: 'reportName', type: 'string', label: 'Report Name' },
{ field: 'runDate', type: 'date', label: 'Batched On', format: 'MM-DD-YYYY hh:mm A' },
{ field: 'createdUserName', type: 'string', label: 'Batched By' },
{ field: 'publicInd', type: 'string', label: 'Public' },
{ field: 'statusName', type: 'selectiveLinkWithTooltip', label: 'Status', showTooltip: true, onClick: () => {}, tooltipField:'tooltip' },
{ field: 'expiredDate', type: 'date', label: 'Expires On', format: 'MM-DD-YYYY hh:mm A' },
{ field: 'reportOutputType', type: 'string', label: 'Format', disableSort: true },
{ field: 'lastViewDate', type: 'date', label: 'Last Accessed', format: 'MM-DD-YYYY' }]

const rowsPerPageOptions = [10, 25, 50, 75, 100, 200, 500];

export default function AvailableReports(props) {
  const { Toolbar, toolbarProps, EnhancedToolbar,messageComponent } = props;
  //searchcontext
  const [pageSize, setPageSize] = React.useState(25);
  const [orderBy, setOrderBy] = React.useState('reportName');
  const [orderByDirection, setOrderByDirection] = React.useState('asc');
  const [folderName, setFolderName] = React.useState('');
  const [reportName, setReportName] = React.useState('');
  const [batchStartdate, setBatchStartdate] = React.useState(null);
  const [batchEnddate, setBatchEnddate] = React.useState(null);
  const [totalCount, setTotalCount] = React.useState(0);
  const [rowsSelected, setRowsSelected] = React.useState([]);
  const [page, setPage] = React.useState(1);
  const [availableReports, setAvailableReports] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [publicInd, setPublicInd] = React.useState('');
  const { enqueueSnackbar } = useSnackbar();
  const api = useApi();
  const dataSourceContext = React.useContext(DataSourceContext);

  const handleBatchMultiDownloadClick = () => {
    api.isAuthenticated().then(res => {
      if (res.data.isAuthenticated) {
        let notFound = [];
        rowsSelected.map((r) => {
          if (r.isExists)
            api.downloadBatchedReport(r.scheduleEventId, r.token)
          else
            notFound.push(r.reportName);
        });
        if (notFound.length !== 0)
          enqueueSnackbar("Following files were not found: " + notFound.toString(), { variant: 'warning' });
      }
    });
  }

  React.useEffect(() => {
    setLoading(true);
    loadBatchedReports();
  }, [pageSize, page, orderByDirection, orderBy, dataSourceContext.dataSourceSelectedKey, reportName, folderName, batchStartdate, batchEnddate])

  const loadBatchedReports = () => {
    setLoading(true);
    let sortInfo = '';
    if (orderBy !== '' && orderBy !== undefined && orderBy !== null) {
      const orderCol = columns.find((item) => orderBy === item.label || orderBy === item.field).field;
      sortInfo = "[{'Property': '" + orderCol + "' , 'direction' : '" + orderByDirection + "' }]";
    }
    let filter = {
      statusIdNotIn: "3",
      reportName: reportName,
      folderName: folderName,
      startRunDateFrom: (batchStartdate === null || batchStartdate === undefined) ? null : batchStartdate.format("MM/DD/YYYY"),
      startRunDateTo: (batchEnddate === null || batchEnddate === undefined) ? null : batchEnddate.format("MM/DD/YYYY"),
    };

    let options = { page: page, pageSize: pageSize, filter: filter, sortInfo: sortInfo, token: dataSourceContext.dataSourceSelectedKey }

    if (dataSourceContext.dataSourceSelectedKey !== '') {
      api.getBatchedReportsForoData(options).then(res => {
        setAvailableReports(res.data.data);
        setPage(res.data.page);
        setPageSize(res.data.pageSize);
        setTotalCount(res.data.totalCount);
        setLoading(false);
      })
    }
  }

  const onChangePage = (newPage) => {
    setPage(newPage);
  }
  const onPageSizeChange = (newPageSize) => {
    setPageSize(newPageSize);
  }
  const onRequestSort = order => {
    setOrderBy(order.orderBy);
    setOrderByDirection(order.order)
  }
  const classes = useStyles();
  return (
    <SearchContext.Provider
      value={{ pageSize, setPageSize, page, setPage, orderBy, setOrderBy, orderByDirection, setOrderByDirection, folderName, setFolderName, reportName, setReportName, batchEnddate, setBatchEnddate, batchStartdate, setBatchStartdate, publicInd, setPublicInd }}>
      <GridContext.Provider value={{ rowsSelected, setRowsSelected }}>
          <Grid item >
            <Topbar
              brandName="Bundles"
              //columns={columns}
              //rowsPerPageOptions={rowsPerPageOptions}
              //AdvancedSearchForm={AdvancedSearchForm}
              Search={Search}
            />
          </Grid>
          <Grid item >
            <EnhancedTable
              classes={classes}
              rowKeyProperty="scheduleEventId"
              enablePaging={true}
              mode='remote'
              page={page}
              rowsPerPage={pageSize}
              columns={columns}
              rows={availableReports}
              groupKey='dataSourceDisplayName'
              groupKeyId='groupId'
              totalCount={totalCount}
              onChangePage={onChangePage}
              onChangeRowsPerPage={onPageSizeChange}
              onRequestSort={onRequestSort}
              defaultOrder={orderByDirection}
              defaultOrderBy={orderBy}
              loading={loading}
              title="Batched Reports"
              ActionCell={ActionCell}
              actionCallback={loadBatchedReports}
              height={useWindowDimensions().height - 180}
              FileCenterToolbar={Toolbar}
              FileCenterToolbarProps={{ refresh: loadBatchedReports, ...toolbarProps }}
              EnhancedTableToolbar={EnhancedToolbar}
              EnhancedTableToolbarProps={{ refresh: loadBatchedReports, multidownload: handleBatchMultiDownloadClick, createCollection:true }}
              persistSelection={true}
              //commands={[<CreateCollection  />]}
            />
          </Grid>
      </GridContext.Provider>
    </SearchContext.Provider>
  );
}

AvailableReports.propTypes = {

}
