
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types';
import deburr from 'lodash/deburr';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

import { DataSourceContext } from 'context';
import { EnhancedTable, DownloadButton, DeleteCommand, useWindowDimensions } from 'components';
import CollectionsEnhancedToolbar from '../Toolbar/CollectionsEnhancedToolbar';
import {OdataCollectionToolbar as Toolbar} from 'views/Odata'
import   ActionCell  from './ActionCell'
const useStyles = makeStyles(theme => ({

  root: {
    width: '100%',
    backgroundColor: theme.palette.contentBackground,
    margin: 0
  },
  column: {
    padding: 0
  },
  tableCellNameLink: {
    color: theme.palette.primary.main,
    whiteSpace: 'nowrap'
  },
  tableCellTitle: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '300px',
    width: '70%'
  },
  tableCellCount: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
    textAlign: 'center',
    minWidth: '105px'
  },
  cellCount: {
    color: theme.palette.primary.main,
  },
  exportContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-center',
    alignItems: 'center'
  },

  toggleContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  toggleIcon: {
    padding: 0,
    color: theme.palette.primary.main,
    fontSize: '1.5rem',
    cursor: 'pointer'
  },
  closeIcon: {
    padding: 0,
    color: '#FE1414',
    fontSize: '0.8rem',
    cursor: 'pointer'
  },
  tableWrapper: {
    boxSizing: 'border-box',
    borderRadius: '4px',
    backgroundColor: '#FFFFFF',
    height: '100%',
    overflowX: 'visible'
  },

  tableSortLabelIcon: {
    fontSize: 14
  },
  headCell: {
    color: theme.palette.text.secondary
  },

  tableCell: {
    fontSize: 12,
    color: theme.palette.text.secondary,
    lineHeight: '14px',
    border: 'none'
  },
  tableSelectionCell: {
    border: 'none'
  },
  searchIcon: {
    color: theme.palette.npd.gray
  },
  textFieldInputRoot: {
    justifyContent: 'center'
  },
  textFieldInput: {
    flex: 1,
    fontSize: '18px !important',
    color: theme.palette.text.secondary,
    lineHeight: '23px',
    fontWeight: 300,
    padding: 8,
    '&::placeholder': { /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: theme.palette.text.secondary,
      opacity: 1,
    },

  },
  textFieldFocused: {
    '& $notchedOutline': {
      borderWidth: '1px !important'
    }
  },
  textFieldStartAdornmentRoot: {
    width: '44%',
    justifyContent: 'flex-end'
  },
  notchedOutline: {
    borderWidth: '0 !important'
  },

  w3p: {
    width: '3%'
  },

  w7p: {
    width: '7%'
  },

  w10p: {
    width: '10%'
  },

  w105: {
    width: '105px'
  },

  w10: {
    width: '10px'
  },

}));

const SavedCollections = props => {
  const { data, onDelete, onExport, onChangeSelectionModel, loading, getData, toolBarProps, pillProps } = props;
  const classes = useStyles();

  const context = React.useContext(DataSourceContext);
  const [columns, setColumns] = useState([]);
  // const [orderBy, setOrderBy] = React.useState('createdDate');
  // const [orderByDirection, setOrderByDirection] = React.useState('desc');

  useEffect(() => {
    setColumns(getColumns(data));
  }, [data])

  const exportColumnRenderer = (column, row, expanded, setExpanded) => {
    return <div className={classes.exportContainer} >
      <DownloadButton onClick={() => onExport(row['id'])} />
    </div>
  };

  const onRequestSort = (sortInfo) => {
    //alert(toolBarProps.orderBy + ' ' + toolBarProps.totalCount)
    toolBarProps.setOrderBy(sortInfo.orderBy);
    toolBarProps.setOrderByDirection(sortInfo.order);
  };

  const onChangePage = (newPage) => {
    toolBarProps.setPage(newPage);
  }
  const onPageSizeChange = (newPageSize) => {
    toolBarProps.setPageSize(newPageSize);
  }

  const getColumns = (dataset) => {

    var columns = [
      { label: 'Name', field: 'name', type: 'link', id: 'id', route: `/workbooks/{0}?ds=${context.dataSourceSelectedKey}&v=collection`, className: classes.tableCellNameLink },
      { label: 'Created', field: 'createdDate', type: 'date', format: 'MM-DD-YYYY hh:mm A', className: classes.w7p },
      { label: 'Created By', field: 'createdBy', type: 'string', className: classes.w7p },
      { label: 'Last Accessed', field: 'lastAccessed', type: 'date', format: 'MM-DD-YYYY', className: classes.w7p },
      { field: 'action', type: 'action', label: '', disableSort: true , className: classes.w3p}
    ];
    return columns
  };


  return (
    <EnhancedTable
      title='Saved Bundles List'
      columns={columns}
      loading={loading}
      rows={data}
      enablePaging={true}
      mode='remote'
      totalCount={toolBarProps.totalCount}
      defaultOrder={toolBarProps.orderByDirection}
      defaultOrderBy={toolBarProps.orderBy}
      rowKeyProperty='id'
      enableExport={false}
      enableSelection={true}
      onChangePage={onChangePage}
      onChangeRowsPerPage={onPageSizeChange}
      onRequestSort={onRequestSort}
      onChangeSelectionModel={onChangeSelectionModel}
      enableDelete={true}
      page={toolBarProps.page}
      rowsPerPage={toolBarProps.pageSize}
      stickyHeader
      onDelete={onDelete}
      height={useWindowDimensions().height - 180}

      FileCenterToolbar={Toolbar}
      FileCenterToolbarProps={{ refresh: getData, ...pillProps }}
      EnhancedTableToolbar={CollectionsEnhancedToolbar}
      EnhancedTableToolbarProps={{ refresh: getData, commands: [<DeleteCommand onDelete={onDelete} />] }}
      ActionCell={ActionCell}
      actionCallback={onDelete}
    ></EnhancedTable>

  );
}

SavedCollections.propTypes = {
  data: PropTypes.array.isRequired,
};

export default SavedCollections;
