import React,  { useState} from 'react';
import PropTypes from 'prop-types';
import {CollectionsList} from './';
import Toolbar from '../OdataCollectionsToolbar';
import {DataSourceContext, SearchContext, } from 'context';

import { makeStyles, Grid,Typography } from "@material-ui/core";
import { Topbar } from 'Topbar';
import {Search} from 'views/Odata/Collections/components'
const useStyles = makeStyles(theme => ({
  message: {
    paddingLeft: 20,
    alignSelf: 'center',
    color: theme.palette.text.primary,
    fontWeight:'bold'
  }
}));
const CollectionsListHome = props => {
    const [view, setView] = useState('collection');
    const [dataSourceSelectedKey, setDataSourceSelectedKey] = useState("");
    const [pageSize, setPageSize] = React.useState(25);
  const [page, setPage] = React.useState(1);
  const [orderBy, setOrderBy] = React.useState('createdDate');
  const [orderByDirection, setOrderByDirection] = React.useState('desc');
  const [reportName, setReportName] = React.useState('');
  const classes = useStyles();
    return (
      <SearchContext.Provider
        value={{ pageSize, setPageSize, orderBy, setOrderBy, orderByDirection, setOrderByDirection, reportName, setReportName }}>
        <DataSourceContext.Provider value={{dataSourceSelectedKey, setDataSourceSelectedKey, appKey:'fc'}}>
          
            <CollectionsList
                Toolbar={Toolbar}
                pillProps = {{setView:setView, view:view, messageComponent: <Typography className={classes.message}>Modify an existing Bundle by creating a new Bundle.</Typography>}}       
            />
          
        </DataSourceContext.Provider>
      </SearchContext.Provider>
    )
};

CollectionsListHome.propTypes = {


};

export default CollectionsListHome;
