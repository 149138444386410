import React, { useState, useEffect } from 'react';
// @material-ui/core components
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

// components

import { SavedCollections,RecentlyViewedCollections } from './components';


//services
import { useCollectionService } from 'components';
import { DataSourceContext, GridContext } from 'context';

//import { captureEvent } from 'utils';
//import FileSaver from 'file-saver';
import CollectionsLayout from './CollectionsLayout';
import {CollectionsLayoutCompact} from './';
import { MainWithTopbar } from 'layouts';

const useStyles = makeStyles(theme => ({
  root: {
  },
  recentPanel: {
    width: '100%'
  },
  listPanel: {
    height: '100%',
    width: '100%'
  },
}));

export default function CollectionsHome(props) {
  
  const { data, getData, wbLoading, toolBarProps, pillProps } = props;
  const classes = useStyles();
  const service = useCollectionService();
  const [rowsSelected, setRowsSelected] = React.useState([]);
  const [loading, setLoading] = useState(false);

    const isSelectionModel = (params) => {
      return typeof params === 'object' && params.hasOwnProperty('selected');
    }

    const handleDelete = (params) => {
      let selections = isSelectionModel(params) ? params.selected : params;
      let idarray = Array.isArray(selections) ? selections : [selections];
      let ids = idarray.map((i) => i["id"])
      if (ids.length > 0) {
        setLoading(true);
        service.deleteAll(ids).then(response => {
          getData();
        });
      }
    };

  const [selectionModel, setSelectionModel] = useState({ selectedCount: 0 });

  const onChangeSelectionModel = (value) => {
    setSelectionModel(value);
  }

  const handleExport = (id) => {
    setLoading(true);
    service.exportCollection(id).then(response => {
      const fileNameHeader = "x-bookscan-filename";
      const suggestedFileName = response.headers[fileNameHeader];
      const effectiveFileName = (suggestedFileName === undefined ? "Collectiond.xls" : suggestedFileName);
      setLoading(false);
    });
   
  };

  return (
    <GridContext.Provider value={{ rowsSelected, setRowsSelected }}>
      <CollectionsLayoutCompact
        showBreadcrumb
      setLoading={setLoading}
      selectionModel={selectionModel}
      >
          <Grid item className={classes.listPanel}>
            <SavedCollections pillProps={pillProps} loading={wbLoading} data={data} onDelete={handleDelete}
              onChangeSelectionModel={onChangeSelectionModel} getData={getData} 
              onExport={handleExport} toolBarProps={toolBarProps} /> 
          </Grid>
      </CollectionsLayoutCompact>
    </GridContext.Provider>
  );
}
