//core components
import React, {Fragment, useState} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
//material ui components
import { makeStyles, lighten } from '@material-ui/core/styles';
import { Toolbar, Typography, Tooltip, IconButton, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, Grid } from '@material-ui/core';
import { GetApp as BatchIcon, Cached as RefreshIcon } from '@material-ui/icons';
//custom components
import { GridContext, SearchContext } from 'context';
import { SelectedSearches } from 'components';


const useStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    flexGrow: 10,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    alignItems: 'flex-start',
    justifyContent: 'flex-end'
  },
  highlight: {
    color: theme.palette.primary.main,
    backgroundColor: lighten(theme.palette.secondary.light, 0.75),
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    alignItems: 'flex-start',
    justifyContent: 'flex-end'
  },
  icon: {
    padding: '1px 5px 1px 1px',
    //margin: '0px 3px 3px 3px',
    fontSize: '23px',
    //lineHeight: '24px'
  },
  title: {
    paddingRight: theme.spacing(3),
    fontSize: '12px',
    //flex: '1 1 100%',
  },
  alignRight: {
    textAlign: 'end'
  },
  alignLeft: {
    textAlign: 'start'
  },
}));

export default function CollectionsEnhancedToolbar(props) {
  const classes = useStyles();
  const { refresh, commands = [] } = props;
  const [rowsSelected, setRowsSelected] = React.useState(0);
  const gridContext = React.useContext(GridContext)
  
  const [selectionModel, setSelectionModel] = useState({
    selected: [],
    selectedCount: 0
  });

  React.useEffect(() => {
    setRowsSelected(gridContext.rowsSelected.length);
    setSelectionModel({
      selected: gridContext.rowsSelected,
      selectedCount: gridContext.rowsSelected.length
    });

  }, [gridContext.rowsSelected])

  const getCommandsWithModel = () => {
    return commands.map(c => {
      return React.cloneElement(c, {
        selectionModel: selectionModel
      });
    });
  }

  return (
    <div
      className={clsx(classes.root, classes.highlight)}
      variant='dense'
    >
      <Grid container>

        <Grid item xs={3} className={classes.alignLeft}>
          {commands ? (
            <Typography className={classes.title} color='primary' variant="subtitle1">
              { 
                getCommandsWithModel().map((command, index) => {
                  return <Fragment key={index}>{command} </Fragment>;
                })
              }
            </Typography>
            ) : (<React.Fragment/>)
          }
          </Grid>
          <Grid item xs={8} className={classes.alignRight}>
          {rowsSelected > 0 ? (
            <Typography className={classes.title} color='primary' variant="subtitle1">
              {rowsSelected} item(s) selected
            </Typography>
          ) : (
              <Typography className={classes.title} color='primary' variant="subtitle1">

              </Typography>
            )}
        </Grid>
        <Grid item xs={1} className={classes.alignRight}>
        <Tooltip title="Refresh">
            <span>
              <IconButton className={classes.icon} onClick={() => refresh()} aria-label="refresh" color="primary">
                <RefreshIcon className={classes.icon} />
              </IconButton>
            </span>
          </Tooltip>
        </Grid>
      </Grid>
    </div>
  );
};

CollectionsEnhancedToolbar.propTypes = {
  refresh: PropTypes.func.isRequired,
  multidownload: PropTypes.func.isRequired
};