import React from 'react';
import { useSnackbar } from 'notistack';
import clsx from 'clsx';
//material ui components
import { IconButton, Tooltip, Button, FormControl, Select, DialogActions, Dialog, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import {GetApp as DownloadIcon,Send as BatchIcon, Today as BatchLater, CancelScheduleSend as RemoveIcon } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
//custom components
import {useReportApi} from 'components'
import {DataSourceContext} from 'context'

const useStyle = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection:'row',
    },
    iconBtn: {
        padding: '0 2px',
        fontSize: '1rem',
        '& > span > svg':{
            fontSize: '1.2rem',
        }
    },
    colorError: {
        color: theme.palette.npd.orange
    },
    colorSuccess: {
        color: theme.palette.npd.green
    },
    hidden:{
        display:"none"
    },
    flexItem: {
        flexGrow: 1
    },
    select: {
        padding: 5,  
        fontSize: 12,
        marginTop: -2
    },
})); 
const renderAction = (row, action, classes, keyName) => {
    let item;
    switch (action.name) {
        case 'download':
            item = <Tooltip key={row[action.keyColumn] + action.name} title={action.tooltip}>
                      <span>
                          <IconButton classes={{root: classes.iconBtn}} size='small' onClick={() => action.handler(keyName)} disabled={!action.isDisabled(row.statusId)}>
                              <DownloadIcon/>
                          </IconButton>
                      </span>
                  </Tooltip>
          break;        
        case 'batch':
            if (!action.showOnRow) break;
                item = <Tooltip key={row[action.keyColumn] + action.name} title={action.tooltip} className={clsx({[classes.hidden]: action.isDisabled()})}>
                            <span>
                                <IconButton color='primary' classes={{root: classes.iconBtn}} size='small' onClick={() => action.handler("batch")}>
                                    <BatchIcon/>
                                </IconButton>
                            </span>
                        </Tooltip>
                        break;
        case 'batchEveryRelease':
            if (!action.showOnRow) break;
            item = <Tooltip key={row[action.keyColumn] + action.name}  title={row.isRunOnEachDataRelease ? action.tooltipAlt : action.tooltip}>
                        <span>
                            <IconButton color='primary' disabled={row.immediateDeliveryInd} classes={{root: classes.iconBtn, colorPrimary: row.isRunOnEachDataRelease ? classes.colorSuccess : classes.colorPrimary}} size='small' onClick={() => action.handler("batchnextload")}>
                                <BatchLater/>
                            </IconButton>
                        </span>
                    </Tooltip>
                    break;
        case 'removeBatch':
            if (!action.showOnRow) break;
            item = <Tooltip key={row[action.keyColumn] + action.name} title={action.tooltip} className={clsx({[classes.hidden]: action.isDisabled()})}>
                        <span>
                            <IconButton classes={{root: classes.iconBtn}} color='primary' onClick={() => action.handler("remove", 1)} size='small'>
                                <RemoveIcon/>
                            </IconButton>
                        </span>
                    </Tooltip>
            break;
        case 'refresh':
            if (!action.showOnRow) break;
            break;
      default:
        item = <React.Fragment/>;
        break;
    };
    return item;
}
const formatOptions = [{name:"Formatted Excel", key: 261}, {name:"Unformatted Excel", key:1285}, {name:"CSV", key: 2}]
const flatfileFormatOptions = [{name:"Flat file", key: 9}]

export default function TableActions(props){
    
    const {keyName, row, callback} = props;
    const classes = useStyle();
    const context = React.useContext(DataSourceContext);
    const [action, setAction] = React.useState("");
    const [openDialog, setOpenDialog] = React.useState(false);
    const [format, setFormat] = React.useState({name:"Formatted Excel", key: 261})
    const api = useReportApi();
    const { enqueueSnackbar } = useSnackbar();
    
    const handleDialogClose = () => setOpenDialog(false);

  const handleDialogOpen = (action) => {
        setAction(action);
        setOpenDialog(true);
    }

    const handleDialogAction = () => {
        switch(action){
            case 'batch': handleBatchClick(); break;
            case 'batchnextload': handleBatchEveryReleaseClick(); break;
            case 'remove': handleUnqueueClick(1); break;
            default: break;
        }
    }

    const handleDownLoadClick = () => {
        api.isAuthenticated().then(res => {
            if (res.data.isAuthenticated)
                api.downloadBatchedReport(row.scheduleEventIds, row.token);
        });
    }

    const canDownload = () => {
        return row.canDownload;
    } 

    const handleBatchClick = () => {
          const data = {
            eventTypeId: 1,
            status: 1,
            reportId: row.reportId,
            reportOutputTypeID: row.immediateDeliveryInd ? 4 : (format.key === 2 ? 2 : 5),
            reportOutputOption: row.isFlatfile ? 9 : format.key,
            notifyUser: true,
            createdBy: row.createdBy,
            priority: 1

        }
        api.batchReport([data]).then((res) => {
            if (callback)
                callback();
            if (res.data)
                enqueueSnackbar(res.data, { variant: 'error' });
        });
      }
    
      const handleBatchEveryReleaseClick = () => {
        if (row.isRunOnEachDataRelease) {
            handleUnqueueClick(7);
            return;
        }
       
        const data = {
            eventTypeId: 2,
            status: 1,
            reportId: row.reportId,
            reportOutputTypeID: row.immediateDeliveryInd ? 4 : (format.key === 2 ? 2 : 5),
            reportOutputOption: row.isFlatfile ? 9 : format.key,
            notifyUser: true,
            createdBy: row.createdBy,
            priority: 1
        }
        api.batchReport([data]).then((res) => {
            if (callback)
                callback();
            if (res.data)
            enqueueSnackbar(res.data, { variant: 'error' });
        });
      }
    
    const handleUnqueueClick = (status) => {
        row.unqueueStatus = status;
        api.unqueueReport([row]).then((res) => {
            if (callback)
                callback();
            if (res.data)
                enqueueSnackbar(res.data, { variant: 'error' });
        });
      }
    
    const isRemoveFromQueueDisabled = () => {
        return !row.isPendingOrRunning;
    }

    const isBatchNowDisabled = () => {
        return row.isPendingOrRunning;
    }    
    const isBatchOnNewDataloadDisabled = (scheduleStatus) => {
        return row.isRunOnEachDataRelease;
    }

    const actions = 
    [{name: 'download',  handler: handleDownLoadClick, tooltip: 'Click to Download', showOnRow: true, isDisabled: canDownload},
    {name: 'removeBatch', handler: handleDialogOpen, isDisabled: isRemoveFromQueueDisabled, tooltip: 'Remove from Queue', keyColumn: 'reportId', showOnRow: true},
    {name: 'batch', handler: handleDialogOpen, isDisabled: isBatchNowDisabled, tooltip: 'Batch It Now', keyColumn: 'reportId', showOnRow: true}, 
    {name: 'batchEveryRelease', handler: handleDialogOpen, isDisabled: isBatchOnNewDataloadDisabled, tooltip: 'Batch It With Every Data Release', tooltipAlt: 'Remove from Queue', keyColumn: 'reportId',showOnRow: true}]
    
    return (
        <div className={classes.root}>
            {actions.map(action => {
                return renderAction(row, action, classes, keyName);
            })}
            <Dialog
        open={openDialog}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Batch Report"}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                   {action === "batch" || (action === "batchnextload" && !row.isRunOnEachDataRelease) ? 
                    <>Please select your file format: 
                    <FormControl variant="outlined" className={classes.formControlLast}>
                        <Select
                            native
                            onChange={(event)=>setFormat(formatOptions.find(x => x.key.toString() === event.target.value))}
                            value={format.key}
                            classes={{root: classes.select}}
                            >
                                {   
                                    row.isFlatfile ? 
                                    flatfileFormatOptions.map(item => { return <option key={item.key} value={item.key}>{item.name}</option>}) :
                                    formatOptions.map(item => { return <option key={item.key} value={item.key}>{item.name}</option>})
                                }
                        </Select>
                        </FormControl>? <br/> </> : 
                        <>Are you sure you want to remove this report from the batch queue?</>
                    }
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button onClick={handleDialogAction} color="primary">
                {action === "batch" || (action === "batchnextload" && !row.isRunOnEachDataRelease) ? "Batch" : "Remove"}
                </Button>
                <Button onClick={handleDialogClose} color="primary" autoFocus>
                    Cancel
                </Button>
                </DialogActions>
            </Dialog> 
        </div>
    );
}