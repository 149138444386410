import request from './request'

const useApi = props => {
    

  //General Calls
    const get = (url) => {
        return request(url)
    };

    const isAuthenticated = () => {
      return request({
        url: '/account/isAuthenticated',
        method: 'GET'
      });  
    };
    const getAppInfo = () => {
      return request({
        url: 'account/getAppInfo',
        method: 'GET'
      });
    };
    const getDataSource = (key) => {
      switch(key){
        case 'cc': return getClientCenterDataSource();
        case 'fc': return getFileCenterDataSources();
        case 'ffa': return getFlatFileDataSource();
        case 'wc': return getWCDataSource();
      }
    };
//Client Center Calls

    const downloadCCFile = (groupId, documentId, token) => {
      window.open('api/document/'+groupId+'/download/'+documentId+'?token='+encodeURIComponent(token)+'', '_blank', '', true);
    };

    const createZipFile = (documents) => {
      return request({
        url: 'api/document/createzip',
        method: 'POST',
        data: {
          Documents: documents
        }
      });
    };

    const downloadZipFile = (filename) => {
      window.open('api/document/downloadzip?filename='+encodeURIComponent(filename)+'', '_blank', '', true);
    };

    const getClientCenterDataSource = () => {
      return request({
        url: 'api/datasource/get',
        method: 'GET'
      });
    };

    const getWCDataSource = () => {
      return request({
        url: 'api/datasource/wc/get',
        method: 'GET'
      });
    };

    const getFormats = (token) => {
      return request({
        url: 'api/filter/formats',
        method: 'POST',
        data: {
          token: token
        }
      });
    }
    const getCategories = (token) => {
      return request({
        url: 'api/filter/categories',
        method: 'POST',
        data: {
          token: token
        }
      });
    }
    const getDocuments = (options) => {
        return request({
          url: 'api/document/list/paging',
          method: 'POST',
          data: {
            filter: options.filter,
            groups: options.group,
            sortJson: options.sortInfo,
            page: options.page,
            pageSize: options.pageSize,
            token: options.token
          }
        });
      };

      const getUserPreference = () => {
        return request({
          url: 'api/userpreference/get', 
          method: 'GET'
        });
    }

    const saveRowPerPage = (options) => {
      return request({
        url: 'api/userpreference/save/rowperpage', 
        method: 'POST',
        data: {
          rowsPerPageOptions: options.rowsPerPageOptions
        }
      });
    }

    const saveUserPreference = (options) => {
      return request({
        url: 'api/userpreference/post', 
        method: 'POST',
        data: {
          EmailOption: options.emailOption
        }
      });
    }

    const getTitleAutoSuggest = (key) => {
      return request({
        url: 'api/document/autosuggest/title',
        method: 'GET',
        params: {
          searchString: key
        }
      })
    }

  //File center calls
  
  const getFileCenterDataSources = () => {
    return request({
      url: 'api/datasource/fc/get',
      method: 'GET'
    });
  };

  const getBatchedReports = (options) => {
    return request({
      url: 'api/filecenter/batchedreports',
      method: 'POST',
      data: {
        page: options.page,
        pageSize: options.pageSize,
        filter: options.filter,
        sortJson: options.sortInfo,
        token: options.token
      }
    });
  }

  const getBatchedReportsForoData = (options) => {
    return request({
      url: 'api/filecenter/batchedreportsodata',
      method: 'POST',
      data: {
        page: options.page,
        pageSize: options.pageSize,
        filter: options.filter,
        sortJson: options.sortInfo,
        token: options.token
      }
    });
  }

  const getFileRepo = (options) => {
    return request({
      url: 'api/filecenter/filerepositories',
      method: 'POST',
      data: {
        page: options.page,
        pageSize: options.pageSize,
        sortJson: options.sortInfo,
        filter: options.filter,
        token: options.token
      }
    });
  };

  const getBatchAutoSuggest = (key) => {
    return request({
      url: 'api/filecenter/autosuggest/batch',
      method: 'GET',
      params: {
        searchString: key
      }
    })
  }

  const getFileRepoTitleAutoSuggest = (key) => {
    return request({
      url: 'api/filecenter/autosuggest/title',
      method: 'GET',
      params: {
        searchString: key
      }
    })
  }

  const getFileRepoFilenameAutoSuggest = (key) => {
    return request({
      url: 'api/filecenter/autosuggest/filename',
      method: 'GET',
      params: {
        searchString: key
      }
    })
  }

  const downloadBatchedReport = (scheduleId, token) => {
    window.open('api/filecenter/batchedreports/download/'+scheduleId+'?token='+ encodeURIComponent(token), '_blank', '', true);
  }

  const downloadBatchedReportZip = (reports) => {
    var request = reports.map(r => r.scheduleEventId + ":" + encodeURIComponent(r.token))
    
    window.open('api/filecenter/batchedreports/download/zip?r='+ request.join(), '_blank', '', true);
  }

  const downloadFileRepoZip = (reports) => {
    var request = reports.map(r => r.fileRepositoryId + ":" + encodeURIComponent(r.token))
    
    window.open('api/filecenter/filerepositories/download/zip?r='+ request.join(), '_blank', '', true);
  }

  const downloadFileRepo = (fileRepositoryId, token) => {
    window.open('api/filecenter/filerepositories/download/'+fileRepositoryId+'?token='+ encodeURIComponent(token), '_blank', '', true);
  }

  const deleteReport = (report) => {
    return request({
      url: 'api/filecenter/deletereport',
      method: 'POST', 
      data: report
    });
  }

  const unqueueBatchReport = (flatFile) => {
    return request({
      url: 'api/filecenter/unqueuereport',
      method: 'POST', 
      data: flatFile
    });
  }

  const getReportOutputTypes = (flatFile) => {
    return request({
      url: 'api/filecenter/getReportOutputTypes',
      method: 'POST', 
    });
  }
  

  //Flat File calls
    const getFlatFileDataSource = () => {
      return request({
        url: 'api/datasource/ffa/get',
        method: 'GET'
      });
    };

    const getFlatFiles = (options) => {
      return request({
        url: 'api/FlatFileAdmin/list/paging',
        method: 'POST',
        data: {
          page: options.page,
          pageSize: options.pageSize,
          sortJson: options.sortInfo,
          filter: options.filter,
          token: options.token
        }
      });
    };

    const getOutputFolderOptions = (flatFile) => {
      return request({
        url:'api/FlatFileAdmin/outputfolderoptions',
        method: 'POST',
        data: flatFile
      });
    };

    const getMoveItFolderOptions = (flatFile) => {
      return request({
        url: 'api/FlatFileAdmin/moveitfolderoptions', 
        method: 'POST',
        data: flatFile
      });
    };

    const updateReport = (flatFile, updates) => {
      return request({
        url: 'api/FlatFileAdmin/updatereport', 
        method: 'POST', 
        data: {flatFile, updates}
      });
    }

    const batchReport = (flatFile) => {
      return request({
        url: 'api/FlatFileAdmin/batchreport',
        method: 'POST',
        data: flatFile
      });
    }

    const unqueueReport = (flatFile) => {
      return request({
        url: 'api/FlatFileAdmin/unqueuereport',
        method: 'POST', 
        data: flatFile
      });
    }

      const getFFReportAutoSuggest = (key) => {
        return request({
          url: 'api/FlatFileAdmin/autosuggest/reportName',
          method: 'GET',
          params: {
            searchString: key
          }
        })
      }
  
      const getFFCreatedByAutoSuggest = (key) => {
      return request({
        url: 'api/FlatFileAdmin/autosuggest/createdBy',
        method: 'GET',
        params: {
          searchString: key
        }
      })
    }

    const getTargetEnvironments = () => {
      return request({
        url: 'api/FlatFileAdmin/environments',
        method: 'GET'
      })
    }

    const getMoveItFilters = () => {
      return request({
        url: 'api/FlatFileAdmin/moveitfolderfilters',
        method: 'GET'
      })
    }

    /*
    const getImmediateFrequencies = () => {
      return request({
        url: 'api/FlatFileAdmin/getimmediatefrequencies',
        method: 'GET'
      })
    }
    */

    const getImmediateFrequencies = (options) => {
      return request({
        url: 'api/FlatFileAdmin/getimmediatefrequencies',
        method: 'POST',
        data: {
          page: options.page,
          pageSize: options.pageSize,
          sortJson: options.sortInfo,
          filter: options.filter,
          token: options.token
        }
      });
    };

    const updateImmediateFrequency = (UniqueId, isRunDaemon) => {
      return request({
        url: 'api/FlatFileAdmin/updateimmediatefrequency', 
        method: 'POST', 
        data: {
          UniqueId: UniqueId, 
          isRunDaemon: isRunDaemon
        }
      });
    }

    const getStatusFilters = () => {
      return request({
        url: 'api/FlatFileAdmin/statusfilters',
        method: 'GET'
      })
    }

    const updateFlatFileOptions = (reportId, flatFileOptions, schemaId) => {
      return request({
        url: 'api/FlatFileAdmin/saveFFOptions',
        method: 'POST',
        data: {
          reportId: reportId,
          flatFileOptions: flatFileOptions,
          schemaId: schemaId
        }
      })
    }

    return {
      get, 
      getDocuments, 
      getDataSource, 
      getFlatFiles, 
      isAuthenticated, 
      getAppInfo, 
      downloadCCFile, 
      createZipFile,
      downloadZipFile,
      getCategories, 
      getFormats, 
      getFlatFileDataSource, 
      getOutputFolderOptions, 
      getMoveItFolderOptions, 
      updateReport,
      batchReport,
      getUserPreference,
      saveUserPreference,
      unqueueReport,
      getBatchedReports,
      getBatchedReportsForoData,
      getFileRepo,
      downloadBatchedReport,
      downloadBatchedReportZip,
      downloadFileRepo,
      downloadFileRepoZip,
      getBatchAutoSuggest,
      getFFReportAutoSuggest,
      getFFCreatedByAutoSuggest,
      getFileRepoTitleAutoSuggest,
      getFileRepoFilenameAutoSuggest,
      getTargetEnvironments,
      getMoveItFilters,
      getImmediateFrequencies,
      updateImmediateFrequency,
      getStatusFilters,
      deleteReport,
      unqueueBatchReport,
      getReportOutputTypes,
      saveRowPerPage,
      updateFlatFileOptions,
      getTitleAutoSuggest
    };
}

export default useApi;