import React, { Fragment, useContext } from "react";
// @material-ui/core components
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import { Button, Paper, Popover, ClickAwayListener, Select, MenuItem, InputBase, TextField, Grid, Divider, IconButton, Tooltip } from "@material-ui/core";
import DataSourceContext from 'context/DataSourceContext';
//import { useHistory } from 'react-router-dom';
import {useHistory} from 'react-router'
import { Star as StarIcon, Close as CloseIcon } from "@material-ui/icons";
import { useCollectionService } from 'components';
import { numberFormat } from 'utilities';
import _ from 'lodash';

const useStyles = makeStyles(theme => ({
  root: {

  },
  button: {
    width: 120,
    fontSize: 12,
    fontWeight: 600,
    color: '#FF9971',
    whiteSpace: 'nowrap'
  },
  buttonDisabled: {
    color: theme.palette.npd.gray,
  },
  popupbuttonDisabled: {
    backgroundColor: `${theme.palette.tableRowDivider} !important`,
    color: `${theme.palette.npd.gray} !important`,
  },
  spacer: {
    width: 10
  },
  paper: {
    zIndex: 3000,
    width: 235,
    opacity: 1,
    backgroundColor: 'rgba(255,255,255,0.93)',//theme.palette.background.tooltip,
    boxShadow: '0 6px 24px 0 rgba(50,64,78,0.17)'
  },

  popupLabel: {
    padding: '11px 16px',
    color: theme.palette.white,
    fontSize: 14,
    backgroundColor: theme.palette.npd.gray,
    height: 37,
    display: 'flex'
  },

  popupInputs: {
    padding: '9px 16px',
    color: theme.palette.text.secondary,
    fontSize: 14,
    fontWeight: 600,
    width: '100%',
    backgroundColor: theme.palette.white,
  },

  popupButtons: {
    borderTop: `1px solid ${theme.palette.tableRowDivider}`,
    padding: 15,
    fontSize: 14,
    fontWeight: 600,
    display: 'inline-flex',
    width: '100%',
    backgroundColor: theme.palette.white
  },

  popper: {
    zIndex: 1
  },

  saveButton: {
    fontWeight: 'bold',
    color: '#FFF',
    width: 94,
    height: 36,
    fontSize: 12,
    backgroundColor: theme.palette.npd.green,
    '&:hover': {
      fontWeight: 'bold',
      color: '#FFF',
      width: 94,
      height: 36,
      fontSize: 12,
      backgroundColor: theme.palette.npd.green
    }
  },

  viewButton: {
    fontWeight: 'bold',
    color: '#FFF',
    width: 94,
    height: 36,
    fontSize: 10,
    backgroundColor: theme.palette.primary.main,
    marginLeft: 18,
    '&:hover': {
      fontWeight: 'bold',
      color: '#FFF',
      width: 94,
      height: 36,
      fontSize: 10,
      backgroundColor: theme.palette.primary.main,
      marginLeft: 18,
    }
  },
  selectInput: {
    width: 206
  },
  inputRoot: {
    fontSize: 12,
    backgroundColor: theme.palette.grey[50],
    borderRadius: 4,
    height: 30,
    "& $inputNotchedOutline": {
      borderWidth: 1
    },
    "&:hover $inputNotchedOutline": {
      borderWidth: 1,
      borderColor: theme.palette.primary.main
    },
    "&$inputFocused $inputNotchedOutline": {
      borderWidth: 1
    }
  },
  inputDisabled: {
    backgroundColor: theme.palette.grey[50],
    height: 30,
    "& $inputNotchedOutline": {
      borderWidth: 1,
      borderColor: 'rgba(0, 0, 0, 0.1) !important'
    }
  },
  inputError: {
    backgroundColor: theme.palette.grey[50],
    borderWidth: 1,
    height: 30,
    "& $inputNotchedOutline": {
      borderColor: '#e53935'
    },
    "&:hover $inputNotchedOutline": {
      borderColor: '#e53935'
    },
  },
  inputName: {
    width: 190,
    flexGrow: 1,
    padding: 8,
    backgroundColor: theme.palette.divider
  },
  inputFocused: {
    backgroundColor: theme.palette.white
  },
  inputNotchedOutline: {
  },

  inputAdornedStart: {
    padding: 0
  },
  inputAdornment: {
    margin: 0
  },
  inputAdornmentHidden: {
    display: 'none'
  },
  container: {
    height: '100%',
    flex: 1,
    marginTop: 14
  },
  divider: {
    width: 56,
    backgroundColor: theme.palette.tableRowDivider
  },

  starIcon: {
    '& > *:first-child': {
      fontSize: 14,
    },
  },
  starBox: {
    border: `1px solid ${theme.palette.tableRowDivider}`,
    height: 18,
    width: 18,
    padding: 2,
    paddingTop: 2,
    paddingLeft: 3,
    borderRadius: 3,
    marginTop: 8,
    marginLeft: 11,
    fontSize: 12
  },
  titleCount: {
    fontWeight: 300,
    fontSize: 10,
    marginLeft: 5,
    padding: '0 5px',
    borderRadius: 4,
    border: `1px solid ${theme.palette.background.default}`,
    backgroundColor: theme.palette.background.default
  }
}));

const HtmlTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}))(Tooltip);

const BootstrapInput = withStyles(theme => ({
  root: {
    fontSize: 12,
    backgroundColor: theme.palette.grey[50],

    '& $inputNotchedOutline': {
      borderWidth: 0
    },
    '&:hover $inputNotchedOutline': {
      borderWidth: 1,
      borderColor: theme.palette.primary.main
    },
    '&$inputFocused $inputNotchedOutline': {
      borderWidth: 1
    }
  },

  input: {
    width: 'auto',
    flexGrow: 1,
    padding: 8
  },

  focused: {
    backgroundColor: theme.palette.white
  }
}))(InputBase);

export default function CreateCollection(props) {
  const { selectionModel, searchParams = {}, hideTitle = false, pillProps } = props;
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const { dataSourceSelectedKey, dataSourceSelectedInfo } = useContext(DataSourceContext);

  const collectionApi = useCollectionService();
  const anchorRef = React.useRef(null);
  const [isSaved, setIssaved] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [collectionList, setCollectionList] = React.useState([]);
  const [selectedCollection, setSelectedCollection] = React.useState(null);
  const [collectionName, setCollectionName] = React.useState('');
  const [collectionNameErrorMsg, setCollectionNameErrorMsg] = React.useState('');
  const [savedId, setSavedId] = React.useState(null);
  const [isSaving, setIsSaving] = React.useState(false);
  const [page, setPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(25);
  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = open;
    setIssaved(false);
    setCollectionName('');
    setSelectedCollection(null);
    setSavedId(null);
  }, [open, selectionModel]);
  let sortInfo = '';
  let filter = {};
 
  React.useEffect(() => {
    // ENABLE AFTER YOU HAVE AN API
    let options = { page: page, pageSize: pageSize, filter: filter, sortInfo: sortInfo }
    if (open === true) {
      collectionApi.getAll(options).then((response) => {
        setCollectionList(response.data);
      });
    }
  }, [open]);

  const handleClick = event => {

    setOpen(prevOpen => !prevOpen);
  };

  const handleChange = e => {
    setSelectedCollection(e.target.value);
    setCollectionName('');
    setCollectionNameErrorMsg('');
  }

  const onClickAway = e => {
    setOpen(prevOpen => !prevOpen);
    setCollectionName('');
    setCollectionNameErrorMsg('');
    setSelectedCollection(null);
    setIssaved(false);
  }

  const onClickClose = e => {
    onClickAway();
    setIssaved(false);
    setCollectionName('');
    setSelectedCollection(null);
    setSavedId(null);
  }

  const handleInputChange = e => {
    if (e.target.name === 'name' && collectionNameErrorMsg.length > 0)
      setCollectionNameErrorMsg('');

    setCollectionName(e.target.value);
    setSelectedCollection(null);
  }

  const handleOnViewCollection = e => {
    history.push('/collection?v=collection');
  }

  const handleOnSave = e =>{
 
    if (isSaved || isSaving)
    return;

  setIsSaving(true);

  
  //setIssaved(true);
  //setIsSaving(false);
    // here you can call your api.
    // selectionModel.selection = all rows selected from table
    var options = selectionModel.selected;
    if (collectionName.length > 0 && options.length > 0) {
        collectionApi.createNewBundle(options,collectionName).then((response) => {
        setIsSaving(false);
        if (response.data) {
          //if (response.data.includes('already exists. Please select another name')) {
            if (response.data.length>0) {
            setCollectionNameErrorMsg(response.data);
          }
        } else {
          //setSavedId(response.data.id);
          setIssaved(true);
        }
      }).catch((error) => {
        setIsSaving(false);
      });
    };
  };

  // if (row && updates) {
  //   api.updateReport(row, updates).then((res) => {
  //     if (res.data && res.data.failed)
  //       enqueueSnackbar(res.data.errorMessage, { variant: 'warning' });
  //     else {
  //       setLoading(true);
  //       loadFlatFiles();
  //     }
  //   });
  // }



  /*const handleOnSave = e => {
    if (isSaved || isSaving)
      return;

    setIsSaving(true);

    var isbns = [];
    var unselectedIsbns = [];
    if (selectionModel.selected) {
      selectionModel.selected.map((isbn) => {
        isbns.push({ isbn: `${isbn}` })
      });
    }
    if (selectionModel.unselected) {
      selectionModel.unselected.map((isbn) => {
        unselectedIsbns.push(`${isbn}`)
      });
    }
    var collection = {
      bookCollectionISBNs: isbns,
      unselected: unselectedIsbns
    }

    if (collectionName.length > 0) {
      collection.name = collectionName;
      //captureEvent({ category: 'Collections', action: 'Create New Collection' });
    }
    else if (selectedCollection !== null) {
      collection.id = selectedCollection;
      //captureEvent({ category: 'Collections', action: 'Add to Existing Collection' });
    }

    var api = collectionApi.add;

    if (selectionModel.remote && selectionModel.selectAll) {
      collection = _.merge(collection, searchParams || {});

      //Find a way to determine between search and advanced search
      if (collection.query)
        api = collectionApi.addBySearch;
      else if (collection.bookCollectionSourceId)
        api = collectionApi.addFromCollection;
      else
        api = collectionApi.addByAdvancedSearch;
    }

    api(collection).then((response) => {
      setIsSaving(false);
      if (response.data.errorDescription) {
        if (response.data && response.data.errorDescription.includes('same name already exists')) {
          setCollectionNameErrorMsg('Collection with the same name already exists.');
        }
      } else {
        setSavedId(response.data.id);
        setIssaved(true);
      }
    }).catch((error) => {
      setIsSaving(false);
    });
  };*/

  const renderDisplay = (value) => {
    if (value === undefined || value === null || value.length === 0) {
      return <span style={{ fontWeight: 300, color: theme.palette.npd.gray }}>Select from Existing Collection</span>
    }

    var selected = collectionList.find((item) => item.id === value);
    return (<Fragment><span style={{ fontWeight: 600, fontSize: 12 }}>{selected.name}</span><span className={classes.titleCount}> {numberFormat(selected.size, 'en-US')} titles</span></Fragment>)
  }

  return (
    <Fragment>
      {(hideTitle) ?
        <IconButton
          className={classes.starBox}
          ref={anchorRef}
          onClick={handleClick}
          disabled={selectionModel.selectedCount === 0}
        >
          <StarIcon fontSize='inherit' style={{ color: selectionModel.selectedCount === 0 ? theme.palette.npd.gray : theme.palette.text.secondary }} />
        </IconButton>
        :
        <HtmlTooltip
          title={(selectionModel.selectedCount === 0) ?
            <React.Fragment>
              Select report(s) to enable this button.
            </React.Fragment> : ''
          }
        ><div>
            <Button
              ref={anchorRef}
              size='small'
              classes={{ root: classes.button, disabled: classes.buttonDisabled, iconSizeSmall: classes.starIcon }}
              onClick={handleClick}
              disabled={selectionModel.selectedCount === 0}
              startIcon={<StarIcon fontSize='inherit' />}
            >
              Add to Bundle
          </Button>
          </div>
        </HtmlTooltip>
      }
      <Popover
        id={'AddCollectionPopover'}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        className={classes.popper}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Paper className={classes.paper} elevation={8}>
          <ClickAwayListener onClickAway={onClickAway}>
            <div>
              {(hideTitle) ? '' : 
                <div className={classes.popupLabel}>
                  <div style={{flex: 1}}>{numberFormat(selectionModel.selectedCount, 'en-US')} report{(selectionModel.selectedCount > 1) ? 's' : ''} selected</div>
                  <div><CloseIcon fontSize='inherit' style={{cursor: 'pointer'}} onClick={onClickClose} /></div>
                </div>
              }
              <div className={classes.popupInputs}>
                {collectionList.length > 0 ?
                  <Fragment>
                    <div>Add to Bundle</div>
                    <div style={{ marginTop: 10, marginBottom: 10 }}>
                      <Select
                        name={'collectionSelect'}
                        variant='outlined'
                        value={selectedCollection}
                        onChange={handleChange}
                        renderValue={renderDisplay}
                        input={<BootstrapInput className={classes.selectInput} />}
                        displayEmpty
                        MenuProps={{
                          disablePortal: true,
                          anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'left'
                          },
                          transformOrigin: {
                            vertical: 'top',
                            horizontal: 'left',
                          },
                          getContentAnchorEl: null
                        }}
                      >
                        {collectionList.map(option => (
                          <MenuItem component='div' key={option['id']} value={option['id']} classes={{ selected: classes.selected }}>
                            <span style={{ fontWeight: 600, fontSize: 12 }}>{option['name']}</span><span className={classes.titleCount}> {numberFormat(option['size'], 'en-US')} titles</span>
                          </MenuItem>
                        ))}
                      </Select>
                    </div>

                    <div style={{ marginTop: 0, marginBottom: 20, fontSize: 12, fontStyle: 'italic', color: theme.palette.npd.gray, display: 'inline-flex', width: '100%' }}>
                      <Grid className={classes.root} container justify='center' alignItems='center' spacing={1}>
                        <Grid item className={classes.container}>
                          <Grid
                            container
                            direction='row'
                            justify='center'
                            alignItems='center'
                          >
                            <Divider className={classes.divider} />
                          </Grid>
                        </Grid>
                        <Grid item><span className={classes.text}>or</span></Grid>
                        <Grid item className={classes.container}>
                          <Grid
                            container
                            direction='row'
                            justify='center'
                            alignItems='center'
                          >
                            <Divider className={classes.divider} />
                          </Grid>
                        </Grid>
                      </Grid>
                    </div>
                  </Fragment>
                  : ''
                }
                <div>Create A New Bundle</div>
                <div style={{ marginTop: 10, marginBottom: 10 }}>
                  <TextField variant="outlined" id="name" name="name" placeholder={'Name Your Bundle'} error={(collectionNameErrorMsg.length > 0)} helperText={collectionNameErrorMsg} value={collectionName}
                    classes={{
                      root: classes.formInputRoot
                    }}
                    InputProps={{
                      classes: {
                        root: classes.inputRoot,
                        disabled: classes.inputDisabled,
                        input: classes.inputName,
                        focused: classes.inputFocused,
                        notchedOutline: classes.inputNotchedOutline,
                        adornedStart: classes.inputAdornedStart,
                        error: classes.inputError
                      }
                    }} onChange={handleInputChange} />
                </div>
              </div>
              <div className={classes.popupButtons}>
                <Button color='primary' variant='text' size='small' disabled={!(collectionName.length > 0 || selectedCollection !== null)} classes={{ root: classes.saveButton, disabled: classes.popupbuttonDisabled }} onClick={handleOnSave}>{isSaved ? 'Saved!' : (isSaving) ? 'Saving...' : 'Save'}</Button>
                <Button color='primary' variant='text' size='small' disabled={!isSaved} classes={{ root: classes.viewButton, disabled: classes.popupbuttonDisabled }} onClick={handleOnViewCollection}>View Bundles</Button>
              </div>
            </div>
          </ClickAwayListener>
        </Paper>
      </Popover>
    </Fragment>

  );
}
