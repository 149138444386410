import React from 'react'
import PropTypes from 'prop-types';
import MomentUtils from '@date-io/moment';

import { makeStyles } from '@material-ui/core/styles';
import {FormControl, Select, Box, OutlinedInput, Button, Divider, Typography, Grid, IconButton} from '@material-ui/core';
import {MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import {Close as CloseIcon} from '@material-ui/icons'

import {SearchContext} from 'context'
import {useApi, AutoCompleteDropdown as SearchAutocomplete} from 'components'


const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
      },
      typography: {
      padding: theme.spacing(2)
    },
    iconOpenRoot:{
        color: theme.palette.common.white,
        backgroundColor: theme.palette.npd.darkBlue,
        borderRadius: 0,
        height: 48,
        width: 48,
        '&:hover':{
            color: theme.palette.common.white,
            backgroundColor: theme.palette.npd.darkBlue,
        }
    },
    iconRoot:{
        height: 48,
        width: 48,
    },
    button:{
        margin: 10
    },
    gridContainer:{
        padding: '3px 10px',
    },
    popperPaper:{
        width: 550,
        height: 380,
        padding: theme.spacing(1),
        textAlign: 'center',
        whiteSpace: 'nowrap',
        marginBottom: theme.spacing(1),
    },
    textBox: {
        height:30,
        width: '100%',
        fontSize: 12,
        '&:input':{
            padding: "0px 5px"
        }
    },
    formControl:{
        width: '100%',
        paddingRight: 5
    },
    formControlLast:{
        width: '100%'
    },
    select: {
        padding: 8,
        fontSize: 12,
        width: '100%'
    },
    dense: {
        padding: 0,
        margin: 0,
        paddingRight: 2,
        width: '50%',
        '& > div > input':{
            padding: 8,
            fontSize: 12
        }
    },
    endAdornment: {
        padding: 0,
        '& > button':{
            padding: 0
        }
    },
  }));
  
export default function AdvancedSearchForm(props){
    const classes = useStyles();
    const {rowsPerPageOptions = [], columns = [], handleClose, reportOutputTypesFilters = [], statusFilters = []} = props;
    const searchContext = React.useContext(SearchContext);
    const api = useApi();

    const [reportName, setReportName] = React.useState(searchContext.reportName);
    const [folderName, setFolderName] = React.useState(searchContext.folderName);
    const [batchStartdate, setBatchStartdate] = React.useState(searchContext.batchStartdate);
    const [batchEnddate, setBatchEnddate] = React.useState(searchContext.batchEnddate);
    const [batchedBy, setBatchedBy] = React.useState(searchContext.batchedBy);
    const [batchStatus, setBatchStatus] = React.useState(searchContext.batchStatus);
    const [format, setFormat] = React.useState(searchContext.format);
    
    const [orderBy, setOrderBy] = React.useState(searchContext.orderBy);
    const [orderByDirection, setOrderByDirection] = React.useState(searchContext.orderByDirection);
    const [pageSize, setPageSize] = React.useState(searchContext.pageSize);

    const handleOrderByChange = (event) => {
        setOrderBy(event.target.value);
    }
    const handleOrderByDirectionChange = (event) => {
        setOrderByDirection(event.target.value);
    }
    const handlePageSizeChange = (event) => {
        setPageSize(event.target.value);
    }

    const onApplyClick = () => {
        setFilters(false);
        handleClose();
    }
    
    const onClearClick = () => {
        setFolderName('');
        setReportName('');
        setBatchEnddate(null);
        setBatchStartdate(null);
        setFilters(true);
        setBatchStatus({scheduleStatus: 0, displayName: ''});
        setBatchedBy('');
        setFormat({reportOutputTypeId: 0, reportOutputType: ""});
    }
    const handleChange = (event, columnName) => {
        var value = event.target.value;
        switch(columnName){
            case 'folderName': setFolderName(value); break;
            case 'reportName': setReportName(value); break;
            case 'batchedBy': setBatchedBy(value); break;
        }
    }

    const handleFormatChange = (event, value) => {
        
    }

    const setFilters = (shouldClear) => {
        if (shouldClear){
            searchContext.setReportName('');
            searchContext.setFolderName('');
            searchContext.setBatchStartdate(null);
            searchContext.setBatchEnddate(null);
            searchContext.setBatchStatus({scheduleStatus: 0, displayName: ''});
            searchContext.setBatchedBy('');
            searchContext.setFormat({reportOutputTypeId: 0, reportOutputType: ""});
        }
        else{
            searchContext.setReportName(reportName);
            searchContext.setFolderName(folderName);
            searchContext.setBatchStartdate(batchStartdate);
            searchContext.setBatchEnddate(batchEnddate);
            searchContext.setBatchStatus(batchStatus);
            searchContext.setBatchedBy(batchedBy);
            searchContext.setFormat(format)
            searchContext.setOrderBy(orderBy);
            searchContext.setOrderByDirection(orderByDirection);
            searchContext.setPageSize(pageSize);
            searchContext.setPage(1);
        }
    }
    return (
<Grid container className={classes.root}>
        <div className={classes.popperPaper}>
            <Grid item xs={12}>
                <Grid container>
                    <Grid item xs={11}>
                        
                    </Grid>
                    <Grid item xs={1}>
                        <IconButton onClick={handleClose}>
                            <CloseIcon/>
                        </IconButton>
                    </Grid>
                </Grid>
            </Grid>
            <Divider></Divider>
            <Grid item xs={12}>
            <Grid container>
                <Grid item xs={6}>
                    <Grid container>
                        <Grid item xs={12} className={classes.gridContainer}>
                            <Box lineHeight={2} textAlign="left" fontSize='subtitle.fontSize'>Folder Name</Box>
                            <OutlinedInput margin='dense' value={folderName} onChange={event =>handleChange(event, 'folderName')}  classes={{root: classes.textBox}} variant='outlined'></OutlinedInput>
                        </Grid>
                        
                        <Grid item xs={12} className={classes.gridContainer}>
                            <Box lineHeight={2} textAlign="left" fontSize='subtitle.fontSize'>Batched By</Box>
                            <OutlinedInput margin='dense' value={batchedBy} onChange={event =>handleChange(event, 'batchedBy')} classes={{root: classes.textBox}} variant='outlined'></OutlinedInput>
                        </Grid>

                        <Grid item xs={12} className={classes.gridContainer}>
                            <Box lineHeight={2} textAlign="left"  fontSize='subtitle.fontSize'>Format</Box>
                            <SearchAutocomplete
                                handleTextBoxOnChange={(event, value) => {if (event !== undefined && event.target !== undefined) setFormat({ reportOutputType: event.target.value })}}
                                handleAutoCompleteValueOnChange={(event, value) => {if (value !== undefined && value.reportOutputTypeId !== undefined) setFormat(value)}}
                                inputTerm={format.reportOutputType}
                                defaultValue={reportOutputTypesFilters.find((item) => item === searchContext.format) || { reportOutputType: '', reportOutputTypeId: '0' }}
                                getOptionLabel={item => item.reportOutputTypeId}
                                options={reportOutputTypesFilters}
                                placeholder=''
                                outlined={true}
                                freeSolo={false}
                                label = "reportOutputType"
                            />
                            {/* <FormControl variant="outlined" className={classes.formControl}>
                                <Select
                                    native
                                    onChange={(event) => setFormat(reportOutputTypesFilters.find(i => i.reportOutputTypeId === event.target.value))}
                                    classes={{root: classes.select}}
                                    value={format.reportOutputTypeId}
                                    >
                                        { reportOutputTypesFilters.map(item => <option key={item.reportOutputTypeId} value={item.reportOutputTypeId}>{item.reportOutputType}</option>)}
                                </Select>
                            </FormControl> */}
                        </Grid>

                    </Grid>
                </Grid>
                <Grid item xs={6}>
                    <Grid container>
                        <Grid item xs={12} className={classes.gridContainer}>
                            <Box lineHeight={2} textAlign="left" fontSize='subtitle.fontSize'>Report Name</Box>
                            <OutlinedInput margin='dense' value={reportName} onChange={event =>handleChange(event, 'reportName')} classes={{root: classes.textBox}} variant='outlined'></OutlinedInput>
                        </Grid>
                        
                        <Grid item xs={12} className={classes.gridContainer}>
                            <Box lineHeight={2} textAlign="left"  fontSize='subtitle.fontSize'>Status</Box>
                            <SearchAutocomplete
                                handleTextBoxOnChange={(event, value) => {if (event !== undefined && event.target !== undefined) setBatchStatus({ displayName: event.target.value })}}
                                handleAutoCompleteValueOnChange={(event, value) => {if (value !== undefined && value.scheduleStatus !== undefined) setBatchStatus(value)}}
                                inputTerm={batchStatus.displayName}
                                defaultValue={statusFilters.find((item) => item === searchContext.batchStatus) || { displayName: '', scheduleStatus: '0' }}
                                options={statusFilters}
                                placeholder=''
                                outlined={true}
                                freeSolo={false}
                                label = "displayName"
                            />
                            {/* <FormControl variant="outlined" className={classes.formControl}>
                                <Select
                                    native
                                    onChange={(event) => setBatchStatus(statusFilters.find(i => i.scheduleStatus.toString() === event.target.value.toString()))}
                                    classes={{root: classes.select}}
                                    value={batchStatus.scheduleStatus}
                                    >
                                        { statusFilters.map(item => <option key={item.scheduleStatus} value={item.scheduleStatus}>{item.displayName}</option>)}
                                </Select>
                            </FormControl> */}
                        </Grid>

                        <Grid item xs={12} className={classes.gridContainer}>
                        <Box lineHeight={2} textAlign="left"  fontSize='subtitle.fontSize'>Batched On</Box>
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                <KeyboardDatePicker
                                    autoOk
                                    variant="inline"
                                    inputVariant="outlined"
                                    placeholder="From"
                                    format="MM/DD/YYYY"
                                    margin="dense"
                                    value={batchStartdate}
                                    classes={{root: classes.dense}}
                                    InputAdornmentProps={{classes: {root: classes.dense, positionEnd: classes.endAdornment}}}
                                    onChange={date => setBatchStartdate(date)}
                                />
                            </MuiPickersUtilsProvider>
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                <KeyboardDatePicker
                                    autoOk
                                    variant="inline"
                                    inputVariant="outlined"
                                    placeholder="To"
                                    format="MM/DD/YYYY"
                                    margin="dense"
                                    disableFuture
                                    value={batchEnddate}
                                    classes={{root: classes.dense}}
                                    InputAdornmentProps={{classes: {root: classes.dense, positionEnd: classes.endAdornment}}}
                                    onChange={date => setBatchEnddate(date)}
                                />
                            </MuiPickersUtilsProvider>
                    </Grid>

                    </Grid>    
                </Grid>    
        </Grid>
    </Grid>
    <Divider></Divider>
    
    <Grid item xs={12}>
        <Grid container className={classes.gridContainer}>
            <Grid item xs={4}>
                <Box lineHeight={2} textAlign="left"  fontSize='subtitle.fontSize'>OrderBy Column</Box>
                <FormControl variant="outlined" className={classes.formControl}>
                    <Select
                        native
                        onChange={handleOrderByChange}
                        classes={{root: classes.select}}
                        value={orderBy}
                        >
                            {columns.map(item => { return !item.disableSort ? <option key={item.field} value={item.field}>{item.label}</option> : ''})}
                    </Select>
                </FormControl>
            </Grid>

            <Grid item xs={4}>
                <Box lineHeight={2} textAlign="left"  fontSize='subtitle.fontSize'>Direction</Box>
                <FormControl variant="outlined" className={classes.formControl}>
                    <Select
                        native
                        onChange={handleOrderByDirectionChange}
                        value={orderByDirection}
                        classes={{root: classes.select}}
                        >
                        <option key="desc" value="desc">Desc</option>
                        <option key="asc" value="asc">Asc</option>
                    </Select>
                </FormControl>
            </Grid>

            <Grid item xs={4}>
                <Box lineHeight={2} textAlign="left"  fontSize='subtitle.fontSize'>Page Size</Box>
                <FormControl variant="outlined" className={classes.formControlLast}>
                    <Select
                        native
                        onChange={handlePageSizeChange}
                        value={pageSize}
                        classes={{root: classes.select}}
                        >
                            {rowsPerPageOptions.map(item => { return <option key={item} value={item}>{item}</option>})}
                    </Select>
                </FormControl>
            </Grid>
        </Grid>
    </Grid>

    <Grid item xs={12}>
        <Grid container className={classes.gridContainer}>
            <Grid item xs={8}></Grid>
            <Grid item xs={4}>
                <Button variant="contained" color="primary" size="small" className={classes.button} onClick={onClearClick} >Clear</Button>
                <Button variant="contained" color="primary" size="small" className={classes.button} onClick={onApplyClick} >Apply</Button>
            </Grid>
        </Grid>
    </Grid>
    </div>
</Grid>

    // <Grid container className={classes.root}>
    //     <div className={classes.popperPaper}>
    //         <Grid item md={12}>
    //             <Grid container className={classes.gridContainer}>
    //                 <Grid item xs={6}>
    //                     <Button component="span" className={classes.button} onClick={onClearClick} >Clear</Button>
    //                 </Grid>
    //                 <Grid item xs={6}>
    //                     <Button component="span" className={classes.button} onClick={onApplyClick} >Apply</Button>
    //                 </Grid>
    //             </Grid>
    //         </Grid>
    //         <Divider></Divider>
    //         <Grid item xs={12}>
    //             <Grid container className={classes.gridContainer}>
    //                 <Grid item xs={3}>
    //                 <Box lineHeight={2} textAlign="left" fontWeight="fontWeightBold" fontSize='h5.fontSize'>Folder Name</Box>
    //                 </Grid>
    //                 <Grid item xs={9}>
    //                     <OutlinedInput margin='dense' value={folderName} onChange={event =>handleChange(event, 'folderName')}  classes={{root: classes.textBox}} variant='outlined'></OutlinedInput>
    //                 </Grid>
    //             </Grid>
    //         </Grid>
           
    //         <Grid item xs={12}>
    //             <Grid container className={classes.gridContainer}>
    //                 <Grid item xs={3}>
    //                 <Box lineHeight={2} textAlign="left" fontWeight="fontWeightBold" fontSize='h5.fontSize'>Report Name</Box>
    //                 </Grid>
    //                 <Grid item xs={9}>
    //                     <OutlinedInput margin='dense' value={reportName} onChange={event =>handleChange(event, 'reportName')} classes={{root: classes.textBox}} variant='outlined'></OutlinedInput>
    //                 </Grid>
    //             </Grid>
    //         </Grid>

    //         {/* <Grid item xs={12}>
    //             <Grid container className={classes.gridContainer}>
    //                 <Grid item xs={3}>
    //                 <Box lineHeight={2} textAlign="left" fontWeight="fontWeightBold" fontSize='h5.fontSize'>Batched By</Box>
    //                 </Grid>
    //                 <Grid item xs={9}>
    //                     <OutlinedInput margin='dense' value={batchedBy} onChange={event =>handleChange(event, 'batchedBy')} classes={{root: classes.textBox}} variant='outlined'></OutlinedInput>
    //                 </Grid>
    //             </Grid>
    //         </Grid> */}

    //         <Grid item xs={12}>
    //         <MuiPickersUtilsProvider utils={MomentUtils}>
    //             <Grid container className={classes.gridContainer}>
    //                 <Grid item xs={3}>
    //                     <Box lineHeight={2} textAlign="left" fontWeight="fontWeightBold" fontSize='h5.fontSize'>Batched On</Box>
    //                 </Grid>
    //                 <Grid item xs={4}>
    //                 <KeyboardDatePicker
    //                         autoOk
    //                         variant="inline"
    //                         inputVariant="outlined"
    //                         placeholder="From"
    //                         format="MM/DD/YYYY"
    //                         margin="dense"
    //                         value={batchStartdate}
    //                         classes={{root: classes.dense}}
    //                         InputAdornmentProps={{classes: {root: classes.dense, positionEnd: classes.endAdornment}}}
    //                         onChange={date => setBatchStartdate(date)}
    //                     />
    //                 </Grid>
    //                 <Grid item xs={1}></Grid>
    //                 <Grid item xs={4}>
    //                     <KeyboardDatePicker
    //                         autoOk
    //                         variant="inline"
    //                         inputVariant="outlined"
    //                         placeholder="To"
    //                         format="MM/DD/YYYY"
    //                         margin="dense"
    //                         disableFuture
    //                         value={batchEnddate}
    //                         classes={{root: classes.dense}}
    //                         InputAdornmentProps={{classes: {root: classes.dense, positionEnd: classes.endAdornment}}}
    //                         onChange={date => setBatchEnddate(date)}
    //                     />
    //                 </Grid>
    //             </Grid>
    //             </MuiPickersUtilsProvider>
    //         </Grid>
    //         <Grid item xs={12}>
    //             <Grid container className={classes.gridContainer}>
    //                 <Grid item xs={3}>
    //                     <Box lineHeight={2} textAlign="left" fontWeight="fontWeightBold" fontSize='h5.fontSize'>Sort / Limit</Box>
    //                 </Grid>
    //                 <Grid item xs={4}>
    //                 <FormControl variant="outlined" className={classes.formControl}>
    //                     <Select
    //                     native
    //                     onChange={handleOrderByChange}
    //                     classes={{root: classes.select}}
    //                     value={orderBy}
    //                     >
    //                         {columns.map(item => { return !item.disableSort ? <option key={item.field} value={item.field}>{item.label}</option> : ''})}
    //                     </Select>
    //                 </FormControl>
    //                 </Grid>
    //                 <Grid item xs={2}>
    //                 <FormControl variant="outlined" className={classes.formControlLast}>
    //                     <Select
    //                     native
    //                     onChange={handleOrderByDirectionChange}
    //                     value={orderByDirection}
    //                     classes={{root: classes.select}}
    //                     >
    //                     <option key="desc" value="desc">Desc</option>
    //                     <option key="asc" value="asc">Asc</option>
    //                     </Select>
    //                 </FormControl>
    //                 </Grid>
    //                 <Grid item xs={1}>
    //                     <Typography variant='h3'>/</Typography>
    //                 </Grid>
    //                 <Grid item xs={2}>
    //                 <FormControl variant="outlined" className={classes.formControlLast}>
    //                     <Select
    //                     native
    //                     onChange={handlePageSizeChange}
    //                     value={pageSize}
    //                     classes={{root: classes.select}}
    //                     >
    //                         {rowsPerPageOptions.map(item => { return <option key={item} value={item}>{item}</option>})}
    //                     </Select>
    //                 </FormControl>
    //                 </Grid>
    //             </Grid> 
    //         </Grid>
    //         </div>
    //     </Grid>
        );
}

AdvancedSearchForm.propTypes = {
    rowsPerPageOptions: PropTypes.array,
    columns: PropTypes.array.isRequired,
    handleClose: PropTypes.func
};