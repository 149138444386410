import React, { useEffect, useState } from 'react';
import useApi from 'components/Api/Api';

import PropTypes from 'prop-types';
import clsx from 'clsx';
import ApplicationContext from 'context/ApplicationContext';

// @material-ui/core components
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Button, Avatar, Badge, Popper, ClickAwayListener, Typography, Link } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

import DialogTitle from '@material-ui/core/DialogTitle';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';

import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

import { Impersonation } from 'components';

const useStyles = makeStyles(theme => ({

  root: {
    display: 'flex',
    marginRight: theme.spacing(1.5),
    padding: theme.spacing(1),
    position: 'relative',
    //height: 64,
    borderRadius: 0,  
  },

  active: {
    backgroundColor: '#32404E',
    '&:hover': {
      backgroundColor: '#32404E'  
    }
  },

  formControl: {
    margin: theme.spacing(3)
  },

  popper: {
    backgroundColor: 'transparent',
    zIndex: 1000
  },
  paper: {
    margin: theme.spacing(0),
    backgroundColor: '#fff'
  },

  container: {
    width: '100%',
    padding: 0
  },
  nameContainer: {
    padding: 15
  },
  name: {
    color: '#000000'
  },
  notYou:{
    color:'#C6C6C6',
    fontStyle: 'italic'
  }, 
  logout: {
    color:'#4E106F',
    fontStyle: 'italic',
    paddingLeft:'3px'
  },
  dialogTitleFont: {
    color: '#000000',
    fontWeight: '500',
    padding: '2px 15px',
    fontSize: 24
  },
  avatar: {
    color: '#fff',
    backgroundColor: theme.palette.primary.main,
    width: theme.spacing(4),
    height: theme.spacing(4)
  }
}));

const SmallAvatar = withStyles(theme => ({
  root: {
    width: 16,
    height: 16,
    border: `2px solid ${theme.palette.background.paper}`,
  },
}))(Avatar);

UserAvatar.propTypes = {

};

export default function UserAvatar(props) {

  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = useState(false);
  const buttonRef = React.useRef(null);
  const { appInfo } = React.useContext(ApplicationContext);
  const user = appInfo.user;
  const [openPrefs, setOpenPrefs] = React.useState(false);
  const [openMsg, setOpenMsg] = React.useState(false);
  const [valuePrefsDB, setValuePrefsDB] = React.useState('0');
  const [valuePrefs, setValuePrefs] = React.useState('0');
  const { showPreferences= false } = props;

  // Get Email Notification Preference
  const api = useApi();
  // useEffect(() => {
  //   api.getUserPreference().then(res => {
  //     setValuePrefsDB(res.data.emailOption);
  //     setValuePrefs(res.data.emailOption);
  //   })
  // }, []);

  const handleClickOpenPrefs = () => {
    api.getUserPreference().then(res => {
      setValuePrefsDB(res.data.emailOption);
      setValuePrefs(res.data.emailOption);
    });
    setOpenPrefs(true);
  };

  const handleClosePrefs = () => {
    setValuePrefs(valuePrefsDB);
    setOpenPrefs(false);
  };

  const handleApplyPrefs = () => {
    // Save Email Notification Preference
    let option = {emailOption: valuePrefs}
    api.saveUserPreference(option);
    setValuePrefs(valuePrefsDB);
    setOpenPrefs(false);
    setOpenMsg(true);
  }

  const handleClick = event => {
    if (anchorEl) {
      setAnchorEl(null);
    } else {
      setAnchorEl(buttonRef.current);
    }
  };

  const handleCloseAway = event => {

    if (!openPrefs) {
      setAnchorEl(null);
    }

  };

  const handleCloseMsg = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenMsg(false);
  };
  
  const handleChange = event => {
    setValuePrefs(event.target.value);
  };

  useEffect(() => {
    setOpen(Boolean(anchorEl));
  }, [anchorEl])
 
  const onComputesStyle = data => {
    let parent=buttonRef.current;
    data.styles.top = parent.offsetHeight;
    data.styles.left = window.innerWidth-data.popper.width;

    return data;
  };

  const getAvatarChar = () => {
    var name = user.firstName;
    return name ? name.substring(0, 1) : '';
  }

  const getAvatarTooltip = () => {
    var toolTip = '';
    if (user) {
      if (user.isAuthenticated) {
        if (user.isImpersonating) {
          toolTip = <React.Fragment><b>{'You are currently impersonating... '}</b><br/><b>{'User:'} </b>{user.impersonateAsUser}<br/><b>{'Company:'} </b>{user.impersonateAsCompany}<br/><b>{'Department:'} </b>{user.impersonateAsDepartment}<br/></React.Fragment>;
        }
        else {
          toolTip = <React.Fragment><b>{'You are currently logged in... '}</b><br/><b>{'User:'} </b>{user.username}<br/><b>{'Company:'} </b>{user.company}<br/><b>{'Department:'} </b>{user.department}<br/></React.Fragment>;
        }
      }
    }
    return toolTip;
  }

  return (
    <>
      <IconButton ref={buttonRef} onClick={handleClick} disableRipple className={classes.root}  >
        <Badge overlap="circle" badgeContent={<SmallAvatar />} invisible={!user.isImpersonating}>
          <Avatar className={classes.avatar}>{getAvatarChar()}</Avatar>
        </Badge>
      </IconButton>

      <ClickAwayListener onClickAway={handleCloseAway}>
        <Popper 
          className={classes.popper} 
          open={open} 
          anchorEl={anchorEl}
          placement='bottom-end'
        >
          <Paper className={classes.paper}>
            <div className={classes.container}>
              <div className={classes.nameContainer}>
                <Typography display="block" className={classes.name}>
                  Hello {user.firstName},
                </Typography>
                
                <Typography variant="caption" display="block" gutterBottom>
                  <span className={classes.notYou}> not you?</span>
                  <Link href="/account/signout" className={classes.logout}>
                    Sign out
                  </Link>
                </Typography>

                {showPreferences && <Button variant="outlined" color="primary" onClick={handleClickOpenPrefs}>
                  Preferences
                </Button>}
              </div>
              <div>
                {(user.canImpersonate) ? <Impersonation /> : ''}
              </div>
            </div>
          </Paper>

          <Dialog open={openPrefs} onClose={handleClosePrefs} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title" className={classes.dialogTitleFont}>Edit Client Center Preferences</DialogTitle>
            <DialogContent>
              <FormControl component="fieldset" className={classes.formControl}>
                <FormLabel component="legend">File Posted Notification:</FormLabel>
                <RadioGroup aria-label="prefs" name="preferences" value={valuePrefs} onChange={handleChange}>                
                  <FormControlLabel key="emailPref_0" value="0" control={<Radio color="primary"/>} label="Don't notify" />
                  <FormControlLabel key="emailPref_1" value="1" control={<Radio color="primary"/>} label="Notify when my files are posted" />
                </RadioGroup>
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClosePrefs} color="primary">
                Cancel
              </Button>
              <Button onClick={handleApplyPrefs} color="primary">
                Apply
              </Button>
            </DialogActions>
          </Dialog>

        </Popper>
      </ClickAwayListener>

      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={openMsg}
        autoHideDuration={3000}
        onClose={handleCloseMsg}
        message="User Preferences Successfully Saved"
        action={
          <React.Fragment>
            <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseMsg}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />

    </>
  );
}