//core components
import React, {useState} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
//material ui components
import { makeStyles, lighten } from '@material-ui/core/styles';
import { Typography, Tooltip, IconButton, Button, Grid, Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';
import { GetApp as BatchIcon, Cached as RefreshIcon, Delete } from '@material-ui/icons';
//custom components
import { GridContext, SearchContext } from 'context';
import { SelectedSearches } from 'components';
import ConfirmDialog from './ConfirmDialog';

const useStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    flexGrow: 10,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    color: theme.palette.primary.main,
    backgroundColor: lighten(theme.palette.secondary.light, 0.75),
  },
  icons: {
    display:'flex',
  },
  icon: {
    padding: '1px 5px 1px 1px',
    fontSize: '23px',
  },
  title: {
    paddingRight: theme.spacing(3),
    fontSize: '12px',
  },
  deleteBtn: {
      backgroundColor: theme.palette.npd.orange,
      '&:hover':{
        backgroundColor: theme.palette.npd.red,
      }
  }
}));

export default function EnhancedTableToolbar(props) {
  const classes = useStyles();
  const { multidownload, refresh, multidelete} = props;
  const {rowsSelected, setRowsSelected} = React.useContext(GridContext);
  const [openDialog, setOpenDialog] = React.useState(false);
  const searchContext = React.useContext(SearchContext);


  const GenerateSelectedSearches = () => {
    let searches = [];

    if (searchContext.reportName !== undefined && searchContext.reportName !== '')
      searches.push({ key: "Report Name", value: searchContext.reportName, handleDelete: () => { searchContext.setReportName(''); } });

    if (searchContext.folderName !== undefined && searchContext.folderName !== '')
      searches.push({ key: "Folder Name: ", value: searchContext.folderName, handleDelete: () => { searchContext.setFolderName(''); } });

    if (searchContext.batchStatus !== undefined && searchContext.batchStatus.scheduleStatus !== undefined && searchContext.batchStatus.scheduleStatus !== 0)
      searches.push({ key: "Status: ", value: searchContext.batchStatus.displayName, handleDelete: () => { searchContext.setBatchStatus({ scheduleStatus: 0, displayName: "" }); } });

    if (searchContext.batchedBy !== undefined && searchContext.batchedBy !== '')
      searches.push({ key: "Batched By: ", value: searchContext.batchedBy, handleDelete: () => { searchContext.setBatchedBy(''); } });

    if (searchContext.format !== undefined && searchContext.format.reportOutputTypeId !== undefined && searchContext.format.reportOutputTypeId !== 0)
      searches.push({ key: "Format: ", value: searchContext.format.reportOutputType, handleDelete: () => { searchContext.setFormat({ reportOutputTypeId: 0, reportOutputType: "" }); } });

    if (searchContext.batchEnddate !== undefined && searchContext.batchEnddate !== null)
      searches.push({ key: "Batch To: ", value: searchContext.batchEnddate.format("MM/DD/YYYY"), handleDelete: () => { searchContext.setBatchEnddate(null); } });

    if (searchContext.batchStartdate !== undefined && searchContext.batchStartdate !== null)
      searches.push({ key: "Batch From: ", value: searchContext.batchStartdate.format("MM/DD/YYYY"), handleDelete: () => { searchContext.setBatchStartdate(null); } });
    return searches;
  }

  const getSeletedReports = () =>  {
      return (<><span>Are you sure you want to delete these reports?</span><ul> {rowsSelected.map(r => <li>{r.reportName}</li>)} </ul></>);
  }

  const onRefresh = () => 
  {
    if (isFunction(refresh))
    {
        refresh();
        setRowsSelected([]);
    }
  }

  const handleMultiDelete = () => {
    multidelete(); 
    setOpenDialog(false)
  }

  const isFunction = functionToCheck => {
    return functionToCheck && {}.toString.call(functionToCheck) === '[object Function]';
  }

  return (
   
      <Grid container className={clsx(classes.root, classes.highlight)}>
        <Grid item>
          <SelectedSearches searches={GenerateSelectedSearches()}></SelectedSearches>
        </Grid>
        <Grid item className={classes.icons}>
            {(rowsSelected.length > 0) &&
                <Typography className={classes.title} color='primary' variant="subtitle1">
                    {rowsSelected.length} item(s) selected
                </Typography>
            }
            {/* spans are for disabled tooltips */}
            <Tooltip title="Download Selected Files">
                <span><IconButton className={classes.icon} onClick={() => multidownload()} aria-label="download" color="primary" disabled={rowsSelected.length === 0}>
                    <BatchIcon className={classes.icon} />
                </IconButton></span>
            </Tooltip>              
            <Tooltip title="Refresh">
                <span><IconButton className={classes.icon} onClick={onRefresh} aria-label="refresh" color="primary">
                    <RefreshIcon className={classes.icon} />
                </IconButton></span>
            </Tooltip>
            <Tooltip title="Delete Selected Files">
                <span><IconButton className={classes.icon} onClick={()=> setOpenDialog(true)} aria-label="delete" color="primary" disabled={rowsSelected.length === 0}>
                    <Delete className={classes.icon} />
                </IconButton></span>
            </Tooltip>
        </Grid>
        <ConfirmDialog 
          openDialog={openDialog} 
          confirmBody={getSeletedReports}
          okHandler={handleMultiDelete}
          cancelHandler={() => {setOpenDialog(false)}}
          >
        </ConfirmDialog>
        {/* <Dialog open={openDialog}>
            <DialogTitle>
                Are you sure?
            </DialogTitle>
            <DialogContent>
                Are you sure you want to delete these reports:
                {getSeletedReports()}
            </DialogContent>
            <DialogActions>
                <Button disableElevation color="primary" variant="contained" size="small" className={classes.deleteBtn} onClick={handleMultiDelete}>Yes</Button>
                <Button disableElevation color="primary" variant="contained" size="small" onClick={() => setOpenDialog(false)}>Cancel</Button>
            </DialogActions>
        </Dialog> */}
      </Grid>
  );
};

EnhancedTableToolbar.propTypes = {
  refresh: PropTypes.func.isRequired,
  multidownload: PropTypes.func.isRequired
};