import React from 'react';
import {IconButton, Typography, Chip, Box} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import {Clear as ClearIcon} from '@material-ui/icons'

const useStyles = makeStyles(theme => ({
    
  }));

export default function SelectedSearches(props){
    const { searches, prefixText = "Selected Searches:" } = props;
    const classes = useStyles();
    
    const handleClick = () => {

    }
    
    return(
        <table className={classes.refineTable}>
        <tbody>
            {
                searches.length > 0 &&
                <tr key="selectedSearches">
                    <td colSpan={3} className={classes.refineTableCol3}><Typography>Selected Searches</Typography></td>
                </tr>  
            }
            {searches.length > 0 && searches.map((item) =>
                <tr key={item.key}>
                <td className={classes.refineTableCol1}>{<IconButton className={classes.iconButton} size='small' aria-label="remove" onClick={() => item.handleDelete()}><ClearIcon style={{ fontSize: 12 }} /></IconButton>}</td>
                <td className={classes.refineTableCol2}>{item.key}</td>
                <td className={classes.refineTableCol3}>{item.value}</td>
                </tr>
            )}
        </tbody>
      </table>

    //    <React.Fragment>
    //    {searches.length > 0 ? <Box lineHeight={2} style={{paddingRight:5}} textAlign="left" fontSize='subtitle.fontSize'>{prefixText}</Box> : <React.Fragment/>}
    //    {
    //        searches.map(item => <Chip key={item.displayName} size='small' variant='outlined' label={item.displayName} onClick={handleClick} onDelete={() => item.handleDelete()} />)
    //    }
    //    </React.Fragment>
    );

}