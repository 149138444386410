import React, { Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Paper, Grid, Typography} from '@material-ui/core';
import AddtoCollection from 'assets/img/add-to-collection.png';
import CollectionsLayout from './CollectionsLayout';
import OdataCollectionToolbar from '../OdataCollectionsToolbar'
import Link from '@material-ui/core/Link';
import {ToggleButton } from '@material-ui/lab';
import DownloadIcon from 'assets/img/ClicktoDownload.png';
import HelpIcon from 'assets/img/Helpicon_png.png';
//import { faInfoCircle as HelpIcon } from '@fortawesome/free-solid-svg-icons'
import WatchTutorialIcon from 'assets/img/WatchtheVideoTutorial_png.png';
import {ApplicationContext} from 'context';
import Button from '@material-ui/core/Button';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(5.6),
    marginLeft:30,
    marginRight:30
  },
  headerText: {
    fontWeight: 'bold',
    fontSize: 16,
    lineHeight: '20px',
    color: theme.palette.text.secondary
  },
  bannerSubtitle: {
    fontSize: 13,
   // textAlign: 'left',
    lineHeight: '30px',
    color: theme.palette.text.primary,

  },
  bannerSubtitle1: {
    fontSize: 13,
    textAlign: 'left',
    lineHeight: '30px',
    color: theme.palette.text.primary,
    
    paddingRight: 300,
    paddingLeft: 300,
  },

  footerText: {
    fontWeight: 300,
    fontSize: 16,
    lineHeight: '18px',
    color: theme.palette.text.secondary,
    textAlign:'center'
  },
  img:{
    paddingBottom:'px'
  },
  compoundToolbar:{
    display: 'flex',
    flexWrap: 'nowrap',
    width: '100%',
    padding: '2px',
    backgroundColor:  '#f2f8fb',
    marginLeft:30
  },  
  buttonRoot: {//
    height: 25,
    fill: '#455a64',
    borderRadius: '0px 18px 18px 0px',
    borderColor: theme.palette.grey,
    width: 130,
    fontSize: 12,
    margin:1,
    marginLeft: 1,
    textAlign: 'center'
  },
  buttonBlue: {
    height: 30,
    //fill: '#455a64',
    borderRadius: 3,
    borderColor: theme.palette.grey,
    width: 100,
    fontSize: 12,
    margin:1,
    marginLeft: 1,
    textAlign: 'center',
    backgroundColor: theme.palette.primary.main,
    fontWeight: 'bold',
    color: '#FFF',
    // width: 94,
    // height: 36,
    // fontSize: 10,
    // backgroundColor: theme.palette.primary.main,
    // marginLeft: 18,
    '&:hover': {
      fontWeight: 'bold',
      color: '#FFF',
      //width: 94,
      //height: 36,
      //fontSize: 10,
      backgroundColor: theme.palette.primary.main,
      //marginLeft: 18,
    }
  }, 
  paperLong: {
    height: 30,
    fill: '#455a64',
    width: 600,
    fontSize: 13,
    textAlign: 'left'
  }, 
  paper: {
    height: 30,
    //lineHeight: '30px',
    fill: '#455a64',
    //borderRadius: 1,
    //borderColor: theme.palette.grey,
    width: 550,
    fontSize: 13,
    //margin:1,
    //marginLeft: 1,
    textAlign: 'left'
  }, 
  shortPaper: {
    height: 30,
    //lineHeight: '30px',
    fill: '#455a64',
    width: 30,
    fontSize: 13,
    textAlign: 'left'
  }, 
  shortPaper1: {
    height: 30,
    //lineHeight: '30px',
    fill: '#455a64',
    width: 90,
    fontSize: 13,
    textAlign: 'left'
  },
  fragment:{
    height: 30,
    visibility: 'hidden',
  } ,
  shortPaper2: {
    height: 30,
    //lineHeight: '30px',
    fill: '#455a64',
    width: 450,
    fontSize: 13,
    textAlign: 'left'
  },
  shortPaper3: {
    height: 100,
    //lineHeight: '30px',
    fill: '#455a64',
    width:140,
    fontSize: 13,
    textAlign: 'center'
  },
  shortPaper4: {
    height:60,
    //lineHeight: '30px',
    fill: '#455a64',
    width: 450,
    fontSize: 13,
    textAlign: 'left',
    borderLeft: '3px solid #D3D3D3',
  },
  paperShortHight: {
    height: 10,
    //lineHeight: '30px',
 
    width: 600,
  },
  buttonNoMargin:{
    margin: 0,
  },
  
greenSubtitle:{
 color: theme.palette.npd.green,
},
solidLine: {
  borderTop: '3px solid red',
  color: '#D3D3D3',
  fill: '#D3D3D3',
  backgroundColor:  '#D3D3D3',
  borderColor:  '#D3D3D3',
}
}));


export default function CollectionsStart(props) {
  const {pillProps,messageProps} = props;
  const classes = useStyles();
  const history = useHistory();


  const helpUrl = 'https://www.npd.com';
  const tutorialUrl = 'https://www.npd.com';
  const appContext = React.useContext(ApplicationContext).appInfo;

  const handleToggleButtonChange = (event, value) => {
    history.push(`/collection?v=odata`);
      if (value !== undefined && value !== null)
    {
  
    }
  };

  const handleOpenHelp = event => {
   
    window.open(helpUrl, "_blank", appContext.help.windowOptions);
    //setOpen(false);
  };
  const handleOpenTutorial = event => {
   
    window.open(tutorialUrl, "_blank", appContext.help.windowOptions);
    //setOpen(false);
  };
  return (
    <Fragment>
    <Fragment>
      <div className={classes.compoundToolbar} >
         <OdataCollectionToolbar {...pillProps} messageProps={messageProps}
        //commands={getCommandsWithModel()}
        >
        </OdataCollectionToolbar> 
      </div>

    <CollectionsLayout allowAdd={false}> </CollectionsLayout>
    
      <Paper className={classes.root} elevation={3} >

        <Grid container direction='column' alignItems='center' spacing={1}>
          <Grid item>
          <Paper elevation={0}  className={classes.paperLong} ><span className={classes.headerText}><b>You currently have no Bundles, so let’s get started! </b></span></Paper>
          </Grid>
          <Grid item >
              <Grid container justify="center" spacing={1}>
                <Grid key='1' item>
                      <Paper elevation={0} className={classes.shortPaper}><b>1</b></Paper>
                </Grid>
                <Grid key='2' item>
                      <Paper elevation={0} className={classes.paper}>Batch your reports in <b> DecisionKey.</b></Paper>
                </Grid>  
              </Grid>          
                {/* <Paper elevation={0} className={classes.paper}><b>1.&nbsp;&nbsp;&nbsp;&nbsp;</b>Batch your reports in <b> DecisionKey.</b></Paper> */}
          </Grid>
          <Grid item>
              <Grid container justify="center" spacing={1}>
                <Grid key='1' item>
                      <Paper elevation={0} className={classes.shortPaper}><b>2.</b></Paper>
                </Grid>
                <Grid key='2' item>
                    <Paper elevation={0} className={classes.paper}>Click the above &nbsp;
                    <ToggleButton  classes={{ root: classes.buttonRoot }}  >
                    <span style={{ paddingLeft: 0 }}><b>Batch Reports</b></span>
                    </ToggleButton>	
                    &nbsp;tab to select reports. </Paper>
                </Grid>  
              </Grid>            

          </Grid>
          <Grid item>
              <Grid container justify="center" spacing={1}>
                <Grid key='1' item>
                      <Paper elevation={0} className={classes.shortPaper}><b>3.</b></Paper>
                </Grid>
                <Grid key='2' item>
                      <Paper elevation={0} className={classes.paper}>Click  <span class={classes.greenSubtitle}>&#9733; Add to Bundle</span> to create a <b>Bundle.</b></Paper>            
                </Grid>  
              </Grid>             
          
          {/* <Typography className={classes.bannerSubtitle}><b>3.&nbsp;&nbsp;&nbsp;&nbsp;</b>Click  <h1  className={classes.bannerSubtitle}> ⭐Add to Bundle </h1> to create a <b>Bundle.</b></Typography> */}
          </Grid>
          <Grid item>
              <Grid container justify="center" spacing={1}>
                <Grid key='1' item>
                      <Paper elevation={0} className={classes.shortPaper}><b>4.</b></Paper>
                </Grid>
                <Grid key='2' item>
                      <Paper elevation={0} className={classes.paper}>Click &nbsp;            
                    <ToggleButton  classes={{ root: classes.buttonBlue }}  >
                      <span style={{ paddingLeft: 0 }}><b>View Bundles</b></span>
                    </ToggleButton>	          
                    &nbsp; to display the <b>Bundle</b> list. </Paper>
                </Grid>  
              </Grid>             

          </Grid>
          <Grid item>
             <Grid container justify="center" spacing={1}>
                <Grid key='1' item>
                      <Paper elevation={0} className={classes.shortPaper}><b>5.</b></Paper>
                </Grid>
                <Grid key='2' item>
                      <Paper elevation={0} className={classes.shortPaper1}>
                      <img src={DownloadIcon}  width="90" height="40"></img>
                      </Paper>
                </Grid>  
                <Grid key='3' item>
                      <Paper elevation={0} className={classes.shortPaper2}>downloads a <b>Bundle</b> to a single MS Excel workbook.</Paper>
                </Grid>                
              </Grid>              

          </Grid>
          {/* <Grid item>
              <Grid container justify="center" spacing={1}>

                <Grid key='1' item>
                    <Paper elevation={0} className={classes.shortPaper}><b>6.</b></Paper>
                </Grid>

                <Grid key='2' item>
                   <Paper elevation={0} className={classes.paper}>Once your <b>DecisionKey</b> data has been updated, click Data > Refresh All in the workbook to update report data for the available data source(s). </Paper>
                </Grid>
              </Grid>
          </Grid> */}
          
        
          <Grid item >
              <Paper elevation={0}  className={classes.paperShortHight}></Paper>
          </Grid>
          <Grid item>
              <Paper elevation={0}  className={classes.paperLong}><hr className={classes.solidLine}/></Paper>
          </Grid>

          <Grid item>
              <Paper elevation={0}  className={classes.paperLong} ><span className={classes.headerText}><b>Need more Help?</b></span></Paper>
          </Grid> 

          <Grid item>
              <Grid container justify="center" spacing={1}>



                <Grid key='1' justify="center" item>
                   <Paper elevation={0} className={classes.shortPaper3}>
     
                    <Button className={classes.buttonNoMargin} size="small">
                    
                   <a
                      rel="noopener noreferrer"
                      onClick={handleOpenHelp}
                    >
                      <img src={HelpIcon} alt="Bundles Reference Guide" />
                  </a>                       
                  </Button> 

                        
                         <Link className={classes.bannerSubtitle} component="button"variant="body1" onClick={handleOpenHelp}>Bundles Reference Guide</Link>

                   </Paper>
                </Grid>
                <Grid key='2' item>
                   <Paper elevation={0} className={classes.shortPaper4}>
                     
                   &nbsp;&nbsp;&nbsp;
                   <Button className={classes.buttonNoMargin} size="small">
                   <a
                      rel="noopener noreferrer"
                      onClick={handleOpenTutorial}
                    >
                      <img src={WatchTutorialIcon} alt="Watch Video" />
                  </a>                           
                  </Button>
                     
                     
                     </Paper>
                </Grid>                
              </Grid>
          </Grid>

          {/* <Grid item >
           
               <Typography  className={classes.footerText}>Click the <a href="javascript:handleToggleButtonChange();"><b>Batch Reports|Ready</b></a> tab to create a new Bundle.</Typography> 
              <Typography  className={classes.footerText}><Link component="button"variant="body1" onClick={handleToggleButtonChange}>Click the <b>Batch Reports</b> tab to create a new Bundle.</Link></Typography>
         
          </Grid> */}
        </Grid>
   
      </Paper>
      
    </Fragment>
    <Paper elevation={0} className={classes.fragment}></Paper></Fragment>
  );
}
