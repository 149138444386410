import React, { useState } from 'react';
// @material-ui/core components
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Button, TextField, Switch } from '@material-ui/core';
import { ImpersonationService } from 'services/api';
import { useSnackbar } from 'notistack';

const UserCompanyToggle = withStyles((theme) => ({
  root: {
    width: 34,
    height: 18,
    padding: 0
  },
  switchBase: {
    margin: 1,
    padding: 1,
    '&$checked': {
      transform: 'translateX(16px)',
      backgroundColor: theme.palette.common.white,
      '& + $track': {
        borderRadius: 26 / 2,
        border: `1px solid #979797`,
        backgroundColor: '#fff',
        opacity: 1,
        transition: theme.transitions.create(['background-color', 'border']),
      },
    },
    '&$focusVisible $thumb': {
      color: '#FF9971',
      border: '6px solid #fff',
      boxShadow: 'none'
    },
  },
  thumb: {
    color: '#FF9971',
    width: 14,
    height: 14,
    boxShadow: 'none'
  },
  disabled: {
    '& span > span': {
      color: 'unset'
    }
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid #979797`,
    backgroundColor: '#fff',
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  checked: {
  },
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
        disabled: classes.disabled
      }}
      {...props}
    />
  );
});

const useStyles = makeStyles(theme => ({

  root: {
    padding: '10px 15px',
  },
  startImpersonating: {
    display: 'flex',
    '& span': {
      color: theme.palette.text.link,
      fontSize: 20,
    }
  },

  topLabel: {
    color: theme.palette.text.primary,
    fontSize: 12,
  },
  wrapper: {
    margin: '10px 0px'
  },
  toggleCompanyLabelSelected: {
    padding: '1px 10px 0px 0px',
    color: theme.palette.primary.main,
    fontWeight: 600
  },
  toggleCompanyLabel: {
    padding: '1px 10px 0px 0px',
    color: theme.palette.npd.gray
  },
  toggleUserLabelSelected: {
    padding: '1px 10px 0px 10px',
    color: theme.palette.primary.main,
    fontWeight: 600
  },
  toggleUserLabel: {
    padding: '1px 10px 0px 10px',
    color: theme.palette.npd.gray
  },
  inputControl: {
    width: 187,
    padding: 8,
    fontSize: 12
  },
  button: {
    minWidth: 202
  },
  disabledbutton: {
    color: `${theme.palette.white} !important`,
    backgroundColor: `${theme.palette.tableRowDivider} !important`
  },

}));

export default function ImpersonationSelection(props) {
  const { user } = props;
  const classes = useStyles();
  const [impersonationType, setImpersonationType] = useState(user.companyImpersonation ? 'company' : 'user');
  const [name, setName] = useState( user.impersonating ? (user.companyImpersonation ? user.impersonateAsCompany : user.impersonateAsUser) : '');
  const [isSaving, setIsSaving] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const onToggleChange = (event, value) => {
    if (isUserImpersonation())
      setImpersonationType('company');
    else
      setImpersonationType('user');
  }
  
  const isUserImpersonation = () => {
    return impersonationType === 'user';
  }

  const getPlaceholder = () => {
    return impersonationType === 'user' ? 'add username' : 'add company or company/department';
  }

  const onChangeName = (event) => {
    setName(event.target.value);
  }

  const onKeyPress = (e) => {
    if (e.key === 'Enter') {
      SetImpersonation();
    }
  }

  const SetImpersonation = () => {
    setIsSaving(true);
    if (isUserImpersonation()) {
      var msg = 'Impersonating user failed.';
      ImpersonationService.impersonateUser(name).then(response => {
        if (response.status === 200)
          window.parent.location = window.parent.location.href;
        else
          impersonationFailed(msg);
      }).catch(e => {
        impersonationFailed(msg);
      });
    }
    else {
      var msg = 'Impersonating company failed.';
      ImpersonationService.impersonateCompany(name).then(response => {
        if (response.status === 200)
          window.parent.location = window.parent.location.href;
        else
          impersonationFailed(msg);
      }).catch(e => {
        impersonationFailed(msg);
      });
    }
  }

  const impersonationFailed = (msg) => {
    enqueueSnackbar(msg, { variant: 'warning' });
    setIsSaving(false);
  }

  return (
    <div className={classes.root}>
      <div className={classes.topLabel}>
        Select user/company to impersonate
      </div>

      <div className={classes.wrapper} style={{ display: 'flex' }}>
                    <div className={(!isUserImpersonation() ? classes.toggleCompanyLabelSelected : classes.toggleCompanyLabel)}>Company</div>
                    <UserCompanyToggle checked={isUserImpersonation()} onChange={onToggleChange} name="userCompanyToogle" disabled={isSaving} />
                    <div className={(isUserImpersonation() ? classes.toggleUserLabelSelected : classes.toggleUserLabel)}>User</div>
                  </div>

      <div className={classes.wrapper}>
        <TextField variant="outlined" id="name" name="name" value={name} disabled={isSaving} onKeyPress={onKeyPress} placeholder={getPlaceholder()}
                      InputProps={{
                        classes: {
                          input: classes.inputControl
                        }
                      }} onChange={onChangeName} />
      </div>
      <div className={classes.wrapper}>
      <Button variant='contained' color='primary' size='small' classes={{ root: classes.button, disabled: classes.disabledbutton }} disabled={isSaving} onClick={() => SetImpersonation()} >Start Impersonating</Button>
      </div>
    </div>
  );
}
