import React from 'react';
//material ui components
import { IconButton, Tooltip } from '@material-ui/core';
import {GetApp as DownloadIcon, Error } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import {useApi} from 'components'

const useStyle = makeStyles(theme => ({
    root: {
        padding: '0 2px',
        fontSize: '1rem',
        '& > span > svg':{
            fontSize: '1.2rem',
        }
    },
    colorError: {
        color: theme.palette.npd.orange
    },
    colorSuccess: {
        color: theme.palette.npd.green
    }

})); 
const renderAction = (row, action, classes, keyName) => {
    let item;
    switch (action.name) {
      case 'download':
        if (!action.showOnRow) break;
        if (!row.isExists)
            item = <Tooltip key={row[action.keyColumn] + action.name} title={row.tooltip ? row.tooltip : 'File Not Found' }>
                        <IconButton color='primary' classes={{root: classes.root, colorPrimary: classes.colorError}} size='small'>
                            <Error/>
                        </IconButton>
                    </Tooltip>
        else
        item = <Tooltip key={row[action.keyColumn] + action.name} title={action.tooltip}>
                    <IconButton classes={{root: classes.root}} size='small' onClick={() => action.handler(keyName)}>
                        <DownloadIcon/>
                    </IconButton>
                </Tooltip>
        break;
      default:
        item = <React.Fragment/>;
        break;
    };
    return item;
}

export default function ActionCell(props){
    const {keyName, row} = props;
    const api = useApi();
    const classes = useStyle();
    
    const handleDownLoadClick = documentId => {
        api.isAuthenticated().then(res => {
            if (res.data.isAuthenticated)
                api.downloadFileRepo(row.fileRepositoryId, row.token);
        });
    };
    const actions = [{name: 'download',  handler: handleDownLoadClick, tooltip: 'Click to Download', showOnRow: true}]
    
    return (
        <div>
            {actions.map(action => {
                return renderAction(row, action, classes, keyName);
            })} 
        </div>
    );

}