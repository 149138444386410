//core components
import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
//material ui components
import { makeStyles, lighten } from '@material-ui/core/styles';
import { Typography, Tooltip, IconButton, Button, Grid, Dialog, DialogTitle, DialogContent, DialogActions,DialogContentText, Select, FormControl } from '@material-ui/core';
import {GetApp as DownloadIcon,Send as BatchIcon, Today as BatchLater, CancelScheduleSend as RemoveIcon,  Cached as RefreshIcon } from '@material-ui/icons';
import { useSnackbar } from 'notistack';
//custom components
import { GridContext, SearchContext, DataSourceContext } from 'context';
import { SelectedSearches,useReportApi } from 'components';

const useStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    flexGrow: 10,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    color: theme.palette.primary.main,
    backgroundColor: lighten(theme.palette.secondary.light, 0.75),
  },
  icons: {
    display:'flex',
  },
  icon: {
    padding: '1px 5px 1px 1px',
    fontSize: '23px',
  },
  title: {
    paddingRight: theme.spacing(3),
    fontSize: '12px',
  },
  deleteBtn: {
      backgroundColor: theme.palette.npd.orange,
      '&:hover':{
        backgroundColor: theme.palette.npd.red,
      }
  },
  formControlLast:{
    //width: '100%'
},
select: {
  padding: 5,  
  fontSize: 12,
  marginTop: -2
},
}));

const formatOptions = [{name:"Formatted Excel", key: 261}, {name:"Unformatted Excel", key:1285}, {name:"CSV", key: 2}]

export default function EnhancedTableToolbar(props) {
  const classes = useStyles();
  const { multidownload, refresh, multidelete} = props;
  const {rowsSelected, setRowsSelected} = React.useContext(GridContext);
  const [openDialog, setOpenDialog] = React.useState(false);
  const searchContext = React.useContext(SearchContext);
  const [reports, setReports] = React.useState([]);
  const [action, setAction] = React.useState('');
  const gridContext = React.useContext(GridContext);
  const dataSourceContext = React.useContext(DataSourceContext);
  const [dialogText, setDialogText] = React.useState('');
  const [format, setFormat] = React.useState({name:"Formatted Excel", key: 261})
  const { enqueueSnackbar } = useSnackbar();
  const api = useReportApi()

  const GenerateSelectedSearches = () => {
    let searches = [];

    if (searchContext.reportName !== undefined && searchContext.reportName !== '')
      searches.push({ key: "Report Name", value: searchContext.reportName, handleDelete: () => { searchContext.setReportName(''); } });

    if (searchContext.folderName !== undefined && searchContext.folderName !== '')
      searches.push({ key: "Folder Name: ", value: searchContext.folderName, handleDelete: () => { searchContext.setFolderName(''); } });

    if (searchContext.batchStatus !== undefined && searchContext.batchStatus.scheduleStatus !== undefined && searchContext.batchStatus.scheduleStatus !== 0)
      searches.push({ key: "Status: ", value: searchContext.batchStatus.displayName, handleDelete: () => { searchContext.setBatchStatus({ scheduleStatus: 0, displayName: "" }); } });

    if (searchContext.batchedBy !== undefined && searchContext.batchedBy !== '')
      searches.push({ key: "Batched By: ", value: searchContext.batchedBy, handleDelete: () => { searchContext.setBatchedBy(''); } });

    if (searchContext.format !== undefined && searchContext.format.reportOutputTypeId !== undefined && searchContext.format.reportOutputTypeId !== 0)
      searches.push({ key: "Format: ", value: searchContext.format.reportOutputType, handleDelete: () => { searchContext.setFormat({ reportOutputTypeId: 0, reportOutputType: "" }); } });

    if (searchContext.batchStartdate !== undefined && searchContext.batchStartdate !== null)
      searches.push({ key: "Batch From: ", value: searchContext.batchStartdate.format("MM/DD/YYYY"), handleDelete: () => { searchContext.setBatchStartdate(null); } });

    if (searchContext.batchEnddate !== undefined && searchContext.batchEnddate !== null)
      searches.push({ key: "Batch To: ", value: searchContext.batchEnddate.format("MM/DD/YYYY"), handleDelete: () => { searchContext.setBatchEnddate(null); } });

    if (searchContext.createdStartdate !== undefined && searchContext.createdStartdate !== null)
      searches.push({ key: "Created From: ", value: searchContext.createdStartdate.format("MM/DD/YYYY"), handleDelete: () => { searchContext.setCreatedStartdate(null); } });

    if (searchContext.createdEnddate !== undefined && searchContext.createdEnddate !== null)
      searches.push({ key: "Created To: ", value: searchContext.createdEnddate.format("MM/DD/YYYY"), handleDelete: () => { searchContext.setCreatedEnddate(null); } });

    if (searchContext.lastModifiedStartdate !== undefined && searchContext.lastModifiedStartdate !== null)
      searches.push({ key: "Last Modified From: ", value: searchContext.lastModifiedStartdate.format("MM/DD/YYYY"), handleDelete: () => { searchContext.setLastModifiedStartdate(null); } });

    if (searchContext.lastModifiedEnddate !== undefined && searchContext.lastModifiedEnddate !== null)
      searches.push({ key: "Last Modified To: ", value: searchContext.lastModifiedEnddate.format("MM/DD/YYYY"), handleDelete: () => { searchContext.setLastModifiedEnddate(null); } });

    return searches;
  }

  const getSeletedReports = () =>  {
      return (<><span>Are you sure you want to delete these reports?</span><ul> {rowsSelected.map(r => <li>{r.reportName}</li>)} </ul></>);
  }

  const onRefresh = () => 
  {
    if (isFunction(refresh))
    {
        refresh();
        setRowsSelected([]);
    }
  }

  const handleMultiDelete = () => {
    multidelete(); 
    setOpenDialog(false)
  }

  const isFunction = functionToCheck => {
    return functionToCheck && {}.toString.call(functionToCheck) === '[object Function]';
  }

  const handleDialogClose = () => setOpenDialog(false);

  const handleDialogOpen = (action) => {
    setAction(action);
    const rpts = gridContext.rowsSelected.map((report) => {
      return report.reportName;      
    }); 
    setReports(rpts);
    switch(action){
        case 'batch': setDialogText('batch the following reports as '); break;
        case 'batchnextload': setDialogText('batch the following reports with every data release as '); break;
        case 'remove': setDialogText('remove the following reports from queue'); break;
        default: break;
      }

    setOpenDialog(true);
  }
  
  const handleDialogAction = () => {
    switch(action){
      case 'batch': handleBatch(); break;
        case 'batchnextload': handleBatchOnDataLoad(); break;
        case 'remove': handleUnQueue(); break;
        default: break;
    }
  }

  const batch = (data, callback) => {
    api.batchReport(data).then((res) => {
      if (callback)
          callback();
      if (res.data)
      enqueueSnackbar(res.data, { variant: 'error' });
  });
  }
    
  const handleBatch = () => {
      var rows = []
      gridContext.rowsSelected.map((row) => {
        //row.scheduleEventTypeId = 1;
        const data = {
            reportName: row.reportName,
            reportId: row.reportId,
            eventTypeId: 1,
            status: 1,
            reportOutputTypeID: row.immediateDeliveryInd ? 4 : (format.key === 2 ? 2 : 5),
            reportOutputOption: row.isFlatfile ? 9 : format.key,
            notifyUser: true,
            createdBy: row.createdBy,
            priority: 1,
            schemaId: dataSourceContext.targetEnvKey


        }
        rows.push(data);
      });

      batch(rows, refresh);
      handleDialogClose();
  };
  const handleBatchOnDataLoad = () => {
    var rows = []
    gridContext.rowsSelected.map((row) => {
      //row.scheduleEventTypeId = 2;
      const data = {
        reportName: row.reportName,
        reportId: row.reportId,
        eventTypeId: 2,
        status: 1,
        reportOutputTypeID: row.immediateDeliveryInd ? 4 : (format.key === 2 ? 2 : 5),
        reportOutputOption: row.isFlatfile ? 9 : format.key,
        notifyUser: true,
        createdBy: row.createdBy,
        priority: 1,
        schemaId: dataSourceContext.targetEnvKey
      }
      rows.push(data);
    });
    batch(rows, refresh);
    handleDialogClose();
  };
  const handleUnQueue = () => {
      gridContext.rowsSelected.map(r => {r.unqueueStatus = "1, 2, 12"});
      api.unqueueReport(gridContext.rowsSelected).then((res) => {
        handleDialogClose();
        if (refresh)
            refresh();
        if (res.data)
            enqueueSnackbar(res.data, { variant: 'error' });
    });
  };  

  return (
   
      <Grid container className={clsx(classes.root, classes.highlight)}>
        <Grid item>
          <SelectedSearches searches={GenerateSelectedSearches()}></SelectedSearches>
        </Grid>
        <Grid item className={classes.icons}>
            {(rowsSelected.length > 0) &&
                <Typography className={classes.title} color='primary' variant="subtitle1">
                    {rowsSelected.length} item(s) selected
                </Typography>
            }
            {/* spans are for disabled tooltips */}
            <Tooltip title="Refresh">
                <span><IconButton className={classes.icon} onClick={onRefresh} aria-label="refresh" color="primary">
                    <RefreshIcon className={classes.icon} />
                </IconButton></span>
            </Tooltip>
            <Tooltip title="Download Selected Files">
                <span><IconButton className={classes.icon} onClick={() => multidownload()} aria-label="download" color="primary" disabled={rowsSelected.length === 0}>
                    <DownloadIcon className={classes.icon} />
                </IconButton></span>
            </Tooltip>              

            <Tooltip title="Batch It Now">
              <span>
                <IconButton className={classes.icon} onClick={() => handleDialogOpen('batch')} aria-label="copy" color="primary" disabled={rowsSelected.length === 0}>
                    <BatchIcon className={classes.icon}/>
                </IconButton>
              </span>
          </Tooltip>
          <Tooltip title="Batch It With Every Data Release">
              <span>
                <IconButton className={classes.icon} onClick={() => handleDialogOpen('batchnextload')} aria-label="edit" color="primary" disabled={rowsSelected.length === 0}>
                    <BatchLater className={classes.icon}/>
                </IconButton>
              </span>
          </Tooltip>
          <Tooltip title="Remove from Queue">            
              <span>
                <IconButton className={classes.icon} onClick={() => handleDialogOpen('remove')} aria-label="delete" color="primary" disabled={rowsSelected.length === 0}>
                    <RemoveIcon className={classes.icon}/>
                </IconButton>
              </span>
          </Tooltip>            
{/*             <Tooltip title="Delete Selected Files">
                <span><IconButton className={classes.icon} onClick={()=> setOpenDialog(true)} aria-label="delete" color="primary" disabled={rowsSelected.length === 0}>
                    <Delete className={classes.icon} />
                </IconButton></span>
            </Tooltip> */}
        </Grid>
        <Dialog
        open={openDialog}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Are you sure?"}
          
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to {dialogText} 
          {action != "remove" &&  
            <FormControl variant="outlined" className={classes.formControlLast}>
              <Select
                  native
                  onChange={(event)=>setFormat(formatOptions.find(x => x.key.toString() === event.target.value))}
                  value={format.key}
                  classes={{root: classes.select}}
                  >
                      {formatOptions.map(item => { return <option key={item.key} value={item.key}>{item.name}</option>})}
              </Select>
            </FormControl>}? <br/>
            {reports.map((r, i) => {
              const data = <span>{i + 1}. {r} {r.isFlatfile ? "[Flatfile]" : ""}<br></br></span>;
              return data;

            })}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogAction} color="primary">
            Yes
          </Button>
          <Button onClick={handleDialogClose} color="primary" autoFocus>
            No
          </Button>
        </DialogActions>
      </Dialog>








{/*         <ConfirmDialog 
          openDialog={openDialog} 
          confirmBody={getSeletedReports}
          okHandler={handleMultiDelete}
          cancelHandler={() => {setOpenDialog(false)}}
          >
        </ConfirmDialog> */}


        {/* <Dialog open={openDialog}>
            <DialogTitle>
                Are you sure?
            </DialogTitle>
            <DialogContent>
                Are you sure you want to delete these reports:
                {getSeletedReports()}
            </DialogContent>
            <DialogActions>
                <Button disableElevation color="primary" variant="contained" size="small" className={classes.deleteBtn} onClick={handleMultiDelete}>Yes</Button>
                <Button disableElevation color="primary" variant="contained" size="small" onClick={() => setOpenDialog(false)}>Cancel</Button>
            </DialogActions>
        </Dialog> */}
      </Grid>
      
  );
};

EnhancedTableToolbar.propTypes = {
  refresh: PropTypes.func.isRequired,
  multidownload: PropTypes.func.isRequired
};