import React, {useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Tooltip, Button, MenuItem, MenuList, Slide } from '@material-ui/core';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Paper from '@material-ui/core/Paper';
import Popover from '@material-ui/core/Popover';
import {ApplicationContext} from 'context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle as HelpOutlineIcon } from '@fortawesome/free-solid-svg-icons';

const useStyles = makeStyles(theme => ({
 root: {
    display: 'flex',
    padding: 0,
    position: 'relative',
    color: theme.palette.grey[500],
    borderRadius: 0,
    textTransform: 'none',
    letterSpacing: 0,
    minWidth: 100, 
    zIndex: 1000,
  },
  active: {
    '& $icon': {
      color: theme.palette.npd.blue,
      alignItems: 'center',
      fontSize:'16px',
      lineHeight: '24px'
     }
  },
icon: {
    alignItems: 'center',
    fontSize:'16px',
    lineHeight: '24px'
  },
  item: {
   paddingTop: 0
  },
  button: {
    color: theme.palette.npd.gray,
    borderRadius: 0,
    textTransform: 'none',
    letterSpacing: 0,
    minWidth: 100,
    lineHeight: '14px',
    padding:'0 0 0 0'
  },
  buttonLabel: {
    display: 'flex',
    flexDirection: 'column'
  },
  iconWrapper: {
    alignItems: 'center',
  },
  text: {
    fontSize: 10,
    fontWeight: 500,
    textAlign: 'center',
    lineHeight: '12px',
   },  
   menuPaper: {
    display: 'flex'
   },
  popper: {
    zIndex: 1000,
    borderRadius: 0,
    fontSize:'small'
  }
 
}));


const HelpLanguages = () => {
  const appContext = React.useContext(ApplicationContext).appInfo;
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [openHelp, setOpenHelp] = useState(false);
  const [refGuide, setRefGuide] = useState("");
  const [passwordGuide, setPasswordGuide] = useState("");
  const [fcGuide, setfcGuide] = useState("");
  const [batchGuide, setBatchGuide] = useState("");
  const [selectedItem, setSelectedItem] = useState("none");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const anchorRef = React.useRef(null);

  const helpUrlHelper = {
    ccGuideCaption: 'Client Center Reference Guide',
    fcGuideCaption: 'File Center Reference Guide',
    batchGuideCaption: 'Batch Reference Guide',
    passGuideCaption: 'Reset Password Guide',
    refGuide: 0,
    passGuide: 1,
    fileCenterGuide: 2,
    batchGuide: 3
  };

  

  const handleToggle = () => {
    setOpen(open => !open);
  };

  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
        return;
    }
    setOpen(false);
    setOpenHelp(false);
    setSelectedItem("none");
  };

  const getMenuList = () => {
      
    return (<MenuList autoFocusItem={open} id="menu-list-grow">
      {
        appContext.user.hasCCAccess  && 
        <MenuItem className={classes.text} onClick={handleGuidesOpen} value={helpUrlHelper.refGuide}>{helpUrlHelper.ccGuideCaption}</MenuItem>
      }
      {
        appContext.user.hasFCAccess &&
        <MenuItem className={classes.text} onClick={handleGuidesOpen} value={helpUrlHelper.fileCenterGuide}>{helpUrlHelper.fcGuideCaption}</MenuItem>
      }
      {
        appContext.user.hasFCAccess &&
        <MenuItem className={classes.text} onClick={handleGuidesOpen} value={helpUrlHelper.batchGuide}>{helpUrlHelper.batchGuideCaption}</MenuItem>
      }
      <MenuItem className={classes.text} onClick={handleGuidesOpen} value={helpUrlHelper.passGuide}>{helpUrlHelper.passGuideCaption}</MenuItem>
    </MenuList>)
  }
  
  const handleOpenHelp = (event, value) => {
    setOpenHelp(false);
    setTimeout(() => {
      setOpenHelp(true);
    }, 100);
    
    setRefGuide(appContext.help[value].urls[helpUrlHelper.refGuide]);
    setfcGuide(appContext.help[value].urls[helpUrlHelper.fileCenterGuide]);
    setBatchGuide(appContext.help[value].urls[helpUrlHelper.batchGuide]);
    setPasswordGuide(appContext.help[value].urls[helpUrlHelper.passGuide]);
    setSelectedItem(value);
  };

  const handleGuidesOpen = event => {
    switch(event.target.value)
    {
      case helpUrlHelper.refGuide: 
        window.open(refGuide || appContext.help["english"].urls[helpUrlHelper.refGuide], "_blank", appContext.help.windowOptions);
        break;
      case helpUrlHelper.passGuide: 
        window.open(passwordGuide || appContext.help["english"].urls[helpUrlHelper.fileCenterGuide], "_blank", appContext.help.windowOptions);
        break;
      case helpUrlHelper.fileCenterGuide: 
        window.open(fcGuide || appContext.help["english"].urls[helpUrlHelper.batchGuide], "_blank", appContext.help.windowOptions);
        break;
      case helpUrlHelper.batchGuide: 
        window.open(batchGuide || appContext.help["english"].urls[helpUrlHelper.passGuide], "_blank", appContext.help.windowOptions);
        break;
      default: break;
    }
    setOpen(false);
    setOpenHelp(false);
    setSelectedItem("none");
  };

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = open;
  }, [open]);

  return (
    <div className={classes.root}>
        <Tooltip title="Help">
        <Button
          classes={{ label: classes.buttonLabel }}
          className={classes.button}
          ref={anchorRef}
          aria-controls={open ? 'menu-list-grow' : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
        >
            <div className={classes.icon}><FontAwesomeIcon icon={HelpOutlineIcon} /></div>
            <div className={classes.text}>Help</div>             

        </Button>
        </Tooltip>

        <Popover 
          anchorOrigin={{vertical: 'top',horizontal: 'right'}} 
          transformOrigin={{vertical: 'top', horizontal: 'left'}}
          className={classes.popper} 
          open={open} 
          anchorEl={anchorRef.current} 
          anchorPosition={{top:0, left: 100 }}
        >
          <ClickAwayListener onClickAway={handleClose}>
            <Paper className={classes.menuPaper}>
              <MenuList autoFocusItem={open} id="menu-list-grow">
                <MenuItem selected={selectedItem === "chinese"} className={classes.text} onClick={(event) => handleOpenHelp(event, "chinese")}>Chinese Reference Guides</MenuItem>
                <MenuItem selected={selectedItem === "english"} className={classes.text} onClick={(event) => handleOpenHelp(event, "english")}>English Reference Guides</MenuItem>
                <MenuItem selected={selectedItem === "french"} className={classes.text} onClick={(event) => handleOpenHelp(event, "french")}>French Reference Guides</MenuItem>
                <MenuItem selected={selectedItem === "german"} className={classes.text} onClick={(event) => handleOpenHelp(event, "german")}>German Reference Guides</MenuItem>
                <MenuItem selected={selectedItem === "italian"} className={classes.text} onClick={(event) => handleOpenHelp(event, "italian")}>Italian Reference Guides</MenuItem>
                <MenuItem selected={selectedItem === "japanese"} className={classes.text} onClick={(event) => handleOpenHelp(event, "japanese")}>Japanese Reference Guides</MenuItem>
                <MenuItem selected={selectedItem === "korean"} className={classes.text} onClick={(event) => handleOpenHelp(event, "korean")}>Korean Reference Guides</MenuItem>
                <MenuItem selected={selectedItem === "portugese"} className={classes.text} onClick={(event) => handleOpenHelp(event, "portugese")}>Portuguese Reference Guides</MenuItem>
                <MenuItem selected={selectedItem === "russian"} className={classes.text} onClick={(event) => handleOpenHelp(event, "russian")}>Russian Reference Guides</MenuItem>
                <MenuItem selected={selectedItem === "spanish"} className={classes.text} onClick={(event) => handleOpenHelp(event, "spanish")}>Spanish Reference Guides</MenuItem>
              </MenuList>
              <Slide timeout={100} direction="right" in={openHelp} mountOnEnter unmountOnExit>
                {getMenuList()}
              </Slide>
            </Paper>
          </ClickAwayListener>
        </Popover>
      </div>

  );
}

export default  HelpLanguages;
