import React, { useState } from 'react';
import {SearchContext} from 'context';
import {useApi} from 'components'
import {Search as SearchBase} from 'Topbar/Topbar/components'

const searchTypes = [{Name: 'Report Name', id: 0}];

export default function Search(props) {
    const [inputTerm, setInputTerm] = useState('');
    const [searchType, setSearchType] = useState(0);
    const handleSearchTypeChange = event => { setSearchType(event.target.value); };
    const searchContext = React.useContext(SearchContext);
    const api = useApi();
    const handleTextBoxOnChange = (e, v) => {
        setInputTerm(e.target.value);
    };

    const handleAutoCompleteValueOnChange = (e, v) => {
        setInputTerm(e.target.value);
        if (typeof v === 'object') {
            setInputTerm(v.keyword);
        }
        handleSearch(v.keyword, v.searchType);
    };

    const handleOnEnter = event => {
        if (event.key === 'Enter') {
        searchContext.setReportName(event.target.value);
        }
    };

    const handleSearch = (term, stype) => {
        if (typeof term === 'object') {
            term = null;
        }

        var searchIcon = document.getElementById('searchIcon');
        if (Boolean(searchIcon)) {
            searchIcon.focus();
        }

        if (term !== undefined || inputTerm.length > 0) {
            searchContext.setReportName(term || inputTerm);
            searchContext.setPage(1);
        }  
    };

  return (
    <SearchBase 
        inputTerm={inputTerm}
        setInputTerm={setInputTerm}
        searchType={searchType}
        searchTypes={searchTypes} 
        handleAutoCompleteValueOnChange={handleAutoCompleteValueOnChange}
        handleOnEnter={handleOnEnter}
        handleTextBoxOnChange={handleTextBoxOnChange}
        handleOnEnter={handleOnEnter}
        GetAutoSuggestOptions={api.getBatchAutoSuggest}
        handleSearchTypeChange={handleSearchTypeChange}
        handleSearch={handleSearch}
        />
    
  );
}


Search.propTypes = {
};
