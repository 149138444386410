import React, {useState, useEffect} from 'react'
import {useApi} from 'components'
import {ApplicationContext} from 'context'
import { makeStyles } from '@material-ui/core/styles';
import { LinearProgress } from '@material-ui/core';
import ImpersonateService from 'services/api/Impersonation';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

const windowRedirect = () => {
    let win = window.top || window,
      redirectPath = win.location.origin;

    redirectPath = redirectPath + '/home';
    win.location.assign(redirectPath);

  }

  
export default function Auth(props) {
    const { children, ...rest } = props;
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [appInfo, setAppInfo] = React.useState({
      user:{firstName:'', lastName: ''},
      copyright: ''
    });
    const api = useApi();
    const classes = useStyles();
     useEffect(() => {
         api.isAuthenticated().then(res => {
            const isAuthenticated = res.data.isAuthenticated;
            if (!res.data.isAuthenticated)
                windowRedirect();
            else {
              api.getAppInfo().then(res => {
                if (!res.data.user.hasAccess && !res.data.user.isImpersonating){
                  window.location = "/account/accessdenied";
                }
                  

                if (res.data.user.hasCCAccess)
                  res.data.home = "/clientcenter";
                else if (res.data.user.hasFCAccess)
                  res.data.home = "/filecenter";
                else if(res.data.user.hasFFAAccess)
                  res.data.home = "/flatfileadmintool";
                else if(res.data.user.hasCollectionsAccess)
                  res.data.home = "/workbooks";                  
                setAppInfo({...res.data });
                setIsAuthenticated(isAuthenticated);
                
              });
            }
        });
     }, []);

     return isAuthenticated ? <ApplicationContext.Provider value={{appInfo}}>{children}</ApplicationContext.Provider> : <div className={classes.root}><LinearProgress/> </div> ;
}
