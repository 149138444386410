//core components
import React, {useState}  from 'react'
import PropTypes from 'prop-types';
import clsx from 'clsx';
import _ from 'lodash';
//material UI components
import { withStyles } from '@material-ui/core/styles';
import { Paper, Table, TableBody } from '@material-ui/core';

//custom components
import { EnhancedTableHeader, CustomTablePagination, TableData, LoadingTable, NoData } from './components';
import GridContext from 'context/GridContext'

export const styles = theme => (
  {

    /* Styles applied to the root element. */
    root: {
      display: 'table',
      width: '100%',
      borderCollapse: 'collapse',
      borderSpacing: 0,
      marginLeft: 0,
      '& caption': {
        ...theme.typography.body2,
        padding: theme.spacing(2),
        color: theme.palette.text.secondary,
        textAlign: 'left',
        captionSide: 'bottom',
      },
    },
    /* Styles applied to the root element if `stickyHeader={true}`. */
    stickyHeader: {
      borderCollapse: 'separate',
    },
    /* Styles applied to the paper wrapper of the table. */
    paper: {
      width: '98%',
      marginLeft: 30,
      backgroundColor: 'transparent',
      boxShadow: 'none',
      marginBottom: theme.spacing(2),
      minHeight: 200,
    },

    compoundToolbar:{
      display: 'flex',
      flexWrap: 'nowrap',
      width: '100%',
      padding: '2px',
      //backgroundColor: 'black',
    },
    /* Styles applied to the table. */
    table: {
      minWidth: 750,
      
    },
    /* Styles applied to the table wrapper. */
    tableWrapper: {
      overflowX: 'auto'
    },
    /* Styles to visually hide. */
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },

    tableHead: {
      backgroundColor: 'transparent',
    },
    tableSortLabelRoot: {
    },
    tableSortLabelIcon: {
      fontSize: 14,
      backgroundColor: theme.palette.background.paper,
      border: '1px solid #979797',
      borderRadius: '100%'
    },
    tableSortLabelIconDirectionDesc: {
    },
    tableSortLabelIconDirectionAsc: {
    },

    headCellLabelSorted: {
      fontWeight: 'bold',
      color: theme.palette.primary.main
    },

    headCell: {
      fontSize: 12,
      lineHeight: '14px'
    },

    tableCell: {
      fontSize: 12,
      color: '#565A5C',
      lineHeight: '14px'
    },
    tableCellString: {
    },
    tableCellNumber: {
      textAlign: 'right'
    },
    tableCellDate: {
    },
    tableCellLink: {
      color: theme.palette.primary.main
    }
  });

const groupBy = (xs, key) => {
  return xs.reduce(function (rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

const EnhancedTable = (props) => {
  const {
    classes,
    className,
    columns,
    rows,
    rowKeyProperty,
    title,
    mode = 'local',
    defaultOrder = 'asc',
    defaultOrderBy,
    totalCount = 0,
    page = 1,
    rowsPerPage = 50,
    enablePaging = false,
    onChangePage,
    onChangeRowsPerPage,
    onRequestSort,
    toolbar,
    customHeaderComponent,
    groupKey,
    ActionCell,
    actionCallback,
    noLeadingCheckbox,
    rowsPerPageOptions,
    handleReportEdit,
    loading = true,
    callerKey,
    FileCenterToolbar,
    FileCenterToolbarProps,    
    EnhancedTableToolbarProps,
    EnhancedTableToolbar,
    commands = [],
    height,
    persistSelection = false,
    isRowHighlighted = () => { return false; }
  } = props;

  const remote = mode === 'remote';
  const [order, setOrder] = React.useState(defaultOrder);
  const [orderBy, setOrderBy] = React.useState(defaultOrderBy);
  const [pageState, setPage] = React.useState(page);
  const [rowsPerPageState, setRowsPerPage] = React.useState(rowsPerPage);
  const [idsSelected, setIdsSelected] = React.useState([]);
  const { rowsSelected, setRowsSelected } = React.useContext(GridContext);

  const [selectionModel, setSelectionModel] = useState({
    selected: [],
    selectedCount: 0
  });
  // const [selectionModel, setSelectionModel] = useState({
  //   remote: remote ? rows.length < totalCount : remote,
  //   selectAll: false,
  //   selected: [],
  //   unselected: [],
  //   selectedCount: 0
  // });

  const isFunction = functionToCheck => {
    return functionToCheck && {}.toString.call(functionToCheck) === '[object Function]';
  }

  React.useEffect(() => {
    setIdsSelected(rowsSelected.map((i) => i[rowKeyProperty]));
    setSelectionModel({
      selected: rowsSelected,   
      selectedCount: rowsSelected.length     
    });  

  }, [rowsSelected]);

  React.useEffect(() => {
     setOrderBy(defaultOrderBy);
     setOrder(defaultOrder);
  }, [defaultOrderBy, defaultOrder])

  const handleRequestSort = (event, property) => {
    const hasSort = Boolean(orderBy);
    const isDesc = orderBy === property && order === 'desc';
    const newOrder = (!hasSort || isDesc) ? 'asc' : 'desc';
    const newOrderBy = (hasSort && isDesc) ? null : property;

    setOrder(newOrder);
    setOrderBy(newOrderBy);

    if (remote && onRequestSort !== null && isFunction(onRequestSort)) {
      onRequestSort({
        order: newOrder,
        orderBy: newOrderBy
      });
    }
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);

    if (remote && onChangePage !== null && isFunction(onChangePage)) {
      onChangePage(newPage);
    }
  };
  const handleChangeRowsPerPage = value => {

    setPage(1);
    let rowsPerPage = parseInt(value, 10);
    setRowsPerPage(rowsPerPage);

    if (remote && onChangeRowsPerPage !== null && isFunction(onChangeRowsPerPage)) {
      onChangeRowsPerPage(rowsPerPage);
    }
  };
   const isSelected = (id) => {
     return idsSelected.indexOf(id) !== -1
   };

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelecteds = rows.map(n => n[rowKeyProperty]);
      setIdsSelected(newSelecteds);
      setRowsSelected(rows);
      return;
    }
    setIdsSelected([]);
    setRowsSelected([])
  };

  const getCommandsWithModel = () => {
    return commands.map(c => {
      return React.cloneElement(c, {
        selectionModel: selectionModel
      });
    });
  }

  const handleClick = (event, id) => {
    const selectedIndex = idsSelected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(idsSelected, id);
      
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(idsSelected.slice(1));
    } else if (selectedIndex === idsSelected.length - 1) {
      newSelected = newSelected.concat(idsSelected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        idsSelected.slice(0, selectedIndex),
        idsSelected.slice(selectedIndex + 1),
      );
    }
    setIdsSelected(newSelected);
    if (persistSelection)
    {
      if (selectedIndex === -1)
        setRowsSelected(rowsSelected.concat(rows.filter((r) => id === r[rowKeyProperty])));
      else
        setRowsSelected(rowsSelected.filter((r) => r[rowKeyProperty] !== id));
    }
    else {
      var selection = _.flatten(_.map(newSelected, function(item){
        return _.filter(rows, (r) =>  r[rowKeyProperty] === item );
      }));

      setRowsSelected(selection);
    }
  };
  
  let groups = [];
  if (rows !== undefined && rows.length > 0) {
    if (groupKey !== 'undefined')
      groups = groupBy(rows, groupKey);
    else
      groups = rows;
  }
  
  const showToolBar = enablePaging ||  commands.length > 0;
  const getData = (groups) => {
    let tableBody = [];
    for (var group in groups) {
      tableBody.push(<TableBody key={group} className={clsx(classes.tableBody, className)}>
        <TableData
          classes={classes}
          columns={columns}
          rows={groups[group]}
          rowKeyProperty={rowKeyProperty}
          mode={mode}
          order={defaultOrder}
          orderBy={defaultOrderBy}
          enablePaging={enablePaging}
          totalCount={mode === 'local' ? rows.length : totalCount}
          page={page}
          rowsPerPage={rowsPerPage}
          handleClick={handleClick}
          isSelected={isSelected}
          isRowHighlighted={isRowHighlighted}
          groupKey={group}
          ActionCell={ActionCell}
          actionCallback={actionCallback}
          noLeadingCheckbox={noLeadingCheckbox}
        />
      </TableBody>)
    }
    if (groups.length === 0){
      tableBody = <NoData colSpan={100} height={height-50}></NoData>
    }
    return tableBody;
  };

  return (
    <div className={clsx(classes.root, className)}>
      <Paper className={clsx(classes.paper, className)}>
        <div className={clsx(classes.compoundToolbar, className)} >
          
        {FileCenterToolbar &&
          <FileCenterToolbar {...FileCenterToolbarProps} 
            commands={getCommandsWithModel()}>
          </FileCenterToolbar>
        }
        {
          enablePaging &&
          <CustomTablePagination
            component='div'
            count={mode === 'local' ? rows.length : totalCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            toolbar={toolbar}
            rowsPerPageOptions={rowsPerPageOptions}
            
          />
        }
        </div>
        {EnhancedTableToolbar && //callerKey &&
          <EnhancedTableToolbar  {...EnhancedTableToolbarProps}/>
        }
        <div 
          className={clsx(classes.tableWrapper, className)} 
          style={{maxHeight: height, minHeight: 150}}
          >
            
          <Table stickyHeader
            className={clsx(classes.table, className)}
            aria-labelledby="tableTitle"
            aria-label={title}
          >
            <EnhancedTableHeader
              classes={classes}
              columns={columns}
              numSelected={idsSelected.length}
              rowCount={rows.length}
              onSelectAllClick={handleSelectAllClick}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              customHeaderComponent={customHeaderComponent}
              noLeadingCheckbox={noLeadingCheckbox}
            />
            {loading ? <LoadingTable colSpan={100} rowCount={25}/> : getData(groups)}
          </Table>
        </div>
      </Paper>
    </div>
  );
};

EnhancedTable.propTypes = {
  /**
   * Override or extend the styles applied to the component.
   * See [CSS API](#css) below for more details.
   */
  classes: PropTypes.object.isRequired,
  /**
   * @ignore
   */
  className: PropTypes.string,


  title: PropTypes.string.isRequired,
  rowKeyProperty: PropTypes.string.isRequired,

  columns: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string,
      type: PropTypes.oneOf(['number', 'currency', 'string', 'date', 'link', 'action', 'size', 'bool', 'selectiveLinkWithTooltip']),
      format: PropTypes.string, //Used for type number or date
      label: PropTypes.string
    })
  ).isRequired,
  rows: PropTypes.array.isRequired,

  defaultOrder: PropTypes.oneOf(['asc', 'desc']),
  defaultOrderBy: PropTypes.string,

  enablePaging: PropTypes.bool,
  mode: PropTypes.oneOf(['local', 'remote']),

  /* Server mode (remote) properties */
  onChangePage: PropTypes.func,
  onChangeRowsPerPage: PropTypes.func,
  onRequestSort: PropTypes.func,

  totalCount: PropTypes.number,
  page: PropTypes.number,
  rowsPerPage: PropTypes.number

};
export default withStyles(styles, { name: 'EnhancedTable' })(EnhancedTable);