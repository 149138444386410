import React from 'react';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useApi } from 'components';
import DataSourceContext from 'context/DataSourceContext';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';

const useStyles = makeStyles(theme => ({
  autoCompleteGroupLabel: {
    fontWeight: 600,
    borderBottom: `1px solid ${theme.palette.tableRowDivider}`,
    padding: '0px 3px',
    margin: '5px 10px',
    height: 20,
    lineHeight: '10px'
  },
  autoCompleteGroupList: {

  },
  autoCompleteItem: {
    color: theme.palette.primary.main,
    width: '100%',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap'
  },
  autoCompleteOption: {
    fontSize: 12,
    margin: 0,
    padding: '1px 10px'
  },
  paper: {
    borderRadius: '0px 0px 4px 4px',
    margin: '1px 0',
    '& > ul':
    {
      maxHeight: '50vh'
    },
    boxShadow: theme.palette.boxShadow
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
    fontSize: 14
  },
  inputRoot: {
    paddingRight: 0
  }
}));



function SearchAutocomplete(props) {
  const {
    inputTerm,
    options,
    setOptions,
    handleOnEnter,
    handleTextBoxOnChange,
    handleSearchRedirect,
    handleAutoCompleteValueOnChange,
    handleOnFocus,
    handleOnBlur,
    GetAutoSuggestOptions,
    optionKey = 'keyword',
    optionId = 'id'
  } = props;
  const classes = useStyles();
  const context = React.useContext(DataSourceContext);
  const service = useApi();
  let initialValue = inputTerm;

  React.useEffect(() => {
    if (inputTerm === '') {
      setOptions([]);
      handleSearchRedirect(inputTerm, '')
      return undefined;
    }

    if (inputTerm.length > 3) {
      GetAutoSuggestOptions(inputTerm)
        .then(response => {
          const res = response.data;
          var result = [];
          Array.prototype.forEach.call(res, item => {
            result.push({ keyword: item[optionKey], id: item[optionId] })
          });
          //setOptions([{}]);
          setOptions([...result]);
        });
    }
    else {
      setOptions([]);
    }
  }, [inputTerm]);

  return (
    <Autocomplete
      id="basicSearch"
      style={{ flex: 1, display: 'flex' }}
      //getOptionLabel={option => (typeof option === 'string' ? option : option.description)}
      filterOptions={x => x}
      options={options}
      autoComplete={true}
      clearOnEscape={true}
      freeSolo
      disableOpenOnFocus
      disableClearable
      inputValue={initialValue}
      onChange={handleAutoCompleteValueOnChange}
      classes={{ paper: classes.paper, input: classes.input, inputRoot: classes.inputRoot, option: classes.autoCompleteOption, groupLabel: classes.autoCompleteGroupLabel, groupUl: classes.autoCompleteGroupList }}
      renderInput={params => (
        <TextField
          {...params}
          InputProps={{ ...params.InputProps, disableUnderline: true }}
          inputProps={{ ...params.inputProps, maxLength: 1000, 'aria-label': 'search' }}
          placeholder='Search'
          onKeyPress={handleOnEnter}
          onFocus={handleOnFocus}
          onBlur={handleOnBlur}
          fullWidth
          onChange={handleTextBoxOnChange}
          classes={{ root: classes.autoCompleteRoot }}
        />
      )}
      getOptionLabel={(x) => x.keyword ? x.keyword : ''}
      renderOption={option => {
        const matches = match(option.keyword, inputTerm);
        const parts = parse(option.keyword, matches);

        return (
          <div onClick={() => {
            handleSearchRedirect(option.keyword, option.id)
          }} className={classes.autoCompleteItem}>
            {parts.map((part, index) => (
              <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                {part.text}
              </span>
            ))}
          </div>
        );
      }}
    />
  );
}

export default SearchAutocomplete;