import React, { useState, useEffect, useRef } from 'react'

//material ui components
import { makeStyles } from '@material-ui/core/styles';
//custom components
import Toolbar from './OdataCollectionsToolbar';
import {FileCenterEnhancedToolbar} from 'views/FileCenter';
import {ExceloData, Collection} from './';
import {DataSourceContext} from 'context'
import { Typography } from '@material-ui/core';
const useStyles = makeStyles(theme => ({
  root: {
    width: '98%',
    marginLeft: 10,
    backgroundColor: theme.palette.contentBackground,
    margin: 0
},
  content: {
    padding: 1,
  },
  /* Styles applied to the Toolbar component. */
  toolbar: {
    height: 24,
    paddingLeft: theme.spacing(3)
  },
  /* Styles applied to the spacer element. */
  spacer: {
    flex: '1 1 100%',
  },
  buttonIcon: {
    marginLeft: 10,
    fill: theme.palette.npd.gray,
    '&:disabled': {
      fill: theme.palette.primary.main
    },
    '&:hover': {
      fill: theme.palette.primary.main,
      backgroundColor: 'none'
    },
  },
  message: {
    paddingLeft: 20,
    alignSelf: 'center',
    color: theme.palette.text.primary,
    fontWeight:'bold'
  },
  greenMessage: {
    paddingLeft: 20,
    alignSelf: 'center',
    color: theme.palette.npd.green,
    fontWeight:'bold'
  }
}));

function FileCenter() {
  const [dataSourceSelectedKey, setDataSourceSelectedKey] = React.useState('');
  const classes = useStyles();
  const [view, setView] = useState('collection');

  const appKey = 'fc';
  document.title = "Bundles";

  const isFirstRun = useRef(true);
  useEffect(() => {
    //dont log the default toggle state
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }
  }, [view]);

  return (
    <React.Fragment>
        {(view == 'collection') ?
            <DataSourceContext.Provider value={{dataSourceSelectedKey, setDataSourceSelectedKey, appKey:'wc'}}>
              <Collection 
                  Toolbar={Toolbar}
                  pillProps = {{setView:setView, view:view,  messageComponent: <Typography className={classes.greenMessage}>Click the 'Batch Reports' tab to create a new Bundle. To modify an existing Bundle, create a new Bundle.</Typography>}}
                  EnhancedToolbar={FileCenterEnhancedToolbar}
              /> 
            </DataSourceContext.Provider> :
            <DataSourceContext.Provider value={{dataSourceSelectedKey, setDataSourceSelectedKey, appKey}}>
              <ExceloData 
                  Toolbar={Toolbar}
                  toolbarProps = {{setView:setView, view:view,  messageComponent: <Typography className={classes.greenMessage}>Files in this list are your Batch Reports with Status = Ready. To group reports into a Bundle, select reports below then click 'Add to Bundle'.</Typography>}}
                  EnhancedToolbar={FileCenterEnhancedToolbar}
              />
            </DataSourceContext.Provider>
        }
    </React.Fragment>
  )
}

export default FileCenter;