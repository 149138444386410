import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types'
import clsx from 'clsx';
import {useApi} from 'components';

import {Search as SearchBase} from 'Topbar/Topbar/components'
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Button, IconButton, Select, MenuItem, Divider } from '@material-ui/core';
import { Close,ArrowDropDown as ArrowDropDownIcon, Cached } from '@material-ui/icons';
import { SearchContext } from 'context';
import SearchIcon from '@material-ui/icons/Search';

const useAutocompleteStyle = makeStyles(theme => ({
  inputRoot: {
    height: 32,
    paddingRight: 0
  },
  input: {
    height: 12,
    marginLeft: theme.spacing(1),
    flex: 1,
    fontSize: 14,
  }
}))

const useStyles = makeStyles(theme => ({
  root: {
    width: 460,
    margin: '0 0 0 30px',
    padding: 0,
    border: '1px solid #D8D8D8',
    borderRadius: theme.shape.borderRadius,
    height: 33,

    '&:hover': {
      borderColor: theme.palette.primary.main,
    }
  },

  active: {
    borderColor: theme.palette.primary.main
  },

  hr: {
    margin: 0,
    height: 20,
  },
  iconButton: {
    marginRight: 3,
    marginLeft: 5,
    padding: '1px 1px',
    minWidth: 30,
    boxShadow: 'none',
    borderRadius: 2
  },
  searchIcon: {
    width: 18,

  },
  select: {
    paddingTop: 7,
    paddingLeft: theme.spacing(1),
    width: 70
  },
  selectDisplayStyle: {
    color: theme.palette.primary.main,
    fontWeight: '600',
    fontSize: 12,
  },
  selectItemStyle: {
    color: theme.palette.primary.main,
    fontWeight: '300',
    fontSize: 12,
    paddingLeft: theme.spacing(1),
    paddingTop: 0,
    paddingBottom: 0

  },
  popOverStyle: {
    width: 85,
    borderRadius: '0 0 4px 4px',
    boxShadow: '0 4px 10px -4px rgba(187,206,219,0.44)'
  },
  menuItemSelected: {
    backgroundColor: 'transparent !important;',
    fontWeight: '600',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.14) !important;'
    }
  },

  closeButton: {
    color: theme.palette.action.disabled
  }
}));

const searchTypes = [{Name: 'Title', id: 0}];

export default function Search(props) {
    const [inputTerm, setInputTerm] = useState('');
    const [searchType, setSearchType] = useState(0);
    const handleSearchTypeChange = event => { setSearchType(event.target.value); };
    const searchContext = React.useContext(SearchContext);
    const api = useApi();
    const handleTextBoxOnChange = (e, v) => {
        setInputTerm(e.target.value);
    };

    const handleAutoCompleteValueOnChange = (e, v) => {
        setInputTerm(e.target.value);
        if (typeof v === 'object') {
            setInputTerm(v.keyword);
        }
        handleSearch(v.keyword, v.searchType);
    };

    const handleOnEnter = event => {
        if (event.key === 'Enter') {
        searchContext.setSearchTitle(event.target.value);
        }
    };

    const handleSearch = (term, stype) => {
        if (typeof term === 'object') {
        term = null;
        }

        var searchIcon = document.getElementById('searchIcon');
        if (Boolean(searchIcon)) {
        searchIcon.focus();
        }

        if (term !== '' || inputTerm.length > 0) {
          searchContext.setSearchTitle(term || inputTerm);
          searchContext.setPage(1);
        }  
    };



  return (
      <SearchBase
        inputTerm={inputTerm}
        setInputTerm={setInputTerm}
        searchType={searchType}
        searchTypes={searchTypes} 
        handleAutoCompleteValueOnChange={handleAutoCompleteValueOnChange}
        handleOnEnter={handleOnEnter}
        handleTextBoxOnChange={handleTextBoxOnChange}
        GetAutoSuggestOptions = {api.getTitleAutoSuggest}
        inputClasses={useAutocompleteStyle()}
        handleSearchTypeChange={handleSearchTypeChange}
        handleSearch={handleSearch}
      />
  )
}

Search.propTypes = {
  
};
