import React, { useEffect, useState, useRef } from 'react';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
// custom components
import useApi from 'components/Api/Api'
import { EnhancedTable, useWindowDimensions } from 'components'
import { DataSourceContext, SearchContext, GridContext, ApplicationContext } from 'context'
import { Topbar } from 'Topbar'
import FileCenterToolBar from '../FileCenter/FileCenterToolBar';
import {ClientCenterEnhancedToolbar, Search, AdvancedSearchForm, ActionCell} from './Components'
import { useSnackbar } from 'notistack';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        backgroundColor: theme.palette.contentBackground,
        margin: 0
    },
    table: {
        minWidth: 500,
    },
    tableWrapper: {
        overflowX: 'auto',
    },
    message: {
      paddingLeft: 20,
      alignSelf: 'center',
      color: theme.palette.text.primary,
      fontWeight:'bold'
    }    
}));

const columns = [{field: 'title', type: 'string', label: 'Title', disableSort: false, fileNamelength: 60}, 
{ field: 'category', type: 'string', label: 'Category', disableSort: false}, 
{ field: 'format', type: 'string', label: 'Format', disableSort: false}, 
{ field: 'docDate', type: 'date', label: 'Date Released', sortField: 'Date Released', format: 'MM-DD-YYYY', disableSort: false}, 
{ field: 'size',  type: 'size',  label: 'Size',  disableSort: true}, 
{ field: 'action', type: 'action', label: '', disableSort: true}]

const rowsPerPageOptions = [10, 25, 50, 75, 100, 200, 500];

export default function ClientCenter() {
    const api = useApi();
    const appContext = React.useContext(ApplicationContext);

    const [rows, setRows] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const [totalCount, setTotalCount] = React.useState(0);
    const [page, setPage] = React.useState(1);
    const [dataSources, setDataSources] = useState([]);
    const [formatFilters, setFormatFilters] = useState([]);
    const [categoryFilters, setCategoryFilters] = useState([]);
    const [dataSourceSelectedKey, setDataSourceSelectedKey] = React.useState('');
    const [rowsSelected, setRowsSelected] = React.useState([]);

    const [searchTitle, setSearchTitle] = React.useState('');
    const [category, setCategory] = React.useState({ name: '', ids: '' });
    const [format, setFormat] = React.useState({ name: '', ids: '' });
    const [startdate, setStartdate] = React.useState(null);
    const [enddate, setEnddate] = React.useState(null);
    const [orderBy, setOrderBy] = React.useState('Date Released');
    const [orderByDirection, setOrderByDirection] = React.useState('desc');
    const [pageSize, setPageSize] = React.useState(appContext.appInfo.user.rowsPerPage || 25);
    const [filterCount, setFilterCount] = React.useState(0);
    
    const [tableView, setTableView] = useState('doc');
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();

    document.title = "Client Center";

    const onRequestSort = order => {
        setOrderBy(order.orderBy);
        setOrderByDirection(order.order)
    }

    const onChangePage = (newPage) => {
        setPage(newPage);
    }
    const onPageSizeChange = (newPageSize) => {
        setPage(1);
        api.saveRowPerPage({rowsPerPageOptions: newPageSize});
        appContext.appInfo.user.rowsPerPage = newPageSize;
        setPageSize(newPageSize);
    }

    const handleRefreshClick = () => {
        setPage(1);
        setRows([]);
        setTotalCount(0);
        setRowsSelected([]);
        loadClientCenter();
    };

    useEffect(() => {
        if (dataSourceSelectedKey !== '') {
            api.getCategories(dataSourceSelectedKey).then(res => {
                setCategoryFilters(res.data);
            });
            api.getFormats(dataSourceSelectedKey).then(res => {
                setFormatFilters(res.data);
          });
        }
      }, [dataSourceSelectedKey]);

    const loadClientCenter = () => {

        if (dataSourceSelectedKey !== "") {
            let filter = {
                dateFrom: (startdate === null || startdate === undefined) ? null : startdate.format("MM/DD/YYYY"),
                dateTo: (enddate === null || enddate === undefined) ? null : enddate.format("MM/DD/YYYY"),
                categoryIds: category.ids,
                formatIds: format.ids,
                title: searchTitle
            };
            let sortInfo = '';
            if (orderBy !== '' && orderBy !== undefined && orderBy !== null) {
                const orderCol = columns.find((item) => orderBy === item.label || orderBy === item.field).field;
                sortInfo = "[{'Property': '" + orderCol + "' , 'direction' : '" + orderByDirection + "' }]";
            }

            let option = {
                filter: filter,
                group: { "property": "summarySourceName", "direction": "asc" },
                page: page,
                pageSize: pageSize,
                sortInfo: sortInfo,
                token: dataSourceSelectedKey
            }
            setLoading(true);
            api.getDocuments(option).then(res => {
                setRows(res.data.data);
                setPage(res.data.page);
                setTotalCount(res.data.totalCount);
                setPageSize(res.data.pageSize);
                setLoading(false);
            })
        }
        
    };

    useEffect(() => {
      loadClientCenter();
      setPage(1);
    }, [dataSourceSelectedKey]);

    useEffect(() => {
        loadClientCenter();
    }, [searchTitle, startdate, enddate, orderBy, orderByDirection, pageSize, page, category.ids, format.ids]);

    const classes = useStyles();
    const  appKey = 'cc';

    const handleMultiDownloadClick = () => {
        if (rowsSelected.length > appContext.appInfo.maxMultiFiledownload)
          enqueueSnackbar("You can only download maximum of " + appContext.appInfo.maxMultiFiledownload + " files at a time.", { variant: 'warning' });
        else {
          api.isAuthenticated().then(res => {
            if (res.data.isAuthenticated) {
              let notFound = '';
              rowsSelected.map((r) => {
                if (r.isExists)
                  api.downloadCCFile(r.groupId, r.documentId, r.token, r.title)
                else if (notFound !== '')
                  notFound += ', ' + r.title;
                else
                  notFound += r.title;
              });
              if (notFound !== '')
                enqueueSnackbar("Following files were not found: " + notFound, { variant: 'warning' });
            }
          });
        }
      };    

    const handleZipMultiDownloadClick = () => {
        if (rowsSelected.length > appContext.appInfo.maxMultiFiledownload)
          enqueueSnackbar("You can only download maximum of " + appContext.appInfo.maxMultiFiledownload + " files at a time.", { variant: 'warning' });
        else {
            var notiKey = enqueueSnackbar("Processing...", { 
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right'
                }, 
                variant: 'info',
                persist: true
             });
          api.isAuthenticated().then(res => {
            if (res.data.isAuthenticated) {
              let notFound = '';
              let found = []
              rowsSelected.map((r) => {
                if (r.isExists)
                  found.push(r);
                else if (notFound !== '')
                  notFound += ', ' + r.title;
                else
                  notFound += r.title;
              });
              api.createZipFile(found).then(res => {
                  if (res.data.item1)
                    api.downloadZipFile(res.data.item2);
                    closeSnackbar(notiKey);
              });
              if (notFound !== '')
                enqueueSnackbar("Following files were not found: " + notFound, { variant: 'warning' });
            }
          });
        }
      };    
      const handleMultiDownloadClick1 = () => {
        api.isAuthenticated().then(res => {
          if (res.data.isAuthenticated){
            let notFound = [];
            rowsSelected.map((r) => {
                if (r.isExists)
                  api.downloadFileRepo(r.fileRepositoryId, r.token)
                else 
                  notFound.push(r.displayName);
              });
              if (notFound.length !== 0)
                enqueueSnackbar("Following files were not found: " + notFound.toString(), { variant: 'warning' });
          }
        });
      }    
    return (
        <SearchContext.Provider
            value={{ searchTitle, setSearchTitle, filterCount, setFilterCount, category, setCategory, format, setFormat, startdate, setStartdate, enddate, setEnddate, orderBy, setOrderBy, orderByDirection, setOrderByDirection, pageSize, setPageSize, page, setPage }}>
            <DataSourceContext.Provider value={{ dataSources, dataSourceSelectedKey, setDataSourceSelectedKey, appKey}}>
                <GridContext.Provider value={{ rowsSelected, setRowsSelected }}>
                    <Grid item>
                        <Topbar
                            brandName="Client Center"
                            Search={Search}
                            AdvancedSearchForm={AdvancedSearchForm}
                            AdvancedSearchFormProps = {{formats: formatFilters, categories: categoryFilters, columns: columns, rowsPerPageOptions: rowsPerPageOptions}}
                        ></Topbar>
                    </Grid>
                    <Grid item>
                        <EnhancedTable
                            title="test"
                            rowKeyProperty="documentId"
                            enablePaging={true}
                            mode='remote'
                            page={page}
                            rowsPerPage={pageSize}
                            columns={columns}
                            classes={classes}
                            rows={rows}
                            groupKey='summarySourceName'
                            groupKeyId='groupId'
                            ActionCell={ActionCell}
                            totalCount={totalCount}
                            onChangePage={onChangePage}
                            onChangeRowsPerPage={onPageSizeChange}
                            onRequestSort={onRequestSort}
                            rowsPerPageOptions={rowsPerPageOptions}
                            defaultOrder={orderByDirection}
                            defaultOrderBy={orderBy}
                            loading={loading}
                            height={useWindowDimensions().height - 185}
                            FileCenterToolbar={FileCenterToolBar}
                            FileCenterToolbarProps={{refresh: loadClientCenter, tableView: tableView, setTableView: setTableView, messageComponent: <Typography className={classes.message}>Select one or more of your files to download.</Typography>}}
                            EnhancedTableToolbar={ClientCenterEnhancedToolbar}
                            EnhancedTableToolbarProps={{refresh: handleRefreshClick, multidownload: appContext.appInfo.user.enableZipCC ? handleZipMultiDownloadClick : handleMultiDownloadClick}}
                            persistSelection={true}
                        />
                    </Grid>
                </GridContext.Provider>
            </DataSourceContext.Provider>
        </SearchContext.Provider>
    );
}
