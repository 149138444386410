import React from 'react'
import clsx from 'clsx';
import MomentUtils from '@date-io/moment';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {Close as CloseIcon} from '@material-ui/icons'
import {Box, OutlinedInput, Button, Divider, Grid, Select, FormControl, IconButton, Typography} from '@material-ui/core';
import {MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import {useApi} from 'components'
import {SearchContext} from 'context'

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
      },
      typography: {
      padding: 3
    },
    iconOpenRoot:{
        color: theme.palette.common.white,
        backgroundColor: theme.palette.npd.darkBlue,
        borderRadius: 0,
        height: 48,
        width: 48,
        '&:hover':{
            color: theme.palette.common.white,
            backgroundColor: theme.palette.npd.darkBlue,
        }
    },
    iconRoot:{
        height: 48,
        width: 48,
    },
    button:{
        margin: 10
    },
    gridContainer:{
        padding: '3px 10px',
    },
    popperPaper:{
        width: 600,
        height: 540,
        padding: theme.spacing(1),
        whiteSpace: 'nowrap',
        marginBottom: theme.spacing(1),
    },
    textBox: {
        height:30,
        width: '100%',
        fontSize: 12,
        '&:input':{
            padding: "0px 5px"
        }
    },
    formControl:{
        width: '100%',
    },
    formControlLast:{
        width: '100%'
    },
    select: {
        padding: 8,
        fontSize: 12,
        width: '100%'
    },
    dense: {
        padding: 0,
        margin: 0,
        paddingRight: 2,
        width: '50%',
        '& > div > input':{
            padding: 8,
            fontSize: 12
        }
    },
    endAdornment: {
        padding: 0,
        '& > button':{
            padding: 0
        }
    },
    adornedEnd: {
        padding: 0
    },
    flexWrap: {
        display: 'flex',
        flexWrap: 'wrap'
    }
  }));

const checkBoxOptions = [{name: "True"},{name: "False"},{name: ""}];
const defaultDelimiterOptions = [{name: 'Comma (,)', value: ','},
                                {name: 'Pipe (|)',value: '|'},
                                {name: 'SemiColon (;)', value: ';'},
                                {name: 'TAB', value: '\t'},
                                {name: '', value: ''}];

export default function AdvancedSearchForm(props){
    const classes = useStyles();
    const api = useApi();
    const {handleClose} = props;
    const searchContext = React.useContext(SearchContext);

    const [moveItFilters, setMoveItFilters] = React.useState([]);
    const [statusFilters, setStatusFilters] = React.useState([]);

    const [reportName, setReportName]  = React.useState(searchContext.reportName);
    const [folderName, setFolderName]  = React.useState(searchContext.folderName);
    const [createdBy, setCreatedBy] = React.useState(searchContext.createdBy);
    const [zipped, setZipped] = React.useState(searchContext.zipped);
    const [lf, setLf] = React.useState(searchContext.lf);
    const [unSuppressAttributes, setUnSuppressedAttributes] = React.useState(searchContext.unSuppressAttributes);
    const [attrAtEnd, setAttrAtEnd] = React.useState(searchContext.attrAtEnd);
    const [delimiter, setDelimiter] = React.useState(searchContext.delimiter);
    const [appendDate, setAppendDate] = React.useState(searchContext.appendDate);
    const [appendSchema, setAppendSchema] = React.useState(searchContext.appendSchema);
    const [immediateDeliveryInd, setImmediateDeliveryInd] = React.useState(searchContext.immediateDeliveryInd);
    const [status, setStatus] = React.useState(searchContext.status);
    const [moveItShare, setMoveItShare] = React.useState(searchContext.moveItShare);
    const [batchStartdate, setBatchStartdate] = React.useState(searchContext.batchStartdate);
    const [batchEnddate, setBatchEnddate] = React.useState(searchContext.batchEnddate);
    
    React.useEffect(() => {
        api.getMoveItFilters().then(res => {
            if (res && res.data)
                setMoveItFilters(res.data);
        });
    }, []);

    React.useEffect(() => {
        api.getStatusFilters().then(res => {
            if (res && res.data)
                setStatusFilters(res.data);
        });
    }, []);

    const onApplyClick = () => {
        setFilters(false);
        handleClose();
    }
    
    const onClearClick = () => {
        setCreatedBy('');
        setReportName('');
        setFolderName('');
        setZipped('');
        setLf('');
        setAppendDate('');
        setAppendSchema('');
        setUnSuppressedAttributes('');
        setAttrAtEnd('');
        setImmediateDeliveryInd('');
        setStatus({scheduleStatus: 0, displayName: ""});
        setDelimiter('');
        setMoveItShare('');
        setBatchEnddate(null);
        setBatchStartdate(null);
        setFilters(true);
    }
    const handleChange = (event, columnName) => {
        var value = event.target.value;
        switch(columnName){
            case 'createdBy': setCreatedBy(value); break;
            case 'reportName': setReportName(value); break;
            case 'folderName': setFolderName(value); break;
        }
    }

    const setFilters = (shouldClear) => {
        if (shouldClear){
            searchContext.setReportName('');
            searchContext.setFolderName('');
            searchContext.setCreatedBy('');
            searchContext.setZipped('');
            searchContext.setLf('');
            searchContext.setAppendDate('');
            searchContext.setAppendSchema('');
            searchContext.setDelimiter('');
            searchContext.setImmediateDeliveryInd('');
            searchContext.setStatus({scheduleStatus: 0, displayName: ""});
            searchContext.setMoveItShare('');
            searchContext.setBatchStartdate(null);
            searchContext.setBatchEnddate(null);
            searchContext.setUnSuppressedAttributes('');
            searchContext.setAttrAtEnd('');
        }
        else{
            searchContext.setReportName(reportName);
            searchContext.setFolderName(folderName);
            searchContext.setCreatedBy(createdBy);
            searchContext.setZipped(zipped);
            searchContext.setLf(lf);
            searchContext.setAppendDate(appendDate);
            searchContext.setAppendSchema(appendSchema);
            searchContext.setImmediateDeliveryInd(immediateDeliveryInd);
            searchContext.setStatus(status);
            searchContext.setDelimiter(delimiter);
            searchContext.setMoveItShare(moveItShare);
            searchContext.setBatchStartdate(batchStartdate);
            searchContext.setBatchEnddate(batchEnddate);
            searchContext.setUnSuppressedAttributes(unSuppressAttributes)
            searchContext.setAttrAtEnd(attrAtEnd);
            searchContext.setPage(1);
        }
    }
    return (
    <Grid container className={classes.root}>
        <div className={classes.popperPaper}>
            <Grid item xs={12}>
                <Grid container className={classes.gridContainer}>
                    <Grid item xs={11}>
                        
                    </Grid>
                    <Grid item xs={1}>
                        <IconButton onClick={handleClose}>
                            <CloseIcon/>
                        </IconButton>
                    </Grid>
                </Grid>
            </Grid>
            <Divider></Divider>
            <Grid item xs={12}>
            <Grid container>
            <Grid item xs={6}>
                <Grid container>
                    <Grid item xs={12} className={classes.gridContainer}>
                        <Box lineHeight={2} textAlign="left" fontSize='subtitle.fontSize'>Report Name</Box>
                        <OutlinedInput margin='dense' value={reportName} onChange={event =>handleChange(event, 'reportName')}  classes={{root: classes.textBox}} variant='outlined'></OutlinedInput>
                    </Grid>

                    <Grid item xs={12} className={classes.gridContainer}>
                        <Box lineHeight={2} textAlign="left"  fontSize='subtitle.fontSize'>Immediate Delivery</Box>
                            <FormControl variant="outlined" className={classes.formControl}>
                                <Select
                                    native
                                    onChange={(event) => setImmediateDeliveryInd(event.target.value)}
                                    classes={{root: classes.select}}
                                    value={immediateDeliveryInd}
                                    >
                                        { checkBoxOptions.map(item => <option key={item.name} value={item.name}>{item.name}</option>)}
                                </Select>
                            </FormControl>
                    </Grid>

                    <Grid item xs={12} className={classes.gridContainer}>
                        <Box lineHeight={2} textAlign="left"  fontSize='subtitle.fontSize'>Append Date</Box>
                        <FormControl variant="outlined" className={classes.formControl}>
                            <Select
                                native
                                onChange={(event) => setAppendDate(event.target.value)}
                                classes={{root: classes.select}}
                                value={appendDate}
                                >
                                    { checkBoxOptions.map(item => <option key={item.name} value={item.name}>{item.name}</option>)}
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} className={classes.gridContainer}>
                        <Box lineHeight={2} textAlign="left"  fontSize='subtitle.fontSize'>Append Schema</Box>
                        <FormControl variant="outlined" className={classes.formControl}>
                            <Select
                                native
                                onChange={(event) => setAppendSchema(event.target.value)}
                                classes={{root: classes.select}}
                                value={appendSchema}
                                >
                                    { checkBoxOptions.map(item => <option key={item.name} value={item.name}>{item.name}</option>)}
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} className={classes.gridContainer}>
                        <Box lineHeight={2} textAlign="left"  fontSize='subtitle.fontSize'>Delimiter</Box>
                        <FormControl variant="outlined" className={classes.formControl}>
                            <Select
                                native
                                onChange={(event) => setDelimiter(event.target.value)}
                                classes={{root: classes.select}}
                                value={delimiter}
                                >
                                    { defaultDelimiterOptions.map(item => { return item.name !== '' ? <option key={item.value} value={item.value}>{item.name}</option> : <option style={{fontSize: '1px'}} disabled key={item.value} value={item.value}>{item.name}</option>})}
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} className={classes.gridContainer}>
                        <Box lineHeight={2} textAlign="left"  fontSize='subtitle.fontSize'>Move It Share</Box>
                        <FormControl variant="outlined" className={classes.formControl}>
                            <Select
                                native
                                onChange={(event) => setMoveItShare(event.target.value)}
                                classes={{root: classes.select}}
                                value={moveItShare}
                                >
                                    { moveItFilters.map(item => <option key={item} value={item}>{item}</option>)}
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} className={classes.gridContainer}>
                        <Box lineHeight={2} textAlign="left"  fontSize='subtitle.fontSize'>Batched On</Box>
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                <KeyboardDatePicker
                                        autoOk
                                        variant="inline"
                                        inputVariant="outlined"
                                        placeholder="From"
                                        format="MM/DD/YYYY"
                                        margin="dense"
                                        value={batchStartdate}
                                        classes={{root: classes.dense}}
                                        inputProps={{classes:{adornedEnd: classes.adornedEnd}}}
                                        InputAdornmentProps={{classes: {root: classes.dense, positionEnd: classes.endAdornment}}}
                                        onChange={date => setBatchStartdate(date)}
                                    />
                                </MuiPickersUtilsProvider>
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                <KeyboardDatePicker
                                    autoOk
                                    variant="inline"
                                    inputVariant="outlined"
                                    placeholder="To"
                                    format="MM/DD/YYYY"
                                    margin="dense"
                                    value={batchEnddate}
                                    classes={{root: classes.dense}}
                                    InputAdornmentProps={{classes: {root: classes.dense, positionEnd: classes.endAdornment}}}
                                    onChange={date => setBatchEnddate(date)}
                                />
                                </MuiPickersUtilsProvider>
                    </Grid>

                </Grid>
            </Grid>
            <Grid item xs={6}>
                <Grid container>
                <Grid item xs={12} className={classes.gridContainer}>
                        <Box lineHeight={2} textAlign="left" fontSize='subtitle.fontSize'>Folder Name</Box>
                        <OutlinedInput margin='dense' value={folderName} onChange={event =>handleChange(event, 'folderName')}  classes={{root: classes.textBox}} variant='outlined'></OutlinedInput>
                    </Grid>
                    
                    <Grid item xs={12} className={classes.gridContainer}>
                        <Box lineHeight={2} textAlign="left" fontSize='subtitle.fontSize'>Created By</Box>
                        <OutlinedInput margin='dense' value={createdBy} onChange={event =>handleChange(event, 'createdBy')} classes={{root: classes.textBox}} variant='outlined'></OutlinedInput>
                    </Grid>

                    <Grid item xs={12} className={classes.gridContainer}>
                        <Box lineHeight={2} textAlign="left"  fontSize='subtitle.fontSize'>Line Feed (LF)</Box>
                        <FormControl variant="outlined" className={classes.formControl}>
                            <Select
                                native
                                onChange={(event) => setLf(event.target.value)}
                                classes={{root: classes.select}}
                                value={lf}
                                >
                                    { checkBoxOptions.map(item => <option key={item.name} value={item.name}>{item.name}</option>)}
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} className={classes.gridContainer}>
                        <Box lineHeight={2} textAlign="left"  fontSize='subtitle.fontSize'>Zipped</Box>
                        <FormControl variant="outlined" className={classes.formControl}>
                            <Select
                                native
                                onChange={(event) => setZipped(event.target.value)}
                                classes={{root: classes.select}}
                                value={zipped}
                                >
                                    { checkBoxOptions.map(item => <option key={item.name} value={item.name}>{item.name}</option>)}
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} className={classes.gridContainer}>
                        <Box lineHeight={2} textAlign="left"  fontSize='subtitle.fontSize'>Attributes At End</Box>
                        <FormControl variant="outlined" className={classes.formControl}>
                            <Select
                                native
                                onChange={(event) => setAttrAtEnd(event.target.value)}
                                classes={{root: classes.select}}
                                value={attrAtEnd}
                                >
                                    { checkBoxOptions.map(item => <option key={item.name} value={item.name}>{item.name}</option>)}
                            </Select>
                        </FormControl>
                    </Grid>
                    
                    <Grid item xs={12} className={classes.gridContainer}>
                        <Box lineHeight={2} textAlign="left"  fontSize='subtitle.fontSize'>UnSuppress Attributes</Box>
                        <FormControl variant="outlined" className={classes.formControl}>
                            <Select
                                native
                                onChange={(event) => setUnSuppressedAttributes(event.target.value)}
                                classes={{root: classes.select}}
                                value={unSuppressAttributes}
                                >
                                    { checkBoxOptions.map(item => <option key={item.name} value={item.name}>{item.name}</option>)}
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} className={classes.gridContainer}>
                        <Box lineHeight={2} textAlign="left"  fontSize='subtitle.fontSize'>Status</Box>
                        <FormControl variant="outlined" className={classes.formControl}>
                            <Select
                                native
                                onChange={(event) => setStatus(statusFilters.find(i => i.scheduleStatus.toString() === event.target.value.toString()))}
                                classes={{root: classes.select}}
                                value={status.scheduleStatus}
                                >
                                    { statusFilters.map(item => <option key={item.scheduleStatus} value={item.scheduleStatus}>{item.displayName}</option>)}
                            </Select>
                        </FormControl>
                    </Grid>

                    
                </Grid>
                    
            </Grid>    
        </Grid>
    </Grid>
    <Divider></Divider>
        <Grid item xs={12}>
            <Grid container className={classes.gridContainer}>
                <Grid item xs={8}></Grid>
                <Grid item xs={4}>
                    <Button variant="contained" color="primary" size="small" className={classes.button} onClick={onClearClick} >Clear</Button>
                    <Button variant="contained" color="primary" size="small" className={classes.button} onClick={onApplyClick} >Apply</Button>
                    {/* <Button variant="contained" color="primary" size="small" onClick={handleClose} >Cancel</Button> */}
                </Grid>
            </Grid>
        </Grid>
    </div>
</Grid>);
}

AdvancedSearchForm.propTypes = {
    handleClose: PropTypes.func
};