import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

// components
import BackgroundImage from 'assets/img/collection-background.jpg';


import DataSourceContext from 'context/DataSourceContext';
import { default as ViewLayout } from 'layouts/Layout';
//services
import { useCollectionService } from 'components';
//import { captureEvent } from 'utils';
//import FileSaver from 'file-saver';


const useStyles = makeStyles(theme => ({
  banner: {
    backgroundPosition: '0% 70%',
    backgroundImage: `url(${BackgroundImage})`
  },
}
));

export default function CollectionsLayout(props) {
  const { setLoading, selectionModel, ...rest } = props;
  const context = React.useContext(DataSourceContext);
  const classes = useStyles();
  const service = useCollectionService();

  const handleExport = (option) => {
    setLoading(true);
    var param = {};
    param.list = (option.id === 1 && selectionModel.selected) ? selectionModel.selected : []
    service.exportCollections(param).then(response => {
      const fileNameHeader = "x-bookscan-filename";
      const suggestedFileName = response.headers[fileNameHeader];
      const effectiveFileName = (suggestedFileName === undefined ? "my_collections.xls" : suggestedFileName);
      // Let the user save the file.
      //FileSaver.saveAs(new Blob([response.data]), effectiveFileName);
      setLoading(false);
    });
    //captureEvent({ category: 'Collections', action: 'Global Export', label: option.label + ' - ' + option.format });
  };


  return (
  
    <ViewLayout
      breadcrumbText={'My Bundles'}
      breadcrumbLink={`/collections?ds=${context.dataSourceSelectedKey}`}
      bannerClass={classes.banner}
      bannerTitle={'Welcome to Bundles'}
      bannerSubtitle={'Bundles enables you to group together and download DecisionKeyÒ Batch Reports within or across all data sources you have access to. Reports are downloaded to a single MS Excel workbook.'}
      exportTypes={[
        { id: 1, format: 'xlsx', label: 'Export Selected Rows', predicate: () => !(selectionModel.selectedCount > 0) },
        { id: 2, format: 'xlsx', label: 'Export All' }
      ]}
      onExport={handleExport}
      showAdd={false}
      exportButtonProps={{
        styleProp: { top: '-16px', marginRight: 10, backgroundColor: 'transparent' },
        iconStyleProp: { height: 34 }
      }}      
      {...rest}></ViewLayout>

  );
}

CollectionsLayout.propTypes = {
  children: PropTypes.element,
  title: PropTypes.string,
  dataSourceWeek: PropTypes.string,
  showDataSourceTooltip: PropTypes.bool,
  showBreadcrumb: PropTypes.bool,
  showDivider: PropTypes.bool,
  showToolbar: PropTypes.bool,
  allowAdd: PropTypes.bool
};
