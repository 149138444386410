//core components
import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useSnackbar } from 'notistack';
//material ui components
import { makeStyles, lighten } from '@material-ui/core/styles';
import { Typography, Tooltip, IconButton, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, Grid } from '@material-ui/core';
import {Send as BatchIcon, Today as BatchLater, CancelScheduleSend as RemoveIcon, Edit as EditIcon, Cached as RefreshIcon} from '@material-ui/icons';
//custom components
import {GridContext, DataSourceContext, SearchContext} from 'context';
import {useApi, SelectedSearches} from 'components';

const useStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    //flexGrow:10,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
    //height: 24,
  },
  highlight:{
    color: theme.palette.primary.main,
    backgroundColor: lighten(theme.palette.secondary.light, 0.75),
    //height: 24,
  },
  icon: {
    padding:'1px 5px 1px 1px',
    //margin: '0px 3px 3px 3px',
    fontSize:'23px',
    //lineHeight: '24px'
  },    
  title: {
    paddingRight: theme.spacing(3),
    fontSize:'12px',
    //flex: '1 1 100%',
  },
  alignRight: {
    textAlign: 'end'
  },
}));

export default function EnhancedTableToolbar(props) {
  const classes = useStyles();
  const { numSelected, handleEdit, refresh } = props;
  const [dialogText, setDialogText] = React.useState('');
  const [reports, setReports] = React.useState([]);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [action, setAction] = React.useState('');
  const gridContext = React.useContext(GridContext);
  const dataSourceContext = React.useContext(DataSourceContext);
  const searchContext = React.useContext(SearchContext);
  const { enqueueSnackbar } = useSnackbar();
  const api = useApi()


  const handleDialogClose = () => setOpenDialog(false);

  const handleDialogOpen = (action) => {
    setAction(action);
    const rpts = gridContext.rowsSelected.map((report) => {
      return report.reportName;      
    }); 
    setReports(rpts);
    switch(action){
        case 'batch': setDialogText('batch the following reports'); break;
        case 'batchnextload': setDialogText('batch the following reports with every data release'); break;
        case 'remove': setDialogText('remove the following reports from queue'); break;
        default: break;
      }

    setOpenDialog(true);
  }
  
  const handleDialogAction = () => {
    switch(action){
      case 'batch': handleBatch(); break;
        case 'batchnextload': handleBatchOnDataLoad(); break;
        case 'remove': handleUnQueue(); break;
        default: break;
    }
  }

  const batch = (data, callback) => {
    api.batchReport(data).then((res) => {
      if (callback)
          callback();
      if (res.data)
      enqueueSnackbar(res.data, { variant: 'error' });
  });
  }
    
  const handleBatch = () => {
      var rows = []
      gridContext.rowsSelected.map((row) => {
        row.scheduleEventTypeId = 1;
        const data = {
            reportName: row.reportName,
            reportId: row.reportId,
            module: row.moduleName,
            summarySourceTypeId: row.summarySourceTypeId,
            scheduleEventId: row.scheduleEventId,
            scheduleEventTypeId: row.scheduleEventTypeId,
            immediateDeliveryInd: row.immediateDeliveryInd,
            summarySchemaName: row.summarySchemaName,
            limitEndDateId: row.limitEndDateId,
            timePeriodKey: row.timePeriodKey,
            schemaId: dataSourceContext.targetEnvKey
        }
        rows.push(data);
      });

      batch(rows, refresh);
      handleDialogClose();
  };
  const handleBatchOnDataLoad = () => {
    var rows = []
    gridContext.rowsSelected.map((row) => {
      row.scheduleEventTypeId = 2;
      const data = {
          reportName: row.reportName,
          reportId: row.reportId,
          module: row.moduleName,
          summarySourceTypeId: row.summarySourceTypeId,
          scheduleEventId: row.scheduleEventId,
          scheduleEventTypeId: row.scheduleEventTypeId,
          immediateDeliveryInd: row.immediateDeliveryInd,
          summarySchemaName: row.summarySchemaName,
          limitEndDateId: row.limitEndDateId,
          timePeriodKey: row.timePeriodKey,
          schemaId: dataSourceContext.targetEnvKey
      }
      rows.push(data);
    });
    batch(rows, refresh);
    handleDialogClose();
  };
  const handleUnQueue = () => {
      gridContext.rowsSelected.map(r => {r.unqueueStatus = "1, 2, 12"});
      api.unqueueReport(gridContext.rowsSelected).then((res) => {
        handleDialogClose();
        if (refresh)
            refresh();
        if (res.data)
            enqueueSnackbar(res.data, { variant: 'error' });
    });
  };
  
  const GenerateSelectedSearches = () => {
    let searches = [];

        if (searchContext.dataSource !== '')
            searches.push({key:"Data Source:", value : searchContext.dataSource, handleDelete: () => {searchContext.setDataSource(''); }});

        if (searchContext.module !== '')
            searches.push({key:"Module:", value : searchContext.module, handleDelete: () => {searchContext.setModule(''); }});

        if (searchContext.status !== undefined && searchContext.status.scheduleStatus !== undefined && searchContext.status.scheduleStatus !== 0)
            searches.push({key:"Status: ", value: searchContext.status.displayName, handleDelete: () => {searchContext.setStatus({scheduleStatus: 0, displayName: ""}); }});  
    
    return searches;
}

  return (

    <div
      className={clsx(classes.root, classes.highlight)}
      variant='dense'
    >
      <Grid container>
        <Grid item xs={8}>
          <SelectedSearches searches={GenerateSelectedSearches()}></SelectedSearches>
        </Grid>
        <Grid item xs={2} className={classes.alignRight}>
          {numSelected > 0 ? (
                <Typography className={classes.title} color='primary' variant="subtitle1">
                  {numSelected} reports selected
                </Typography>
                  ) : (
                <Typography className={classes.title} color='primary' variant="subtitle1">
                    
                </Typography>
              )}
        </Grid>
        <Grid item xs={2} className={classes.alignRight}>
          <Tooltip title="Refresh">
              <span>
                <IconButton className={classes.icon} onClick={() => refresh()} aria-label="refresh" color="primary">
                    <RefreshIcon className={classes.icon} />
                </IconButton>
              </span>
          </Tooltip>
        </Grid>
      </Grid>
      <Dialog
        open={openDialog}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Are you sure?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to {dialogText}? <br/>
            {reports.map((r, i) => {
              const data = <span>{i + 1}. {r}<br></br></span>;
              return data;

            })}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogAction} color="primary">
            Yes
          </Button>
          <Button onClick={handleDialogClose} color="primary" autoFocus>
            No
          </Button>
        </DialogActions>
      </Dialog>

    </div>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};