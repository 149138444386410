
import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faStar as userColl, faCube as DecisionKey, faCopy as FileCenterIcon, faUsersCog as AdminToolIcon, faCogs as DeliveryConfigIcon, faHome as HomeIcon_Home, faArchive as HomeIcon} from '@fortawesome/free-solid-svg-icons'
import {ApplicationContext} from 'context';

const Pages = [
    {
        title: 'Client Center',
        href: '/clientcenter',
        icon: <FontAwesomeIcon icon={HomeIcon}/>,
        text: 'Client Center',
        isVisibleKey: ["hasCCAccess"]
    },
    {
        title: 'File Center',
        href: '/filecenter',
        icon: <FontAwesomeIcon icon={FileCenterIcon}/>,
        text: 'File Center',
        isVisibleKey: ["hasFCAccess"]
    },
    {
        title: 'Bundles',
        href: '/collection',
        icon: <FontAwesomeIcon icon={userColl}/>,
        text: 'Bundles',
        isVisibleKey: ["hasCollectionsAccess"]
    },
    {
        title: 'Flat File Admin Tool',
        href:'/flatfileadmintool',
        icon: <FontAwesomeIcon icon={AdminToolIcon}/>,
        text: 'Flat File Admin Tool',
        isVisibleKey: ["hasFFAAccess"]
    },
    {
        title: 'Immediate Delivery Configuration',
        href:'/deliveryconfig',
        icon: <FontAwesomeIcon icon={DeliveryConfigIcon}/>,
        text: 'Delivery Configuration',
        isVisibleKey: ["hasFFAAccess"]
    },
    {
        title: 'Opens the DecisionKey application in a new browser tab.',
        icon: <FontAwesomeIcon icon={DecisionKey}/>,
        text: 'DecisionKey',
        id: 'reportbuilder',
        href: '/sso/#applications/reportbuilder/',
        link: true,
        landingPage: true,
        navigation: true,
        isVisibleKey: ["hasDKIconAccess"]
}];

export default Pages;