import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import { PlayArrow as PlayArrowIcon } from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
  button: {
    padding: '4px 6px',
    minWidth: 20,
    marginRight: 3,
    backgroundColor: 'white',
    border: 'none',
    '&:disabled':
    {
      border: 'none'
    },
    '&:hover':
    {
      border: 'none'
    }
  },
  previous: {
    transform: 'scale(-1,1)'
  },
  next: {
  },

  fontSizeSmall: {
    fontSize: '16px'
  }
}));

const CustomTablePaginationActions = React.forwardRef(function TablePaginationActions(props, ref) {
  const {
    backIconButtonProps,
    count,
    nextIconButtonProps,
    onChangePage,
    page,
    rowsPerPage,
    ...other
  } = props;

  const classes = useStyles();
  const theme = useTheme();

  const handleBackButtonClick = event => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = event => {
    onChangePage(event, page + 1);
  };

  return (
    <div ref={ref} {...other}>
      <Button
        size='small'
        variant='outlined'
        onClick={handleBackButtonClick}
        disabled={page === 1}
        color='primary'
        className={clsx(theme.direction === 'rtl' ? classes.next : classes.previous, classes.button)}
        {...backIconButtonProps}
      >
        <PlayArrowIcon
          classes={{
            fontSizeSmall: classes.fontSizeSmall
          }}
          fontSize='small'
        />
      </Button >
      <Button
        size='small'
        variant='outlined'
        onClick={handleNextButtonClick}
        disabled={page  >= Math.ceil(count / rowsPerPage)}
        color="primary"
        className={clsx(theme.direction === 'rtl' ? classes.previous : classes.next, classes.button)}
        {...nextIconButtonProps}
      >
        <PlayArrowIcon
          classes={{
            fontSizeSmall: classes.fontSizeSmall
          }}
          fontSize='small'
        />
      </Button >
    </div>
  );
});

CustomTablePaginationActions.propTypes = {
  /**
   * Props applied to the back arrow [`IconButton`](/api/icon-button/) element.
   */
  backIconButtonProps: PropTypes.object,
  /**
   * The total number of rows.
   */
  count: PropTypes.number.isRequired,
  /**
   * Props applied to the next arrow [`IconButton`](/api/icon-button/) element.
   */
  nextIconButtonProps: PropTypes.object,
  /**
   * Callback fired when the page is changed.
   *
   * @param {object} event The event source of the callback.
   * @param {number} page The page selected.
   */
  onChangePage: PropTypes.func.isRequired,
  /**
   * The zero-based index of the current page.
   */
  page: PropTypes.number.isRequired,
  /**
   * The number of rows per page.
   */
  rowsPerPage: PropTypes.number.isRequired,
};

export default CustomTablePaginationActions;