import React, { Fragment } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

// @material-ui/core components
import { Typography, Grid, Divider, Button } from '@material-ui/core';
import { Add as AddIcon, Edit as EditIcon } from '@material-ui/icons';
import Fab from '@material-ui/core/Fab';
import { makeStyles } from '@material-ui/core/styles';
import { NavLink as RouterLink } from 'react-router-dom';
// components
//import { ExportButtonPro, InfoTooltip } from 'components';
import { DataSourceContext } from 'context';

//services
//import { dateFormat } from 'utils';


const useStyles = makeStyles(theme => ({
  root: {
  },
  banner: {
    height: 31,
    width: '100%',
    padding: '12px 20px',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    display: 'flex'
  },
  headerTitle: {
    fontSize: 20,
    lineHeight: '20px',
    height: 24,
    fontWeight: 'bold',
    color: theme.palette.text.dark
  },
  headerSubtitle: {
    fontSize: 12,
    lineHeight: '14px',
    fontStyle: 'italic',
    fontWeight: 'bold',
    color: theme.palette.npd.gray
  },
  buttonPanel: {
    width: '100%',
    height: 0,
    textAlign: 'right',
    paddingRight: 20
  },
  contentPanel: {
    //padding: `32px ${theme.spacing(4)}px 0 ${theme.spacing(4)}px`, <----- This is the white space culprit!
    width: '100%'
  },
  addButton: {
    color: theme.palette.white,
    width: 40,
    height: 40,
    top: '-20px',
    backgroundColor: theme.palette.npd.green,
    boxShadow: 'none',
    '&:hover':
    {
      backgroundColor: theme.palette.npd.green,
      boxShadow: 'none',
      transform: 'scale(1.2)',
      transition: 'all .3s ease-in-out'
    }
  },
  disabledButton: {
    color: `${theme.palette.white} !important`,
    backgroundColor: `${theme.palette.tableRowDivider} !important`
  },

  divider: {
    backgroundColor: 'rgba(216,216,216,0.42)'
  },

  tooltipWrapper: {
    display: 'flex',
    flexDirection: 'column',
    padding: 5,
    alignItems: 'center'
  },

  tooltip: {
    fontSize: 12,
    color: theme.palette.text.secondary
  },

  tooltipHeader: {
    fontWeight: 'bold'
  },

  breadcrumb: {
    fontSize: 12,
    fontWeight: 'bold',
    lineHeight: '14px',
    color: '#8E908F',
    textDecoration: "none"
  },
  breadcrumbArrow: {
    paddingLeft: '5px',

  },
  editButton: {

  },
  headerDivider: {
    backgroundColor: theme.palette.divider2,
    width: 2,
    margin: '0px 24px'
  }
}));

export default function LayoutCompact(props) {
  const {
    showToolbar,
    showAdd,
    allowAdd,
    children,
    title,
    subTitle,
    dataSourceWeek,
    showBreadcrumb,
    breadcrumbText,
    breadcrumbLink,
    breadcrumbText2,
    breadcrumbLink2,
    bannerClass,
    showDataSourceTooltip,
    showDivider,
    showEdit = false,
    allowEdit = false,
    allowExport = false,
    bannerTitle,
    bannerSubtitle,
    exportTypes,
    onExport = () => { },
    onAdd = () => { },
    onEdit = () => { },
    hideDataSourceLabel = false,
    exportButtonProps = {},
    headerComponents = null,
    bannerComponent
  } = props;


  const classes = useStyles();
  const context = React.useContext(DataSourceContext);

  return (
    <div className={classes.root}>
      <div style={{ justifyContent: 'flex-end', alignItems: 'center', width: '100%', display: 'flex', flexWrap: 'wrap', boxSizing: 'border-box' }}>
        {/* <div className={clsx(classes.banner, bannerClass)} >
          <div>
            {showBreadcrumb && (
              <div>
                <RouterLink className={classes.breadcrumb} to={breadcrumbLink}><span className={classes.breadcrumbText}>{breadcrumbText}</span><span className={classes.breadcrumbArrow}>></span></RouterLink>
                {breadcrumbText2 && (
                  <RouterLink className={classes.breadcrumb} to={breadcrumbLink2}><span className={classes.breadcrumbText}>{breadcrumbText2}</span><span className={classes.breadcrumbArrow}>></span></RouterLink>
                )}
              </div>
            )}
            <div className={classes.headerTitle}>{title}</div>
            <div className={classes.headerSubtitle}>{subTitle}</div>
          </div>
          {
            (headerComponents) &&
            (<Fragment>
              {headerComponents}
            </Fragment>
            )
          }
          {/* {showEdit && (
            <Fragment>
              <div className={classes.headerDivider}></div>
              <div item style={{ margin: 'auto' }}>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  className={classes.button}
                  startIcon={<EditIcon style={{ fontSize: '13px' }} />}
                  onClick={onEdit}
                  className={classes.editButton}
                  disabled={!allowEdit}
                > EDIT </Button>
              </div>
              <div style={{ flex: '1' }}></div>
            </Fragment>
          )} */}
     {/*   </div> */}
        {showToolbar && (<div item className={classes.buttonPanel}>

          {/* {allowExport &&
            <ExportButtonPro styleProp={exportButtonProps.styleProp} iconStyleProp={exportButtonProps.iconStyleProp}
              globalDownload={true}
              exportTypes={exportTypes}
              onExport={onExport}></ExportButtonPro>
          } */}
          {showAdd && (
            (allowAdd) ?
              <Fab size='small' className={classes.addButton} onClick={onAdd} aria-label="add">
                <AddIcon />
              </Fab>

              :
              <Fab size='small' classes={{ root: classes.addButton, disabled: classes.disabledButton }} disabled={true} aria-label="add">
                <AddIcon />
              </Fab>
          )}
        </div>
        )}
        <Grid item container direction='column' className={classes.contentPanel}>


          {/* {showDivider && (
            <Divider className={classes.divider} />
          )} */}
          {children}
        </Grid>
      </div>
    </div >
  );
}

LayoutCompact.propTypes = {
  children: PropTypes.element,
  dataSourceWeek: PropTypes.string,
  showDataSourceTooltip: PropTypes.bool,
  showBreadcrumb: PropTypes.bool,
  showDivider: PropTypes.bool,
  showToolbar: PropTypes.bool,
  showAdd: PropTypes.bool,
  allowAdd: PropTypes.bool,
  breadcrumbText: PropTypes.string,
  breadcrumbLink: PropTypes.string,
  bannerTitle: PropTypes.string,
  bannerSubtitle: PropTypes.string,
  exportTitle: PropTypes.string,
  onExport: PropTypes.func,
  onAdd: PropTypes.func,
  exportButtonProps: PropTypes.object,
};
