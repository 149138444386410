import React, { useState, useEffect, useRef } from "react";
// @material-ui/core components
import { makeStyles, Grid } from "@material-ui/core";
import _ from 'lodash';
//services
import { useCollectionService } from 'components';
import { Topbar } from 'Topbar';
import {Search} from 'views/Odata/Collections/components'
//contexts
//import DataSourceContext from 'context/DataSourceContext';
import CollectionsStart from './CollectionsStart';
import CollectionsHome from './CollectionsHome';

// from Filecenter:
import { DataSourceContext, SearchContext } from 'context';

const useStyles = makeStyles(theme => ({
  root: {

  }
}));

export default function Workbooks(props) {
  /*************************************************************************** */
  const {pillProps} = props;
  const service = useCollectionService();
  const [collections, setCollections] = useState([]);

  const dataSourceContext = React.useContext(DataSourceContext);

  //searchcontext
  const [totalCount, setTotalCount] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(25);
  const [page, setPage] = React.useState(1);
  const [orderBy, setOrderBy] = React.useState('createdDate');
  const [orderByDirection, setOrderByDirection] = React.useState('desc');
  const [reportName, setReportName] = React.useState('');
  const [loading, setLoading] = React.useState(true);
const hideMessage = true;
  const getData = () => {
    let sortInfo = '';
    if (orderBy !== '' && orderBy !== undefined && orderBy !== null) {
      sortInfo = "[{'Property': '" + orderBy + "' , 'direction' : '" + orderByDirection + "' }]";
    }
    let filter = {
      reportName: Boolean(reportName) ? reportName : null,
    };
    let options = { page: page, pageSize: pageSize, filter: filter, sortInfo: sortInfo }
    setLoading(true);
    service.getAll(options).then(response => {
      if (response.data !== null || response.data.data.length > 0) {
        setCollections(response.data.data);
        setPage(response.data.page);
        setPageSize(response.data.pageSize);
        setTotalCount(response.data.totalCount);
        setLoading(false);
      }
    });
  };

  useEffect(() => {
    if (Boolean(dataSourceContext.dataSourceSelectedKey))
      getData();

  }, [dataSourceContext.dataSourceSelectedKey, reportName,pageSize, page, orderBy, orderByDirection ]);//[dataSourceSelectedKey]


  const renderCollectionScreen = () => {
    return (collections.length > 0 || loading || reportName) ? <CollectionsHome pillProps={pillProps} wbLoading={loading} data={collections} getData={getData}
      toolBarProps={{
        page,
        setPageSize,
        pageSize,
        setPage,
        orderByDirection,
        setOrderByDirection,
        orderBy,
        setOrderBy,
        totalCount,
        setTotalCount

      }} />
      : <CollectionsStart setLoading={setLoading} pillProps={pillProps} messageProps={hideMessage} />
  };


  return (
    <SearchContext.Provider
      value={{ pageSize, setPageSize, orderBy, setOrderBy, orderByDirection, setOrderByDirection, reportName, setReportName, page, setPage }}>
          <Grid item><Topbar
              brandName="Bundles"
              Search={Search}
          />
          </Grid>
          <Grid item>
            {renderCollectionScreen()}
          </Grid>
      </SearchContext.Provider>
    );
}
