import React from 'react'

import {TableRow, TableBody, TableCell} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

const getLoadingRow = (colSpan, index) => {
    return <TableRow key={index}><TableCell colSpan={colSpan}><Skeleton animation='pulse'></Skeleton></TableCell></TableRow>
}

export default function LoadingTable(props){

    const {colSpan, rowCount} = props;
    return (
        <TableBody>
           {[...Array(rowCount)].map((e, i) => getLoadingRow(colSpan, i))}
        </TableBody>);
      
};