import React, { useState, useEffect, useRef } from 'react'

//material ui components
import { makeStyles } from '@material-ui/core/styles';
//custom components
import FileCenterToolBar from './FileCenterToolBar';
import EnhancedToolbar from './FileCenterEnhancedToolbar';
import {FileRepo, BatchReports, Reports} from './';
import {ExceloData} from 'views/Odata'
import {DataSourceContext} from 'context';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.contentBackground,
    margin: 0
},
  content: {
    padding: 1,
  },
  /* Styles applied to the Toolbar component. */
  toolbar: {
    height: 24,
    paddingLeft: theme.spacing(3)
  },
  /* Styles applied to the spacer element. */
  spacer: {
    flex: '1 1 100%',
  },
  buttonIcon: {
    marginLeft: 10,
    fill: theme.palette.npd.gray,
    '&:disabled': {
      fill: theme.palette.primary.main
    },
    '&:hover': {
      fill: theme.palette.primary.main,
      backgroundColor: 'none'
    },
  },
  message: {
    paddingLeft: 20,
    alignSelf: 'center',
    color: theme.palette.error.light,
    fontWeight:'bold'
  }
}));

function FileCenter() {
  const [dataSourceSelectedKey, setDataSourceSelectedKey] = React.useState('');
  const appKey = 'fc';
  const classes = useStyles();
  const [tableView, setTableView] = useState('batch');
  const isFirstRun = useRef(true);

  document.title = "File Center";
  useEffect(() => {
    //dont log the default toggle state
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }
  }, [tableView]);

  return (
    <DataSourceContext.Provider value={{dataSourceSelectedKey, setDataSourceSelectedKey, appKey}}>
            {(tableView == 'batch') ?
                <BatchReports 
                  Toolbar={FileCenterToolBar}
                  toolbarProps = {{setTableView:setTableView, tableView:tableView, messageComponent: <Typography className={classes.message}>Files in this list will be deleted after 90 days or overwritten if the report is run again.</Typography>}}
                  EnhancedToolbar={EnhancedToolbar}
                  /> :
                  (tableView == 'reports') ?
                  <Reports
                    Toolbar={FileCenterToolBar}
                    toolbarProps = {{setTableView:setTableView, tableView:tableView}}
                    EnhancedToolbar={EnhancedToolbar}
                    />:
                    <FileRepo 
                      Toolbar={FileCenterToolBar}
                      toolbarProps = {{setTableView:setTableView, tableView:tableView}}
                      EnhancedToolbar={EnhancedToolbar}
                      />
              }
    </DataSourceContext.Provider>
  )
}

export default FileCenter;