import React, { useState } from "react";

import {makeStyles} from '@material-ui/core';
import { useApi, CustomSelect } from 'components';
import { DataSourceContext } from 'context';

const useStyles = makeStyles((theme) => ({
    label: {
        color: theme.palette.white,
        fontWeight: 600,
        fontSize: 12,
        marginRight : 23,
        marginLeft : 100
    }
}));

export default function TargetEnvironmentDropdown(props) {
  const api = useApi();
  const classes = useStyles();
  const context = React.useContext(DataSourceContext);
  const selected = context.targetEnvKey;
  const setSelected = context.setTargetEnvKey;
  const [targets, setTargets] = React.useState([]);

  React.useEffect(() => {
      api.getTargetEnvironments().then((res) => {
          if (res.data !== undefined){
            setTargets(res.data);
            setSelected(res.data[0].id);
            context.setTargetEnvKey(res.data[0].id);
          }
      });
  }, [])

  return (
    <div>
        <span className={classes.label}>TARGET ENVIRONMENT</span>
        <CustomSelect
            options={targets}
            keyName={selected} 
            setKeyName={setSelected} 
            valueColumn="displayName"
            keyColumn="id"
        ></CustomSelect>
    </div>
  );
}

TargetEnvironmentDropdown.propTypes = {
};
