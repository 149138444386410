import React from "react";
import { makeStyles, Card, CardContent, Typography } from "@material-ui/core";
import { GetApp } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  root: {
    width: 150,
    height: 100,
    cursor: "pointer",
    margin: 10,
    alignContent: "CardContent",
    '&:hover': {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white
    }
  }
}));

export default function AppTile(props) {
  const { link, labelText, icon, target } = props;
  const classes = useStyles();
  return (
    <Card
      raised={true}
      onClick={() => window.open(link, target)}
      className={classes.root}
      variant="outlined"
    >
      <CardContent>
        {icon}
        <Typography color="inherit" variant="h5" component="h2">
            {labelText}    
        </Typography>
      </CardContent>
    </Card>
  );
}
