import React, { useState, useEffect, useContext } from 'react';

import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types'
import clsx from 'clsx';
import axios from 'axios';

import { AutoCompleteDropdown as SearchAutocomplete } from 'components';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Button, IconButton, Select, MenuItem, Divider } from '@material-ui/core';
import { Close,ArrowDropDown as ArrowDropDownIcon, Cached } from '@material-ui/icons';
import { SearchContext } from 'context';
import SearchIcon from '@material-ui/icons/Search';

const useAutocompleteStyle = makeStyles(theme => ({
  inputRoot: {
    height: 32,
    paddingRight: 0
  },
  input: {
    height: 12,
    marginLeft: theme.spacing(1),
    flex: 1,
    fontSize: 14,
  }
}))

const useStyles = makeStyles(theme => ({
  root: {
    width: 460,
    margin: '0 0 0 30px',
    padding: 0,
    border: '1px solid #D8D8D8',
    borderRadius: theme.shape.borderRadius,
    height: 33,

    '&:hover': {
      borderColor: theme.palette.primary.main,
    }
  },

  active: {
    borderColor: theme.palette.primary.main
  },

  hr: {
    margin: 0,
    height: 20,
  },
  iconButton: {
    marginRight: 3,
    marginLeft: 5,
    padding: '1px 1px',
    minWidth: 30,
    boxShadow: 'none',
    borderRadius: 2
  },
  searchIcon: {
    width: 18,

  },
  select: {
    paddingTop: 7,
    paddingLeft: theme.spacing(1),
    width: 70
  },
  selectDisplayStyle: {
    color: theme.palette.primary.main,
    fontWeight: '600',
    fontSize: 12,
  },
  selectItemStyle: {
    color: theme.palette.primary.main,
    fontWeight: '300',
    fontSize: 12,
    paddingLeft: theme.spacing(1),
    paddingTop: 0,
    paddingBottom: 0

  },
  popOverStyle: {
    width: 85,
    borderRadius: '0 0 4px 4px',
    boxShadow: '0 4px 10px -4px rgba(187,206,219,0.44)'
  },
  menuItemSelected: {
    backgroundColor: 'transparent !important;',
    fontWeight: '600',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.14) !important;'
    }
  },

  closeButton: {
    color: theme.palette.action.disabled
  }
}));

export default function Search(props) {
  const classes = useStyles();
  const history = useHistory();
  const context = React.useContext(SearchContext);
  
  const {
    //handleSearch,
    GetAutoSuggestOptions,
    showClearButton = true
  } = props;
  
  const [inputTerm, setInputTerm] = useState('');
  const [active, setActive] = useState(false);
  const [options, setOptions] = React.useState([]);

  const handleTextBoxOnChange = (e, v) => {
    setInputTerm(e.target.value);
  };
  
  const handleAutoCompleteValueOnChange = (e, v) => {
    setInputTerm(e.target.value);
  };

  const handleOnEnter = event => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };

  const handleSearch = () => {
    if (inputTerm !== context.searchTitle) {
      context.setSearchTitle(inputTerm);
      if (inputTerm === '') {
        context.setFilterCount(0);
        context.setDataSource('');
        context.setModule('');
      }
      else {
        context.setPage(1);
        context.setFilterCount(1);
        if (searchType === 0){
          context.setDataSource(inputTerm);
          context.setModule('');
        }
        else {
          context.setDataSource('');
          context.setModule(inputTerm);
        }        
      }
    }
  };

  const handleClearClick = () => {
    if (context.searchTitle !== '')
      context.setFilterCount(--context.filterCount);

    setInputTerm('');
    context.setSearchTitle('');
  };
  
  const searchTypes = [{Name: 'Data Source ', id: 0},{Name: 'Module ', id: 1}];
  const [searchType, setSearchType] = useState(0);
  const handleSearchTypeChange = event => { setSearchType(event.target.value); };

  return (
    <Grid container alignItems='center' className={active ? clsx(classes.root, classes.active) : classes.root} id='search-bar'>
      <Select id='searchType' classes={{
        root: classes.select,
        selectMenu: classes.selectDisplayStyle
      }}
        //IconComponent={searchTypes.length === 1 ? "ArrowDropDownIcon" : ArrowDropDownIcon}
        //IconComponent={"ArrowDropDownIcon"}
        value={searchType}
        onChange={handleSearchTypeChange}
        disableUnderline={true}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left'
          },
          getContentAnchorEl: null,
          classes:
          {
            paper: classes.popOverStyle
          }
        }}
      >
        {searchTypes.map(stype => (
          <MenuItem className={classes.selectItemStyle} classes={{ selected: classes.menuItemSelected }} key={stype.id} value={stype.id}>{stype.Name}</MenuItem>
        ))}
      </Select>
      <Divider orientation='vertical' className={classes.hr} />
      <SearchAutocomplete
        inputTerm={inputTerm}
        setInputTerm={setInputTerm}
        searchType={searchType}
        setSearchType={handleSearchTypeChange}
        options={options}
        setOptions={setOptions}
        handleOnEnter={handleOnEnter}
        handleOnFocus={() => setActive(true)}
        handleOnBlur={() => setActive(false)}
        handleTextBoxOnChange={handleTextBoxOnChange}
        handleSearchRedirect={handleSearch}
        handleAutoCompleteValueOnChange={handleAutoCompleteValueOnChange}
        GetAutoSuggestOptions={GetAutoSuggestOptions}
        inputClasses={useAutocompleteStyle()}
      />
      {(inputTerm !== '') ?
        <IconButton variant='contained' color='secondary' className={classes.iconButton} onClick={handleClearClick}>
          <Close classes={{ root: classes.searchIcon }} />
        </IconButton> : ''
      }
      <Button id='searchIcon' variant='contained' color='primary' size='small' className={classes.iconButton} aria-label='search' onClick={handleSearch}>
        <SearchIcon classes={{ root: classes.searchIcon }} />
      </Button>
    </Grid>

  );
}

var CancelToken = axios.CancelToken;
var cancel;

Search.propTypes = {
  handleSearchTypeChange: PropTypes.func.isRequired,
  handleTextBoxOnChange: PropTypes.func.isRequired,
  handleAutoCompleteValueOnChange: PropTypes.func.isRequired,
  handleOnEnter: PropTypes.func.isRequired,
  handleSearch: PropTypes.func.isRequired,
  GetAutoSuggestOptions: PropTypes.func.isRequired,
  searchTypes: PropTypes.array.isRequired,
  inputTerm: PropTypes.string,
  setInputTerm: PropTypes.func.isRequired,
  searchType: PropTypes.number  
};
