import React from 'react'
import { Switch, Redirect, Route } from 'react-router-dom'

import { ClientCenter, FileCenter, FlatFileAdminTool, DeliveryConfig, ODataCollection, CollectionsListHome as WorkbooksListView, } from './views'
import { NotFound } from 'layouts'
import RouteWithLayout from './layouts/RouteWithLayout'
import { ApplicationContext } from 'context';
import { MainWithTopbar } from 'layouts';

const Routes = () => {
  const context = React.useContext(ApplicationContext);

  return (
    <Switch>
      <Redirect exact from="/" to={context.appInfo.home}></Redirect>
      <Redirect exact from="/Home" to={context.appInfo.home}></Redirect>
      <RouteWithLayout secure exact path="/clientcenter" component={context.appInfo.user.hasCCAccess ? ClientCenter : NotFound} keyName='cc' />
      <RouteWithLayout secure exact path="/filecenter" component={context.appInfo.user.hasFCAccess ? FileCenter : NotFound} keyName='fc' />
      <RouteWithLayout secure exact path="/collection" component={context.appInfo.user.hasCollectionsAccess ? ODataCollection : NotFound} keyName='fc' />
      <RouteWithLayout secure path="/workbooks/:id" component={context.appInfo.user.hasCollectionsAccess ? WorkbooksListView : NotFound} keyName='fc' />
      <RouteWithLayout secure exact path="/flatfileadmintool" component={context.appInfo.user.hasFFAAccess ? FlatFileAdminTool : NotFound} keyName='ffa' />
      <RouteWithLayout secure exact path="/deliveryconfig" component={context.appInfo.user.hasFFAAccess ? DeliveryConfig : NotFound} keyName='dc' />
      <Route secure exact path="/404" component={NotFound}></Route>
      <Redirect to="/404" />
    </Switch>
  );
};

export default Routes;