import request from 'components/Api/request'

const impersonateUser = (username) => {
  return request({
    url: `/api/account/impersonateuser`,
    method: 'GET',
    params: {
      username: username
    }
  });
};

const impersonateCompany = (company) => {
  return request({
    url: `/api/account/impersonatecompany`,
    method: 'GET',
    params: {
      company: company
    }
  });
};

const removeImpersonation = () => {
  return request({
    url: `/api/account/removeimpersonation`,
    method: 'GET'
  });
};

const ImpersonateService = {
  impersonateUser,
  impersonateCompany,
  removeImpersonation
}

export default ImpersonateService;