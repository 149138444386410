import React from 'react';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';
import { Autocomplete } from "@material-ui/lab";

const useStyles = makeStyles(theme => ({
  autoCompleteGroupLabel: {
    fontWeight: 600,
    borderBottom: `1px solid ${theme.palette.tableRowDivider}`,
    padding: '0px 3px',
    margin: '5px 10px',
    height: 20,
    lineHeight: '10px'
  },
  autoCompleteGroupList: {
    paddingBottom: 10,
    paddingTop: 3,
    
  },
  autoCompleteItem: {
    color: theme.palette.npd.blue,
    width: '100%',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap'
  },
  autoCompleteOption: {
    fontSize: 12,
    margin: 0,
    padding: '1px 10px'
  },
  paper: {
    borderRadius: '0px 0px 4px 4px',
    margin: '1px 0',
    '& > ul':
    {
      maxHeight: '50vh'
    },
    boxShadow: theme.palette.boxShadow
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
    fontSize: 12,
  },
  autoCompleteOutlineRoot: {
    border: 'solid 1px #a6a6a6',
    borderRadius: 4,
    '&:hover': {
      border: 'solid 1px',
      borderColor: theme.palette.common.black
    },
    '&:focus': {
      border: 'solid 2px',
      borderColor: theme.palette.npd.blue
    }
  },
  tag: {
    margin: 3,
    height: 24
  }
}));

function AutoCompleteDropdown(props) {
  const {
    inputTerm,
    options,
    handleOnEnter,
    handleAutoCompleteValueOnChange,
    handleTextBoxOnChange,
    freeSolo = true,
    handleOnFocus,
    handleOnBlur,
    placeholder = 'Search',
    outlined = false,
    hideClearButton = true,
    inputClasses = {},
    label = 'name',
    ...rest
  } = props;
  const classes = useStyles();
  let initialValue = inputTerm;

  React.useEffect(() => {
    initialValue = inputTerm;
  }, []);

  return (
    <Autocomplete
      {...rest}
      //defaultValue={initialValue}
      id={'basicSearch-' + placeholder}
      style={{ flex: 1, display: 'flex' }}
      getOptionLabel={option => option.name}
      //filterOptions={x => x}
      options={options}
      autoComplete={true}
      clearOnEscape={true}
      freeSolo={freeSolo}
      disableOpenOnFocus
      disableClearable={hideClearButton}
      inputValue={initialValue}
      onChange={handleAutoCompleteValueOnChange}
      classes={{ tag: classes.tag, paper: classes.paper, input: inputClasses.input ? inputClasses.input : classes.input, inputRoot: inputClasses.inputRoot, option: classes.autoCompleteOption, groupLabel: classes.autoCompleteGroupLabel, groupUl: classes.autoCompleteGroupList }}
      renderInput={params => (
        <TextField
          {...params}
          InputProps={{ ...params.InputProps, disableUnderline: true }}
          inputProps={{ ...params.inputProps, maxLength: 1000, 'aria-label': 'search books' }}
          placeholder={placeholder}
          onKeyPress={handleOnEnter}
          onFocus={handleOnFocus}
          onBlur={handleOnBlur}
          fullWidth
          onChange={handleTextBoxOnChange}
          classes={{ root: outlined ? classes.autoCompleteOutlineRoot : classes.autoCompleteRoot }}
        />
      )}
      getOptionLabel={(x) => x[label] === undefined ? '' : x[label]}
    />
  );
}


export default AutoCompleteDropdown;