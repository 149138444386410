import React, { useEffect, useContext, useState } from 'react';
import { useLocation, useHistory, useParams } from 'react-router-dom';
import { Paper, Grid, Typography, FormHelperText, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
//Components
import { EnhancedTable, DeleteCommand } from 'components';
import { ApplicationContext, DataSourceContext, SearchContext, GridContext } from 'context';
import _ from 'lodash';
import { useCollectionService, useWindowDimensions } from 'components';
import {CollectionsLayoutCompact, CreateCollection} from './';
import Toolbar from '../OdataCollectionsToolbar';
import { Topbar } from 'Topbar';
import Search from './components/Search';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.contentBackground,
    padding: '0px',
    margin: '0px'
  }
}));
const columns = [{ field: 'folderName', type: 'string', label: 'Folder Name' },
{ field: 'reportName', type: 'string', label: 'Report Name' },
//{ field: 'runDate', type: 'date', label: 'Batched On', format: 'MM-DD-YYYY hh:mm A' },
{ field: 'createdUserName', type: 'string', label: 'Batched By' },
{ field: 'statusName', type: 'selectiveLinkWithTooltip', label: 'Status', showTooltip: true, onClick: () => { }, tooltipField: 'tooltip' },
//{ field: 'expiredDate', type: 'date', label: 'Expires On', format: 'MM-DD-YYYY hh:mm A' },
{ field: 'reportOutputType', type: 'string', label: 'Format', disableSort: true },
  //{ field: 'lastViewDate', type: 'date', label: 'Last Accessed', format: 'MM-DD-YYYY' },
  //{ field: 'action', type: 'action', label: '', disableSort: true }
];

const rowsPerPageOptions = [10, 25, 50, 75, 100, 200, 500];

export default function CollectionsList(props) {
  const [loading, setLoading] = React.useState(true);
  const { fields } = useContext(ApplicationContext);
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const service = useCollectionService();
  const [collection, setCollection] = useState({});
  const [dataRows, setDataRows] = useState([]);
  const [totalCount, setTotalCount] = React.useState(0);
  const [order, setOrder] = React.useState();
  const [initialLoad, setInitialLoad] = useState(true);
  const [orderChange, setOrderChange] = useState(false);
  const [isOwner, setIsOwner] = useState(false);

  //New
  const {pillProps} = props;
  const [page, setPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(25);
  const [orderBy, setOrderBy] = React.useState('reportName');
  const [orderByDirection, setOrderByDirection] = React.useState('asc');
  const [folderName, setFolderName] = React.useState('');
  const [reportName, setReportName] = React.useState('');
  const [batchStartdate, setBatchStartdate] = React.useState(null);
  const [batchEnddate, setBatchEnddate] = React.useState(null);
  const [rowsSelected, setRowsSelected] = React.useState([]);
  const [availableReports, setAvailableReports] = React.useState([]);
  const dataSourceContext = React.useContext(DataSourceContext);
  //const gridContext = React.useContext(GridContext)
  //const { enqueueSnackbar } = useSnackbar();
  const appKey = 'fc';

  React.useEffect(() => {
    if (Boolean(dataSourceContext.dataSourceSelectedKey)) {
      setLoading(true);
      loadBatchedReports();
    }
  }, [pageSize, page, orderByDirection, orderBy, dataSourceContext.dataSourceSelectedKey, reportName, folderName, batchStartdate, batchEnddate]);



  let { id } = useParams();
  const loadBatchedReports = () => {
    setLoading(true);
    let sortInfo = '';
    if (orderBy !== '' && orderBy !== undefined && orderBy !== null) {
      const orderCol = columns.find((item) => orderBy === item.label || orderBy === item.field).field;
      sortInfo = "[{'Property': '" + orderCol + "' , 'direction' : '" + orderByDirection + "' }]";
    }
    let filter = {

      statusIdNotIn: "3",
      reportName: reportName,
      folderName: folderName,
      startRunDateFrom: (batchStartdate === null || batchStartdate === undefined) ? null : batchStartdate.format("MM/DD/YYYY"),
      startRunDateTo: (batchEnddate === null || batchEnddate === undefined) ? null : batchEnddate.format("MM/DD/YYYY"),
    };

    let options = { page: page, pageSize: pageSize, filter: filter, sortInfo: sortInfo, token: dataSourceContext.dataSourceSelectedKey, id: id }
    //alert('['+dataSourceContext.dataSourceSelectedKey + '] , []');
    if (dataSourceContext.dataSourceSelectedKey !== '') {
      service.getBundle(options).then(res => {
        setAvailableReports(res.data.data);
        setPage(res.data.page);
        setPageSize(res.data.pageSize);
        setTotalCount(res.data.totalCount);
        setLoading(false);
        // if (initialLoad) {
        //   setOrderBy(response.data.collection.sortColumn);
        //   setOrder(response.data.collection.sortAscending ? 'asc' : 'desc');
        // }
        setInitialLoad(false);
      })
    }
  }

  const onChangePage = (newPage) => {
    setPage(newPage);
  }
  const onPageSizeChange = (newPageSize) => {
    setPageSize(newPageSize);
  }
  const onRequestSort = order => {
    setOrderBy(order.orderBy);
    setOrderByDirection(order.order)
  }


  const handleDelete = (selectionModel) => {

    // let params = {
    //   id: props.match.params.id,
    //   selectionModel: {
    //     remote: selectionModel.remote,
    //     selectAll: selectionModel.selectAll,
    //     selected: selectionModel.selected.map(s => `${s}`),
    //     unselected: selectionModel.unselected.map(s => `${s}`),
    //   }
    // };
    // setLoading(true);
    // service.deleteISBN(params).then(response => {
    //   loadCollections();
    // });
  };



  const handleExport = (options) => {
    if (options) {
      setLoading(true);
      service.exportCollection(props.match.params.id, (options.id == 1) ? options : {}).then(response => {
        const fileNameHeader = "x-bookscan-filename";
        const suggestedFileName = response.headers[fileNameHeader];
        const effectiveFileName = (suggestedFileName === undefined ? "Collection.xls" : suggestedFileName);
        // Let the user save the file.
        //FileSaver.saveAs(new Blob([response.data]), effectiveFileName);
        setLoading(false);
      });
      // captureEvent({ category: 'Collections', action: 'Export', label: options.label + ' - ' + options.format });
    }
  };

  const getTableCommands = () => {
    let commands = [<CreateCollection pillProps={pillProps} searchParams={{ bookCollectionSourceId: collection.id }} />];
    if (isOwner)
      commands.push(<DeleteCommand onDelete={handleDelete} />);
    return commands;
  }



  return (

    <SearchContext.Provider value={{ reportName, setReportName, page, setPage }}>
      <GridContext.Provider value={{ rowsSelected, setRowsSelected }}>
        <Grid container spacing={1} className={classes.root} direction='column'>
          <Grid item >
            <Topbar
              brandName="Bundles"
              Search={Search}
            />
          </Grid>
          <Grid item >
            <CollectionsLayoutCompact
              title={collection.name}
              breadcrumbText2={' Bundles'}
              breadcrumbLink2={`/workbooks/${id}?ds=${dataSourceContext.dataSourceSelectedKey}`}              
              showBreadcrumb
              setLoading={setLoading}>
              <EnhancedTable
                classes={classes}
                rowKeyProperty="scheduleEventId"
                enablePaging={true}
                mode='remote'
                page={page}
                rowsPerPage={pageSize}
                columns={columns}
                rows={availableReports}
                groupKey='dataSourceDisplayName'
                groupKeyId='groupId'
                totalCount={totalCount}
                onChangePage={onChangePage}
                onChangeRowsPerPage={onPageSizeChange}
                onRequestSort={onRequestSort}
                defaultOrder={orderByDirection}
                defaultOrderBy={orderBy}
                loading={loading}
                title="Batched Reports"
                noLeadingCheckbox="true"
                // ActionCell={ActionCell}
                actionCallback={loadBatchedReports}
                height={useWindowDimensions().height - 180}
                FileCenterToolbar={Toolbar}
                FileCenterToolbarProps={{ refresh: loadBatchedReports, ...pillProps}}
                // EnhancedTableToolbar={EnhancedToolbar}
                // EnhancedTableToolbarProps={{ refresh: loadBatchedReports }}

              ></EnhancedTable>
            </CollectionsLayoutCompact>

          </Grid>
        </Grid>

      </GridContext.Provider>
    </SearchContext.Provider>
  );
}
