import React from 'react';
import {Dialog, DialogTitle, DialogContent, DialogActions, Button} from '@material-ui/core';
import {JsonEditor as Editor} from 'jsoneditor-react';
import 'jsoneditor-react/es/editor.min.css';
import Ajv from 'ajv';
import {useApi} from 'components'


export default function JsonEditor(props){
    const { jsonObj, reportId, schemaId, open, setOpen } = props;
    const ajv = new Ajv({ allErrors: true, verbose: true });
    const [disableSave, setDisableSave] = React.useState(true);
    const [newJson, setNewJson] = React.useState("");
    const api = useApi();
    
    const validateAndSaveJson = (newObj) => {
        setDisableSave(false);
        setNewJson(JSON.stringify(newObj));
    }

    const SaveFlatFile = () => {
        api.updateFlatFileOptions(reportId, newJson, schemaId)
        setOpen(false);
    }

    const handleClose = () => {
        setOpen(false);
    }

    return (
        <Dialog fullWidth={true} maxWidth='md' aria-labelledby="json-editor-title" onClose={handleClose} open={open}>
            <DialogTitle id="json-editor-title">Update Flat File Options</DialogTitle>
            <DialogContent>
                <Editor
                    value={JSON.parse(jsonObj)}
                    mode='tree'
                    ajv={ajv}
                    onChange={validateAndSaveJson}
                    onError={() => setDisableSave(true)}
                    >
                </Editor>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={SaveFlatFile} disabled={disableSave} color="primary">
                    Save
                </Button>
            </DialogActions>
        </Dialog>        
    );
}