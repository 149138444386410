import React from 'react';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';

import MainLayout from './Main'
import { ApplicationContext } from 'context'

const RouteWithLayout = props => {
  const { component: Component, secure = true, layout = null, keyName, ...rest } = props;
  const context = React.useContext(ApplicationContext);
  const Layout = Boolean(layout) ? layout :  MainLayout;
  return (
    <Route
      {...rest}
      render={matchProps => secure ?
        (
          <Layout>
            <Component {...matchProps} />
          </Layout>
        ) :
        (
          <Layout>
            <Component {...matchProps} />
          </Layout>
        )}
    />
  );
};

RouteWithLayout.propTypes = {
  component: PropTypes.any.isRequired,
  path: PropTypes.string
};

export default RouteWithLayout;
