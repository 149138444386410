import React, { useState } from 'react';

import _ from 'lodash';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import { StartImpersonation, ImpersonationSelection, ImpersonationDetails } from './components/'
import { ApplicationContext } from 'context';

const useStyles = makeStyles(theme => ({

  root: {
    padding: 0,
    borderTop: `1px solid ${theme.palette.tableRowDivider}`,
    minWidth: 242
  }
}));

export default function Impersonation(props) {

  const classes = useStyles();
  const { appInfo } = React.useContext(ApplicationContext);
  const user = appInfo.user;
  const [view, setView] = useState( user.isImpersonating ? 'details' : 'start');

  return (
    <div className={classes.root}>
      {
        view == 'start' ? 
        <StartImpersonation setView={setView}/> : ''
      }
      {
        view == 'select' ? 
        <ImpersonationSelection setView={setView} user={user}/> : ''
      }
      {
        view == 'details' ? 
        <ImpersonationDetails setView={setView} user={user}/> : ''
      }
    </div>
  );
}
