import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import {Grid} from '@material-ui/core'

import {useWindowDimensions} from 'components'
import Footer from './Footer/Footer'
import {NotFound} from 'views'


const useStyles = makeStyles(theme => ({
    toolbar: theme.mixins.toolbar,
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
    content: {
      paddingTop: 70,
      flexGrow: 1,
      backgroundColor: theme.palette.background.default
    },
    
    overlay: {
      position: 'absolute',
      backgroundColor: 'black',
      height: '100%',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      display: 'none'
    },
    root: {
      width: '100%',
      height: '100%',
      backgroundColor: theme.palette.contentBackground,
      margin: 0
  },
  }));
  

const NF = props => {
    const classes = useStyles();
    const window = useWindowDimensions();

    return (
        <div className={classes.root} >
          <main className={classes.content}  style={{height: window.height - 95}}>
            <Grid container spacing={1} className={classes.root} direction='column'>
              <NotFound/>
            </Grid>
          </main>
          <Footer/>
        </div>
    );
};

NF.propTypes = {
    children: PropTypes.node
  };

export default NF;