//core components
import React, { useEffect} from 'react'
import { useLocation, useHistory } from 'react-router-dom';
import {ApplicationContext} from 'context';
import query from 'query-string';
//material ui components
import { makeStyles, lighten } from '@material-ui/core/styles';
import { ToggleButtonGroup, ToggleButton } from '@material-ui/lab';
import {Tooltip} from '@material-ui/core'


const useStyles = makeStyles(theme => ({
  toolbar: {    
     paddingLeft: 20,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    flexGrow: 2,
  },
  toggleButtonGroupRoot: {
    backgroundColor: 'transparent'
  },
  buttonSelected: {
    fill: `${theme.palette.white} !important`,
    backgroundColor: `${theme.palette.primary.main} !important`
  },
  buttonRoot: {
    height: 25,
    fill: '#455a64',
    borderRadius: 18,
    borderColor: theme.palette.grey,
    width: 130,
    fontSize: 12,
    margin:1,
    marginLeft: 1
  },
  flex: {
    flexGrow: 2,
  },
  iconButton:{
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    marginLeft: 10,
    '&:hover': {
      backgroundColor: theme.palette.npd.darkBlue
    },
    '&:disabled':{
      backgroundColor: lighten(theme.palette.npd.gray, .7)
    }
  }
}));

function FileCenterToolBar(props) {
  const { tableView, setTableView, messageComponent} = props;
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const appContext = React.useContext(ApplicationContext);
  
  const handleToggleButtonChange = (event, value) => {
    //Do not allow to untoggle
    if (value !== undefined && value !== null)
    {
      setTableView(value);
      Redirect(value); 
    }
  }
  
  useEffect(() => {
    if (location.search){
      const tab = query.parse(location.search);
      setTableView(tab.tab);
    }
  }, [location])
  
const Redirect = (value) => {
  if (value=='doc')
    history.push(`/clientcenter`);
  else if (value === 'odata') 
    history.push(`/filecenter?tab=odata`);	    
  else if (value === 'batch') 
    history.push(`/filecenter`);
  else if (value == 'reports')
    history.push(`/filecenter?tab=reports`)
  else 
    history.push(`/filecenter?tab=repo`);
};

  return (
    <div className={classes.toolbar} >
      {appContext.appInfo.user.hasFCAccess && 
        <ToggleButtonGroup
          value={tableView}
          exclusive
          onChange={handleToggleButtonChange}
          aria-label='toggle'
          size='small'
          classes={{ root: classes.toggleButtonGroupRoot }}
        >
          {appContext.appInfo.user.hasCCAccess && 
            <ToggleButton classes={{ root: classes.buttonRoot, selected: classes.buttonSelected }} value='doc' aria-label='clientcenterfiles'>
              <Tooltip title="Client Center">
                <span style={{ paddingLeft: 0 }}>Client Center</span>
              </Tooltip>
            </ToggleButton>        
          }          
          <ToggleButton classes={{ root: classes.buttonRoot, selected: classes.buttonSelected }} value='batch' aria-label='batchreports'  >
            <Tooltip title="DecisionKey batch reports to which you have access across all of your data sources. Download or delete your batch reports from this screen.">
              <span style={{ paddingLeft: 8 }}>Batch Reports</span>
            </Tooltip>
          </ToggleButton>
          <ToggleButton classes={{ root: classes.buttonRoot, selected: classes.buttonSelected }} value='repo' aria-label='filerepo' >
            <Tooltip title="Information about the DecisionKey data source(s) to which you have access, such as data delivery calendars and definitions. Download these files from this screen.">
              <span style={{ paddingLeft: 0 }}>File Repository</span>
            </Tooltip>
          </ToggleButton>
          {(appContext.appInfo.user.isSharedAccountImpersonation) &&
            <ToggleButton classes={{ root: classes.buttonRoot, selected: classes.buttonSelected }} value='reports' aria-label='reports' >
              <Tooltip title="DecisionKey batch reports to which you have access across all of your data sources. Download or delete your batch reports from this screen.">
                <span style={{ paddingLeft: 0 }}>My Reports</span>
              </Tooltip>
            </ToggleButton>
          }

        </ToggleButtonGroup>
    }
    {messageComponent}
    </div>
    
  )
}

export default FileCenterToolBar;