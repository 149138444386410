import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { useSnackbar } from 'notistack';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Collapse } from '@material-ui/core';
// custom components
import { useApi, EnhancedTable, useWindowDimensions } from 'components'
import { DataSourceContext, SearchContext, GridContext, ApplicationContext } from 'context'
import { ReportEditor, Search, EnhancedTableToolbar, ActionCell, TargetEnvironmentDropdown, AdvancedSearchForm } from './Components'
import { Topbar } from 'Topbar'

const useStyles = makeStyles(theme => ({
  root: {
    width: '98%',
    marginLeft: 10,
    backgroundColor: theme.palette.contentBackground,
    margin: 0
  },
  table: {
    minWidth: 500,
  },
  tableRowHighlight: {
    background: 'lightpink'
  }
}));

const useStylesAgain = makeStyles(theme => ({
  showEditor: {
    display: 'block'
  },
  hideEditor: {
    display: 'none'
  },
  topbarHolder: {
    padding: '3px 12px'
  }
}));

const rowsPerPageOptions = [10, 25, 50, 75, 100, 200, 500];

export default function FlatFileAdminTool() {
  const classes = useStyles();
  const localClasses = useStylesAgain();
  const api = useApi();
  const { enqueueSnackbar } = useSnackbar();
  const appContext = React.useContext(ApplicationContext);

  //state hooks
  const [flatFiles, setFlatFiles] = useState([]);
  const [loading, setLoading] = React.useState(false);
  const [totalCount, setTotalCount] = React.useState(0);
  const [page, setPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(appContext.appInfo.user.rowsPerPage || 25);

  const [dataSources, setDataSources] = useState([]);
  const [dataSourceSelectedKey, setDataSourceSelectedKey] = React.useState('');
  const [targetEnvKey, setTargetEnvKey] = React.useState(1);
  const [orderBy, setOrderBy] = React.useState('reportName');
  const [orderByDirection, setOrderByDirection] = React.useState('asc');
  const [rowsSelected, setRowsSelected] = React.useState([]);
  const [editRow, setEditRow] = React.useState({ moveItFolders: '' })
  const [editing, setEditing] = React.useState(false)
  const [editReportLoading, setEditReportLoading] = React.useState(false);

  const [reportName, setReportName] = React.useState('');
  const [folderName, setFolderName] = React.useState('');
  const [createdBy, setCreatedBy] = React.useState('');
  const [zipped, setZipped] = React.useState('');
  const [lf, setLf] = React.useState('');
  const [unSuppressAttributes, setUnSuppressedAttributes] = React.useState('');
  const [attrAtEnd, setAttrAtEnd] = React.useState('');
  const [delimiter, setDelimiter] = React.useState('');
  const [appendDate, setAppendDate] = React.useState('');
  const [appendSchema, setAppendSchema] = React.useState('');
  const [immediateDeliveryInd, setImmediateDeliveryInd] = React.useState('');
  const [status, setStatus] = React.useState({scheduleStatus: 0, displayName: ""});
  const [moveItShare, setMoveItShare] = React.useState('');
  const [batchStartdate, setBatchStartdate] = React.useState(null);
  const [batchEnddate, setBatchEnddate] = React.useState(null);

  document.title = "Flat File Admin Tool";
  const appKey = 'ffa';

  const height = useWindowDimensions().height - 175 - (editing ? 202 : 0);

  const buildUpdates = (row) => {
    const updates = {
      immediateDeliveryInd: row.immediateDeliveryInd,
      zipped: row.compression,
      crlf: row.crlf,
      appendDateToFile: row.appendDate,
      delimiter: row.delimiter,
      reportName: row.reportName,
      regularFlatFileShare: row.regularFlatFileShare,
      moveItFolders: row.ffOptions.moveITFolders,
      unSuppressAttributes: row.unSuppressAttributes,
      attributeAtEnd: row.attributeAtEnd,
      appendSchema: row.appendSchema
    }
    return updates;
  };

  const getReport = (reportId) => {
    return flatFiles.find((i) => i.uniqueId === reportId);
  }

  const onImmediateDeliveryClick = (event, value) => {
    setLoading(true);
    const row = getReport(value);
    const oldRow = { ...row };
    row.immediateDeliveryInd = !row.immediateDeliveryInd;
    api.updateReport(oldRow, buildUpdates(row)).then((res) => {
      if (res.data && res.data.failed) {
        enqueueSnackbar(res.data.errorMessage, { variant: 'warning' });
        row.immediateDeliveryInd = !row.immediateDeliveryInd; //reset since there was error
      }
      loadFlatFiles();
    });
  }

  const onCheckboxClick = (event, value, column) => {
    setLoading(true);
    const row = getReport(value);
    row[column] = !row[column];
    api.updateReport(row, buildUpdates(row)).then((res) => {
      if (res.data && res.data.failed) {
        enqueueSnackbar(res.data.errorMessage, { variant: 'warning' });
        row[column] = !row[column]; 
      }
      loadFlatFiles();
    });
  }

  const handleReportEdit = (event, reportId) => {
    if (rowsSelected.length > 1) {
      setEditing(false);
      setEditRow(null);
      return;
    }
    setEditing(false);
    setTimeout(() => {
      const row = flatFiles.find((item) => {
        return item.uniqueId === reportId;
      });
      setEditRow(row);
      setEditing(true);
    }, 1);
  }

  const columns =
    [{ field: 'reportName', type: 'link', label: 'Report Name', onClick: handleReportEdit },
    { field: 'folderName', type: 'string', label: 'Folder Name'},
    { field: 'createdBy', type: 'string', label: 'Created By' },
    { field: 'rundate', type: 'date', label: 'Last Batched On', format: 'MM-DD-YYYY hh:mm A', showTooltip: true, tooltipField:'lastRunDuration' },
    { field: 'compression', type: 'bool', label: 'Zipped', onClick: onCheckboxClick, disableSort: true },
    { field: 'crlf', type: 'bool', label: 'Line Feed(LF)', onClick: onCheckboxClick, disableSort: true },
    { field: 'unSuppressAttributes', type: 'bool', label: 'UnSuppress Attributes', onClick: onCheckboxClick, disableSort: true },
    { field: 'attributeAtEnd', type: 'bool', label: 'Attr At End', onClick: onCheckboxClick, disableSort: true },
    { field: 'delimiterDisplayName', type: 'string', label: 'Delimiter', disableSort: true },
    { field: 'appendDate', type: 'bool', label: 'Append Date', onClick: onCheckboxClick, disableSort: true },
    { field: 'appendSchema', type: 'bool', label: 'Append Schema', onClick: onCheckboxClick, disableSort: true },
    { field: 'regularFlatFileShare', type: 'string', label: 'Output Folder', disableSort: true },
    { field: 'statusNames', type: 'selectiveLinkWithTooltip', label: 'Status', showTooltip: true, onClick: () => {}, tooltipField:'tooltip'  },
    { field: 'moveItFolders', type: 'string', label: 'Move It Share', disableSort: true },
    { field: 'immediateDeliveryInd', type: 'bool', label: 'Immediate Delivery', onClick: onImmediateDeliveryClick, disableSort: false, disabled: true, tooltip: "Click on Report Name to edit this field." },
    { field: 'actions', type: 'action', label: '', disableSort: true }]

  const handleClose = (row, updates) => {
    if (row && updates) {
      api.updateReport(row, updates).then((res) => {
        if (res.data && res.data.failed)
          enqueueSnackbar(res.data.errorMessage, { variant: 'warning' });
        else {
          setLoading(true);
          loadFlatFiles();
        }
      });
    }
    setEditing(false);
  }

  const onChangePage = (newPage) => {
    setPage(newPage);
  }
  const onPageSizeChange = (newPageSize) => {
    setPage(1);
    api.saveRowPerPage({rowsPerPageOptions: newPageSize});
    appContext.appInfo.user.rowsPerPage = newPageSize;
    setPageSize(newPageSize);
  }
  const onRequestSort = order => {
    setOrderBy(order.orderBy);
    setOrderByDirection(order.order)
  }

  const isRowHighlighted = (row) => {
    if (row.lastRunDurationInSec === 0) return false;
    return row.currentRunDuration > row.lastRunDurationInSec;
  }

  const loadFlatFiles = () => {
    setLoading(true);
    setEditing(false);
    setRowsSelected([]);
    let sortInfo = '';
    if (orderBy !== '' && orderBy !== undefined && orderBy !== null) {
      const orderCol = columns.find((item) => orderBy === item.label || orderBy === item.field).field;
      sortInfo = "[{'Property': '" + orderCol + "' , 'direction' : '" + orderByDirection + "' }]";
    }
    let filter = {
      reportName: reportName,
      createdBy: createdBy,
      status: status.scheduleStatus,
      schemaId: targetEnvKey
    };
    if (folderName !== '') filter.folderName = folderName;
    if (immediateDeliveryInd !== '') filter.immediateDeliveryInd = immediateDeliveryInd === 'True' ? true : false;
    if (lf !== '') filter.crlf = lf === 'True' ? true : false;
    if (unSuppressAttributes !== '') filter.unSuppressAttributes = unSuppressAttributes === 'True' ? true : false;
    if (attrAtEnd !== '') filter.attributeAtEnd = attrAtEnd === 'True' ? true : false;
    if (appendDate !== '') filter.appendDateToFile = appendDate === 'True' ? true : false;
    if (appendSchema !== '') filter.appendSchema = appendSchema === 'True' ? true : false;
    if (zipped !== '') filter.zipped = zipped === 'True' ? true : false;
    if (delimiter !== '') filter.delimiter = delimiter;
    if (moveItShare !== '') filter.moveItShare = moveItShare;
    if (batchEnddate !== null) filter.batchToDate = batchEnddate;
    if (batchStartdate !== null) filter.batchFromDate = batchStartdate;

    let options = { page: page, pageSize: pageSize, token: dataSourceSelectedKey, sortInfo: sortInfo, filter: filter }

    if (dataSourceSelectedKey !== '') {
      api.getFlatFiles(options).then(res => {
        setFlatFiles(res.data.data);
        setEditRow(res.data.data[0]);
        setPage(res.data.page);
        setPageSize(res.data.pageSize);
        setTotalCount(res.data.totalCount);
        setLoading(false);
      })
    }
  }

  useEffect(() => {
    setLoading(true);
    loadFlatFiles();
    setPage(1);
  }, [dataSourceSelectedKey]);


  //effect hooks
  useEffect(() => {
    setLoading(true);
    loadFlatFiles();

  }, [page, pageSize, orderBy, orderByDirection, reportName, folderName, createdBy, zipped, delimiter, lf, unSuppressAttributes, attrAtEnd, appendDate, appendSchema, immediateDeliveryInd, status, batchEnddate, batchStartdate, moveItShare, targetEnvKey]);

  return (
    <SearchContext.Provider value={{
      reportName, setReportName, folderName, setFolderName, createdBy, setCreatedBy, page, setPage, zipped, setZipped, lf, setLf, delimiter, setDelimiter,
      appendDate, setAppendDate, appendSchema, setAppendSchema, immediateDeliveryInd, setImmediateDeliveryInd, status, setStatus, moveItShare, setMoveItShare,
      batchStartdate, setBatchStartdate, batchEnddate, setBatchEnddate, unSuppressAttributes, setUnSuppressedAttributes, attrAtEnd, setAttrAtEnd
    }}>
      <DataSourceContext.Provider value={{ dataSources, dataSourceSelectedKey, setDataSourceSelectedKey, targetEnvKey, setTargetEnvKey, appKey }}>
        <GridContext.Provider value={{ rowsSelected, setRowsSelected }}>
          <Grid item className={localClasses.topbarHolder}>
            <Topbar
              brandName="Flat File Admin Tool"
              Search={Search}
              RightComponent={TargetEnvironmentDropdown}
              AdvancedSearchForm={AdvancedSearchForm}
            />
          </Grid>
          <Collapse in={editing} timeout='auto'>
            <Grid item className={clsx({ [localClasses.showEditor]: editing, [localClasses.hideEditor]: !editing })}>
              {editing && <ReportEditor loading={editReportLoading} row={editRow} handleClose={handleClose}></ReportEditor>}
            </Grid>
          </Collapse>
          <Grid item>
            <EnhancedTable
              classes={classes}
              rowKeyProperty="uniqueId"
              enablePaging={true}
              mode='remote'
              page={page}
              rowsPerPage={pageSize}
              columns={columns}
              rows={flatFiles}
              groupKey='summarySourceName'
              groupKeyId='groupId'
              totalCount={totalCount}
              onChangePage={onChangePage}
              onChangeRowsPerPage={onPageSizeChange}
              onRequestSort={onRequestSort}
              rowsPerPageOptions={rowsPerPageOptions}
              defaultOrder={orderByDirection}
              defaultOrderBy={orderBy}
              title="Flat File Admin Tool"
              callerKey="FFAT"
              loading={loading}
              EnhancedTableToolbar={EnhancedTableToolbar}
              EnhancedTableToolbarProps={{ handleEdit: handleReportEdit, refresh: loadFlatFiles, numSelected: rowsSelected.length }}
              ActionCell={ActionCell}
              actionCallback={loadFlatFiles}
              height={height}
              isRowHighlighted= { isRowHighlighted }
            />
          </Grid>
        </GridContext.Provider>
      </DataSourceContext.Provider>
    </SearchContext.Provider>
  );
}