import React from 'react'
import { AutoCompleteDropdown as SearchAutocomplete } from 'components'
import { makeStyles } from '@material-ui/core/styles';
import {Close as CloseIcon} from '@material-ui/icons';
import { FormControl, Select, Box, OutlinedInput, Button, Divider, Typography, Grid, IconButton } from '@material-ui/core';
import { SearchContext } from 'context'
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
      },
      typography: {
      padding: theme.spacing(2)
    },
    iconOpenRoot:{
        color: theme.palette.common.white,
        backgroundColor: theme.palette.npd.darkBlue,
        borderRadius: 0,
        height: 48,
        width: 48,
        '&:hover':{
            color: theme.palette.common.white,
            backgroundColor: theme.palette.npd.darkBlue,
        }
    },
    iconRoot:{
        height: 48,
        width: 48,
    },
    button:{
        margin: 10
    },
    gridContainer:{
        padding: '3px 10px',
    },
    popperPaper:{
        width: 550,
        height: 300,
        padding: theme.spacing(1),
        textAlign: 'center',
        whiteSpace: 'nowrap',
        marginBottom: theme.spacing(1),
    },
    textBox: {
        height:30,
        width: '100%',
        fontSize: 12,
        '&:input':{
            padding: "0px 5px"
        }
    },
    formControl:{
        width: '100%',
        paddingRight: 5
    },
    formControlLast:{
        width: '100%'
    },
    select: {
        padding: 8,
        fontSize: 12,
        width: '100%'
    },
    dense: {
        padding: 0,
        margin: 0,
        paddingRight: 2,
        width: '50%',
        '& > div > input':{
            padding: 8,
            fontSize: 12
        }
    },
    endAdornment: {
        padding: 0,
        '& > button':{
            padding: 0
        }
    },
  }));

export default function AdvancedSearchForm(props) {
    const classes = useStyles();
    const { categories = [], formats = [], rowsPerPageOptions = [], columns = [], handleClose } = props;
    const searchContext = React.useContext(SearchContext);

    const [title, setTitle] = React.useState(searchContext.searchTitle);
    const [category, setCategory] = React.useState(searchContext.category);
    const [format, setFormat] = React.useState(searchContext.format);
    const [startdate, setStartdate] = React.useState(searchContext.startdate);
    const [enddate, setEnddate] = React.useState(searchContext.enddate);
    const [orderBy, setOrderBy] = React.useState(searchContext.orderBy);
    const [orderByDirection, setOrderByDirection] = React.useState(searchContext.orderByDirection);
    const [pageSize, setPageSize] = React.useState(searchContext.pageSize);

    const handleTitleChange = (event) => {
        setTitle(event.target.value);
    }
    const handleCategoryChange = (event, value) => {
        if (value !== undefined && value.ids !== undefined)
            setCategory(value);
    }
    const handleFormatChange = (event, value) => {
        if (value !== undefined && value.ids !== undefined)
            setFormat(value);
    }
    const handleFormatTextBoxChange = (event) => {
        if (event !== undefined && event.target !== undefined)
            setFormat({ name: event.target.value });
    }
    const handleCategoryTextBoxChange = (event) => {
        if (event !== undefined && event.target !== undefined)
            setCategory({ name: event.target.value });
    }
    const handleOrderByChange = (event) => {
        setOrderBy(event.target.value);
    }
    const handleOrderByDirectionChange = (event) => {
        setOrderByDirection(event.target.value);
    }
    const handlePageSizeChange = (event) => {
        setPageSize(event.target.value);
    }

    const onApplyClick = () => {
        setFilters(false);
        searchContext.setPage(1);
        
        handleClose();
    }

    const onClearClick = () => {
        setTitle('');
        setEnddate(null);
        setStartdate(null);
        setFormat({ name: '', ids: '' });
        setCategory({ name: '', ids: '' });
        setFilters(true);
    }

    const setFilters = (shouldClear) => {
        if (shouldClear) {
            console.log(searchContext);
            console.log(format);
            console.log(category);
            searchContext.setSearchTitle('');
            searchContext.setCategory({ name: '', ids: '' });
            searchContext.setFormat({ name: '', ids: '' });
            searchContext.setStartdate(null);
            searchContext.setEnddate(null);
        }
        else {
            searchContext.setSearchTitle(title);
            searchContext.setCategory(category);
            searchContext.setFormat(format);
            searchContext.setStartdate(startdate);
            searchContext.setEnddate(enddate);
            searchContext.setOrderBy(orderBy);
            searchContext.setOrderByDirection(orderByDirection);
            searchContext.setPageSize(pageSize);
        }
    }
    return (
        <Grid container className={classes.root}>
        <div className={classes.popperPaper}>
            <Grid item xs={12}>
                <Grid container>
                    <Grid item xs={11}>
                        
                    </Grid>
                    <Grid item xs={1}>
                        <IconButton onClick={handleClose}>
                            <CloseIcon/>
                        </IconButton>
                    </Grid>
                </Grid>
            </Grid>
            <Divider></Divider>
            <Grid item xs={12}>
            <Grid container>
                <Grid item xs={6}>
                    <Grid container>
                        <Grid item xs={12} className={classes.gridContainer}>
                            <Box lineHeight={2} textAlign="left" fontSize='subtitle.fontSize'>Title</Box>
                            <OutlinedInput margin='dense' value={title} onChange={handleTitleChange} classes={{ root: classes.textBox }} variant='outlined'></OutlinedInput>
                        </Grid>
                        
                        <Grid item xs={12} className={classes.gridContainer}>
                            <Box lineHeight={2} textAlign="left" fontSize='subtitle.fontSize'>Format</Box>
                            <SearchAutocomplete
                                handleTextBoxOnChange={handleFormatTextBoxChange}
                                handleAutoCompleteValueOnChange={handleFormatChange}
                                inputTerm={format.name}
                                defaultValue={formats.find((item) => item === searchContext.format) || { name: '' }}
                                getOptionLabel={option => option.name}
                                options={formats}
                                placeholder='All Formats'
                                outlined={true}
                                freeSolo={false}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={6}>
                    <Grid container>
                        <Grid item xs={12} className={classes.gridContainer}>
                            <Box lineHeight={2} textAlign="left" fontSize='subtitle.fontSize'>Category</Box>
                            <SearchAutocomplete
                                handleTextBoxOnChange={handleCategoryTextBoxChange}
                                handleAutoCompleteValueOnChange={handleCategoryChange}
                                inputTerm={category.name}
                                defaultValue={categories.find((item) => item === searchContext.category) || { name: '' }}
                                getOptionLabel={option => option.name}
                                options={categories}
                                placeholder='All Categories'
                                outlined={true}
                                freeSolo={false}
                            />
                        </Grid>
                        
                        <Grid item xs={12} className={classes.gridContainer}>
                            <Box lineHeight={2} textAlign="left" fontSize='subtitle.fontSize'>Date Released</Box>
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                <KeyboardDatePicker
                                    autoOk
                                    variant="inline"
                                    inputVariant="outlined"
                                    placeholder="From"
                                    format="MM/DD/YYYY"
                                    margin="dense"
                                    value={startdate}
                                    classes={{ root: classes.dense }}
                                    InputAdornmentProps={{ classes: { root: classes.dense, positionEnd: classes.endAdornment } }}
                                    onChange={date => setStartdate(date)}
                                />
                            </MuiPickersUtilsProvider>
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                <KeyboardDatePicker
                                    autoOk
                                    variant="inline"
                                    inputVariant="outlined"
                                    placeholder="To"
                                    format="MM/DD/YYYY"
                                    margin="dense"
                                    disableFuture
                                    value={enddate}
                                    classes={{ root: classes.dense }}
                                    InputAdornmentProps={{ classes: { root: classes.dense, positionEnd: classes.endAdornment } }}
                                    onChange={date => setEnddate(date)}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>

                    </Grid>    
                </Grid>    
        </Grid>
    </Grid>
    <Divider></Divider>
    
    <Grid item xs={12}>
        <Grid container className={classes.gridContainer}>
            <Grid item xs={4}>
                <Box lineHeight={2} textAlign="left"  fontSize='subtitle.fontSize'>OrderBy Column</Box>
                    <FormControl variant="outlined" className={classes.formControl}>
                        <Select
                            native
                            onChange={handleOrderByChange}
                            classes={{ root: classes.select }}
                            value={columns.find((item) => orderBy === item.label || orderBy === item.field).field}
                        >
                            {columns.map(item => { return !item.disableSort ? <option key={item.field} value={item.field}>{item.label}</option> : '' })}
                        </Select>
                    </FormControl>
            </Grid>

            <Grid item xs={4}>
                <Box lineHeight={2} textAlign="left"  fontSize='subtitle.fontSize'>Direction</Box>
                <FormControl variant="outlined" className={classes.formControl}>
                    <Select
                        native
                        onChange={handleOrderByDirectionChange}
                        value={orderByDirection}
                        classes={{root: classes.select}}
                        >
                        <option key="desc" value="desc">Desc</option>
                        <option key="asc" value="asc">Asc</option>
                    </Select>
                </FormControl>
            </Grid>

            <Grid item xs={4}>
                <Box lineHeight={2} textAlign="left"  fontSize='subtitle.fontSize'>Page Size</Box>
                <FormControl variant="outlined" className={classes.formControlLast}>
                    <Select
                        native
                        onChange={handlePageSizeChange}
                        value={pageSize}
                        classes={{root: classes.select}}
                        >
                            {rowsPerPageOptions.map(item => { return <option key={item} value={item}>{item}</option>})}
                    </Select>
                </FormControl>
            </Grid>
        </Grid>
    </Grid>

    <Grid item xs={12}>
        <Grid container className={classes.gridContainer}>
            <Grid item xs={8}></Grid>
            <Grid item xs={4}>
                <Button variant="contained" color="primary" size="small" className={classes.button} onClick={onClearClick} >Clear</Button>
                <Button variant="contained" color="primary" size="small" className={classes.button} onClick={onApplyClick} >Apply</Button>
            </Grid>
        </Grid>
    </Grid>
    </div>
</Grid>
    
    // <Grid container className={classes.root}>
    //     <div className={classes.popperPaper}>
    //         <Grid item md={12}>
    //             <Grid container className={classes.gridContainer}>
    //                 <Grid item xs={6}>
    //                     <Button component="span" className={classes.button} onClick={onClearClick} >Clear</Button>
    //                 </Grid>
    //                 <Grid item xs={6}>
    //                     <Button component="span" className={classes.button} onClick={onApplyClick} >Apply</Button>
    //                 </Grid>
    //             </Grid>
    //         </Grid>
    //         <Divider></Divider>
    //         <Grid item xs={12}>
    //             <Grid container className={classes.gridContainer}>
    //                 <Grid item xs={3}>
    //                     <Box lineHeight={2} textAlign="left" fontWeight="fontWeightBold" fontSize='h5.fontSize'>Title</Box>
    //                 </Grid>
    //                 <Grid item xs={9}>
    //                     <OutlinedInput margin='dense' value={title} onChange={handleTitleChange} classes={{ root: classes.textBox }} variant='outlined'></OutlinedInput>
    //                 </Grid>
    //             </Grid>
    //         </Grid>
    //         <Grid item xs={12}>
    //             <Grid container className={classes.gridContainer}>
    //                 <Grid item xs={3}>
    //                     <Box lineHeight={2} textAlign="left" fontWeight="fontWeightBold" fontSize='h5.fontSize'>Category</Box>
    //                 </Grid>
    //                 <Grid item xs={9}>
    //                     <SearchAutocomplete
    //                         handleTextBoxOnChange={handleCategoryTextBoxChange}
    //                         handleAutoCompleteValueOnChange={handleCategoryChange}
    //                         //inputTerm={category.name}
    //                         defaultValue={categories.find((item) => item === searchContext.category) || { name: '' }}
    //                         getOptionLabel={option => option.name}
    //                         options={categories}
    //                         placeholder='All Categories'
    //                         outlined={true}
    //                         freeSolo={false}
    //                     />
    //                 </Grid>
    //             </Grid>
    //         </Grid>
    //         <Grid item xs={12}>
    //             <Grid container className={classes.gridContainer}>
    //                 <Grid item xs={3}>
    //                     <Box lineHeight={2} textAlign="left" fontWeight="fontWeightBold" fontSize='h5.fontSize'>Format</Box>
    //                 </Grid>
    //                 <Grid item xs={9}>
    //                     <SearchAutocomplete
    //                         handleTextBoxOnChange={handleFormatTextBoxChange}
    //                         handleAutoCompleteValueOnChange={handleFormatChange}
    //                         //inputTerm={format.name}
    //                         defaultValue={formats.find((item) => item === searchContext.format) || { name: '' }}
    //                         getOptionLabel={option => option.name}
    //                         options={formats}
    //                         placeholder='All Formats'
    //                         outlined={true}
    //                         freeSolo={false}
    //                     />
    //                 </Grid>
    //             </Grid>
    //         </Grid>
    //         <Grid item xs={12}>
    //             <MuiPickersUtilsProvider utils={MomentUtils}>
    //                 <Grid container className={classes.gridContainer}>
    //                     <Grid item xs={3}>
    //                         <Box lineHeight={2} textAlign="left" fontWeight="fontWeightBold" fontSize='h5.fontSize'>Date Released</Box>
    //                     </Grid>
    //                     <Grid item xs={4}>
    //                         <KeyboardDatePicker
    //                             autoOk
    //                             variant="inline"
    //                             inputVariant="outlined"
    //                             placeholder="From"
    //                             format="MM/DD/YYYY"
    //                             margin="dense"
    //                             value={startdate}
    //                             classes={{ root: classes.dense }}
    //                             InputAdornmentProps={{ classes: { root: classes.dense, positionEnd: classes.endAdornment } }}
    //                             onChange={date => setStartdate(date)}
    //                         />
    //                     </Grid>
    //                     <Grid item xs={1}></Grid>
    //                     <Grid item xs={4}>
    //                         <KeyboardDatePicker
    //                             autoOk
    //                             variant="inline"
    //                             inputVariant="outlined"
    //                             placeholder="To"
    //                             format="MM/DD/YYYY"
    //                             margin="dense"
    //                             disableFuture
    //                             value={enddate}
    //                             classes={{ root: classes.dense }}
    //                             InputAdornmentProps={{ classes: { root: classes.dense, positionEnd: classes.endAdornment } }}
    //                             onChange={date => setEnddate(date)}
    //                         />
    //                     </Grid>
    //                 </Grid>
    //             </MuiPickersUtilsProvider>
    //         </Grid>
    //         <Grid item xs={12}>
    //             <Grid container className={classes.gridContainer}>
    //                 <Grid item xs={3}>
    //                     <Box lineHeight={2} textAlign="left" fontWeight="fontWeightBold" fontSize='h5.fontSize'>Sort / Limit</Box>
    //                 </Grid>
    //                 <Grid item xs={4}>
    //                     <FormControl variant="outlined" className={classes.formControl}>
    //                         <Select
    //                             native
    //                             onChange={handleOrderByChange}
    //                             classes={{ root: classes.select }}
    //                             value={orderBy || ''}
    //                         >
    //                             {columns.map(item => { return !item.disableSort ? <option key={item.field} value={item.field}>{item.label}</option> : '' })}
    //                         </Select>
    //                     </FormControl>
    //                 </Grid>
    //                 <Grid item xs={2}>
    //                     <FormControl variant="outlined" className={classes.formControlLast}>
    //                         <Select
    //                             native
    //                             onChange={handleOrderByDirectionChange}
    //                             value={orderByDirection}
    //                             classes={{ root: classes.select }}
    //                         >
    //                             <option key="desc" value="desc">Desc</option>
    //                             <option key="asc" value="asc">Asc</option>
    //                         </Select>
    //                     </FormControl>
    //                 </Grid>
    //                 <Grid item xs={1}>
    //                     <Typography variant='h3'>/</Typography>
    //                 </Grid>
    //                 <Grid item xs={2}>
    //                     <FormControl variant="outlined" className={classes.formControlLast}>
    //                         <Select
    //                             native
    //                             onChange={handlePageSizeChange}
    //                             value={pageSize}
    //                             classes={{ root: classes.select }}
    //                         >
    //                             {rowsPerPageOptions.map(item => { return <option key={item} value={item}>{item}</option> })}
    //                         </Select>
    //                     </FormControl>
    //                 </Grid>
    //             </Grid>
    //         </Grid>
    //     </div>
    // </Grid>
    );
}