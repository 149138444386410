import React from 'react'
import PropTypes from 'prop-types';
import MomentUtils from '@date-io/moment';

import { makeStyles } from '@material-ui/core/styles';
import {Box, OutlinedInput, Button, Divider, Grid, IconButton} from '@material-ui/core';
import {MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import {Close as CloseIcon} from '@material-ui/icons'

import {SearchContext} from 'context'
import {useApi, AutoCompleteDropdown as SearchAutocomplete} from 'components'


const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
      },
      typography: {
      padding: theme.spacing(2)
    },
    iconOpenRoot:{
        color: theme.palette.common.white,
        backgroundColor: theme.palette.npd.darkBlue,
        borderRadius: 0,
        height: 48,
        width: 48,
        '&:hover':{
            color: theme.palette.common.white,
            backgroundColor: theme.palette.npd.darkBlue,
        }
    },
    iconRoot:{
        height: 48,
        width: 48,
    },
    button:{
        margin: 10
    },
    gridContainer:{
        padding: '3px 10px',
    },
    popperPaper:{
        width: 550,
        height: 300,
        padding: theme.spacing(1),
        textAlign: 'center',
        whiteSpace: 'nowrap',
        marginBottom: theme.spacing(1),
    },
    textBox: {
        height:30,
        width: '100%',
        fontSize: 12,
        '&:input':{
            padding: "0px 5px"
        }
    },
    formControl:{
        width: '100%',
        paddingRight: 5
    },
    formControlLast:{
        width: '100%'
    },
    select: {
        padding: 8,
        fontSize: 12,
        width: '100%'
    },
    dense: {
        padding: 0,
        margin: 0,
        paddingRight: 2,
        width: '50%',
        '& > div > input':{
            padding: 8,
            fontSize: 12
        }
    },
    endAdornment: {
        padding: 0,
        '& > button':{
            padding: 0
        }
    },
  }));
  
export default function AdvancedSearchForm(props){
    const classes = useStyles();
    const {handleClose, statusFilters = []} = props;
    const searchContext = React.useContext(SearchContext);
    
    const [reportName, setReportName] = React.useState(searchContext.reportName);
    const [folderName, setFolderName] = React.useState(searchContext.folderName);
    const [lastModifiedStartdate, setLastModifiedStartdate] = React.useState(searchContext.lastModifiedStartdate);
    const [lastModifiedEnddate, setLastModifiedEnddate] = React.useState(searchContext.lastModifiedEnddate);
    const [createdStartdate, setCreatedStartdate] = React.useState(searchContext.createdStartdate);
    const [createdEnddate, setCreatedEnddate] = React.useState(searchContext.createdEnddate);
    const [batchStartdate, setBatchStartdate] = React.useState(searchContext.batchStartdate);
    const [batchEnddate, setBatchEnddate] = React.useState(searchContext.batchEnddate);
    const [status, setStatus] = React.useState(searchContext.batchStatus);
    const [format, setFormat] = React.useState(searchContext.format);


    const onApplyClick = () => {
        setFilters(false);
        handleClose();
    }
    
    const onClearClick = () => {
        setFolderName('');
        setReportName('');
        setLastModifiedEnddate(null);
        setLastModifiedStartdate(null);
        setCreatedEnddate(null);
        setCreatedStartdate(null);
        setBatchEnddate(null);
        setBatchStartdate(null);
        setFilters(true);
        setStatus({scheduleStatus: 0, displayName: ''});
        setFormat({reportOutputTypeId: 0, reportOutputType: ""});
    }
    const handleChange = (event, columnName) => {
        var value = event.target.value;
        switch(columnName){
            case 'folderName': setFolderName(value); break;
            case 'reportName': setReportName(value); break;
        }
    }

    const handleFormatChange = (event, value) => {
        
    }

    const setFilters = (shouldClear) => {
        if (shouldClear){
            searchContext.setReportName('');
            searchContext.setFolderName('');
            searchContext.setBatchEnddate(null);
            searchContext.setBatchStartdate(null);
            searchContext.setLastModifiedStartdate(null);
            searchContext.setLastModifiedEnddate(null);
            searchContext.setCreatedEnddate(null);
            searchContext.setCreatedStartdate(null);
            searchContext.setBatchStatus({scheduleStatus: 0, displayName: ''});
            searchContext.setFormat({reportOutputTypeId: 0, reportOutputType: ""});
        }
        else{
            searchContext.setReportName(reportName);
            searchContext.setFolderName(folderName);
            searchContext.setBatchEnddate(batchEnddate);
            searchContext.setBatchStartdate(batchStartdate);
            searchContext.setLastModifiedStartdate(lastModifiedStartdate);
            searchContext.setLastModifiedEnddate(lastModifiedEnddate);
            searchContext.setCreatedEnddate(createdEnddate);
            searchContext.setCreatedStartdate(createdStartdate);
            searchContext.setBatchStatus(status);
            searchContext.setFormat(format)
        }
    }
    return (
        <Grid container className={classes.root}>
            <div className={classes.popperPaper}>
                <Grid item xs={12}>
                    <Grid container>
                        <Grid item xs={11}>
                            
                        </Grid>
                        <Grid item xs={1}>
                            <IconButton onClick={handleClose}>
                                <CloseIcon/>
                            </IconButton>
                        </Grid>
                    </Grid>
                </Grid>
                <Divider></Divider>
                <Grid item xs={12}>
                <Grid container>
                    <Grid item xs={6}>
                        <Grid container>
                            <Grid item xs={12} className={classes.gridContainer}>
                                <Box lineHeight={2} textAlign="left" fontSize='subtitle.fontSize'>Folder Name</Box>
                                <OutlinedInput margin='dense' value={folderName} onChange={event =>handleChange(event, 'folderName')}  classes={{root: classes.textBox}} variant='outlined'></OutlinedInput>
                            </Grid>
                         
                        <Grid item xs={12} className={classes.gridContainer}>
                            <Box lineHeight={2} textAlign="left"  fontSize='subtitle.fontSize'>Status</Box>
                            <SearchAutocomplete
                                handleTextBoxOnChange={(event, value) => {if (event !== undefined && event.target !== undefined) setStatus({ displayName: event.target.value })}}
                                handleAutoCompleteValueOnChange={(event, value) => {if (value !== undefined && value.scheduleStatus !== undefined) setStatus(value)}}
                                inputTerm={status.displayName}
                                defaultValue={statusFilters.find((item) => item === searchContext.status) || { displayName: '', scheduleStatus: '0' }}
                                options={statusFilters}
                                placeholder=''
                                outlined={true}
                                freeSolo={false}
                                label = "displayName"
                            />
                        </Grid>
                        <Grid item xs={12} className={classes.gridContainer}>
                            <Box lineHeight={2} textAlign="left"  fontSize='subtitle.fontSize'>Created On</Box>
                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                    <KeyboardDatePicker
                                        autoOk
                                        variant="inline"
                                        inputVariant="outlined"
                                        placeholder="From"
                                        format="MM/DD/YYYY"
                                        margin="dense"
                                        value={createdStartdate}
                                        classes={{root: classes.dense}}
                                        InputAdornmentProps={{classes: {root: classes.dense, positionEnd: classes.endAdornment}}}
                                        onChange={date => setCreatedStartdate(date)}
                                    />
                                </MuiPickersUtilsProvider>
                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                    <KeyboardDatePicker
                                        autoOk
                                        variant="inline"
                                        inputVariant="outlined"
                                        placeholder="To"
                                        format="MM/DD/YYYY"
                                        margin="dense"
                                        disableFuture
                                        value={createdEnddate}
                                        classes={{root: classes.dense}}
                                        InputAdornmentProps={{classes: {root: classes.dense, positionEnd: classes.endAdornment}}}
                                        onChange={date => setCreatedEnddate(date)}
                                    />
                                </MuiPickersUtilsProvider>
                        </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={6}>
                        <Grid container>
                            <Grid item xs={12} className={classes.gridContainer}>
                                <Box lineHeight={2} textAlign="left" fontSize='subtitle.fontSize'>Report Name</Box>
                                <OutlinedInput margin='dense' value={reportName} onChange={event =>handleChange(event, 'reportName')} classes={{root: classes.textBox}} variant='outlined'></OutlinedInput>
                            </Grid>
                            
                            <Grid item xs={12} className={classes.gridContainer}>
                            <Box lineHeight={2} textAlign="left"  fontSize='subtitle.fontSize'>Last Modified On</Box>
                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                    <KeyboardDatePicker
                                        autoOk
                                        variant="inline"
                                        inputVariant="outlined"
                                        placeholder="From"
                                        format="MM/DD/YYYY"
                                        margin="dense"
                                        value={lastModifiedStartdate}
                                        classes={{root: classes.dense}}
                                        InputAdornmentProps={{classes: {root: classes.dense, positionEnd: classes.endAdornment}}}
                                        onChange={date => setLastModifiedStartdate(date)}
                                    />
                                </MuiPickersUtilsProvider>
                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                    <KeyboardDatePicker
                                        autoOk
                                        variant="inline"
                                        inputVariant="outlined"
                                        placeholder="To"
                                        format="MM/DD/YYYY"
                                        margin="dense"
                                        disableFuture
                                        value={lastModifiedEnddate}
                                        classes={{root: classes.dense}}
                                        InputAdornmentProps={{classes: {root: classes.dense, positionEnd: classes.endAdornment}}}
                                        onChange={date => setLastModifiedEnddate(date)}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>

                            <Grid item xs={12} className={classes.gridContainer}>
                            <Box lineHeight={2} textAlign="left"  fontSize='subtitle.fontSize'>Batched On</Box>
                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                    <KeyboardDatePicker
                                        autoOk
                                        variant="inline"
                                        inputVariant="outlined"
                                        placeholder="From"
                                        format="MM/DD/YYYY"
                                        margin="dense"
                                        value={batchStartdate}
                                        classes={{root: classes.dense}}
                                        InputAdornmentProps={{classes: {root: classes.dense, positionEnd: classes.endAdornment}}}
                                        onChange={date => setBatchStartdate(date)}
                                    />
                                </MuiPickersUtilsProvider>
                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                    <KeyboardDatePicker
                                        autoOk
                                        variant="inline"
                                        inputVariant="outlined"
                                        placeholder="To"
                                        format="MM/DD/YYYY"
                                        margin="dense"
                                        disableFuture
                                        value={batchEnddate}
                                        classes={{root: classes.dense}}
                                        InputAdornmentProps={{classes: {root: classes.dense, positionEnd: classes.endAdornment}}}
                                        onChange={date => setBatchEnddate(date)}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                        </Grid>    
                    </Grid>    
            </Grid>
        </Grid>
        <Divider></Divider>
        
            <Grid item xs={12}>
                <Grid container className={classes.gridContainer}>
                    <Grid item xs={8}></Grid>
                    <Grid item xs={4}>
                        <Button variant="contained" color="primary" size="small" className={classes.button} onClick={onClearClick} >Clear</Button>
                        <Button variant="contained" color="primary" size="small" className={classes.button} onClick={onApplyClick} >Apply</Button>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    </Grid>
);
}

AdvancedSearchForm.propTypes = {
    rowsPerPageOptions: PropTypes.array,
    columns: PropTypes.array.isRequired,
    handleClose: PropTypes.func
};