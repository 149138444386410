import React, { Component } from 'react'
import {BrowserRouter} from 'react-router-dom'
import { ThemeProvider } from '@material-ui/core/styles';

import theme from 'theme'
import Routes from './routes'
import Auth from 'services/authService'

export default class App extends Component {
  render(){
    return (
      <ThemeProvider theme={theme}>
          <BrowserRouter basename={process.env.REACT_APP_ROUTE_BASENAME}>
            <Auth>
              <Routes />
            </Auth>
          </BrowserRouter>
        </ThemeProvider>
    );
  }
}