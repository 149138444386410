import React, { useEffect, useState } from 'react';

import _ from 'lodash';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import { Button, Tooltip } from '@material-ui/core';
import Icon from '@mdi/react';
import { mdiIncognito } from '@mdi/js';
import { ImpersonationService } from 'services/api';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles(theme => ({

  root: {
    padding: '10px 15px',
    backgroundColor: theme.palette.background.darkGray,
    borderRadius: '0px 0px 4px 4px'
  },
  topLabel: {
    color: theme.palette.white,
    fontSize: 12,
  },
  wrapper: {
    margin: '10px 0px'
  },
  info: {
    display: 'flex',
    minWidth: 169,
    minHeight: 20,
    backgroundColor: theme.palette.white,
    borderRadius: 4,
    margin: '0px 5px'
  },
  infoValue: {
    flex: '1',
    padding: 5
  },
  infoWrapper: {
    display: 'flex',
    margin: '10px 0px',
    '& svg': {
      fill: theme.palette.white
    }
  },
  department: {
    fontSize: 9,
    display: 'block',
    lineHeight: '10px'
  },
  changeButton: {
    color: theme.palette.text.link,
    fontSize: 12,
    margin: 5,
    cursor: 'pointer'
  },
  button: {
    minWidth: 129,
    color: theme.palette.white,
    border: `1px solid ${theme.palette.white}`,
    background: 'transparent',
    padding: '0px 10px',
    fontWeight: 400
  },

}));

export default function ImpersonationDetails(props) {
  const { setView, user } = props;
  const classes = useStyles();
  const [isSaving, setIsSaving] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const CancelImpersonation = () => {
    setIsSaving(true);
    ImpersonationService.removeImpersonation().then(response => {
      window.parent.location = window.parent.location.href;
    }).catch(e => {
      enqueueSnackbar('Canceling impersonation failed.', { variant: 'warning' });
      setIsSaving(false);
    });
  }

  const ChangeImpersonation = () => {
    if (!isSaving)
      setView('select');
  }

  const getImpersonateAsDepartments = () => {
    //return first few and do ellipsis if more
    var departments = _.split(user.impersonateAsDepartment, ';');
    if (departments.length > 4) {
      departments = _.slice(departments, 0, 3);
      departments.push('...');
    }
    return departments.map((x, i) => {
      return (<React.Fragment key={i}>{x}<br /></React.Fragment>)
    });
  };

  return (
    <div className={classes.root}>
      <div className={classes.topLabel}>
        You are currently impersonating
      </div>
      <div className={classes.infoWrapper}>
        <div>
        <Icon path={mdiIncognito}
          title="User Profile"
          size={1}
          color="#E0E0E0"/>
        </div>
        <div className={classes.info}>
          <div className={classes.infoValue}>
              {user.isImpersonating && (
                <React.Fragment>
                  {user.impersonateAsUser && user.impersonateAsUser.length ? 
                    <div>
                        <span>{user.impersonateAsUser}</span>
                        <span className={classes.department}>{user.impersonateAsCompany}</span>
                        <Tooltip title={user.impersonateAsDepartment.replace(/;/g, ', ')}>
                          <span className={classes.department}>
                            {getImpersonateAsDepartments()}
                          </span>
                        </Tooltip>
                    </div>
                   : 
                    <div>
                        <span>{user.impersonateAsCompany}</span>
                        <Tooltip title={user.impersonateAsDepartment.replace(/;/g, ', ')}>
                          <span className={classes.department}>
                            {getImpersonateAsDepartments()}
                          </span>
                        </Tooltip>
                    </div>
                    }
                </React.Fragment>
              )}
          </div>
          <div className={classes.changeButton} onClick={() => ChangeImpersonation()}>Change</div>
        </div>
      </div>
      <div className={classes.wrapper} style={{textAlign: 'center'}}>
      <Button variant='contained' color='primary' size='small' className={classes.button}  disabled={isSaving} onClick={() => CancelImpersonation()} >Cancel Impersonating</Button>
      </div>
    </div>
  );
}
