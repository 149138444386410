import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
//material ui components
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, Button, TextField, Tooltip  } from '@material-ui/core';
import { Save, Cancel, List  } from '@material-ui/icons';
//custom components
import {ApplicationContext} from 'context'
import {AutoCompleteDropdown as SearchAutocomplete} from 'components'
import {JsonEditor} from './'
import CheckBox from 'components/Checkbox/CustomCheckbox'
import {dateFormat} from 'utilities'
import {useApi, MultiSelect} from 'components'

const useStyles = makeStyles(theme => ({
  root: {
    padding: 0,
    backgroundColor: 'inherit',
    minWidth: 701,
    borderRadius: '4px'
  },
  flexGrow: {
    flex: 1
  },
  metaHeader: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: '4px 4px 0 0',
    paddingLeft: 20,
  },
  metadataTitle: {
    fontSize: 12,
    fontWeight: 600,
    color: '#fff',
    textTransform: 'uppercase',
    lineHeight: '28px',
    letterSpacing: '0.5px',
    height: '33px',
    padding: '3px'
  },
  fieldLabel: {
    fontSize: 12,
    fontWeight: 600,
    paddingLeft: 20,
    paddingTop: 5,
    backgroundColor: '#fff',
    color: '#565A5C',
    height: '100%'
  },
  fieldValue: {
    fontSize: 12,
    paddingLeft: 0,
    paddingTop: 5,
    backgroundColor: '#fff',
    color: '#565A5C',
    height: '100%'
  },
  textBox: {
    height:33,
    width: '100%',
    margin: 0,
    '& > div > input':{
        padding: 6,
        fontSize: 14,
        marginLeft: 2,
        height: 19.5
      }
  },
  fieldCell: {
    paddingTop: 6,
    borderBottom: '1px solid #f8f8f8'
  },

  detailContainer:{
    boxShadow: '0 11px 22px -4px rgba(186,186,186,0.3)',
    backgroundColor:'#ffffff'
  },
  buttonRoot:{
    margin: 5
  }

}));

const defaultDelimiterOptions = [{name: 'Comma (,)', value: ','},
                                {name: 'Pipe (|)',value: '|'},
                                {name: 'TAB', value: '\t'},
                                {name: 'SemiColon (;)', value: ';'}
                                ];

function ReportEditor(props) {
  const { 
    row = {}, 
    handleClose,
    loading = false
  } = props;

  const classes = useStyles();
  const api = useApi();
  const appContext = React.useContext(ApplicationContext);

  const [reportName, setReportName] = React.useState('');
  const [delimiter, setDilimiter] = React.useState('');
  const [compression, setCompression] = React.useState(false);
  const [lf, setLf] = React.useState(false);
  const [unSuppressedAttributes, setUnSuppressedAttributes] = React.useState(false);
  const [attrAtEnd, setAttrAtEnd] = React.useState(false);
  const [immediateDeliveryInd, setImmediateDeliveryInd] = React.useState(false)
  const [appendDateToFile, setAppendDateToFile] = React.useState(false);
  const [appendSchema, setAppendSchema] = React.useState(true);
  const [moveItFolders, setMoveItFolders] = React.useState([]);
  const [outputFolder, setOutputFolder] = React.useState('');
  const [outputFolderOptions, setOutputFolderOptions] = React.useState([]);
  const [moveItFolderOptions, setMoveItFolderOptions] = React.useState([]);
  const [isTbValid, setIsTbValid] = React.useState(true);
  const [openEditorDialog, setOpenEditorDialog] = React.useState(false);

  const col1Width = 2;
  const col2Width = 2;

  React.useEffect(() => {
    if (row.moduleName !== undefined)
      api.getOutputFolderOptions(row).then(res => {
        setOutputFolderOptions(res.data);
      });
  }, []);

  React.useEffect(() => {
    if (row.moduleName !== undefined)
      api.getMoveItFolderOptions(row).then(res => {
        setMoveItFolderOptions(res.data);
      });
  }, []);

  React.useEffect(() => {
    if (!loading){
      setReportName(row.reportName);
      const rDelimiter = defaultDelimiterOptions.find((item) => item.value === row.delimiter) || {name:''};
      setDilimiter(rDelimiter.name);
      setCompression(row.compression || false);
      setLf(row.crlf || false);
      setImmediateDeliveryInd(row.immediateDeliveryInd || false);
      setAppendDateToFile(row.appendDate || false);
      setAppendSchema(row.appendSchema || false);
      setUnSuppressedAttributes(row.unSuppressAttributes || false);
      setAttrAtEnd(row.attributeAtEnd || false);
      setOutputFolder(row.regularFlatFileShare);
      setMoveItFolders(row.ffOptions.moveITFolders);
    }
  }, [row])

  const ifJson = (json) => {
    try {
        JSON.parse(json);
    } catch (e) {
        return false;
    }
    return true;
  } 
  const handleReportNameChange = (event) => {
    if (event.target.value.length > 60 || event.target.value.length === 0)
      setIsTbValid(false);
    else 
      setIsTbValid(true);
    
    setReportName(event.target.value);
  }

  const handleDelimiterTextboxChange = (event) => {
    if (event !== undefined && event.target !== undefined)
      setDilimiter(event.target.value);
  }
  const handleDelimiterAutoCompleteChange = (event, value) => {
    if (value !== undefined && value.name !== undefined)
      setDilimiter(value.name);
  }
  
  const handleCompressionChange = (event, value) => {
    setCompression(value);
  }

  const handleLfChange = (event, value) => {
    setLf(value);
  }

  const handleAppendDateChange = (event, value) => {
    setAppendDateToFile(value);
  }

  const handleImmediateDeliveryChange = (event, value) => {
    setImmediateDeliveryInd(value);
  }

  const handleOutputTextboxChange = (event, value) => {
    if (event !== undefined && event.target !== undefined)
      setOutputFolder(event.target.value);
  }
  const handleOutputAutoCompleteChange = (event, value) => {
    if (value !== undefined && value.name !== undefined)
      setOutputFolder(value.name);
  }
  const handleOutputOnBlur= (event) => {
    var value = outputFolderOptions.find(f => f.name === outputFolder);
    if (value === undefined)
      setOutputFolder('');
  }
  const handleMoveItTextboxChange = (event, value) => {
    if (event !== undefined && event.target !== undefined)
      setMoveItFolders(event.target.value);
  }
  const handleMoveItAutoCompleteChange = (event, value) => {
    if (value !== undefined)
      setMoveItFolders(value);
  }

  const onSaveClick = () => {
    var del = defaultDelimiterOptions.find(d => {return d.name === delimiter}).value;

    const updates = {
      immediateDeliveryInd: immediateDeliveryInd, 
      zipped: compression,
      appendDateToFile: appendDateToFile,
      appendSchema: appendSchema,
      crlf: lf,
      delimiter: del,
      reportName: reportName,
      regularFlatFileShare: outputFolder,
      //moveItFolders: immediateDeliveryInd ? moveItFolders : [],
      moveItFolders: moveItFolders,
      UnSuppressAttributes: unSuppressedAttributes,
      attributeAtEnd: attrAtEnd
    }
    handleClose(row, updates);
  }

  return (
    
      <Grid container spacing={0} direction="row" className={classes.root}>
        <Grid item xl={1} lg={false}></Grid>
        <Grid item lg={12} xl={10}  style={{ padding: '0 20px', margin: 0 }}>
          <Grid className={clsx(classes.detailContainer)} container>
            <Grid item className={clsx(classes.metaHeader)} xs={12} sm={12}>
              <Typography variant="h5" className={classes.metadataTitle}>Edit Report</Typography>
            </Grid>
            <Grid item className={classes.fieldCell} xs={6} sm={col1Width}>
              <Typography className={classes.fieldLabel}>Report Name</Typography>
            </Grid>
            <Grid item className={classes.fieldCell} xs={6} sm={col2Width}>
              <TextField
                error={!isTbValid}
                onChange={handleReportNameChange} 
                margin='dense' 
                label={!isTbValid ? 'max: 60, min: 1': ''}
                variant='outlined' 
                value={reportName}
                className={classes.textBox} />
            </Grid>

            <Grid item className={classes.fieldCell} xs={6} sm={col1Width}>
              <Typography className={classes.fieldLabel}>Line Feed (LF)</Typography>
            </Grid>
            <Grid item className={classes.fieldCell} xs={6} sm={col2Width}>
              <CheckBox checked={lf} onChange={handleLfChange}></CheckBox>
            </Grid>

            <Grid item className={classes.fieldCell} xs={6} sm={col1Width}>
              <Typography className={classes.fieldLabel}>UnSuppress Attributes</Typography>
            </Grid>
            <Grid item className={classes.fieldCell} xs={6} sm={col2Width}>
              <CheckBox checked={unSuppressedAttributes} onChange={(event, value) => setUnSuppressedAttributes(value) }></CheckBox>
            </Grid>

            <Grid item className={classes.fieldCell} xs={6} sm={col1Width}>
              <Typography className={classes.fieldLabel}>Output Folder</Typography>
            </Grid>
            <Grid item className={classes.fieldCell} xs={6} sm={col2Width}>
              <SearchAutocomplete 
                      handleTextBoxOnChange={handleOutputTextboxChange} 
                      handleAutoCompleteValueOnChange={handleOutputAutoCompleteChange} 
                      handleOnBlur={handleOutputOnBlur}
                      defaultValue={outputFolderOptions.find((item) => item.name === row.outputFolder)}
                      inputTerm={outputFolder}
                      getOptionLabel={option => option.name} 
                      options={outputFolderOptions} 
                      placeholder={outputFolder}
                      outlined={true} 
                      freeSolo={false}
                  />
            </Grid>

            <Grid item className={classes.fieldCell} xs={6} sm={col1Width}>
              <Typography className={classes.fieldLabel}>Zipped</Typography>
            </Grid>
            <Grid item className={classes.fieldCell} xs={6} sm={col2Width}>
              <CheckBox checked={compression} onChange={handleCompressionChange}></CheckBox>
            </Grid>

            <Grid item className={classes.fieldCell} xs={6} sm={col1Width}>
              <Typography className={classes.fieldLabel}>Attributes at End</Typography>
            </Grid>
            <Grid item className={classes.fieldCell} xs={6} sm={col2Width}>
              <CheckBox checked={attrAtEnd} onChange={(event, value) => setAttrAtEnd(value) }></CheckBox>
            </Grid>
            
            <Grid item className={classes.fieldCell} xs={6} sm={col1Width}>
              <Typography className={classes.fieldLabel}>Delimiter</Typography>
            </Grid>
            <Grid item className={classes.fieldCell} xs={6} sm={col2Width}>
                <SearchAutocomplete 
                    handleTextBoxOnChange={handleDelimiterTextboxChange} 
                    handleAutoCompleteValueOnChange={handleDelimiterAutoCompleteChange} 
                    defaultValue={defaultDelimiterOptions.find((item) => item.value === row.delimiter)}
                    getOptionLabel={option => option.name} 
                    options={defaultDelimiterOptions} 
                    placeholder={delimiter}
                    outlined={true}
                    freeSolo={false}
                    getOptionDisabled={(x) => x.name === ''}
                    noOptionsText="Move It folder not available"
                />
            </Grid>

            <Grid item className={classes.fieldCell} xs={6} sm={col1Width}>
              <Typography className={classes.fieldLabel}>Append Date</Typography>
            </Grid>
            <Grid item className={classes.fieldCell} xs={6} sm={col2Width}>
              <CheckBox checked={appendDateToFile} onChange={handleAppendDateChange}></CheckBox>
            </Grid>

            <Grid item className={classes.fieldCell} xs={6} sm={col1Width}>
              <Typography className={classes.fieldLabel}>Append Schema</Typography>
            </Grid>
            <Grid item className={classes.fieldCell} xs={6} sm={col2Width}>
              <CheckBox checked={appendSchema} onChange={(event, value) => setAppendSchema(value)}></CheckBox>
            </Grid>
            
            <Grid item className={classes.fieldCell} xs={6} sm={col1Width}>
              <Typography className={classes.fieldLabel}>Move It Share</Typography>
            </Grid>
            <Grid item className={classes.fieldCell} xs={6} sm={col2Width}>
              <Tooltip placement='top' arrow={true} title={!immediateDeliveryInd ? "Please check Immediate Delivery to enable output to Move It Shares" : ''}>
                <span>
                  <MultiSelect 
                    handleTextBoxOnChange={handleMoveItTextboxChange} 
                    handleAutoCompleteValueOnChange={handleMoveItAutoCompleteChange} 
                    //defaultValue={row.moveItFolders.length > 0 ? row.moveItFolders.split(',') : []} 
                    inputTerm={row.moveItFolders.length > 0 ? row.moveItFolders.split(',') : []} 
                    options={moveItFolderOptions} 
                    outlined={true} 
                    freeSolo={false}
                    //disabled={!immediateDeliveryInd}
                  />
                </span>
              </Tooltip>
            </Grid>

            <Grid item className={classes.fieldCell} xs={6} sm={col1Width}>
              <Typography className={classes.fieldLabel}>Immediate Delivery</Typography>
            </Grid>
            <Grid item className={classes.fieldCell} xs={6} sm={col2Width}>
              <CheckBox checked={immediateDeliveryInd} onChange={handleImmediateDeliveryChange}></CheckBox>
            </Grid>

            <Grid item className={classes.fieldCell} xs={6} sm={col2Width+col1Width}>
              <Button
                  classes={{root: classes.buttonRoot}}
                  variant="contained"
                  color="primary"
                  size="small"
                  disabled={loading}
                  onClick={handleClose}
                  className={classes.button}
                  startIcon={<Cancel/>}
                > Cancel
                </Button>
                <Button
                  classes={{root: classes.buttonRoot}}
                  variant="contained"
                  color="primary"
                  onClick={onSaveClick}
                  size="small"
                  className={classes.button}
                  disabled={!isTbValid || loading}
                  startIcon={<Save/>}
                > Save
                </Button>
                {appContext.appInfo.user.isSU &&
                  <Button
                    classes={{root: classes.buttonRoot}}
                    variant="contained"
                    color="primary"
                    onClick={() => setOpenEditorDialog(true)}
                    size="small"
                    className={classes.button}
                    startIcon={<List/>}
                  > Show Json
                  </Button>
                } 
            </Grid>
            <JsonEditor open={openEditorDialog} setOpen={setOpenEditorDialog} jsonObj={ifJson(row.flatFileOptions) ? row.flatFileOptions : "{}"} reportId={row.reportId} schemaId={row.schemaId}></JsonEditor>
          </Grid>
        </Grid>
      </Grid>
  )
}

ReportEditor.propTypes = {
  row: PropTypes.shape({
    reportName: PropTypes.string.isRequired,
  }),
}

export default ReportEditor;

