import React, { useState } from "react";

import { useApi, CustomSelect } from 'components';
import { DataSourceContext } from 'context';

export default function DataSourceSelection(props) {
  const api = useApi();
  const context = React.useContext(DataSourceContext);

  const dataSourceSelectedKey = context.dataSourceSelectedKey;
  const setDataSourceSelectedKey = context.setDataSourceSelectedKey;
  const [dataSources, setDataSources] = useState([]);
  
  // load only once  
  React.useEffect(() => {
      api.getDataSource(context.appKey).then(res => {
        setDataSources(res.data);
        if (res.data.length)
          setDataSourceSelectedKey(res.data[0].token)
      })}, []);
  

  const preventDefault = event => event.preventDefault();
  return (
          <CustomSelect
            options={dataSources}
            keyName={dataSourceSelectedKey} 
            setKeyName={setDataSourceSelectedKey} 
            valueColumn="summarySourceName"
            keyColumn="token"
            showSearch={true}
          ></CustomSelect>
  );
}

DataSourceSelection.propTypes = {
};
