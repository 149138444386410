import React, { useContext } from 'react';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import {DataSourceContext} from 'context';
import { Star as StarIcon } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
//import { RecentlyViewed } from 'components';




const useStyles = makeStyles(theme => ({
  root: {
  },

  cardContent: {
    paddingTop: 16,

    '& > div': {
      marginRight: 15
    },

    '& > div:last-child': {
      marginRight: 0
    }
  },

  tooltipWrapper: {
    display: 'flex',
    flexDirection: 'column',
    padding: 5,
    alignItems: 'center'
  },

  tooltip: {
    fontSize: 12,
    color: theme.palette.text.secondary
  },

  tooltipHeader: {
    fontWeight: 'bold'
  }
}));


export default function RecentlyViewedCollections(props) {
  const { data, onExport, onDelete } = props;
  const context = useContext(DataSourceContext);
  const history = useHistory();
  const handleClick = (id) => {
    history.push(`/collections/${id}?ds=${context.dataSourceSelectedKey}`);
  };
 
  const getImageKeys = (item) => {
    return  Promise.resolve({
      data : item.previewImages
    });
  };
  


  return (
    <div/>
    // <RecentlyViewed
    //   onExport={onExport}
    //   onDelete={onDelete}
    //   data={data}
    //   onClick={handleClick}
    //   getImageKeys = {getImageKeys}
    //   titleIcon={<StarIcon style={{fontSize:18}} />}
    //   noImageMsg='No books in collection'
    //   properties = {{
    //     id: 'id',
    //     createdDate: 'createdDate',
    //     size: 'size',
    //     name: 'name',
    //     lastAccessDate: 'lastAccessDate'
    //   }}
    // />
  );
}

RecentlyViewedCollections.propTypes = {
};
