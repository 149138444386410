import React from 'react';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';
import { Autocomplete } from "@material-ui/lab";
import useApi from 'components/Api/Api'
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';

const useStyles = makeStyles(theme => ({
  autoCompleteGroupLabel: {
    fontWeight: 600,
    borderBottom: `1px solid ${theme.palette.tableRowDivider}`,
    padding: '0px 3px',
    margin: '5px 10px',
    height: 20,
    lineHeight: '10px'
  },
  autoCompleteGroupList: {
    paddingBottom: 10,
    paddingTop: 3,
    paddingLeft: 3
  },
  autoCompleteItem: {
    color: theme.palette.npd.blue,
    width: '100%',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap'
  },
  autoCompleteOption: {
    fontSize: 12,
    margin: 0,
    padding: '1px 10px'
  },
  paper: {
    borderRadius: '0px 0px 4px 4px',
    margin: '1px 0',
    '& > ul':
    {
      maxHeight: '50vh'
    },
    boxShadow: theme.palette.boxShadow
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
    fontSize: 14
  },
  inputRoot: {
    paddingRight: 0
  },
  autoCompleteOutlineRoot: {
    border: 'solid 1px #a6a6a6',
    borderRadius: 4,
    '&:hover': {
      border: 'solid 1px',
      borderColor: theme.palette.common.black
    },
    '&:focus': {
      border: 'solid 2px',
      borderColor: theme.palette.npd.blue
    }
  },
  tag: {
    //margin: 3,
    height: 24,
    maxWidth: 'calc(100% - 30px)',
    fontSize: 12
  },
  label: {
    marginRight:6,
    overflow:"hidden",
    textOverflow: "ellipsis",
    whiteSpace:'nowrap',
    display: 'inline-block'
  }
}));



function MultiSelect(props) {
  const { 
    inputTerm,
    options, 
    handleOnEnter, 
    handleAutoCompleteValueOnChange,
    handleTextBoxOnChange, 
    freeSolo = true,
    handleOnFocus,
    handleOnBlur,
    placeholder = 'Search',
    outlined = false,
    hideClearButton = true,
    ...rest
   } = props;
  const classes = useStyles();
  
  let initialValue = inputTerm;

  React.useEffect(() => {
    initialValue = inputTerm;
  }, [inputTerm]);

  return (
    <Autocomplete
      {...rest}
      multiple
      defaultValue={initialValue}
      filterSelectedOptions
      id="tags-outlined"
      options={options}
      autoComplete={true}
      clearOnEscape={true}
      freeSolo = {freeSolo}
      getOptionLabel={option => option}
      disableClearable={hideClearButton}
      onChange={handleAutoCompleteValueOnChange}
      classes={{tag: classes.tag ,  paper:classes.paper, input: classes.input, inputRoot: classes.inputRoot, option: classes.autoCompleteOption, groupLabel: classes.autoCompleteGroupLabel, groupUl: classes.autoCompleteGroupList }}
      ChipProps={{size: 'small', classes:{label: classes.label} }}
      renderInput={params => (
          <TextField
            {...params}
            InputProps={{ ...params.InputProps, disableUnderline: true }}
            inputProps={{ ...params.inputProps, maxLength: 1000, 'aria-label': {placeholder} }}
            classes={{ root: outlined ? classes.autoCompleteOutlineRoot: classes.autoCompleteRoot }}
            fullWidth
          />
        )}
    />     
  );
}


export default MultiSelect;