import { colors } from '@material-ui/core';

const white = '#FFFFFF';
const black = '#000000';

//const npdBlue = '#0078BE';
//const npdGreen = '#82C341';
//const npdOrange = '#EC7A08';
//const npdYellow = '#F0AB00';
//const npdRed = '#822433';
//const npdDarkBlue = '#00517D';
//const npdGray = '#8E908F';

const npdBlue = '#4E106F';
const npdGreen = '#FDD900';
const npdOrange = '#EF2A79';
const npdYellow = '#FDD900';
const npdRed = '#EF2A79';
const npdDarkBlue = '#000000';
const npdGray = '#000000';

export default {
  black,
  white,
  npd: {
    blue: npdBlue,
    green: npdGreen,
    orange: npdOrange,
    yellow: npdYellow,
    red: npdRed,
    darkBlue: npdDarkBlue,
    gray: npdGray
  },
  primary: {
    contrastText: white,
    dark: npdDarkBlue,
    main: npdBlue,
    light: '#99C9E5'
  },
  secondary: {
    contrastText: white,
    dark: colors.blue[900],
    main: '#99C9E5',
    light: colors.blue['A400']
  },
  success: {
    contrastText: white,
    dark: colors.green[900],
    main: colors.green[600],
    light: colors.green[400]
  },
  info: {
    contrastText: white,
    dark: colors.blue[900],
    main: colors.blue[600],
    light: colors.blue[400]
  },
  warning: {
    contrastText: white,
    dark: colors.orange[900],
    main: colors.orange[600],
    light: colors.orange[400]
  },
  error: {
    contrastText: white,
    dark: colors.red[900],
    main: colors.red[600],
    light: colors.red[400]
  },
  text: {
    primary: colors.blueGrey[700],
    secondary: colors.blueGrey[600],
    link: colors.blue[600]
  },
  background: {
    default: '#FCFDFE',
    paper: white,
    darkGray: '#565A5C'
  },
  chart: [

  ],
  icon: colors.blueGrey[600],
  divider: '#F8F8F8',
  tableRowDivider: '#D8D8D8',
  gray: npdGray,
  contentBackground: '#f2f8fb',
  contentText: '#565A5C'
};
