import React, {forwardRef,useContext} from 'react'
import clsx from 'clsx';
import {NavLink} from 'react-router-dom'
import {List, ListItem, Tooltip, Button} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles'
import { Help as HelpSection } from './Components';
import {ApplicationContext} from 'context';
import Pages from 'Pages'
//import Icon from '@mdi/react';
//import { mdiFileCabinet as HomeIcon } from '@mdi/js';


const useStyle = makeStyles(theme => ({
    List: {
        flexGrow: 2
      },
    root: {
    color: theme.palette.grey[500],
    borderRadius: 0,
    textTransform: 'none',
    letterSpacing: 0,
    minWidth: 100,
    //lineHeight: '14px',
    //fontSize:'small'
    },
    active: {
        '& $icon': {
          color: theme.palette.npd.blue,
          alignItems: 'center',
          fontSize:'16px',
          lineHeight: '24px'
          
         }
      },
    icon: {
        alignItems: 'center',
        fontSize:'16px',
        lineHeight: '24px'
      },
      item: {
       paddingTop: 0
      },
      button: {
        color: theme.palette.npd.gray,
        borderRadius: 0,
        textTransform: 'none',
        letterSpacing: 0,
        minWidth: 100,
        lineHeight: '14px',
        padding:'0 0 0 0',
        alignItems: 'center'
      },
      buttonLabel: {
        display: 'flex',
        flexDirection: 'column'
      },
      iconWrapper: {
        alignItems: 'center',
      },
      text: {
        fontSize: 10,
        fontWeight: 500,
        textAlign: 'center',
        lineHeight: '12px',
        flexWrap: 'wrap',
        width: 100
       },      
}));



const CustomRouterLink = forwardRef((props, ref) => (
    <div
      ref={ref}
      style={{ flexGrow: 1 }}
    >
      <NavLink activeClassName={props.activeclassname} {...props} />
    </div>
  ));
  


export default function SideBarNav()
{
    const appContext = React.useContext(ApplicationContext);
    const classes = useStyle();

    const isVisible = (keys) =>{
      var item = keys.map(k => appContext.appInfo.user[k])
      if (item.indexOf(true) > -1)
        return true;
      return false;
    }    

    return (
        <List className={classes.List}>
            {Pages.map(page => (isVisible(page.isVisibleKey) && 
                <ListItem disableGutters key={page.title}>
                    <Tooltip title={page.title}>
                    <Button
                        activeclassname={classes.active} 
                        className={clsx(classes.button)}
                        classes={{label: classes.buttonLabel }}
                        to={page.href}
                        component={page.link ? undefined : CustomRouterLink}
                        target={page.link ? ('myWindow') : ''}
                        href={page.link ? appContext.appInfo.decisionKeyUrl : page.href}
                        >
                            <div className={classes.icon}>{page.icon }</div>
                            <div className={classes.text}>{page.text}</div>                        
                        </Button>
                    </Tooltip>
                </ListItem>
                ))}
                { (appContext.appInfo.user.hasCCAccess || appContext.appInfo.user.hasFCAccess) &&
                  <ListItem disableGutters key='help_key'>
                      <span><HelpSection></HelpSection></span>
                  </ListItem>     
                }           
        </List>
    )
}


