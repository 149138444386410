import React from "react";
import { useSnackbar } from 'notistack';
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from '@material-ui/core'
// custom components
import { EnhancedTable, useApi, useWindowDimensions } from 'components';
import { SearchContext, DataSourceContext, GridContext, ApplicationContext } from 'context';
import { Topbar } from 'Topbar';
import { AdvancedSearchForm, Search, ActionCell, EnhancedToolbar } from './Components'


const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.contentBackground,
    padding: '0px',
    margin: '0px'
  }
}));
//lastViewDate
const columns = [{ field: 'folderName', type: 'string', label: 'Folder Name' },
{ field: 'reportName', type: 'string', label: 'Report Name' },
{ field: 'runDate', type: 'date', label: 'Batched On', format: 'MM-DD-YYYY hh:mm A' },
{ field: 'createdUserName', type: 'string', label: 'Batched By' },
{ field: 'statusName', type: 'selectiveLinkWithTooltip', label: 'Status', showTooltip: true, onClick: () => {}, tooltipField:'tooltip' },
{ field: 'expiredDate', type: 'date', label: 'Expires On', format: 'MM-DD-YYYY hh:mm A' },
{ field: 'reportOutputType', type: 'string', label: 'Format', disableSort: true },
{ field: 'lastViewDate', type: 'date', label: 'Last Accessed', format: 'MM-DD-YYYY' },
{ field: 'action', type: 'action', label: '', disableSort: true }]

const rowsPerPageOptions = [10, 25, 50, 75, 100, 200, 500];

export default function BatchReports(props) {
  const { Toolbar, toolbarProps } = props;
  //searchcontext
  const [pageSize, setPageSize] = React.useState(25);
  const [orderBy, setOrderBy] = React.useState('reportName');
  const [orderByDirection, setOrderByDirection] = React.useState('asc');
  
  const [folderName, setFolderName] = React.useState('');
  const [reportName, setReportName] = React.useState('');
  const [batchStartdate, setBatchStartdate] = React.useState(null);
  const [batchEnddate, setBatchEnddate] = React.useState(null);
  const [batchedBy, setBatchedBy] = React.useState('');
  const [batchStatus, setBatchStatus] = React.useState({scheduleStatus: 0, displayName: ""});
  const [format, setFormat] = React.useState({reportOutputTypeId: 0, reportOutputType: ""});
  
  const [totalCount, setTotalCount] = React.useState(0);
  const [rowsSelected, setRowsSelected] = React.useState([]);
  const [page, setPage] = React.useState(1);
  const [batchReports, setBatchedReports] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  const [statusFilters, setStatusFilters] = React.useState([]);
  const [reportOutputTypesFilters, setReportOutputTypesFilters] = React.useState([]);

  const dataSourceContext = React.useContext(DataSourceContext);
  const gridContext = React.useContext(GridContext);
  const appContext = React.useContext(ApplicationContext);

  const { enqueueSnackbar } = useSnackbar();
  const api = useApi();

  const handleMultiDeleteClick = () => {
    api.isAuthenticated().then(res => {
      if (res.data.isAuthenticated) {
        api.deleteReport(rowsSelected).then((res) => {
          setRowsSelected([]);
          loadBatchedReports();
          if (res.data)
            enqueueSnackbar(res.data, { 
              variant: 'error', 
              autoHideDuration: 10000
            });
        });
      }
    });
  }

  const handleBatchMultiDownloadClick = () => {
    if (rowsSelected.length > appContext.appInfo.maxMultiFiledownload)
          enqueueSnackbar("You can only download maximum of " + appContext.appInfo.maxMultiFiledownload + " files at a time.", { variant: 'warning' });
    else {
        api.isAuthenticated().then(res => {
          if (res.data.isAuthenticated) {
            let notFound = [];
            rowsSelected.map((r) => {
              if (r.isExists)
                api.downloadBatchedReport(r.scheduleEventId, r.token)
              else
                notFound.push(r.reportName);
            });
            if (notFound.length !== 0)
              enqueueSnackbar("Following files were not found: " + notFound.toString(), { variant: 'warning' });
          }
        });
    }
  }

  const handleZipBatchMultiDownloadClick = () => {
    if (rowsSelected.length > appContext.appInfo.maxMultiFiledownload)
      enqueueSnackbar("You can only download maximum of " + appContext.appInfo.maxMultiFiledownload + " files at a time.", { variant: 'warning' });
    else {
      api.isAuthenticated().then(res => {
        if (res.data.isAuthenticated) {
          let notFound = [];
          let found = [];
          rowsSelected.map((r) => {
            if (r.isExists)
              found.push(r);
            else
              notFound.push(r.reportName);
          });
          if (found.length > 0)
          {
              if (found.length === 1)
                api.downloadBatchedReport(found[0].scheduleEventId, found[0].token)
              else 
                api.downloadBatchedReportZip(found);
          }
          
          if (notFound.length !== 0)
            enqueueSnackbar("Following files were not found: " + notFound.toString(), { variant: 'warning' });
        }
      });
    }
  }

  React.useEffect(() => {
      api.getStatusFilters().then(res => {
          if (res && res.data)
              setStatusFilters(res.data);
      });
  }, []);

  React.useEffect(() => {
    api.getReportOutputTypes().then(res => {
        if (res && res.data)
            setReportOutputTypesFilters(res.data);
    });
  }, []);

  React.useEffect(() => {
    loadBatchedReports();
    setPage(1);
  }, [dataSourceContext.dataSourceSelectedKey])

  React.useEffect(() => {
    
    loadBatchedReports();
  }, [pageSize, page, batchedBy, batchStatus, orderByDirection, format, orderBy,  reportName, folderName, batchStartdate, batchEnddate])

  const loadBatchedReports = () => {
    
    //setRowsSelected([]);
    let sortInfo = '';
    if (orderBy !== '' && orderBy !== undefined && orderBy !== null) {
      const orderCol = columns.find((item) => orderBy === item.label || orderBy === item.field).field;
      sortInfo = "[{'Property': '" + orderCol + "' , 'direction' : '" + orderByDirection + "' }]";
    }
    let filter = {
      reportName: reportName,
      folderName: folderName,
      startRunDateFrom: (batchStartdate === null || batchStartdate === undefined) ? null : batchStartdate.format("MM/DD/YYYY"),
      startRunDateTo: (batchEnddate === null || batchEnddate === undefined) ? null : batchEnddate.format("MM/DD/YYYY"),
    };
    if (batchStatus.scheduleStatus !== '0') filter.statusId = batchStatus.scheduleStatus;
    if (batchedBy !== '') filter.createdBy = batchedBy;
    if (format.reportOutputTypeId !== '0') filter.reportOutputTypeId = format.reportOutputTypeId;
    
    let options = { page: page, pageSize: pageSize, filter: filter, sortInfo: sortInfo, token: dataSourceContext.dataSourceSelectedKey }

    if (dataSourceContext.dataSourceSelectedKey !== '') {
      setLoading(true);
      api.getBatchedReports(options).then(res => {
        setBatchedReports(res.data.data);
        setPage(res.data.page);
        setPageSize(res.data.pageSize);
        setTotalCount(res.data.totalCount);
        setLoading(false);
      })
    }
  }

  const onChangePage = (newPage) => {
    setPage(newPage);
  }
  const onPageSizeChange = (newPageSize) => {
    setPage(1);
    api.saveRowPerPage({rowsPerPageOptions: newPageSize});
    appContext.appInfo.user.rowsPerPage = newPageSize;
    setPageSize(newPageSize);
  }
  const onRequestSort = order => {
    setOrderBy(order.orderBy);
    setOrderByDirection(order.order)
  }
  const classes = useStyles();
  return (
    <SearchContext.Provider
      value={{ pageSize, setPageSize, orderBy, setOrderBy, orderByDirection, setOrderByDirection, folderName, setFolderName, 
              reportName, setReportName, batchEnddate, setBatchEnddate, batchStartdate, setBatchStartdate, batchedBy, setBatchedBy,
              batchStatus, setBatchStatus, format, setFormat, page, setPage }}>
      <GridContext.Provider value={{ rowsSelected, setRowsSelected }}>
        <React.Fragment >
          <Grid item >
            <Topbar
              brandName="File Center"
              columns={columns}
              rowsPerPageOptions={rowsPerPageOptions}
              AdvancedSearchForm={AdvancedSearchForm}
              AdvancedSearchFormProps={{columns: columns, rowsPerPageOptions: rowsPerPageOptions, reportOutputTypesFilters: reportOutputTypesFilters, statusFilters:statusFilters}}
              Search={Search}
            />
          </Grid>
          <Grid item >
            {/*<Toolbar {...toolbarProps} refresh={loadBatchedReports} ></Toolbar>*/}
            <EnhancedTable
              classes={classes}
              rowKeyProperty="scheduleEventId"
              enablePaging={true}
              mode='remote'
              page={page}
              rowsPerPage={pageSize}
              columns={columns}
              rows={batchReports}
              groupKey='dataSourceDisplayName'
              groupKeyId='groupId'
              totalCount={totalCount}
              onChangePage={onChangePage}
              onChangeRowsPerPage={onPageSizeChange}
              onRequestSort={onRequestSort}
              defaultOrder={orderByDirection}
              defaultOrderBy={orderBy}
              loading={loading}
              title="Batched Reports"
              ActionCell={ActionCell}
              actionCallback={loadBatchedReports}
              height={useWindowDimensions().height - 180}
              FileCenterToolbar={Toolbar}
              FileCenterToolbarProps={{ refresh: loadBatchedReports, ...toolbarProps }}
              EnhancedTableToolbar={EnhancedToolbar}
              EnhancedTableToolbarProps={{ multidelete: handleMultiDeleteClick, refresh: loadBatchedReports, multidownload: appContext.appInfo.user.enableZipFC ? handleZipBatchMultiDownloadClick : handleBatchMultiDownloadClick }}
              persistSelection={true}
            />
          </Grid>
        </React.Fragment>
      </GridContext.Provider>
    </SearchContext.Provider>

  );
}

BatchReports.propTypes = {

}
