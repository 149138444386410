import request from './request'
const useReportApi = () => {
    const getAll = (options) => {
        return request({
         url: 'api/report/list',
         method: 'POST',
         data: {
            pagingInfo: options.pagingInfo,
           filter: options.filter,
         },
 
     });
 };

 const autoSuggestReportName = (keyword) => {
     return request({
         url: 'api/report/autosuggest/report',
         method: 'GET',
         params: {
            searchString: keyword
         }
     });
 }

 const batchReport = (flatFile) => {
    // alert(flatFile);
    return request({
      url: 'api/Report/batchreport',
      method: 'POST',
      data: flatFile
    });
  };

  const unqueueReport = (flatFile) => {
    return request({
      url: 'api/report/unqueuereport',
      method: 'POST', 
      data: flatFile
    });
  };

  const downloadBatchedReport = (scheduleIds, token) => {
    window.open('api/filecenter/batchedreports/downloadfromids?scheduleIds='+scheduleIds+'&token='+ encodeURIComponent(token), '_blank', '', true);
  };

  const isAuthenticated = () => {
    return request({
      url: '/account/isAuthenticated',
      method: 'GET'
    });  
  };

 return {
        getAll,
        batchReport,
        unqueueReport,
        downloadBatchedReport,
        isAuthenticated, 
        autoSuggestReportName 
    };
}
  
   


export default useReportApi;