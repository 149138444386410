import React from 'react';
import Moment from 'react-moment';

export const dateFormat = (value, format) => {
  if (!Boolean(value)) {
    return null;
  }
  
  if (!Boolean(format)) {
    format = 'YYYY-MM-DD';
  }

  return <Moment format={format}>{value}</Moment>;
};