import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { TableHead, TableRow, TableCell, TableSortLabel } from '@material-ui/core';
import CustomCheckbox from 'components/Checkbox/CustomCheckbox'

const capitalize = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export default function EnhancedTableHeader(props) {
  const { classes, columns, order, orderBy, onRequestSort, numSelected, rowCount, onSelectAllClick, customHeaderComponent,noLeadingCheckbox } = props;
  
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  const getSortField = headCell => {
    return Boolean(headCell.sortField) ? headCell.sortField : headCell.field;
  };

  return (
    <TableHead className={classes.tableHead}>
      <TableRow>
        {(noLeadingCheckbox!=='true')?
        <TableCell padding="checkbox">
          <CustomCheckbox tooltip="Select All"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all Reports' }}
          />
        </TableCell>:null}
        {columns.map(headCell => (
          <TableCell
            key={headCell.field}
            className={clsx(
              classes.headCell,
              classes[`headCell${capitalize(headCell.type)}`]
            )}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.field ? order : false}
          >

            {!headCell.disableSort ? <TableSortLabel
              classes={{
                root: classes.tableSortLabelRoot,
                icon: classes.tableSortLabelIcon,
                iconDirectionAsc: classes.tableSortLabelIconDirectionAsc,
                iconDirectionDesc: classes.tableSortLabelIconDirectionDesc
              }}
              active={orderBy === getSortField(headCell)}
              direction={order}
              onClick={createSortHandler(getSortField(headCell))}
            >
              {orderBy === getSortField(headCell) ? (<span className={classes.headCellLabelSorted}>{headCell.label}</span>) : headCell.label}
              {orderBy === getSortField(headCell) ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel> : headCell.label}
          </TableCell>
        ))}
      </TableRow>
      {(customHeaderComponent !== null) ? customHeaderComponent : ''}
    </TableHead>
  );
}

EnhancedTableHeader.propTypes = {
  classes: PropTypes.object.isRequired,
  columns: PropTypes.array.isRequired,
  numSelected: PropTypes.number.isRequired,
  rowCount: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']),
  orderBy: PropTypes.string
};