import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

// @material-ui/core components
import { Typography, Grid, Divider, Button } from '@material-ui/core';
import { Add as AddIcon, Edit as EditIcon } from '@material-ui/icons';
import Fab from '@material-ui/core/Fab';
import { makeStyles } from '@material-ui/core/styles';
import { NavLink as RouterLink } from 'react-router-dom';
// components
//import { ExportButtonPro, InfoTooltip } from 'components';
import { DataSourceContext } from 'context';

//services
import { dateFormat } from 'utilities';


const useStyles = makeStyles(theme => ({
  root: {
  },
  banner: {
    height: 125,
    width: '100%',
    padding: '20px 0px',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
  dataSourceText: {
    fontSize: 11,
    textAlign: 'center',
    lineHeight: '20px',
    height: 24,
    color: theme.palette.text.primary
  },
  bannerTitle: {
    fontSize: 28,
    textAlign: 'center',
    lineHeight: '30px',
    color: theme.palette.text.primary
  },
  bannerSubtitle: {
    fontSize: 13,
    textAlign: 'left',
    lineHeight: '20px',
    color: theme.palette.text.primary,
    
    paddingRight: 150,
    paddingLeft: 150,
  },
  buttonPanel: {
    width: '100%',
    height: 0,
    textAlign: 'right',
    paddingRight: 20
  },
  contentPanel: {
    padding: `40px ${theme.spacing(4)}px 0 ${theme.spacing(4)}px`,
    width: '100%'
  },
  addButton: {
    color: theme.palette.white,
    width: 40,
    height: 40,
    top: '-20px',
    backgroundColor: theme.palette.npd.green,
    boxShadow: 'none',
    '&:hover':
    {
      backgroundColor: theme.palette.npd.green,
      boxShadow: 'none',
      transform: 'scale(1.2)',
      transition: 'all .3s ease-in-out'
    }
  },
  disabledButton: {
    color: `${theme.palette.white} !important`,
    backgroundColor: `${theme.palette.tableRowDivider} !important`
  },

  headerTitle: {
    flexGrow: 1,
    fontSize: 20,
    fontWeight: 'bold',
    lineHeight: '24px',
    color: '#353638',
    paddingBottom: 10
  },

  divider: {
    backgroundColor: 'rgba(216,216,216,0.42)'
  },

  tooltipWrapper: {
    display: 'flex',
    flexDirection: 'column',
    padding: 5,
    alignItems: 'center'
  },

  tooltip: {
    fontSize: 12,
    color: theme.palette.text.secondary
  },

  tooltipHeader: {
    fontWeight: 'bold'
  },

  breadcrumb: {
    fontSize: 12,
    fontWeight: 'bold',
    lineHeight: '14px',
    color: '#8E908F'
  },
  breadcrumbArrow: {
    paddingLeft: '5px',
  },
  editButton:{

  }
}));

export default function ViewLayout(props) {
  const {
    showToolbar,
    showAdd,
    allowAdd,
    children,
    title,
    dataSourceWeek,
    showBreadcrumb,
    breadcrumbText,
    breadcrumbLink,
    breadcrumbText2,
    breadcrumbLink2,
    bannerClass,
    showDataSourceTooltip,
    showDivider,
    showEdit = false,
    allowEdit = false,
    allowExport = false,
    bannerTitle,
    bannerSubtitle,
    exportTypes,
    onExport = () => { },
    onAdd = () => { },
    onEdit = () => { },
    hideDataSourceLabel = false,
    exportButtonProps = {},
    headerComponents = null
  } = props;


  const classes = useStyles();
  const context = React.useContext(DataSourceContext);

  return (
    <div className={classes.root}>
      <Grid container direction='column' alignItems='center' justify='flex-end'>
        <Grid item className={clsx(classes.banner, bannerClass)}>
          <Typography className={classes.bannerTitle}>Welcome to <b>Bundles</b></Typography>
          <Typography className={classes.bannerSubtitle}>
          <b>Bundles</b> enables you to group together and download <b>DecisionKey&#174;</b> Batch Reports within or across all data sources you have access to. 
          Reports are downloaded to a single MS Excel workbook. 
          </Typography>
        </Grid>

        <Grid item container direction='column' className={classes.contentPanel}>

            <Grid item>
            {children}
            </Grid>
            </Grid>
      </Grid>
    </div >
  );
}

ViewLayout.propTypes = {
  children: PropTypes.element,
  dataSourceWeek: PropTypes.string,
  showDataSourceTooltip: PropTypes.bool,
  showBreadcrumb: PropTypes.bool,
  showDivider: PropTypes.bool,
  showToolbar: PropTypes.bool,
  showAdd: PropTypes.bool,
  allowAdd: PropTypes.bool,
  breadcrumbText: PropTypes.string,
  breadcrumbLink: PropTypes.string,
  bannerTitle: PropTypes.string,
  bannerSubtitle: PropTypes.string,
  exportTitle: PropTypes.string,
  onExport: PropTypes.func,
  onAdd: PropTypes.func,
  exportButtonProps: PropTypes.object,
};
