import MuiButton from './MuiButton';
import MuiIconButton from './MuiIconButton';
import MuiPaper from './MuiPaper';
import MuiTableCell from './MuiTableCell';
import MuiTableHead from './MuiTableHead';
import MuiTableBody from './MuiTableBody';
import MuiTypography from './MuiTypography';
import MuiToggleButtonGroup from './MuiToggleButtonGroup';
import MuiToggleButton from './MuiToggleButton';
import MuiTableRow from './MuiTableRow'

export default {
  MuiButton,
  MuiIconButton,
  MuiPaper,
  MuiTableCell,
  MuiTableHead,
  MuiTableBody,
  MuiTypography,
  MuiToggleButtonGroup,
  MuiToggleButton,
  MuiTableRow
};
