import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { makeStyles, lighten } from '@material-ui/core/styles';

import { Toolbar, Typography, Tooltip, IconButton } from '@material-ui/core';
import {LibraryAdd as BatchIcon, DynamicFeed as BatchLater, LayersClear as RemoveIcon} from '@material-ui/icons';

import {GridContext} from 'context';

const useStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:{
    color: theme.palette.secondary.main,
    backgroundColor: lighten(theme.palette.secondary.light, 0.75),
  },
    
  title: {
    flex: '1 1 100%',
  },
}));



export default function EnhancedTableToolbar(props) {
  const classes = useStyles();
  const { numSelected,commands = [] } = props;
  const gridContext = React.useContext(GridContext)
  
  const handleBatch = (event) => {
      
  };
  const handleBatchOnDataLoad = (event) => {

  };
  const handleUnQueue = (event) => {

  };
  
  return (
    <Toolbar
      className={clsx(classes.root, classes.highlight)}
      variant='dense'
    >
      { 
        commands.map((command, index) => {
          return <Fragment key={index}>{command} </Fragment>;
        })
      }
       {numSelected > 0 ? (
        <Typography className={classes.title} color='primary' variant="subtitle1">
          {numSelected} reports selected
        </Typography>
          ) : (
        <Typography className={classes.title} color='primary' variant="subtitle1">
            
        </Typography>
      )}
      <Tooltip title="Batch It Now">
          <span>
            <IconButton onClick={handleBatch} aria-label="copy" color="primary" disabled={numSelected === 0}>
                <BatchIcon />
            </IconButton>
          </span>
      </Tooltip>
      <Tooltip title="Batch It With Every Data Release">
          <span>
            <IconButton onClick={handleBatchOnDataLoad} aria-label="edit" color="primary" disabled={numSelected === 0}>
                <BatchLater />
            </IconButton>
          </span>
      </Tooltip>
  
      <Tooltip title="Remove from Queue">            
          <span>
            <IconButton onClick={handleUnQueue} aria-label="delete" color="primary" disabled={numSelected === 0}>
                <RemoveIcon />
            </IconButton>
          </span>
      </Tooltip>
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};