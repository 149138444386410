import React from "react";
import {Dialog, DialogTitle, DialogContent, DialogActions, Button} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles((theme) => ({
    root: {

    },
    deleteBtn: {
        backgroundColor: theme.palette.npd.orange,
        '&:hover':{
          backgroundColor: theme.palette.npd.red,
        }
    }
}))
export default function ConfirmDialog(props){
    const {openDialog, confirmBody, okHandler, cancelHandler} = props;
    const classes = useStyles();
    return ( 
    <Dialog open={openDialog}>
        <DialogTitle>
            Are you sure?
        </DialogTitle>
        <DialogContent>
            {confirmBody()}
        </DialogContent>
        <DialogActions>
            <Button disableElevation color="primary" variant="contained" size="small" className={classes.deleteBtn} onClick={okHandler}>Yes</Button>
            <Button disableElevation color="primary" variant="contained" size="small" onClick={cancelHandler}>Cancel</Button>
        </DialogActions>
    </Dialog>);
}