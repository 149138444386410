import React from 'react';
import { useSnackbar } from 'notistack';
//material ui components
import { IconButton, Tooltip } from '@material-ui/core';
import {GetApp as DownloadIcon, Error, DeleteForever as DeleteIcon, CancelScheduleSend as UnqueueIcon } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import {useApi} from 'components'
import {ApplicationContext} from 'context';
import ConfirmDialog from './ConfirmDialog';

const useStyle = makeStyles(theme => ({
    root: {
        padding: '0 2px',
        fontSize: '1rem',
        '& > span > svg':{
            fontSize: '1.2rem',
        }
    },
    colorError: {
        color: theme.palette.npd.orange
    },
    colorSuccess: {
        color: theme.palette.npd.green
    }

})); 
const renderAction = (row, action, classes, keyName) => {
    let item;
    switch (action.name) {
      case 'download':
          item = <Tooltip key={row[action.keyColumn] + action.name} title={action.tooltip}>
                    <span>
                        <IconButton classes={{root: classes.root}} size='small' onClick={() => action.handler(keyName)} disabled={!row.isExists}>
                            <DownloadIcon/>
                        </IconButton>
                    </span>
                </Tooltip>
        break;
        case 'delete': item = <Tooltip key={row[action.keyColumn] + action.name} title={action.tooltip}>
                                <span>
                                    <IconButton onClick={() => action.handler(keyName)} classes={{root: classes.root}} size='small' disabled={!action.isEnabled(row.statusId)}>
                                        <DeleteIcon/>
                                    </IconButton>
                                </span>
                            </Tooltip>
        break;
        case 'unqueue': item = <Tooltip key={row[action.keyColumn] + action.name} title={action.tooltip}>
                                <span>
                                    <IconButton onClick={() => action.handler(keyName)} classes={{root: classes.root}} size='small' disabled={!action.isEnabled(row.statusId)}>
                                        <UnqueueIcon/>
                                    </IconButton>
                                </span>
                            </Tooltip>
        break;
      default:
        item = <React.Fragment/>;
        break;
    };
    return item;
}
/*
SCHEDULE_STATUS	    STATUS_NAME	            STATUS_DESCRIPTION
1	                Pending	                        Pending
2	                Running...	                    Running...
7	                On New Data Load	            On New Data Load
12	                Pending	                        Azure Batch Pending
13	                Flat File Running	            Flat File Running
19	                Pending on Hadoop Injection	    Pending on Hadoop Injection
20	                Running...	                    Report Runner Complete
21	                Running...	                    Exporter Pending
22	                Running...	                    Exporter Running
*/
const runStatuses = [1, 2, 7, 12, 13, 19, 20, 21, 22];

export default function ActionCell(props){
    const {keyName, row, callback} = props;
    const [openDialog, setOpenDialog] = React.useState(false);
    const [action, setAction] = React.useState("delete");
    const api = useApi();
    const classes = useStyle();
    const appContext = React.useContext(ApplicationContext);
    const { enqueueSnackbar } = useSnackbar();
    
    const canDelete = (status) => {
        return status !== 1 && status !== 2 && status !==7 && status !==20 && status !==21 && status !==22 &&  row.createdBy.toLowerCase() === appContext.appInfo.user.username.toLowerCase();
    }
    const canRemoveFromQueue = (status) => {
        const canRemove = runStatuses.find(s => status === s) !== undefined;
        return canRemove && row.createdBy.toLowerCase() === appContext.appInfo.user.username.toLowerCase();
    }
    const canDownload = (status, outputType) => {
        return ((status === 6 || status === 14 || status === 15 || status === 16 || status === 17) && outputType !== 9);
    }

    const handleDownLoadClick = () => {
        api.isAuthenticated().then(res => {
            if (res.data.isAuthenticated)
                api.downloadBatchedReport(row.scheduleEventId, row.token);
        });
    };

    const handleDeleteClick = () => {
        api.deleteReport([row]).then((res) => {
            if (callback)
                callback();
            if (res.data)
                enqueueSnackbar(res.data, { variant: 'error' });
        });
    }
    const getSeletedReports = () =>  {
        switch(action){
            case "delete": return (<><span>Are you sure you want to delete this report?</span><ul> <li>{row.reportName}</li></ul></>);
            case "unqueue": return (<><span>Are you sure you want to remove this report from the batch queue?</span><ul> <li>{row.reportName}</li></ul></>);
            default: return (<><span>Are you sure you want to delete these reports?</span><ul> <li>{row.reportName}</li></ul></>);
        }
        
    }
    const handleUnqueueClick = () => {
        api.unqueueBatchReport([row]).then((res) => {
            if (callback)
                callback();
            if (res.data)
                enqueueSnackbar(res.data, { variant: 'error' });
        });
      }
    const showConfirmDialog = (key) => {
        setAction(key)
        setOpenDialog(true);
    }
    const actions = [{name: 'download',  handler: handleDownLoadClick, tooltip: 'Click to Download', showOnRow: true, isEnabled: canDownload},
                     {name: 'delete',  handler: () => showConfirmDialog("delete"), tooltip: 'Click to Delete', showOnRow: true, isEnabled: canDelete},
                     {name: 'unqueue',  handler: () => showConfirmDialog("unqueue"), tooltip: 'Remove from Queue', showOnRow: true, isEnabled: canRemoveFromQueue}
                    ]
    
    return (
        <div>
            {actions.map(action => {
                return renderAction(row, action, classes, keyName);
            })}
        <ConfirmDialog 
          openDialog={openDialog} 
          confirmBody={getSeletedReports}
          okHandler={action === "delete" ? handleDeleteClick : handleUnqueueClick}
          cancelHandler={() => {setOpenDialog(false)}}
          >
        </ConfirmDialog>
        </div>
    );

}