import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {Logo} from 'components'
import { AppBar, Toolbar, Box, Divider } from '@material-ui/core';
import { UserAvatar } from 'components';

const drawerWidth = 0;
const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: 'none',
    backgroundColor: theme.palette.common.white,
    zIndex: 1000,
    height: 40,
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth
    }
  },
  toolbar: {
    paddingLeft: 0,
    paddingRight:0
  },
  brand: {
    backgroundColor: theme.palette.background.default,
    textAlign: 'center',
   // paddingTop: 23,
    borderRight: `1px solid #F7F7F7`,
    fontSize: 15,
    fontWeight: '600',
    color: '#565A5C',
    width: 140,
   // height: 64,

    //cursor: 'pointer'
  },
  flexGrow: {
    flexGrow: 1
  },
  avatar: {
    marginLeft: theme.spacing(2),
    color: '#fff',
    backgroundColor: theme.palette.primary.main,
  },
  dataSourceSelection: {
    backgroundColor: theme.palette.primary.dark,
    minHeight: 31
  },
  avatarBtnActive:{
    backgroundColor: '#32404E',
  },
  profileLabel: {
    color: theme.palette.white,
    fontWeight: 600,
    fontSize: 12,
    marginRight : 23,
    marginLeft : 100
  }
}));

const NFTopbar = props => {
  const { className, ...rest } = props;
  const classes = useStyles();
  const [mainHeaderRef, setMainHeaderRef] = React.useState(null);
  
  return (
    <AppBar
      className={clsx(classes.root, className)}
    >
      <Toolbar variant="dense" className={classes.toolbar} ref={setMainHeaderRef}>
          <Logo></Logo>
        <Divider />
        <div className={classes.flexGrow} />
        <UserAvatar />
      </Toolbar>
      <Toolbar className={classes.dataSourceSelection} variant="dense">
      </Toolbar>
    </AppBar>
  );
};

NFTopbar.propTypes = {
  className: PropTypes.string,
};

export default NFTopbar;
