import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import { IconButton, Grid } from '@material-ui/core'
import { Close } from '@material-ui/icons'
import CssBaseline from '@material-ui/core/CssBaseline';
import { SnackbarProvider } from 'notistack';
import { useWindowDimensions } from 'components'
import SideBar from './SideBar/Sidebar'
import Footer from './Footer/Footer'


const useStyles = makeStyles(theme => ({
  toolbar: theme.mixins.toolbar,
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  content: {
    paddingTop: 70,
    paddingLeft: 60,
    flexGrow: 1
  },

  overlay: {
    position: 'absolute',
    backgroundColor: 'black',
    height: '100%',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    display: 'none'
  },
  root: {
    width: '100%',
    margin: 0
  },
  notistack: {
    whiteSpace: 'pre-wrap'
  }
}));


const Main = props => {
  const { children } = props;
  const classes = useStyles();
  const notistackRef = React.createRef();
  const onClickDismiss = key => () => {
    notistackRef.current.closeSnackbar(key);
  }

  const window = useWindowDimensions();

  return (
    <SnackbarProvider
      className={classes.notistack}
      maxSnack={3}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      ref={notistackRef}
      action={(key) => (
        <IconButton onClick={onClickDismiss(key)}>
          <Close />
        </IconButton>
      )}>
      <CssBaseline />
      <div className={classes.root}  >
        <SideBar />
        <div className={classes.content} style={{minHeight: 'calc(100vh - 25px)' }}>
          <Grid container spacing={1} className={classes.root} direction='column'>
            {React.cloneElement(children)}
          </Grid>
        </div>
        <Footer />
      </div>
    </SnackbarProvider>
  );
};

Main.propTypes = {
  children: PropTypes.node
};

export default Main;