import React from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';

import {Logo} from 'components'
import { hidden } from 'ansi-colors';
import SideBarNav from './SideBarNav'

const drawerWidth = 100;

const useStyles = makeStyles(theme => ({
drawer: {
    width: drawerWidth,
    flexShrink: 0    
  },
  drawerPaper: {
    width: drawerWidth,
    boxShadow: '0px 0px 0.5px black',
    border: 0,
    overflow: 'hidden',
  },
  root: {
    backgroundColor: theme.palette.white,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    border: 0
  },
  divider: {
    margin: theme.spacing(2, 0)
  },
  nav: {
    marginBottom: theme.spacing(2)
  },
  toolbar: theme.mixins.toolbar,
  icon: {
    fontSize: 16
  }
}));

 const Sidebar = () => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  //const { variant, onClose} = props;
  return (
    <div >
      <CssBaseline />
        <Drawer
          anchor="left"
          className={classes.drawer}
          classes={{ paper: classes.drawerPaper }}
          open={open}
          variant="permanent"
        >
        <Logo />
        <div
          className={classes.root}
        >
          <SideBarNav
            className={classes.nav}          
          />
        </div>
        </Drawer>
      </div>
  );
}
export default Sidebar;