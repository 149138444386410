
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import { IconButton, Grid } from '@material-ui/core'
import { Close } from '@material-ui/icons'
import CssBaseline from '@material-ui/core/CssBaseline';
import { SnackbarProvider } from 'notistack';
import { useWindowDimensions } from 'components'
import SideBar from './SideBar/Sidebar'
import Footer from './Footer/Footer'

import { Topbar } from 'Topbar';
import { SearchContext, GridContext } from 'context';
import {Search} from 'views/Odata/Collections/components'


const useStyles = makeStyles(theme => ({
    toolbar: theme.mixins.toolbar,
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    content: {
        paddingTop: 70,
        paddingLeft: 60,
        flexGrow: 1,
        zIndex:1,
    },

    overlay: {
        position: 'absolute',
        backgroundColor: 'black',
        height: '100%',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        display: 'none'
    },
    root: {
        width: '100%',
        margin: 0,
        position:'relative',
    },
}));


const MainWithTopbar = props => {
    const { children } = props;
    const classes = useStyles();
    const notistackRef = React.createRef();
    const onClickDismiss = key => () => {
        notistackRef.current.closeSnackbar(key);
    }

    const window = useWindowDimensions();
    const [reportName, setReportName] = React.useState('');
    const [rowsSelected, setRowsSelected] = React.useState([]);

    return (
        <SnackbarProvider
            maxSnack={3}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            ref={notistackRef}
            action={(key) => (
                <IconButton onClick={onClickDismiss(key)}>
                    <Close />
                </IconButton>
            )}>
            <CssBaseline />
            <div className={classes.root}  >
                <SideBar />
                
                    <SearchContext.Provider value={{ reportName, setReportName}}>
                        <GridContext.Provider value={{ rowsSelected, setRowsSelected }}>
                            <div className={classes.content} style={{ height: window.height - 25 }}>
                                <Grid container spacing={1} className={classes.root} direction='column'>
                                    <Grid item >
                                        <Topbar
                                            brandName="Collections"
                                            Search={Search}
                                        />
                                    </Grid>
                                    <Grid item >
                                        {React.cloneElement(children)}
                                    </Grid>
                                </Grid>
                            </div>
                        </GridContext.Provider>
                    </SearchContext.Provider>
                <Footer />
            </div>
        </SnackbarProvider>
    );
};

MainWithTopbar.propTypes = {
    children: PropTypes.node
};

export default MainWithTopbar;