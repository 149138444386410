import React from 'react'
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {FormControl, Select, Box, OutlinedInput, Button, Divider, Typography, Grid} from '@material-ui/core';
import {SearchContext} from 'context'
import {MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
      },
      typography: {
      padding: theme.spacing(2)
    },
    iconOpenRoot:{
        color: theme.palette.common.white,
        backgroundColor: theme.palette.npd.darkBlue,
        borderRadius: 0,
        height: 48,
        width: 48,
        '&:hover':{
            color: theme.palette.common.white,
            backgroundColor: theme.palette.npd.darkBlue,
        }
    },
    iconRoot:{
        height: 48,
        width: 48,
    },
    button:{
        width: 150,
        color: theme.palette.npd.blue,
        '&:hover':{
            boxShadow: '0px 5px 10px 1px rgba(0,0,0,.15)',
            fontWeight: 600
        }
    },
    gridContainer:{
        padding: theme.spacing(1)
    },
    popperPaper:{
        width: 450,
        height: 280,
        padding: theme.spacing(1),
        textAlign: 'center',
        whiteSpace: 'nowrap',
        marginBottom: theme.spacing(1),
    },
    textBox: {
        height:33,
        width: '100%',
        '&:input':{
            padding: 8
        }
    },
    formControl:{
        width: '100%',
        paddingRight: 5
    },
    formControlLast:{
        width: '100%'
    },
    select: {
        padding: 8,
        width: '100%'
    },
    dense: {
        padding: 0,
        margin: 0,
        '& > div > input':{
            padding: 8
        }
    },
    endAdornment: {
        padding: 0,
        '& > button':{
            padding: 0
        }
    }
  }));
  
export default function AdvancedSearchForm(props){
    const classes = useStyles();
    const {rowsPerPageOptions, columns, handleClose} = props;
    const searchContext = React.useContext(SearchContext);

    const [reportName, setReportName] = React.useState(searchContext.reportName);
    const [folderName, setFolderName] = React.useState(searchContext.folderName);
    const [batchStartdate, setBatchStartdate] = React.useState(searchContext.batchStartdate);
    const [batchEnddate, setBatchEnddate] = React.useState(searchContext.batchEnddate);
    const [orderBy, setOrderBy] = React.useState(searchContext.orderBy);
    const [orderByDirection, setOrderByDirection] = React.useState(searchContext.orderByDirection);
    const [pageSize, setPageSize] = React.useState(searchContext.pageSize);

    const handleOrderByChange = (event) => {
        setOrderBy(event.target.value);
    }
    const handleOrderByDirectionChange = (event) => {
        setOrderByDirection(event.target.value);
    }
    const handlePageSizeChange = (event) => {
        setPageSize(event.target.value);
    }

    const onApplyClick = () => {
        setFilters(false);
        handleClose();
    }
    
    const onClearClick = () => {
        setFolderName('');
        setReportName('');
        setBatchEnddate(null);
        setBatchStartdate(null);
        setFilters(true);
    }
    const handleChange = (event, columnName) => {
        var value = event.target.value;
        switch(columnName){
            case 'folderName': setFolderName(value); break;
            case 'reportName': setReportName(value); break;
        }
    }
    const setFilters = (shouldClear) => {
        if (shouldClear){
            searchContext.setReportName('');
            searchContext.setFolderName('');
            searchContext.setBatchStartdate(null);
            searchContext.setBatchEnddate(null);
        }
        else{
            searchContext.setReportName(reportName);
            searchContext.setFolderName(folderName);
            searchContext.setBatchStartdate(batchStartdate);
            searchContext.setBatchEnddate(batchEnddate);
            searchContext.setOrderBy(orderBy);
            searchContext.setOrderByDirection(orderByDirection);
            searchContext.setPageSize(pageSize);
        }
    }
    return (<Grid container className={classes.root}>
        <div className={classes.popperPaper}>
            <Grid item md={12}>
                <Grid container className={classes.gridContainer}>
                    <Grid item xs={6}>
                        <Button component="span" className={classes.button} onClick={onClearClick} >Clear</Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button component="span" className={classes.button} onClick={onApplyClick} >Apply</Button>
                    </Grid>
                </Grid>
            </Grid>
            <Divider></Divider>
            <Grid item xs={12}>
                <Grid container className={classes.gridContainer}>
                    <Grid item xs={3}>
                    <Box lineHeight={2} textAlign="left" fontWeight="fontWeightBold" fontSize='h5.fontSize'>Folder Name</Box>
                    </Grid>
                    <Grid item xs={9}>
                        <OutlinedInput margin='dense' value={folderName} onChange={event =>handleChange(event, 'folderName')}  classes={{root: classes.textBox}} variant='outlined'></OutlinedInput>
                    </Grid>
                </Grid>
            </Grid>
           
            <Grid item xs={12}>
                <Grid container className={classes.gridContainer}>
                    <Grid item xs={3}>
                    <Box lineHeight={2} textAlign="left" fontWeight="fontWeightBold" fontSize='h5.fontSize'>Report Name</Box>
                    </Grid>
                    <Grid item xs={9}>
                        <OutlinedInput margin='dense' value={reportName} onChange={event =>handleChange(event, 'reportName')} classes={{root: classes.textBox}} variant='outlined'></OutlinedInput>
                    </Grid>
                </Grid>
            </Grid>

            {/* <Grid item xs={12}>
                <Grid container className={classes.gridContainer}>
                    <Grid item xs={3}>
                    <Box lineHeight={2} textAlign="left" fontWeight="fontWeightBold" fontSize='h5.fontSize'>Batched By</Box>
                    </Grid>
                    <Grid item xs={9}>
                        <OutlinedInput margin='dense' value={batchedBy} onChange={event =>handleChange(event, 'batchedBy')} classes={{root: classes.textBox}} variant='outlined'></OutlinedInput>
                    </Grid>
                </Grid>
            </Grid> */}

            <Grid item xs={12}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
                <Grid container className={classes.gridContainer}>
                    <Grid item xs={3}>
                        <Box lineHeight={2} textAlign="left" fontWeight="fontWeightBold" fontSize='h5.fontSize'>Batched On</Box>
                    </Grid>
                    <Grid item xs={4}>
                    <KeyboardDatePicker
                            autoOk
                            variant="inline"
                            inputVariant="outlined"
                            placeholder="From"
                            format="MM/DD/YYYY"
                            margin="dense"
                            value={batchStartdate}
                            classes={{root: classes.dense}}
                            InputAdornmentProps={{classes: {root: classes.dense, positionEnd: classes.endAdornment}}}
                            onChange={date => setBatchStartdate(date)}
                        />
                    </Grid>
                    <Grid item xs={1}></Grid>
                    <Grid item xs={4}>
                        <KeyboardDatePicker
                            autoOk
                            variant="inline"
                            inputVariant="outlined"
                            placeholder="To"
                            format="MM/DD/YYYY"
                            margin="dense"
                            disableFuture
                            value={batchEnddate}
                            classes={{root: classes.dense}}
                            InputAdornmentProps={{classes: {root: classes.dense, positionEnd: classes.endAdornment}}}
                            onChange={date => setBatchEnddate(date)}
                        />
                    </Grid>
                </Grid>
                </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={12}>
                <Grid container className={classes.gridContainer}>
                    <Grid item xs={3}>
                        <Box lineHeight={2} textAlign="left" fontWeight="fontWeightBold" fontSize='h5.fontSize'>Sort / Limit</Box>
                    </Grid>
                    <Grid item xs={4}>
                    <FormControl variant="outlined" className={classes.formControl}>
                        <Select
                        native
                        onChange={handleOrderByChange}
                        classes={{root: classes.select}}
                        value={orderBy}
                        >
                            {columns.map(item => { return !item.disableSort ? <option key={item.field} value={item.field}>{item.label}</option> : ''})}
                        </Select>
                    </FormControl>
                    </Grid>
                    <Grid item xs={2}>
                    <FormControl variant="outlined" className={classes.formControlLast}>
                        <Select
                        native
                        onChange={handleOrderByDirectionChange}
                        value={orderByDirection}
                        classes={{root: classes.select}}
                        >
                        <option key="desc" value="desc">Desc</option>
                        <option key="asc" value="asc">Asc</option>
                        </Select>
                    </FormControl>
                    </Grid>
                    <Grid item xs={1}>
                        <Typography variant='h3'>/</Typography>
                    </Grid>
                    <Grid item xs={2}>
                    <FormControl variant="outlined" className={classes.formControlLast}>
                        <Select
                        native
                        onChange={handlePageSizeChange}
                        value={pageSize}
                        classes={{root: classes.select}}
                        >
                            {rowsPerPageOptions.map(item => { return <option key={item} value={item}>{item}</option>})}
                        </Select>
                    </FormControl>
                    </Grid>
                </Grid> 
            </Grid>
            </div>
        </Grid>);
}

AdvancedSearchForm.propTypes = {
    rowsPerPageOptions: PropTypes.array,
    columns: PropTypes.array.isRequired,
    handleClose: PropTypes.func
};