import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx'

import { TableRow, TableCell, Typography } from '@material-ui/core';

//import { dateFormat } from 'utils/index';

export default function TableRowHeaderData(props) {
  const {
    columns,
    groupKey,
    classes,
    rowCount
  } = props;


  return (
    <TableRow key={groupKey.replace(" ", "_")}
      tabIndex={-1}>
      <TableCell 
      colSpan={columns.length +1} 
      className={clsx(
        classes.tableCell,
        classes[`tableCell$number`])}>
        <Typography variant='h6' >{groupKey} ({rowCount})</Typography>
      </TableCell>
    </TableRow>
  );
}

TableRowHeaderData.propTypes = {
  columns: PropTypes.array.isRequired,
  groupKey: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired
};