import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles';
import { IconButton } from '@material-ui/core';
import DownloadIcon from 'assets/img/download-export.png';


//import DownloadIcon from 'assets/npd.png'


const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.white,
    borderRadius: 4,
    padding: 0,
    border: '1px solid #D8D8D8'
  }
}));
function DownloadButton(props) {
  const { onClick } = props;
  const classes = useStyles();
  return (
    <IconButton
      variant='small'
      onClick={onClick}
      aria-controls={'download-button'}
      aria-haspopup='true'
      className={classes.root}
    >
      <img src={DownloadIcon} alt='download' />
    </IconButton>
  )
}

DownloadButton.propTypes = {
  onClick: PropTypes.func.isRequired
}

export default DownloadButton

