import React from 'react';
import { useSnackbar } from 'notistack';
import clsx from 'clsx';
//material ui components
import { IconButton, Tooltip } from '@material-ui/core';
import {Send as BatchIcon, Today as BatchLater, CancelScheduleSend as RemoveIcon } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
//custom components
import {useApi} from 'components'
import {DataSourceContext} from 'context'


const useStyle = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection:'row',
    },
    iconBtn: {
        padding: '0 2px',
        fontSize: '1rem',
        '& > span > svg':{
            fontSize: '1.2rem',
        }
    },
    colorError: {
        color: theme.palette.npd.orange
    },
    colorSuccess: {
        color: theme.palette.npd.green
    },
    hidden:{
        display:"none"
    },
    flexItem: {
        flexGrow: 1
    }
})); 
const renderAction = (row, action, classes, keyName) => {
    let item;
    switch (action.name) {
        case 'batch':
            if (!action.showOnRow) break;
                item = <Tooltip key={row[action.keyColumn] + action.name} title={action.tooltip} className={clsx({[classes.hidden]: !action.isDisabled(keyName, row.scheduleStatus)})}>
                            <span>
                                <IconButton color='primary' classes={{root: classes.iconBtn}} size='small' onClick={() => action.handler(keyName)}>
                                    <BatchIcon/>
                                </IconButton>
                            </span>
                        </Tooltip>
                        break;
        case 'batchEveryRelease':
                if (!action.showOnRow) break;
                item = <Tooltip key={row[action.keyColumn] + action.name}  title={row.isRunOnEachDataRelease ? action.tooltipAlt : action.tooltip}>
                            <span>
                                <IconButton color='primary' disabled={row.immediateDeliveryInd} classes={{root: classes.iconBtn, colorPrimary: row.isRunOnEachDataRelease ? classes.colorSuccess : classes.colorPrimary}} size='small' onClick={() => action.handler(keyName)}>
                                    <BatchLater/>
                                </IconButton>
                            </span>
                        </Tooltip>
                        break;
        case 'removeBatch':
            if (!action.showOnRow) break;
            item = <Tooltip key={row[action.keyColumn] + action.name} title={action.tooltip} className={clsx({[classes.hidden]: action.isDisabled(keyName, row.scheduleStatus)})}>
                        <span>
                            <IconButton classes={{root: classes.iconBtn}} color='primary' onClick={() => action.handler('1, 2, 12')} size='small'>
                                <RemoveIcon/>
                            </IconButton>
                        </span>
                    </Tooltip>
            break;
        case 'refresh':
            if (!action.showOnRow) break;
            break;
      default:
        item = <React.Fragment/>;
        break;
    };
    return item;
}

export default function TableActions(props){
    
    const {keyName, row, callback} = props;
    const classes = useStyle();
    const context = React.useContext(DataSourceContext);
    const api = useApi();
    const { enqueueSnackbar } = useSnackbar();
    
    const handleBatchClick = () => {
        row.scheduleEventTypeId = 1;
        const data = {
            reportName: row.reportName,
            reportId: row.reportId,
            module: row.moduleName,
            summarySourceTypeId: row.summarySourceTypeId,
            scheduleEventId: row.scheduleEventId,
            scheduleEventTypeId: row.scheduleEventTypeId,
            immediateDeliveryInd: row.immediateDeliveryInd,
            summarySchemaName: row.summarySchemaName,
            limitEndDateId: row.limitEndDateId,
            timePeriodKey: row.timePeriodKey,
            schemaId: context.targetEnvKey
        }
        api.batchReport([data]).then((res) => {
            if (callback)
                callback();
            if (res.data)
                enqueueSnackbar(res.data, { variant: 'error' });
        });
      }
    
      const handleBatchEveryReleaseClick = () => {
        if (row.isRunOnEachDataRelease) {
            handleUnqueueClick(7);
            return;
        }

        row.scheduleEventTypeId = 2;
        const data = {
            reportName: row.reportName,
            reportId: row.reportId,
            module: row.moduleName,
            summarySourceTypeId: row.summarySourceTypeId,
            scheduleEventId: row.scheduleEventId,
            scheduleEventTypeId: row.scheduleEventTypeId,
            immediateDeliveryInd: row.immediateDeliveryInd,
            summarySchemaName: row.summarySchemaName,
            limitEndDateId: row.limitEndDateId,
            timePeriodKey: row.timePeriodKey,
            schemaId: context.targetEnvKey
        }
        api.batchReport([data]).then((res) => {
            if (callback)
                callback();
            if (res.data)
            enqueueSnackbar(res.data, { variant: 'error' });
        });
      }
    
      const unqueueDisabledStatus =  [1, 2, 12];
      const handleUnqueueClick = (status) => {
        row.unqueueStatus = status;
        api.unqueueReport([row]).then((res) => {
            if (callback)
                callback();
            if (res.data)
                enqueueSnackbar(res.data, { variant: 'error' });
        });
      }
    
    const isRemoveFromQueueDisabled = (reportId, scheduleStatus) => {
        return !(row.isPendingOrRunning);
    }

    const actions = 
    [{name: 'batch', handler: handleBatchClick, isDisabled: isRemoveFromQueueDisabled, tooltip: 'Batch It Now', keyColumn: 'reportId', showOnRow: true}, 
    {name: 'removeBatch', handler: handleUnqueueClick, isDisabled: isRemoveFromQueueDisabled, tooltip: 'Remove from Queue', keyColumn: 'reportId', showOnRow: true},
    {name: 'batchEveryRelease', handler: handleBatchEveryReleaseClick, tooltip: 'Batch It With Every Data Release', tooltipAlt: 'Remove from Queue', keyColumn: 'reportId',showOnRow: true}]
    
    return (
        <div className={classes.root}>
            {actions.map(action => {
                return renderAction(row, action, classes, keyName);
            })} 
        </div>
    );
}