import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import { Button, Typography } from '@material-ui/core';
import Delete from '@material-ui/icons/Delete';

import { red } from '@material-ui/core/colors';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },

  deleteConfirmationWrapper: {
    padding: '4px 8px 4px 4px',
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    '& > *': {
      marginRight: theme.spacing(1)
    },
  },
  button:{
  fontSize: 12,
  fontWeight: 600,
  color: theme.palette.text.secondary,
  },
  buttonLabel: {
    lineHeight: '20px',
    fontWeight: 'bold',
    textTransform: 'uppercase'
  },

  cancelButton: {
    fontSize: 12,
    padding:'1px 10px',
    border: 'solid 1px #acacac',
    '&:hover': {
      backgroundColor: theme.palette.white,
    },
  },

  deleteButton: {
    fontSize: 12,
    color: '#ffffff',
    padding:'1px 10px',
    backgroundColor: theme.palette.error.main,

    '&:hover': {
      backgroundColor: theme.palette.error.main,
    },
  },

}));

const DeleteIcon = (props) => {
  return <Delete style={{ fontSize: 14 }} {...props} />
};


const DeleteButton = props => {
  const { selectionModel, onDelete } = props;
  const classes = useStyles();
  const [isDelete, setIsDelete] = useState(false);


  useEffect(() => {
    if (selectionModel.selectedCount === 0)
      setIsDelete(false);
  }, [selectionModel])


  const handleDelete = () => {
    setIsDelete(false);
    onDelete(selectionModel);
   
  }

  return (
    <div className={classes.root}>
{/*       {!isDelete && (
        <Button
        className={classes.button}
          startIcon={<DeleteIcon />}
          onClick={() => setIsDelete(true)}
          disabled={selectionModel.selectedCount == 0}
        >
          Delete
        </Button>
      )}
      {isDelete && (
        <div className={classes.deleteConfirmationWrapper}>
          <DeleteIcon />
          <Typography noWrap style={{fontSize:'12px'}}> Are you Sure? </Typography>
          <Button variant="contained" size="small" onClick={() => setIsDelete(false)} classes={{ root: classes.cancelButton, label: classes.buttonLabel }} disableElevation disableRipple>
            Cancel
          </Button>
          <Button variant="contained" size="small" aria-label="Delete" classes={{ root: classes.deleteButton, label: classes.buttonLabel }} onClick={handleDelete} disableElevation disableRipple>Delete</Button>
        </div>
      )} */}
    </div>
  )
}

DeleteButton.propTypes = {
  hasSelection: PropTypes.bool,
  onDelete: PropTypes.func
}

export default DeleteButton
