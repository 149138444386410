import React, {useEffect, useState} from 'react'
import { makeStyles } from '@material-ui/core/styles';
import {Popover, Tooltip, Button, Badge} from '@material-ui/core';
import {FilterList as FilterListIcon} from '@material-ui/icons';

import AdvancedSearchForm  from './Components/AdvancedSearchForm'
import {SearchContext, DataSourceContext} from 'context'
import {useApi} from 'components'


const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
      },
      typography: {
      padding: theme.spacing(2)
    },
    iconOpenRoot:{
        color: theme.palette.common.white,
        backgroundColor: theme.palette.npd.blue,
        borderRadius: 0,
        height: 32,
        width: 32,
        marginLeft:5,
        '&:hover':{
            color: theme.palette.common.white,
            backgroundColor: theme.palette.npd.blue,
        }
    },
    iconRoot:{
        height: 32,
        width: 32,
        marginLeft:5
    },
    button:{
        width: 150,
        color: theme.palette.npd.blue,
        '&:hover':{
            boxShadow: '0px 5px 10px 1px rgba(0,0,0,.15)',
            fontWeight: 600
        }
    },
    gridContainer:{
        padding: theme.spacing(1)
    },
    textBox: {
        height:33,
        width: '100%',
        padding:8
    },
    popoverRoot: {
      backgroundColor: 'rgba(0,0,0,.2)'
    },
    AdvanceSeachButton: {
      height: 32,
      width: 79,
      marginLeft: 15,
      lineHeight: '10px',
      letterSpacing: 0.3,
      fontSize: 10,    
      fontWeight: 'bold'
    },
    
  }));

export default function AdvancedSearch(props){
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [open, setOpen] = useState(false);
    const [badgeCount, setBadgeCount] = React.useState(0);
    const searchContext = React.useContext(SearchContext);
    const buttonRef = React.useRef(null);
    const {rowsPerPageOptions, columns} = props;

    const handleClose = (event) => {
        setAnchorEl(null);
      };
    const datasourceContext = React.useContext(DataSourceContext);
    const [formats, setFormat] = React.useState([]);
    const [categories, setCategories] = React.useState([]);
    const api = useApi();

    useEffect(() => {
      if (datasourceContext.dataSourceSelectedKey !== '') {
        api.getFormats(datasourceContext.dataSourceSelectedKey).then(res => {
            setFormat(res.data);
        });
        api.getCategories(datasourceContext.dataSourceSelectedKey).then(res => {
          setCategories(res.data);
      });
      }
    }, [datasourceContext.dataSourceSelectedKey]);
    
    useEffect(() => {
        setOpen(Boolean(anchorEl));
      }, [anchorEl])

    useEffect(() => {
      setBadgeCount(searchContext.filterCount)
    }, [searchContext.filterCount]);

    const handleClick = event => {
        if (anchorEl) {
          setAnchorEl(null);
        } else {
          setAnchorEl(buttonRef.current);
        }
      };

    return (
    <div>

    <Tooltip title="Advanced Search">
        <Button variant='text' color='primary' className={classes.AdvanceSeachButton} buttonRef={buttonRef} onClick={handleClick} id='advanced-search-button'>
          Advanced Search
        </Button>



       {/*  <IconButton classes={{root: open ? classes.iconOpenRoot : classes.iconRoot}} buttonRef={buttonRef} aria-label="Advanced Search" size='small' color='inherit' onClick={handleClick}>
            <Badge badgeContent={badgeCount} color='secondary'>
              <FilterListIcon />
            </Badge>
    </IconButton>*/}


      </Tooltip>
      <Popover
        id='advanced-search-popover'
        open={open}
        classes={{root:classes.popoverRoot}}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        disableRestoreFocus
      >
          <AdvancedSearchForm handleClose={handleClose} columns={columns} rowsPerPageOptions={rowsPerPageOptions} formats={formats} categories={categories}></AdvancedSearchForm>
        </Popover>
      </div>);
}