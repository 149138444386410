//core components
import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
//material ui components
import { makeStyles, lighten } from '@material-ui/core/styles';
import { Toolbar, Typography, Tooltip, IconButton, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, Grid } from '@material-ui/core';
import {GetApp as BatchIcon, Cached as RefreshIcon} from '@material-ui/icons';
//custom components
import {GridContext, SearchContext} from 'context';
import {useApi, SelectedSearches} from 'components';

const useStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    flexGrow:10,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    alignItems: 'flex-start',
    justifyContent: 'flex-end'
  },
  highlight:{
    color: theme.palette.primary.main,
    backgroundColor: lighten(theme.palette.secondary.light, 0.75),
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    alignItems: 'flex-start',
    justifyContent: 'flex-end'
  },
  icon: {
    padding:'1px 5px 1px 1px',
    //margin: '0px 3px 3px 3px',
    fontSize:'23px',
    //lineHeight: '24px'
  },    
  title: {
    paddingRight: theme.spacing(3),
    fontSize:'12px',
    //flex: '1 1 100%',
  },
  alignRight: {
    textAlign: 'end'
  },
}));

export default function EnhancedTableToolbar(props) {
  const classes = useStyles();
  const { multidownload, refresh } = props;
  const [rowsSelected, setRowsSelected] = React.useState(0);
  const gridContext = React.useContext(GridContext)
  const searchContext = React.useContext(SearchContext)
  
  const GenerateSelectedSearches = () => {
    let searches = [];

        if (searchContext.searchTitle !== undefined && searchContext.searchTitle !== '')
            searches.push({key:"Title", value : searchContext.searchTitle, handleDelete: () => {searchContext.setSearchTitle(''); }});

        if (searchContext.category !== undefined && searchContext.category.name !== '' && searchContext.category !== '')
            searches.push({key:"Category: ", value: searchContext.category.name, handleDelete: () => {searchContext.setCategory({ name: '', ids: '' }); }});

        if (searchContext.format !== undefined && searchContext.format.name !== '' && searchContext.format.reportOutputTypeId !== 0)
          searches.push({key:"Format: ", value: searchContext.format.name, handleDelete: () => {searchContext.setFormat({ name: '', ids: '' }); }});

        if (searchContext.startdate !== undefined && searchContext.startdate !== null)
            searches.push({key:"Date Released From: " , value: searchContext.startdate.format("MM/DD/YYYY"), handleDelete: () => {searchContext.setStartdate(null); }});
        
        if (searchContext.enddate !== undefined && searchContext.enddate !== null)
            searches.push({key:"Date Released To: " , value: searchContext.enddate.format("MM/DD/YYYY"), handleDelete: () => {searchContext.setEnddate(null); }});
        
    
    return searches;
}

  React.useEffect(() => {
      setRowsSelected(gridContext.rowsSelected.length);
  }, [gridContext.rowsSelected])

  return (
    <div
      className={clsx(classes.root, classes.highlight)}
      variant='dense'
    >
      <Grid container>
      <Grid item xs={8}>
        <SelectedSearches searches={GenerateSelectedSearches()}></SelectedSearches>
      </Grid>
      <Grid item xs={3} className={classes.alignRight}>
        {rowsSelected > 0 ? (
            <Typography className={classes.title} color='primary' variant="subtitle1">
              {rowsSelected} items selected
            </Typography>
              ) : (
            <Typography className={classes.title} color='primary' variant="subtitle1">
                
            </Typography>
          )}
      </Grid>
      <Grid item xs={1} className={classes.alignRight}>
        <Tooltip title="Download Selected Files">
              <span >
                <IconButton className={classes.icon} onClick={() => multidownload()} aria-label="download" color="primary" disabled={rowsSelected === 0}>
                    <BatchIcon className={classes.icon}/>
                </IconButton>
              </span>
          </Tooltip>      
          <Tooltip title="Refresh">
              <span>
                <IconButton className={classes.icon} onClick={() => refresh()} aria-label="refresh" color="primary">
                    <RefreshIcon className={classes.icon}/>
                </IconButton>
              </span>
          </Tooltip>
      </Grid>
    </Grid>
  </div>
  );
};

EnhancedTableToolbar.propTypes = {
  refresh: PropTypes.func.isRequired,
  multidownload: PropTypes.func.isRequired
};