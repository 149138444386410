import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types';
//material ui components
import { makeStyles } from '@material-ui/core/styles';
import {Popover, Tooltip, Button, IconButton, Badge} from '@material-ui/core';
//custom components
import {SearchContext, DataSourceContext} from 'context'
import useApi from 'components/Api/Api'


const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
      },
      typography: {
      padding: theme.spacing(2)
    },
    iconOpenRoot:{
        color: theme.palette.common.white,
        backgroundColor: theme.palette.npd.darkBlue,
        borderRadius: 0,
        height: 48,
        width: 48,
        '&:hover':{
            color: theme.palette.common.white,
            backgroundColor: theme.palette.npd.darkBlue,
        }
    },
    iconRoot:{
        height: 48,
        width: 48,
    },
    button:{
        width: 150,
        color: theme.palette.npd.blue,
        '&:hover':{
            boxShadow: '0px 5px 10px 1px rgba(0,0,0,.15)',
            fontWeight: 600
        }
    },
    AdvanceSeachButton: {
      height: 32,
      width: 79,
      marginLeft: 15,
      lineHeight: '10px',
      letterSpacing: 0.3,
      fontSize: 10,    
      fontWeight: 'bold'
    },
    gridContainer:{
        padding: theme.spacing(1)
    },
    textBox: {
        height:33,
        width: '100%',
        padding:8
    },
    popoverRoot: {
      backgroundColor: 'rgba(0,0,0,.2)'
    }
    
  }));

export default function AdvancedSearch(props){
    const classes = useStyles();
    
    const [open, setOpen] = useState(false);
    const [badgeCount, setBadgeCount] = React.useState(0);
    const searchContext = React.useContext(SearchContext);
    const buttonRef = React.useRef(null);
    const {rowsPerPageOptions, columns, AdvancedSearchForm, AdvancedSearchFormProps} = props;

    const handleClose = (event) => {
        setOpen(false);
      };
      
    useEffect(() => {
      setBadgeCount(searchContext.filterCount)
    }, [searchContext.filterCount]);

    const handleClick = event => {
      setOpen(!open);
    };

    return (
    <div>
    <Tooltip title="Advanced Search">
        <Button variant='text' color='primary' className={classes.AdvanceSeachButton} buttonRef={buttonRef} onClick={handleClick} id='advanced-search-button'>
          Advanced Search
        </Button>
      </Tooltip>
      <Popover
        id='advanced-search-popover'
        open={open}
        classes={{root:classes.popoverRoot}}
        anchorEl={buttonRef.current}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
          <AdvancedSearchForm {...AdvancedSearchFormProps} handleClose={handleClose} ></AdvancedSearchForm>
        </Popover>
      </div>);
}

AdvancedSearch.propTypes = {
  AdvancedSearchForm: PropTypes.func.isRequired
};