import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { AppBar, Toolbar, Badge, Hidden, IconButton, Avatar, Box, Divider } from '@material-ui/core';

import MenuIcon from '@material-ui/icons/Menu';

import { UserAvatar } from 'components';
import { DataSourceSelection, AdvancedSearch} from './components';

const drawerWidth = 100;
const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: 'none',
    backgroundColor: theme.palette.common.white,
    zIndex: 1000,
    height: 48,
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth
    }
  },
  toolbar: {
    paddingLeft: 0,
    paddingRight:0
  },
  brand: {
    backgroundColor: theme.palette.background.default,
    textAlign: 'center',
   // paddingTop: 23,
    paddingLeft: 5,
    borderRight: `1px solid #F7F7F7`,
    fontSize: 15,
    fontWeight: '600',
    color: '#565A5C',
    width: 150,
   // height: 64,

    //cursor: 'pointer'
  },
  flexGrow: {
    flexGrow: 1
  },
  avatar: {
    marginLeft: theme.spacing(2),
    color: '#fff',
    backgroundColor: theme.palette.primary.main,
  },
  dataSourceSelection: {
    backgroundColor: theme.palette.primary.dark,
    minHeight: 31
  },
  avatarBtnActive:{
    backgroundColor: '#32404E',
  },
  profileLabel: {
    color: theme.palette.white,
    fontWeight: 600,
    fontSize: 12,
    marginRight : 23,
    marginLeft : 10
  },
  shiftRight:{
    flexGrow: 1
  }
}));

const Topbar = props => {
  const { className, onSidebarOpen, setBackdrop, brandName, AdvancedSearchForm, AdvancedSearchFormProps, Search, RightComponent } = props;
  const classes = useStyles();
  const [mainHeaderRef, setMainHeaderRef] = React.useState(null);
  
  return (
    <AppBar
      className={clsx(classes.root, className)}
    >
      <Toolbar variant="dense" className={classes.toolbar} ref={setMainHeaderRef}>
        <Box className={classes.brand}>{brandName}</Box>
        <Divider />
        <Search />
        {AdvancedSearchForm && <AdvancedSearch AdvancedSearchFormProps={AdvancedSearchFormProps} AdvancedSearchForm={AdvancedSearchForm}></AdvancedSearch>}
        <div className={classes.flexGrow} />
        <Hidden mdUp>
          <IconButton
            color="default"
            onClick={onSidebarOpen}
          >
            <MenuIcon />
          </IconButton>
        </Hidden>
        <UserAvatar showPreferences={brandName.toLowerCase() === "client center"? true : false}/>
      </Toolbar>
      <Toolbar className={classes.dataSourceSelection} variant="dense">
        <span className={classes.profileLabel}>DATA SOURCE</span> <DataSourceSelection />
        <span className={classes.shiftRight}/>
        {RightComponent &&
          <RightComponent/>}
      </Toolbar>
    </AppBar>
  );
};

Topbar.propTypes = {
  className: PropTypes.string,
  onSidebarOpen: PropTypes.func,
  AdvancedSearchForm: PropTypes.func,
  setBackdrop: PropTypes.func
};

export default Topbar;
