import palette from '../palette';
import { fade } from '@material-ui/core/styles';

export default {
  root: {
    borderRadius: 14,
    color: palette.text.primary,
    backgroundColor: palette.white,
    textTransform: 'none',

    '&$selected': {
      color: palette.primary.contrastText,
      backgroundColor: palette.primary.main,
      '&:hover': {
        backgroundColor: fade(palette.primary.main, 0.15),
      },
      '& + &': {
        borderLeft: 0,
        marginLeft: 0,
      },
    },
  },

  sizeSmall: {
    height: 24,
    fontSize: 12,
    fontWeight: 'bold',    
  },
 
};
