import React from 'react';
import { Typography, Grid, makeStyles } from '@material-ui/core';
import {ApplicationContext} from 'context'
import AppTile from './Components/AppTile'
import {NFTopbar} from 'Topbar'
import Pages from 'Pages'

const useStyle = makeStyles((theme) => ({
  root:{
    margin: 60,
    textAlign: 'center'
  },
  cardRoot: {
    minWidth: 150,
    cursor: "pointer",
    alignContent: "CardContent"
  },
}));

export default function NotFound() { 
  const classes = useStyle();
  const appContext = React.useContext(ApplicationContext)
  const isVisible = (keys) =>{
    var item = keys.map(k => appContext.appInfo.user[k])
    if (item.indexOf(true) > -1)
      return true;
    return false;
  }  

  document.title = "Page not found";

  return (
    <Grid item className={classes.root}>
      <Grid item>
        <NFTopbar></NFTopbar>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={10} direction="row">
          <Grid item xs={3}></Grid>
          <Grid item xs={6}>
            <Typography variant='h1'>404</Typography>
            <Typography variant='caption'>URL not found</Typography>
            <Typography variant='subtitle1'>The page you are looking for is not present. Here is what you can do... </Typography>
          </Grid>
          <Grid item xs={3}></Grid>
          <Grid item xs={12} justify="center" container={true}>
            {Pages.map(page => (isVisible(page.isVisibleKey) && 
                  <AppTile 
                    labelText={page.text} 
                    icon={page.icon} 
                    link={page.link ? appContext.appInfo.decisionKeyUrl : page.href}
                    target={page.link ? "_mywindow" : "_self"}
                    />
            ))}
          </Grid>
        </Grid>
        
      </Grid>
    </Grid>
  );
}
