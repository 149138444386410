import axios from 'axios';

/**
 * Create an Axios Client with defaults
 */
const client = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: { 'Cache-Control': 'no-cache', 'Pragma': 'no-cache' },
});


/**
 * Request Wrapper with default success/error actions
 */
const request = function (options) {
  const onSuccess = function (response) {
    if (response.data.failed)
    {
      let win = window.top || window,
      redirectPath = win.location.origin;

      redirectPath = redirectPath + '/error';
      win.location.assign(redirectPath);
    }
    console.debug('Request Successful!', response);
    return response;
  }

  const onError = function (error) {
    client({
      url: '/account/isAuthenticated',
      method: 'GET'
    }).then((res) => {
      //if the error is due to authorization, redirect to login page.
        if (!res.data.isAuthenticated){
          let win = window.top || window,
          redirectPath = win.location.origin;

          redirectPath = redirectPath + '/home';
          win.location.assign(redirectPath);
      }
    });

    console.error('Request Failed:', error.config);

    if (error.response) {
      // Request was made but server responded with something
      // other than 2xx
      console.error('Status:', error.response.status);
      console.error('Data:', error.response.data);
      console.error('Headers:', error.response.headers);

    } else {
      // Something else happened while setting up the request
      // triggered the error
      console.error('Error Message:', error.message);
    }

    return Promise.reject(error.response || error.message);
  }

  if (options.autoCancel !== false) {
    if (cancelCollection[options.url] != undefined) {
      cancelCollection[options.url]();
    }
    options.cancelToken = new CancelToken(function executor(c) {
      // An executor function receives a cancel function as a parameter
      cancelCollection[options.url] = c;
    });
  }

  return client(options)
    .then(onSuccess)
    .catch(onError);
}

var CancelToken = axios.CancelToken;
var cancelCollection = {};

export default request;