import React from 'react';
import PropTypes from 'prop-types';

import TableRowData from './TableRowData';
import TableRowHeader from './TableRowHeader'

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  if (array == null) {
    return [];
  }
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

export default function TableData(props) {
  const {
    classes,
    columns,
    rows,
    mode,
    order,
    orderBy,
    enablePaging,
    totalCount,
    handleClick,
    handleReportEdit,
    page,
    rowsPerPage,
    isSelected,
    isRowHighlighted,
    groupKey,
    rowKeyProperty, 
    ActionCell,
    actionCallback,
    noLeadingCheckbox
  } = props;

  const remote = mode === 'remote';
  
  const renderRowHeader = (groupKey, columns) =>{
    if (groupKey !== 'undefined')
      return <TableRowHeader key={groupKey} groupKey={groupKey} rowCount={rows.length} columns={columns} classes={classes}></TableRowHeader>
  };
  const renderRowData = (columns, row) => {
    return <TableRowData
      classes={{
        tableCell: classes.tableCell,
        tableCellString: classes.tableCellString,
        tableCellNumber: classes.tableCellNumber,
        tableCellDate: classes.tableCellDate,
        tableCellLink: classes.tableCellLink,
        tableRow: classes.tableRow,
        tableRowHighlight: classes.tableRowHighlight
      }}
      columns={columns}
      row={row}
      key={row[rowKeyProperty]}
      keyName={row[rowKeyProperty]}
      handleClick={handleClick}
      handleReportEdit={handleReportEdit}
      isSelected={isSelected}
      isRowHighlighted={isRowHighlighted}
      ActionCell={ActionCell}
      actionCallback={actionCallback}
      noLeadingCheckbox={noLeadingCheckbox}
      >
    </TableRowData>;
  };

  const renderData = (columns, rows) => {
    if (remote) {
      return rows.map(row => renderRowData(columns, row));
    }
    else {
      if (enablePaging) {
        return renderLocalSortedDataWithPaging(rows);
      }
      else {
        return renderLocalSortedData(rows);
      }
    }
  };

  const renderLocalSortedData = rows => {
    return stableSort(rows, getSorting(order, orderBy))
      .map((row, index) => renderRowData(columns, row));
  };


  const renderLocalSortedDataWithPaging = rows => {
    return stableSort(rows, getSorting(order, orderBy))
      .slice((page - 1) * rowsPerPage, (page - 1) * rowsPerPage + rowsPerPage)
      .map((row, index) => renderRowData(columns, row));
  };
  
  return  [renderRowHeader(groupKey, columns) , renderData(columns, rows)];
};

TableData.propTypes = {
  classes: PropTypes.object.isRequired,
  columns: PropTypes.array.isRequired,
  rows: PropTypes.array.isRequired,

  rowKeyProperty: PropTypes.string,

  mode: PropTypes.oneOf(['local', 'remote']),

  order: PropTypes.oneOf(['asc', 'desc']),
  orderBy: PropTypes.string,

  enablePaging: PropTypes.bool,
  totalCount: PropTypes.number,
  page: PropTypes.number,
  rowsPerPage: PropTypes.number
};