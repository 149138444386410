import React from 'react';
import PropTypes from 'prop-types';
import { chainPropTypes } from '@material-ui/utils';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';

import { MenuItem, TableCell, Toolbar, Typography } from '@material-ui/core';
import CustomTablePaginationActions from './CustomTablePaginationActions';

import CustomDropdown from 'components/Dropdown/Dropdown';
//import { numberFormat } from 'utils';

export const useStyles = makeStyles(theme => ({
  /* Styles applied to the root element. */
  root: {
    color: theme.palette.text.primary,
    fontSize: theme.typography.pxToRem(14),
    paddingBottom: 5,
    // Increase the specificity to override TableCell.
    '&:last-child': {
      padding: 0,
      
    },
    flexGrow: 1
  },
  /* Styles applied to the Toolbar component. */
  toolbar: {
    minHeight: 25,
    paddingRight: 2,
    paddingLeft: 0,

  },
  /* Styles applied to the spacer element. */
  spacer: {
    flex: '1 1 100%',
  },
  /* Styles applied to the caption Typography components if `variant="caption"`. */
  caption: {
    flexShrink: 0,
  },
  /* Styles applied to the Select component root element. */
  selectRoot: {
    // `.selectRoot` should be merged with `.input` in v5.
    marginRight: 32,
    marginLeft: 8,
  },
  /* Styles applied to the Select component `select` class. */
  select: {
    paddingLeft: 8,
    paddingRight: 24,
    textAlign: 'right',
    textAlignLast: 'right', // Align <select> on Chrome.
  },
  /* Styles applied to the Select component `icon` class. */
  selectIcon: {
    top: 1,
  },
  /* Styles applied to the `InputBase` component. */
  input: {
    color: 'inherit',
    fontSize: 'inherit',
    flexShrink: 0,
  },
  /* Styles applied to the MenuItem component. */
  menuItem: {},
  /* Styles applied to the internal `TablePaginationActions` component. */
  actions: {
    minWidth: 62,
    flex: 1,
    marginLeft: 20,
    display: 'flex'
  },

  rowsPerPageButton: {
    marginLeft: 20,
    marginRight: 10,
    padding: 1,
    fontWeight: 'bold',
    backgroundColor: 'white',
    border: 'none',
    '&:hover':
    {
      border: 'none'
    }
  }
}));
const defaultRowsPerPageOptions = [10, 25, 50, 75, 100, 200, 500];

/**
 * A `TableCell` based component for placing inside `TableFooter` for pagination.
 */
const CustomTablePagination = React.forwardRef(function CustomTablePagination(props, ref) {
  const {
    ActionsComponent = CustomTablePaginationActions,
    backIconButtonProps,
    className,
    colSpan: colSpanProp,
    component: Component = TableCell,
    count,
    nextIconButtonProps,
    onChangePage,
    onChangeRowsPerPage,
    page,
    rowsPerPage,
    rowsPerPageOptions = defaultRowsPerPageOptions,
    SelectProps = {},
    toolbar,
    ...other
  } = props;

    const classes = useStyles();
  let colSpan;

  if (Component === TableCell || Component === 'td') {
    colSpan = colSpanProp || 1000; // col-span over everything
  }

  const MenuItemComponent = SelectProps.native ? 'option' : MenuItem;

  return (
    <Component className={clsx(classes.root, className)} colSpan={colSpan} ref={ref} {...other}>
      
      <Toolbar className={classes.toolbar}>
        { toolbar !== undefined ? props.toolbar : '' }
        <div className={classes.spacer} />
        {rowsPerPageOptions.length > 1 && (
          <Typography color="inherit" variant="body2" className={classes.caption}>
            <strong>View</strong>
          </Typography>
        )}
        {rowsPerPageOptions.length > 1 && (
          <CustomDropdown
            buttonText={`${count === 0 ? 0 : (page-1) * rowsPerPage + 1} - ${Math.min(count, (page) * rowsPerPage)}`}
            buttonProps={{
              className: classes.rowsPerPageButton,
              size: 'small',
              color: 'primary',
              variant: 'outlined'
            }}
            caret={false}
            dropdownHeader='Rows'
            dropdownList={rowsPerPageOptions}
            onClick={onChangeRowsPerPage}
            pageSize={rowsPerPage}
          />
        )}
        <Typography color="inherit" variant="body2" className={classes.caption}>
          {/* {count === 0 ? 0 : (page - 1) * rowsPerPage + 1}-{Math.min(count, (page) * rowsPerPage)} of <strong>{count}</strong> */}
          of&nbsp;&nbsp;&nbsp;<strong>{count}</strong>
        </Typography>
        <ActionsComponent
          className={classes.actions}
          backIconButtonProps={backIconButtonProps}
          count={count}
          nextIconButtonProps={nextIconButtonProps}
          onChangePage={onChangePage}
          page={page}
          rowsPerPage={rowsPerPage}
        />
      </Toolbar>
    </Component>
  );
});

CustomTablePagination.propTypes = {
  /**
   * The component used for displaying the actions.
   * Either a string to use a DOM element or a component.
   */
  ActionsComponent: PropTypes.elementType,
  /**
   * Props applied to the back arrow [`IconButton`](/api/icon-button/) component.
   */
  backIconButtonProps: PropTypes.object,
  /**
   * @ignore
   */
  className: PropTypes.string,
  /**
   * @ignore
   */
  colSpan: PropTypes.number,
  /**
   * The component used for the root node.
   * Either a string to use a DOM element or a component.
   */
  component: PropTypes.elementType,
  /**
   * The total number of rows.
   */
  count: PropTypes.number.isRequired,
  /**
   * Props applied to the next arrow [`IconButton`](/api/icon-button/) element.
   */
  nextIconButtonProps: PropTypes.object,
  /**
   * Callback fired when the page is changed.
   *
   * @param {object} event The event source of the callback.
   * @param {number} page The page selected.
   */
  onChangePage: PropTypes.func.isRequired,
  /**
   * Callback fired when the number of rows per page is changed.
   *
   * @param {object} event The event source of the callback.
   */
  onChangeRowsPerPage: PropTypes.func,
  /**
   * The zero-based index of the current page.
   */
  page: chainPropTypes(PropTypes.number.isRequired, props => {
    const { count, page, rowsPerPage } = props;
    const newLastPage = Math.max(1, Math.ceil(count / rowsPerPage));
    if (count > 0 && (page < 1 || page > newLastPage)) {
      return new Error(
        'Material-UI: the page prop of a TablePagination is out of range ' +
        `(1 to ${newLastPage}, but page is ${page}).`,
      );
    }
    return null;
  }),
  /**
   * The number of rows per page.
   */
  rowsPerPage: PropTypes.number.isRequired,
  /**
   * Customizes the options of the rows per page select field. If less than two options are
   * available, no select field will be displayed.
   */
  rowsPerPageOptions: PropTypes.array,
  /**
   * Props applied to the rows per page [`Select`](/api/select/) element.
   */
  SelectProps: PropTypes.object,
};

export default CustomTablePagination;