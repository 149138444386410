import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { useSnackbar } from 'notistack';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Collapse } from '@material-ui/core';

// custom components
import { useApi, EnhancedTable, useWindowDimensions } from 'components'
import { DataSourceContext, SearchContext, GridContext, ApplicationContext } from 'context'
import { Search, EnhancedTableToolbar, ActionCell, TargetEnvironmentDropdown } from './Components'
import { DCTopbar } from 'Topbar'

const useStyles = makeStyles(theme => ({
  root: {
    width: '98%',
    marginLeft: 10,
    backgroundColor: theme.palette.contentBackground,
    margin: 0
  },
  table: {
    minWidth: 500,
  }
}));

const useStylesAgain = makeStyles(theme => ({
  showEditor: {
    display: 'block'
  },
  hideEditor: {
    display: 'none'
  },
  topbarHolder: {
    padding: '3px 12px'
  }
}));

const rowsPerPageOptions = [10, 25, 50, 75, 100, 200, 500];

export default function FlatFileAdminTool() {

  const classes = useStyles();
  const localClasses = useStylesAgain();
  const api = useApi();
  const { enqueueSnackbar } = useSnackbar();
  const appContext = React.useContext(ApplicationContext);

  //state hooks
  const [loading, setLoading] = React.useState(false);
  const [totalCount, setTotalCount] = React.useState(0);
  const [page, setPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(appContext.appInfo.user.rowsPerPage || 25);

  const [orderBy, setOrderBy] = React.useState('dataSource');
  const [orderByDirection, setOrderByDirection] = React.useState('asc');

  const [rowsSelected, setRowsSelected] = React.useState([]);
  const [editRow, setEditRow] = React.useState({ moveItFolders: '' })
  const [editing, setEditing] = React.useState(false)
  const [editReportLoading, setEditReportLoading] = React.useState(false);

  const [dataSourceSelectedKey, setDataSourceSelectedKey] = React.useState('');
  const [targetEnvKey, setTargetEnvKey] = React.useState(1);
  const [dataSources, setImmediateFrequencies] = React.useState([]);
  const [dataSource, setDataSource] = React.useState('');
  const [module, setModule] = React.useState('');
  const [summarySource, setSummarySource] = React.useState('');
  const [rootFolder, setRootFolder] = React.useState('');
  const [runDaemon, setRunDaemon] = React.useState('');

  const [searchTitle, setSearchTitle] = React.useState('');
  const [filterCount, setFilterCount] = React.useState(0);

  document.title = "Immediate Delivery Configuration";
  const appKey = 'idc';

  const height = useWindowDimensions().height - 175;

  React.useEffect(() => {
    setLoading(true);
    loadDataSources();
  }, []);

  const handleClose = (row, updates) => {
    if (row && updates) {
      api.updateReport(row, updates).then((res) => {
        if (res.data && res.data.failed)
          enqueueSnackbar(res.data.errorMessage, { variant: 'warning' });
        else {
          setLoading(true);
          loadDataSources();
        }
      });
    }
  }

  const onChangePage = (newPage) => {
    setPage(newPage);
  }
  
  const onPageSizeChange = (newPageSize) => {
    setPage(1);
    api.saveRowPerPage({rowsPerPageOptions: newPageSize});
    appContext.appInfo.user.rowsPerPage = newPageSize;
    setPageSize(newPageSize);
  }

  const onRequestSort = order => {
    setOrderBy(order.orderBy);
    setOrderByDirection(order.order)
  }

  const getDataSource = (dsId) => {
    return dataSources.find((i) => i.uniqueId === dsId);
  }

  const onImmediateDeliveryClick = (event, value) => {
    setLoading(true);
    const row = getDataSource(value);
    //const oldRow = { ...row };
    row.isRunDaemon = !row.isRunDaemon;
    api.updateImmediateFrequency(row.uniqueId, row.isRunDaemon).then((res) => {
        if (res.data && res.data.failed) {
            enqueueSnackbar(res.data.errorMessage, { variant: 'warning' });
            row.isRunDaemon = !row.isRunDaemon; //reset since there was error
        }
        loadDataSources();
    });
  }

  const columns =
    [{field: 'dataSource', type: 'string', label: 'Data Source'},
    { field: 'module', type: 'string', label: 'Module'} ,
    { field: 'summarySource', type: 'string', label: 'Summary Source', disableSort: true},
    { field: 'rootFolder', type: 'string', label: 'Root Folder', disableSort: true},
    { field: 'isRunDaemon', type: 'bool', label: 'Enable Delivery', onClick: onImmediateDeliveryClick}]


  const loadDataSources = () => {
    setLoading(true);
    setRowsSelected([]);

    let sortInfo = '';
    if (orderBy !== '' && orderBy !== undefined && orderBy !== null) {
      const orderCol = columns.find((item) => orderBy === item.label || orderBy === item.field).field;
      sortInfo = "[{'Property': '" + orderCol + "' , 'direction' : '" + orderByDirection + "' }]";
    }
    let filter = {
      dataSource: dataSource,
      module: module,
      schemaId: targetEnvKey
    };

    let options = { page: page, pageSize: pageSize, token: dataSourceSelectedKey, sortInfo: sortInfo, filter: filter };

    //if (dataSourceSelectedKey !== '') {
      api.getImmediateFrequencies(options).then(res => {
        setImmediateFrequencies(res.data.data);
        setPage(res.data.page);
        setPageSize(res.data.pageSize);
        setTotalCount(res.data.totalCount);
        setLoading(false);
      })
    //}
  }

  //effect hooks
  useEffect(() => {
    setLoading(true);
    loadDataSources();
  }, [page, pageSize, orderBy, orderByDirection, dataSource, module, summarySource, rootFolder, runDaemon, targetEnvKey]);

  return (
    <SearchContext.Provider value={{
      dataSource, setDataSource,
      module, setModule, 
      summarySource, setSummarySource, 
      rootFolder, setRootFolder, 
      runDaemon, setRunDaemon,
      searchTitle, setSearchTitle, 
      orderBy, setOrderBy, 
      orderByDirection, setOrderByDirection, 
      pageSize, setPageSize, 
      page, setPage,
      filterCount, setFilterCount
    }}>
      <DataSourceContext.Provider value={{ dataSources, dataSourceSelectedKey, setDataSourceSelectedKey, targetEnvKey, setTargetEnvKey, appKey }}>
        <GridContext.Provider value={{ rowsSelected, setRowsSelected }}>
          <Grid item className={localClasses.topbarHolder}>
            <DCTopbar
              brandName="Delivery Configuration"
              Search={Search}
              RightComponent={TargetEnvironmentDropdown}
            />
          </Grid>
          <Grid item>
            <EnhancedTable
              classes={classes}
              rowKeyProperty="uniqueId"
              enablePaging={true}
              mode='remote'
              page={page}
              rowsPerPage={pageSize}
              columns={columns}
              rows={dataSources}
              groupKey='summarySourceName'
              groupKeyId='groupId'
              totalCount={totalCount}
              onChangePage={onChangePage}
              onChangeRowsPerPage={onPageSizeChange}
              onRequestSort={onRequestSort}
              rowsPerPageOptions={rowsPerPageOptions}
              defaultOrder={orderByDirection}
              defaultOrderBy={orderBy}
              title="Immediate Delivery Frequencies"
              callerKey="DC"
              loading={loading}
              EnhancedTableToolbar={EnhancedTableToolbar}
              EnhancedTableToolbarProps={{ refresh: loadDataSources }}
              ActionCell={ActionCell}
              actionCallback={loadDataSources}
              height={height}
              noLeadingCheckbox="true"
            />
          </Grid>
        </GridContext.Provider>
      </DataSourceContext.Provider>
    </SearchContext.Provider>
  );
}