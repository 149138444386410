import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { TableRow, TableCell, Box, Tooltip, Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import format from 'string-format';
import { NavLink as RouterLink } from 'react-router-dom';
import CustomCheckBox from 'components/Checkbox/CustomCheckbox'
import { numberFormat, dateFormat, capitalize,htmlDecode } from 'utilities';

const useStyles = makeStyles(() => ({
  link: {
    cursor: 'pointer'
  }
}));

const sizeRenderer = (value) => {
  const formatOptions = { style: 'decimal', maximumFractionDigits: 2 }
  let size = '0 B';
  if (!value)
    return size;
  if (value < 1024) {
    size = numberFormat(value, "en-US", formatOptions);
    return size + ' B';
  }
  else if (value < 1048576) {
    size = numberFormat((value / 1024), "en-US", formatOptions);
    return size + ' KB';
  }
  else if (value < 1073741824) {
    size = numberFormat((value / 1048576), "en-US", formatOptions);
    return size + ' MB';
  }
  else {
    size = numberFormat((value / 1073741824), "en-US", formatOptions);
    return size + ' GB';
  }
}

export default function TableRowData(props) {
  const {
    classes,
    columns,
    handleClick,
    isSelected,
    isRowHighlighted,
    row,
    keyName,
    ActionCell,
    actionCallback,
    noLeadingCheckbox
  } = props;

  const localClasses = useStyles();

  const renderData = (column, row) => {
    let data;
    const value = row[column.field];
    const totalLength = column.fileNamelength;
    switch (column.type) {
      case 'string':
        if (value !== undefined && value.length > totalLength)
          data = <Tooltip title={value}><span>{value.substring(0, totalLength) + "..."}</span></Tooltip>
        else
          data = value;
        break;
      case 'number':
        data = value === '' ? '' : numberFormat(value, 'en-US', column.formatOptions);
        break;
      case 'currency':
        if (!Boolean(column.currency)) {
          column.currency = 'USD';
        }
        data = numberFormat(value, 'en-US', { style: 'currency', currency: column.currency });
        break;
      case 'date':
        if (column.showTooltip && row[column.tooltipField] !== undefined && row[column.tooltipField] !== "")
          data = <Tooltip title={row[column.tooltipField]}>
                    <span>
                      {dateFormat(value, column.format)}
                    </span>
                  </Tooltip>
        else  
          data = dateFormat(value, column.format);
        break;
      case 'selectiveLinkWithTooltip': 
        if (column.showTooltip && row[column.tooltipField])
          data = <Tooltip
                    title={row[column.tooltipField]}>
                    <span>
                      <Link
                        className={localClasses.link}
                        onClick={event => column.onClick(event, keyName)}>{value}
                      </Link>
                    </span>
                  </Tooltip>
        else 
          data = <span>{value}</span>;
            break;
      case 'link':
        if (column.onClick === undefined)

          data = <RouterLink className={classes.tableCellLink} to={format(column.route, row[column.id])}>{htmlDecode(row[column.field])}</RouterLink>;
        else
        if (value !== undefined && value.length > 40)
          data = <Tooltip
            title={value}>
            <span>
              <Link
                className={localClasses.link}
                onClick={event => column.onClick(event, keyName)}>{value.substring(0, 40) + "..."}
              </Link>
            </span>
          </Tooltip>
        else
          data = <Link
            className={localClasses.link}
            onClick={event => column.onClick(event, keyName)}>{value}
          </Link>;
        break;
      case 'action':
        data = <ActionCell key={keyName} callback={actionCallback} keyName={keyName} row={row}></ActionCell>
        break;
      case 'size':
        data = sizeRenderer(value);
        break;
      case 'bool':
        data = <CustomCheckBox
                  checked={value}
                  disabled={column["disabled"]}
                  onChange={event => column.onClick(event, keyName, column.field)}
                ></CustomCheckBox>;
        break;
      default:
        data = '';
        break;
    };

    if (Boolean(column.tooltip)) {
      return <Tooltip title={column.tooltip || row[column.tooltip]}><Box style={{ cursor: 'pointer' }}>{data}</Box></Tooltip>;
    }
    else {
      return data;
    }
  };

  var isChecked = isSelected(keyName);
  return (
    <TableRow
      className={clsx(classes.tableRow, {[classes.tableRowHighlight] : isRowHighlighted(row)})}
      onClick={event => handleClick(event, keyName)}
      tabIndex={-1}>
      {(noLeadingCheckbox!=='true')?
      <TableCell
        padding="checkbox"
        className={clsx(
          classes.tableCell,
          classes[`tableCell$number`])} >
        <CustomCheckBox
          checked={isChecked}
          onChange={event => {}}
        />
      </TableCell>:null}

      {columns.map(column => {
        const cells = <TableCell
          key={column.field}
          className={clsx(
            classes.tableCell,
            classes[`tableCell${column.type}`],
            classes[`tableCell${capitalize(column.type)}`]
          )}>
          {renderData(column, row)}
        </TableCell>
        return cells;
      })}
    </TableRow>
  );
}

TableRowData.propTypes = {
  classes: PropTypes.object.isRequired,
  columns: PropTypes.array.isRequired,
  row: PropTypes.object,
  keyName: PropTypes.any.isRequired
};