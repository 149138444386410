import React from 'react';
import request from './request'
import DataSourceContext from 'context/DataSourceContext';

const useCollectionService = () => {
  const context = React.useContext(DataSourceContext);

  const downloadCollection = (Id, token,name) => {
    window.open('api/workbookcollections/download/'+Id+'?token='+ encodeURIComponent(token) + '&name=' + encodeURIComponent(name), '_blank', '', true);
  }

  const getAll = (options) => {
       return request({
        url: 'api/workbookcollections/getallcollections',
        method: 'POST',
        data: {
          page: options.page,
          pageSize: options.pageSize,
          filter: options.filter,
          sortJson: options.sortInfo,
          token: context.dataSourceSelectedKey
        },

   //   headers: { 'DataSource': context.dataSourceSelectedKey }
    });
   
  };
  const getBundle = (options) => {
    return request({
      url: `/api/workbookcollections/getbundle`,
      method: 'POST',
      data: {
        page: options.page,
        pageSize: options.pageSize,
        filter: options.filter,
        sortJson: options.sortInfo,
        token: options.token,
        id: options.id
      }
    });
  };
  
  const getBundle2 = (options) => {
    return request({
      url: `/api/workbookcollections/getbundle/${options.id}?token=${context.dataSourceSelectedKey}`,
      method: 'GET',
      params: {
        from: options.from,
        pageSize: options.pageSize,
        sortMode: options.sortMode,
        sortField: options.sortField

      }
    });
  };

  const createNewBundle = (options, name) => {
    //alert(options.name);
    return request({
      url: 'api/workbookcollections/saveabundle',
      method: 'POST',
      data: {batchRequest:options,name:name}
    });
  }

  const deleteAll = (keys) => {
    return request({
      url: '/api/workbookcollections/deletecollections',
      method: 'POST',
      data: keys
    });
  };


  const updateSort = (option) => {
    return request({
      url: `/api/collections/${option.id}`,
      method: 'PUT',
      params: {
        sortMode: option.sortMode,
        sortField: option.sortField
      },
      headers: {
        'Content-Type': 'application/json',
        'DataSource': context.dataSourceSelectedKey
      }
    });
  };

  const add = (data) => {
    return request({
      url: `/api/collections/`,
      method: 'POST',
      data: data,
      headers: {
        'Content-Type': 'application/json',
        'DataSource': context.dataSourceSelectedKey
      }
    });
  };

  const addBySearch = (data) => {
    return request({
      url: `/api/collections/search`,
      method: 'POST',
      data: data,
      headers: {
        'Content-Type': 'application/json',
        'DataSource': context.dataSourceSelectedKey
      }
    });
  };

  const addByAdvancedSearch = (data) => {
    return request({
      url: `/api/collections/advancedsearch`,
      method: 'POST',
      data: data,
      headers: {
        'Content-Type': 'application/json',
        'DataSource': context.dataSourceSelectedKey
      }
    });
  };

  const addFromCollection = (data) => {
    return request({
      url: `/api/collections/fromcollection`,
      method: 'POST',
      data: data,
      headers: {
        'Content-Type': 'application/json',
        'DataSource': context.dataSourceSelectedKey
      }
    });
  };



  const exportCollection = (id, option, salesDateFrom, salesDateTo) => {
    return request({
      url: `/api/export/collections/${id}`,
      method: 'POST',
      data: {
        selectAll: (option && option.selectionModel) ? option.selectionModel.selectAll : false,
        selectedIsbn: (option && option.selectionModel) ? option.selectionModel.selected : [],
        unSelectedIsbn: (option && option.selectionModel) ? option.selectionModel.unselected : [],

        salesDateFrom: salesDateFrom,
        salesDateTo: salesDateTo
      },
      responseType: 'blob',
      headers: { 'DataSource': context.dataSourceSelectedKey }
    });
  };

  const exportCollections = (option) => {
    return request({
      url: `/api/export/collections`,
      method: 'POST',
      data: {
        list: option.list || []
      },
      responseType: 'blob',
      headers: { 'DataSource': context.dataSourceSelectedKey }
    });
  };

  const deleteISBN = (option) => {
    return request({
      url: `/api/collections/delete/${option.id}/isbn`,
      method: 'POST',
      data:option.selectionModel,
      headers: {
        'Content-Type': 'application/json',
        'DataSource': context.dataSourceSelectedKey
      }
    });
  };
  const deleteAllISBN = (option) => {
    return request({
      url: `/api/collections/delete/${option.id}/isbn/all`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'DataSource': context.dataSourceSelectedKey
      }
    });
  };

  return {downloadCollection, createNewBundle, getBundle, add, getAll, deleteAll, deleteISBN, deleteAllISBN, updateSort, exportCollection, exportCollections, addBySearch, addByAdvancedSearch, addFromCollection }
}

export default useCollectionService;