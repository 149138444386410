//core
import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
//material ui
import { makeStyles } from '@material-ui/core/styles';
import { AppBar, Toolbar, Hidden, Box, Divider,IconButton } from '@material-ui/core';
//custom components
import useApi from 'components/Api/Api';
import { DataSourceSelection, Search, AdvancedSearch } from './components';
import { UserAvatar } from 'components';
import { GridContext, ApplicationContext } from 'context';
import MenuIcon from '@material-ui/icons/Menu';

const drawerWidth = 100;
const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: 'none',
    backgroundColor: theme.palette.white,
    zIndex: 1000,
    height: 40,
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth
    }
  },
  toolbar: {
    paddingLeft: 0,
    paddingRight: 0
  },
  pageTitle: {
    color: theme.palette.primary.contrastText
  },
  brand: {
    backgroundColor: theme.palette.background.default,
    textAlign: 'center',
   // paddingTop: 23,
    borderRight: `1px solid #F7F7F7`,
    fontSize: 15,
    fontWeight: '600',
    color: '#565A5C',
    width: 140,
  },
  flexGrow: {
    flexGrow: 1
  },
  avatar: {
    marginLeft: theme.spacing(2),
    color: '#fff',
    backgroundColor: theme.palette.primary.main,
  },

  dataSourceSelection: {
    backgroundColor: theme.palette.primary.dark,
    minHeight: 31
  },
  avatarBtnActive: {
    backgroundColor: '#32404E',
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.common.white,
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'red'//theme.palette.primary.main
  },
  iconRoot: {
    margin: 3
  },

shiftRight:{
  flexGrow: 1
},
  profileLabel: {
    color: theme.palette.white,
    fontWeight: 600,
    fontSize: 12,
    marginRight : 23,
    marginLeft : 10
  }
}));

const Topbar = props => {
  const { onSidebarOpen,className, dataSources, dataSourceSelectedKey, handleDataSourceChange, rowsPerPageOptions, columns, refresh, RightComponent, ...rest } = props;
  const { rowsSelected: rowsSelected } = React.useContext(GridContext);
  const appContext = React.useContext(ApplicationContext);
  const classes = useStyles();
  const api = useApi();
  const [mainHeaderRef, setMainHeaderRef] = React.useState(null);
  const { enqueueSnackbar } = useSnackbar();

  return (
    <AppBar
    
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Toolbar variant="dense" className={classes.toolbar} ref={setMainHeaderRef}>
        <Box className={classes.brand}>Client Center</Box>
        <Divider />
        {/*<span className={classes.profileLabel}>SEARCH TITLE</span>*/} 
        <Search></Search>
        <AdvancedSearch columns={columns} rowsPerPageOptions={rowsPerPageOptions}></AdvancedSearch>        

        <div className={classes.flexGrow} />
        {/*<Hidden smDown>
          <UserAvatar showPreferences></UserAvatar>
        </Hidden>*/}
        <Hidden mdUp>
          <IconButton
            color="default"
            onClick={onSidebarOpen}
          >
            <MenuIcon />
          </IconButton>
        </Hidden>
        <UserAvatar showPreferences></UserAvatar>
      </Toolbar>
      <Toolbar className={classes.dataSourceSelection} variant="dense">
        <span className={classes.profileLabel}>DATA SOURCE</span>
        <DataSourceSelection dataSources={dataSources} dataSourceSelectedKey={dataSourceSelectedKey} handleDataSourceChange={handleDataSourceChange} />
        <span className={classes.shiftRight}/>
        {RightComponent &&
          <RightComponent/>}
      </Toolbar>
    </AppBar>
  );
};

Topbar.propTypes = {
  className: PropTypes.string,
  onSidebarOpen: PropTypes.func,

  setBackdrop: PropTypes.func
};

export default Topbar;
