import React from "react";
import { useSnackbar } from 'notistack';
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import {Grid} from '@material-ui/core';
// custom components
import {EnhancedTable, useApi, useReportApi, useWindowDimensions} from 'components';
import {SearchContext, DataSourceContext, GridContext, ApplicationContext} from 'context';
import {Search, ActionCell, AdvancedSearchForm,EnhancedToolbar } from './Components'
import {Topbar} from 'Topbar';
import {dateFormat} from 'utilities'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.contentBackground,
    padding: '0px',
    margin: 0
  }
}));

const columns = [
  {
    field: 'folderName',
    type: 'string',
    label: 'Folder Name'
  },
  {
    field: 'reportName',
    type: 'string',
    label: 'Report Name',
  },
  {
  field: 'createdBy',
  type: 'string',
  label: 'Created By'
},

{
  field: 'createdDate',
  type: 'date',
  label: 'Created Date',
  format: 'MM-DD-YYYY hh:mm A'
},
{
  field: 'updatedDate',
  type: 'date',
  label: 'Last Modified Date',
  format: 'MM-DD-YYYY hh:mm A'
},
{
  field: 'batchDate',
  type: 'date',
  label: 'Batched On',
  format: 'MM-DD-YYYY hh:mm A'
},
{
  field: 'statusNames',
  type: 'string',
  label: 'Batch Status'
},
{
  field: 'action',
  type: 'action',
  label: ''
}]

export default function Reports(props) {
  const { Toolbar, toolbarProps } = props;
  const [totalCount, setTotalCount] = React.useState(0);
  const [rowsSelected, setRowsSelected] = React.useState([]);
  const [page, setPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(25);
  const [fileRepo, setFileRepo] = React.useState([]);
  const [orderBy, setOrderBy] = React.useState('reportName');
  const [orderByDirection, setOrderByDirection] = React.useState('desc');
  const [loading, setLoading] = React.useState(false);
  
  const [reportName, setReportName] = React.useState('');
  const [folderName, setFolderName] = React.useState('');
  const [lastModifiedStartdate, setLastModifiedStartdate] = React.useState(null);
  const [lastModifiedEnddate, setLastModifiedEnddate] = React.useState(null);
  const [createdStartdate, setCreatedStartdate] = React.useState(null);
  const [createdEnddate, setCreatedEnddate] = React.useState(null);
  const [batchStartdate, setBatchStartdate] = React.useState(null);
  const [batchEnddate, setBatchEnddate] = React.useState(null);
  const [batchStatus, setBatchStatus] = React.useState('');
  const [format, setFormat] = React.useState('');

  const [statusFilters, setStatusFilters] = React.useState([]);

  const dataSourceContext = React.useContext(DataSourceContext);
  const appContext = React.useContext(ApplicationContext);
  
  const {enqueueSnackbar} = useSnackbar();
  const api = useApi();
  const reportApi = useReportApi();

  React.useEffect(() => {
    setLoading(true);
    loadAllReports();
  },  [pageSize, page, orderByDirection, orderBy, dataSourceContext.dataSourceSelectedKey, reportName, folderName, batchStatus, batchEnddate, batchStartdate, lastModifiedStartdate, lastModifiedEnddate, createdStartdate, createdEnddate])

//   const canDownload = (r) => {
//     return ((r.statusId === 6 || r.statusId === 14 || r.statusId === 15 || r.statusId === 16 || r.statusId === 17) && r.reportOutputTypeId !== 9);
// } 
  const handleZipRepoMultiDownloadClick = () => {
    if (rowsSelected.length > appContext.appInfo.maxMultiFiledownload)
      enqueueSnackbar("You can only download maximum of " + appContext.appInfo.maxMultiFiledownload + " files at a time.", { variant: 'warning' });
    else {
      api.isAuthenticated().then(res => {
        if (res.data.isAuthenticated){
          let notFound = [];
          let found = [];
          rowsSelected.map((r) => {
              if (r.canDownload)
                found.push(r);
                //
              else 
                notFound.push(r.displayName);
            });

            if (found.length > 0)
          {
              if (found.length === 1)
                api.downloadBatchedReport(found[0].scheduleEventId, found[0].token);
              else 
                api.downloadFileRepoZip(found);
          }
            if (notFound.length !== 0)
              enqueueSnackbar("Following files were not found: " + notFound.toString(), { variant: 'warning' });
        }
      });
    }
  }

  const handleReportMultiDownloadClick = () => {
    if (rowsSelected.length > appContext.appInfo.maxMultiFiledownload)
      enqueueSnackbar("You can only download maximum of " + appContext.appInfo.maxMultiFiledownload + " files at a time.", { variant: 'warning' });
    else {
      api.isAuthenticated().then(res => {
        if (res.data.isAuthenticated){
          let notFound = [];
          rowsSelected.map((r) => {
              if (r.canDownload)
                reportApi.downloadBatchedReport(r.scheduleEventIds, r.token)
              else 
                notFound.push(r.reportName);
            });
            if (notFound.length !== 0)
              enqueueSnackbar("Following files were not found: " + notFound.toString(), { variant: 'warning' });
        }
      });
    }
  }

  const refresh = () =>{
    setRowsSelected([]);
    loadAllReports();
  }
 
  const loadAllReports = () => {
    setLoading(true);
    console.log(dateFormat(createdStartdate, "mm/dd/yyyy"))
    let filter = {
      reportName: reportName,
      folderName: folderName,
      lastModifiedTo: lastModifiedEnddate !== null ? lastModifiedEnddate.format("MM/DD/YYYY 11:59A") : null,
      lastModifiedFrom: lastModifiedStartdate !== null ? lastModifiedStartdate.format("MM/DD/YYYY") : null,
      batchDateTo: batchEnddate !== null ? batchEnddate.format("MM/DD/YYYY 11:59A") : null,
      batchDateFrom: batchStartdate !== null ? batchStartdate.format("MM/DD/YYYY") : null,
      createdFrom: createdStartdate !== null ? createdStartdate.format("MM/DD/YYYY") : null,
      createdTo: createdEnddate !== null ? createdEnddate.format("MM/DD/YYYY 11:59A") : null,
      batchStatus: batchStatus.displayName,
      token: dataSourceContext.dataSourceSelectedKey
    };

    let pagingInfo = {
      page: page,
      limit: pageSize,
      orderBy: orderBy,
      orderByDirection: orderByDirection
    };

    let options = {
      pagingInfo: pagingInfo,
      filter: filter
    }
    
    if (dataSourceContext.dataSourceSelectedKey !== ''){
      reportApi.getAll(options).then(res => {
        setFileRepo(res.data.data);
        setPage(res.data.page);
        setPageSize(res.data.pageSize);
        setTotalCount(res.data.totalCount);
        setLoading(false);
      })}
  }

  const onChangePage = (newPage) => {
    setPage(newPage);
  }
  const onPageSizeChange = (newPageSize) => {
    setPage(1);
    api.saveRowPerPage({rowsPerPageOptions: newPageSize});
    appContext.appInfo.user.rowsPerPage = newPageSize;
    setPageSize(newPageSize);
  }
  const onRequestSort = order => {
    setOrderBy(order.orderBy);
    setOrderByDirection(order.order)
  }

  React.useEffect(() => {
    api.getStatusFilters().then(res => {
        if (res && res.data)
            setStatusFilters(res.data);
    });
}, []);

  const classes = useStyles();
  return (
    <SearchContext.Provider value={{batchStatus, setBatchStatus, reportName, setReportName, page, setPage, folderName, setFolderName, lastModifiedStartdate, setLastModifiedStartdate, lastModifiedEnddate, setLastModifiedEnddate, format, setFormat, createdStartdate, setCreatedStartdate, createdEnddate, setCreatedEnddate, batchStartdate, setBatchStartdate, batchEnddate, setBatchEnddate}}>
      <GridContext.Provider value={{rowsSelected, setRowsSelected}}>
        <React.Fragment>
          <Grid item >
              <Topbar 
                brandName="File Center" 
                Search={Search} 
                AdvancedSearchForm={AdvancedSearchForm} 
                AdvancedSearchFormProps={{statusFilters:statusFilters}}
              />
          </Grid>
          <Grid item>
          {/*<Toolbar {...toolbarProps} refresh={loadFileRepo} ></Toolbar>*/}
            <EnhancedTable 
              classes={classes}
              rowKeyProperty='reportId' 
              enablePaging={true} 
              mode='remote'
              page={page}
              rowsPerPage={pageSize}
              columns={columns} 
              rows={fileRepo}
              groupKey='dataSourceDisplayName'
              groupKeyId='groupId'
              totalCount={totalCount}
              onChangePage={onChangePage}
              onChangeRowsPerPage={onPageSizeChange}
              onRequestSort={onRequestSort}
              defaultOrder={orderByDirection}
              defaultOrderBy={orderBy}
              loading={loading}
              title="File Repo"
              ActionCell={ActionCell}
              actionCallback={refresh}
              height={useWindowDimensions().height - 180}
              FileCenterToolbar={Toolbar}
              FileCenterToolbarProps={{refresh: refresh, ...toolbarProps}}
              EnhancedTableToolbar={EnhancedToolbar}
              EnhancedTableToolbarProps={{refresh: refresh, multidownload: appContext.appInfo.user.enableZipFC ? handleZipRepoMultiDownloadClick : handleReportMultiDownloadClick}}
              persistSelection={true}
            />
            </Grid>
          
        </React.Fragment>
      </GridContext.Provider>
    </SearchContext.Provider>

  );
}
