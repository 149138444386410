import React from 'react';
import ApplicationContext from 'context/ApplicationContext'

import {makeStyles, Typography} from '@material-ui/core'

const useStyle = makeStyles(() => ({

    footer: {
        paddingLeft: 80,
        marginLeft: 30         
    },
    version: {
        float: "right",
        paddingRight: 20
    }
}));

export default function Footer() {
    var appContext = React.useContext(ApplicationContext)
    var classes = useStyle();

    return (
        <div className={classes.footer}>
            <Typography variant='caption'> {appContext.appInfo.copyright}<span className={classes.version}>Version: {process.env.REACT_APP_VERSION}</span></Typography>
        </div>)
    ;
}