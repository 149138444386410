import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types'

import clsx from 'clsx';
import {SearchAutocomplete} from 'components';
import { makeStyles } from '@material-ui/core/styles';
import { Select, MenuItem, Divider, Grid, Button, IconButton } from '@material-ui/core';
import { Search as SearchIcon, Close, ArrowDropDown as ArrowDropDownIcon, Cached } from '@material-ui/icons'
import {SearchContext} from 'context';

const useStyles = makeStyles(theme => ({
  root: {
    width: 460,
    margin: '0 0 0 30px',
    padding: 0,
    border: '1px solid #D8D8D8',
    borderRadius: theme.shape.borderRadius,
    height: 33,

    '&:hover': {
      borderColor: theme.palette.primary.main,
    }
  },

  active: {
    borderColor: theme.palette.primary.main
  },

  hr: {
    margin: 0,
    height: 30,
  },
  iconButton: {
    marginRight: 3,
    marginLeft: 5,
    padding: '1px 1px',
    minWidth: 30,
    boxShadow: 'none',
    borderRadius: 2
  },
  searchIcon: {
    width: 18
  },
  select: {
    paddingTop: 7,
    paddingLeft: theme.spacing(1),
    width: 70
  },
  selectDisplayStyle: {
    color: theme.palette.primary.main,
    fontWeight: '600',
    fontSize: 12,
  },
  selectItemStyle: {
    color: theme.palette.primary.main,
    fontWeight: '300',
    fontSize: 12,
    paddingLeft: theme.spacing(1),
    paddingTop: 0,
    paddingBottom: 0

  },
  popOverStyle: {
    width: 85,
    borderRadius: '0 0 4px 4px',
    boxShadow: '0 4px 10px -4px rgba(187,206,219,0.44)'
  },
  menuItemSelected: {
    backgroundColor: 'transparent !important;',
    fontWeight: '600',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.14) !important;'
    }
  }
}));



export default function Search(props) {
  const classes = useStyles();
  const searchContext = React.useContext(SearchContext);
  const {
    handleSearchTypeChange,
    handleTextBoxOnChange,
    handleAutoCompleteValueOnChange,
    handleOnEnter,
    handleSearch,
    handleClearClick,
    GetAutoSuggestOptions,
    searchTypes,
    inputTerm,
    setInputTerm,
    searchType,
    showClearButton = true,
    optionKey = 'keyword',
    optionId= 'id'
  } = props;

  const [options, setOptions] = React.useState([]);
  const [active, setActive] = useState(false);

  const onClearClick = () => {
    setInputTerm('');
    if (handleClearClick)
      handleClearClick();
  }

  return (
    <Grid container alignItems='center' className={active ? clsx(classes.root, classes.active) : classes.root} id='search-bar'>
      <Select id='searchType' classes={{
        root: classes.select,
        selectMenu: classes.selectDisplayStyle
      }}
        value={searchType}
        onChange={handleSearchTypeChange}
        disableUnderline={true}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left'
          },
          getContentAnchorEl: null,
          classes:
          {
            paper: classes.popOverStyle
          }
        }}
      >
        {searchTypes.map(stype => (
          <MenuItem className={classes.selectItemStyle} classes={{ selected: classes.menuItemSelected }} key={stype.id} value={stype.id}>{stype.Name}</MenuItem>
        ))}
      </Select>
      <Divider orientation='vertical' className={classes.hr} />
      <SearchAutocomplete
        optionKey={optionKey}
        optionId={optionId}
        inputTerm={inputTerm}
        setInputTerm={setInputTerm}
        searchType={searchType}
        setSearchType={handleSearchTypeChange}
        options={options}
        setOptions={setOptions}
        handleOnEnter={handleOnEnter}
        handleOnFocus={() => setActive(true)}
        handleOnBlur={() => setActive(false)}
        handleTextBoxOnChange={handleTextBoxOnChange}
        handleSearchRedirect={handleSearch}
        handleAutoCompleteValueOnChange={handleAutoCompleteValueOnChange}
        GetAutoSuggestOptions={GetAutoSuggestOptions}
      />
       {(showClearButton && inputTerm !== '') ?
        <IconButton variant='contained' color='secondary' className={classes.iconButton} onClick={onClearClick}>
          <Close classes={{ root: classes.searchIcon }} />
        </IconButton> : ''
      }
      <Button id='searchIcon' variant='contained' color='primary' size='small' className={classes.iconButton} aria-label='search' onClick={handleSearch}>
        <SearchIcon classes={{ root: classes.searchIcon }} />
      </Button>
    </Grid>

  );
}


Search.propTypes = {
    handleSearchTypeChange: PropTypes.func.isRequired,
    handleTextBoxOnChange: PropTypes.func.isRequired,
    handleAutoCompleteValueOnChange: PropTypes.func.isRequired,
    handleOnEnter: PropTypes.func.isRequired,
    handleSearch: PropTypes.func.isRequired,
    GetAutoSuggestOptions: PropTypes.func.isRequired,
    searchTypes: PropTypes.array.isRequired,
    inputTerm: PropTypes.string,
    setInputTerm: PropTypes.func.isRequired,
    searchType: PropTypes.number
};
