import React, { useEffect, useState } from 'react';

import _ from 'lodash';
// @material-ui/core components
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Icon from '@mdi/react'
import { mdiIncognito } from '@mdi/js'

const useStyles = makeStyles(theme => ({

  root: {
    padding: 2,
  },
  startImpersonating: {
    paddingLeft: 5,
    display: 'flex',
    '& span': {
      color: theme.palette.text.link,
      fontSize: 20
    }
  },
  startImpersonatingText: {
    color: '#4E106F',
    fontSize: 12,
    margin: 5,
    cursor: 'pointer'
  },
  icon: {
    background: '#F2F2F2',
    borderRadius: 4,
    padding: 2,
    margin: 0,
    height: 28,
  }

}));

export default function StartImpersonation(props) {
  const { setView } = props;
  const classes = useStyles();
  
  const onClick = () => {
    setView('select');
  }

  return (
    <div className={classes.root}>
      <div className={classes.startImpersonating} onClick={onClick}>
        <div className={classes.icon}>
          <Icon path={mdiIncognito}
            title="User Profile"
            size={1}
            color="#E0E0E0"/>
        </div>
        <div className={classes.startImpersonatingText}>Start impersonating</div>
      </div>
    </div>
  );
}
