import React from "react";
import { useSnackbar } from 'notistack';
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import {Grid} from '@material-ui/core';
// custom components
import {EnhancedTable, useApi, useWindowDimensions} from 'components';
import {SearchContext, DataSourceContext, GridContext, ApplicationContext} from 'context';
import {Search, ActionCell } from './Components'
import {Topbar} from 'Topbar';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.contentBackground,
    padding: '0px',
    margin: 0
  }
}));

const columns = [
  {
    field: 'fileName',
    type: 'string',
    label: 'File',
  },{
  field: 'description',
  type: 'string',
  label: 'Description'
},{
  field: 'action',
  type: 'action',
  label: ''
}]

export default function FileRepo(props) {
  const { Toolbar, toolbarProps, EnhancedToolbar } = props;
  const [totalCount, setTotalCount] = React.useState(0);
  const [rowsSelected, setRowsSelected] = React.useState([]);
  const [page, setPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(25);
  const [fileRepo, setFileRepo] = React.useState([]);
  const [orderBy, setOrderBy] = React.useState('fileName');
  const [orderByDirection, setOrderByDirection] = React.useState('desc');
  const [loading, setLoading] = React.useState(false);
  const [title, setTitle] = React.useState('');
  const [fileName, setFileName] = React.useState('');

  const dataSourceContext = React.useContext(DataSourceContext);
  const appContext = React.useContext(ApplicationContext);
  
  const {enqueueSnackbar} = useSnackbar();
  const api = useApi();

  React.useEffect(() => {
    loadFileRepo();
    setPage(1);
  }, [dataSourceContext.dataSourceSelectedKey]);

  React.useEffect(() => {
    //setLoading(true);
    loadFileRepo();
  },  [pageSize, page, orderByDirection, orderBy, title, fileName])

  const handleZipRepoMultiDownloadClick = () => {
    if (rowsSelected.length > appContext.appInfo.maxMultiFiledownload)
      enqueueSnackbar("You can only download maximum of " + appContext.appInfo.maxMultiFiledownload + " files at a time.", { variant: 'warning' });
    else {
      api.isAuthenticated().then(res => {
        if (res.data.isAuthenticated){
          let notFound = [];
          let found = [];
          rowsSelected.map((r) => {
              if (r.isExists)
                found.push(r);
                //
              else 
                notFound.push(r.displayName);
            });

            if (found.length > 0)
          {
              if (found.length === 1)
                api.downloadFileRepo(found[0].fileRepositoryId, found[0].token);
              else 
                api.downloadFileRepoZip(found);
          }
            if (notFound.length !== 0)
              enqueueSnackbar("Following files were not found: " + notFound.toString(), { variant: 'warning' });
        }
      });
    }
  }

  const handleRepoMultiDownloadClick = () => {
    if (rowsSelected.length > appContext.appInfo.maxMultiFiledownload)
      enqueueSnackbar("You can only download maximum of " + appContext.appInfo.maxMultiFiledownload + " files at a time.", { variant: 'warning' });
    else {
      api.isAuthenticated().then(res => {
        if (res.data.isAuthenticated){
          let notFound = [];
          rowsSelected.map((r) => {
              if (r.isExists)
                api.downloadFileRepo(r.fileRepositoryId, r.token)
              else 
                notFound.push(r.displayName);
            });
            if (notFound.length !== 0)
              enqueueSnackbar("Following files were not found: " + notFound.toString(), { variant: 'warning' });
        }
      });
    }
  }

  const handleDownLoadClick = uniqueId => {
    const document = fileRepo.find((row) => {
        return row.uniqueId === uniqueId;
    });
    api.isAuthenticated().then(res => {
        if (res.data.isAuthenticated)
            api.downloadFileRepo(document.fileRepositoryId, document.token);
    });
  };

  const actions = [{
    name: 'download',
    handler: handleDownLoadClick,
    tooltip: 'Click to Download',
    keyColumn: 'uniqueId',
    showOnRow: true
  }];
  
  const loadFileRepo = () => {
   
    //setRowsSelected([]);
    
    let sortInfo = '';
    if (orderBy !== '' && orderBy !== undefined && orderBy !== null) {
        const orderCol = columns.find((item) => orderBy === item.label || orderBy === item.field).field;
        sortInfo = "[{'Property': '" + orderCol + "' , 'direction' : '" + orderByDirection + "' }]";
    }
    let filter = {
      displayName: title,
      fileName: fileName
    };

    let options = {page: page, pageSize: pageSize, token: dataSourceContext.dataSourceSelectedKey, filter: filter, sortInfo: sortInfo}
    
    if (dataSourceContext.dataSourceSelectedKey !== ''){
      setLoading(true);
      api.getFileRepo(options).then(res => {
        setFileRepo(res.data.data);
        setPage(res.data.page);
        setPageSize(res.data.pageSize);
        setTotalCount(res.data.totalCount);
        setLoading(false);
      })}
  }

  const onChangePage = (newPage) => {
    setPage(newPage);
  }
  const onPageSizeChange = (newPageSize) => {
    setPage(1);
    api.saveRowPerPage({rowsPerPageOptions: newPageSize});
    appContext.appInfo.user.rowsPerPage = newPageSize;
    setPageSize(newPageSize);
  }
  const onRequestSort = order => {
    setOrderBy(order.orderBy);
    setOrderByDirection(order.order)
  }

  const classes = useStyles();
  return (
    <SearchContext.Provider value={{title, setTitle, fileName, setFileName, page, setPage}}>
      <GridContext.Provider value={{rowsSelected, setRowsSelected}}>
        <React.Fragment>
          <Grid item >
              <Topbar brandName="File Center" Search={Search} />
          </Grid>
          <Grid item>
          {/*<Toolbar {...toolbarProps} refresh={loadFileRepo} ></Toolbar>*/}
            <EnhancedTable 
              classes={classes}
              rowKeyProperty="uniqueId" 
              enablePaging={true} 
              mode='remote'
              page={page}
              rowsPerPage={pageSize}
              columns={columns} 
              rows={fileRepo}
              groupKey='summarySourceName'
              groupKeyId='groupId'
              totalCount={totalCount}
              onChangePage={onChangePage}
              onChangeRowsPerPage={onPageSizeChange}
              onRequestSort={onRequestSort}
              defaultOrder={orderByDirection}
              defaultOrderBy={orderBy}
              loading={loading}
              title="File Repo"
              ActionCell={ActionCell}
              height={useWindowDimensions().height - 180}
              FileCenterToolbar={Toolbar}
              FileCenterToolbarProps={{refresh: loadFileRepo, ...toolbarProps}}
              EnhancedTableToolbar={EnhancedToolbar}
              EnhancedTableToolbarProps={{refresh: loadFileRepo, multidownload: appContext.appInfo.user.enableZipFC ? handleZipRepoMultiDownloadClick : handleRepoMultiDownloadClick}}
              persistSelection={true}
            />
            </Grid>
          
        </React.Fragment>
      </GridContext.Provider>
    </SearchContext.Provider>

  );
}
