
import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import logo from 'assets/npd.png';

const useStyles = makeStyles(theme => ({
  toolbar: theme.mixins.toolbar,
  img: {
    width: 110,
    margin: '0px 0px'
    }
}));

const defaultUrl = 'https://www.npd.com';
const defaultImageUrl =  {logo};
const defaultImageUrl2 = process.env.REACT_APP_ROUTE_BASENAME + '/assets/npd.png';
const Logo = props => {
  const classes = useStyles();
  const npdlogo = { url: defaultUrl, imageUrl: defaultImageUrl };
    return (
    <div className={classes.toolbar}>
      <a
        href={npdlogo.url}
        target="_blank"
        rel="noopener noreferrer"
      >
         <img src={logo} alt="logo" className={classes.img} />
     </a>
        </div>
  );
};

Logo.propTypes = {
};

export default Logo;