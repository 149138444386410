import React from 'react';
import { useSnackbar } from 'notistack';
//material ui components
import { IconButton, Tooltip } from '@material-ui/core';
import { AddBoxTwoTone , GetApp as DownloadIcon, Error, DeleteForever as DeleteIcon, CancelScheduleSend as UnqueueIcon } from '@material-ui/icons';
//import {AddToPhotosTwoToneIcon as AddCollectionIcon} from '@material-ui/icons/AddToPhotosTwoTone';

import { makeStyles } from '@material-ui/core/styles';
import {useApi} from 'components'
import {ApplicationContext} from 'context';


const useStyle = makeStyles(theme => ({
    root: {
        padding: '0 2px',
        fontSize: '1rem',
        '& > span > svg':{
            fontSize: '1.2rem',
        }
    },
    colorError: {
        color: theme.palette.npd.orange
    },
    colorSuccess: {
        color: theme.palette.npd.green
    }

})); 
const renderAction = (row, action, classes, keyName) => {
    let item;
    switch (action.name) {
      case 'add':
          item = <Tooltip key={row[action.keyColumn] + action.name} title={action.tooltip}>
                    <span>
                        <IconButton classes={{root: classes.root}} size='small' onClick={() => action.handler(keyName)} disabled={!row.isExists}>
                            <AddBoxTwoTone/>
                        </IconButton>
                    </span>
                </Tooltip>
        break;
        case 'delete': item = <Tooltip key={row[action.keyColumn] + action.name} title={action.tooltip}>
                                <span>
                                    <IconButton onClick={() => action.handler(keyName)} classes={{root: classes.root}} size='small' disabled={!action.isEnabled(row.statusId)}>
                                        <DeleteIcon/>
                                    </IconButton>
                                </span>
                            </Tooltip>
        break;
        case 'unqueue': item = <Tooltip key={row[action.keyColumn] + action.name} title={action.tooltip}>
                                <span>
                                    <IconButton onClick={() => action.handler(keyName)} classes={{root: classes.root}} size='small' disabled={!action.isEnabled(row.statusId)}>
                                        <UnqueueIcon/>
                                    </IconButton>
                                </span>
                            </Tooltip>
        break;
      default:
        item = <React.Fragment/>;
        break;
    };
    return item;
}

export default function ActionCell(props){
    const {keyName, row, callback} = props;
    const api = useApi();
    const classes = useStyle();
    const appContext = React.useContext(ApplicationContext);
    const { enqueueSnackbar } = useSnackbar();
    
    const canDelete = (status) => {
        return status !== 1 && status !== 2 && status !==7 && row.createdUserName === appContext.appInfo.user.username;
    }
    const canRemoveFromQueue = (status) => {
        return status === 1 || status === 2 || status === 7 && row.createdUserName === appContext.appInfo.user.username;
    }
    const canDownload = (status, outputType) => {
        return ((status === 6 || status === 14 || status === 15 || status === 16 || status === 17) && outputType !== 9);
    }

    const handleDownLoadClick = () => {
        api.isAuthenticated().then(res => {
            if (res.data.isAuthenticated)
                api.downloadBatchedReport(row.scheduleEventId, row.token);
        });
    };

    const handleDeleteClick = () => {
        api.deleteReport([row]).then((res) => {
            if (callback)
                callback();
            if (res.data)
                enqueueSnackbar(res.data, { variant: 'error' });
        });
    }

    const handleUnqueueClick = () => {
        api.unqueueBatchReport([row]).then((res) => {
            if (callback)
                callback();
            if (res.data)
                enqueueSnackbar(res.data, { variant: 'error' });
        });
      }
    const actions = [{name: 'add',  handler: handleDownLoadClick, tooltip: 'Add to Bundle', showOnRow: true, isEnabled: canDownload}
                    ]
    
    return (
        <div>
            {actions.map(action => {
                return renderAction(row, action, classes, keyName);
            })} 
            
        </div>
    );

}