import React, { useState } from "react";
// @material-ui/core components
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Select, MenuItem, Link } from '@material-ui/core';

import { useApi } from 'components';

import { DataSourceContext } from 'context';

const useStyles = makeStyles(theme => ({
  select: {
    color: theme.palette.primary.contrastText,
    fontSize: 12,
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(1),
    fontWeight: 300,
    borderRadius: 12,
    border: `2px solid ${theme.palette.primary.contrastText}`,
    '&:focus': {
      borderRadius: 12,
      border: `2px solid ${theme.palette.primary.contrastText}`
    }
  },
  selectIcon: {
    color: theme.palette.primary.contrastText,

  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  selectDisplayStyle: {
    color: theme.palette.white,
    fontSize: 12,
    lineHeight: 0.5,
    margin: 0,
    fontWeight: 600,
    minHeight: 'auto'
  },
  selectItemStyle: {
    color: theme.palette.primary.main,
    fontWeight: '500',
    padding: '5px 25px 0px 10px',
    fontSize: 12
  },
  popOverStyle: {
    marginTop: 0,
    marginLeft: 0,
    borderRadius: 12,
    boxShadow: theme.palette.boxShadow
  },
  selectPopupLabel: {
    fontSize: 12,
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.primary.main,
    fontWeight: 600,
    height: 15,
    padding: '9px 3px 9px 10px',
  },
  menuRoot: {
    padding: '5px 0px'
  },
  searchInput: {
    border: 0,
    color: theme.palette.primary.main,
    fontWeight: '500',
    padding: '5px 25px 10px 10px',
    fontSize: 12,
    outline: 'none',
    width: '100%',
    '&: focus': {
      outline: 'none'
    }

  }
}));

export default function CustomSelect(props) {
  const classes = useStyles();
  const theme = useTheme();  
  const {options, keyName, setKeyName, valueColumn, keyColumn, showSearch = false, ...rest} = props;
  const context = React.useContext(DataSourceContext);
  const [selectOpen, setSelectOpen] = React.useState(false);
  const [filterValue, setFilterValue] = React.useState('');
  const dataSourceSelectedKey = context.dataSourceSelectedKey;
  const setDataSourceSelectedKey = context.setDataSourceSelectedKey;

  const handleChange = event => {
    event.stopPropagation();
    event.preventDefault();
    if (event.target !== undefined && event.target.value !==undefined){
      setKeyName(event.target.value);
      setSelectOpen(false);
    }
  };

  const getSelectedValue = () => {
    return options.find((item) => item[keyColumn] === keyName) ? keyName : '';
  };

  const filterOptions = (e) => {
    setFilterValue(e.target.value);
  }

  const showOption = (item) => 
  {
    if (item[keyColumn] === keyName)  return false;
    if (filterValue === '')  return true;
    if (item[valueColumn].toLocaleLowerCase().indexOf(filterValue.toLocaleLowerCase()) > -1 ) return true;
    return false;
  }

  const preventDefault = event => event.preventDefault();
  return (
    <Select
      {...rest}
      id='data-source-selection'
      value={getSelectedValue()}
      onChange={handleChange}
      classes={{
        select: classes.select,
        icon: classes.selectIcon,
        selectMenu: classes.selectDisplayStyle
      }}
      open={selectOpen}
      onOpen={() => {setSelectOpen(true)}}
      onClose={() => {setFilterValue(''); setSelectOpen(false)}}
      disableUnderline={true}
      renderValue={selected => {
        var select = options.find((item) => item[keyColumn] === selected);
        if (select)
          return select[valueColumn];
        else
          return '';
      }}
      onBlur={() => {setSelectOpen(false)}}
      MenuProps={{
        anchorOrigin: {
          vertical: "top",
          horizontal: "left"
        },
        getContentAnchorEl: null,
        classes: {
          list: classes.menuRoot,
          paper: classes.popOverStyle
        }
      }}
    >
      <div style={{ padding: 0, margin: 0 }}></div>
      {showSearch && <MenuItem button={false} selected={false} className={classes.selectPopupLabel} style={{ backgroundColor: 'transparent', borderBottom: `2px solid ${theme.palette.tableRowDivider}` }}>
        <input 
          placeholder="Search..."
          className={classes.searchInput} 
          onChange={filterOptions} 
          onKeyDown={(event => event.stopPropagation())} 
          onClick={(event => event.stopPropagation())} 
          onFocus={(event => event.stopPropagation())}>

          </input>
      </MenuItem>}
      {options.map(d => (
        (d[keyColumn] === keyName) ? <MenuItem className={classes.selectPopupLabel} style={{ backgroundColor: 'transparent', borderBottom: `2px solid ${theme.palette.tableRowDivider}` }} key={d[keyColumn]} value={d[keyColumn]}>{d[valueColumn]}
          <div style={{ flex: 1 }}></div><svg className="MuiSvgIcon-root" style={{ transform: 'rotate(180deg)' }} focusable="false" viewBox="0 0 24 24" aria-hidden="true" role="presentation"><path d="M7 10l5 5 5-5z"></path></svg>
        </MenuItem> : ''))}
      {options.map(d => (
        (showOption(d) ? <MenuItem  className={classes.selectItemStyle} key={d[keyColumn]} value={d[keyColumn]}>{d[valueColumn]}</MenuItem> : '')
      ))}
    </Select>
  );
}

CustomSelect.propTypes = {
};
