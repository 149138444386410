import React from 'react'

import {TableRow, TableBody, TableCell, Typography} from '@material-ui/core';
import {Info} from '@material-ui/icons'

const getNoDataRow = (colSpan, rowSpan, height) => {
    return <TableRow key={colSpan} style={{height: height}}><TableCell rowSpan={rowSpan} align='center' valign='center' colSpan={colSpan}><Typography variant='h3'><Info></Info> Your datasource or filter selection returned no data. Please Update your selection.</Typography></TableCell></TableRow>
}

export default function NoData(props){

    const {colSpan, rowCount, height} = props;
    return (
        <TableBody>
           {getNoDataRow(colSpan, rowCount, height)}
        </TableBody>);
      
};