//core components
import React, { useEffect} from 'react'
import { useLocation, useHistory } from 'react-router-dom';
import {ApplicationContext} from 'context';
import query from 'query-string';
//material ui components
import { makeStyles, lighten } from '@material-ui/core/styles';
import { ToggleButtonGroup, ToggleButton } from '@material-ui/lab';
import {Typography} from '@material-ui/core'


const useStyles = makeStyles(theme => ({
  toolbar: {
    marginLeft: 0,
    paddingLeft: 20,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    flexGrow: 2,
  },
  toggleButtonGroupRoot: {
    backgroundColor: 'transparent'
  },
  buttonSelected: {
    fill: `${theme.palette.white} !important`,
    backgroundColor: `${theme.palette.primary.main} !important`
  },
  buttonRoot: {
    height: 25,
    fill: '#455a64',
    borderRadius: 18,
    borderColor: theme.palette.grey,
    width: 130,
    fontSize: 12,
    margin:1,
    marginLeft: 1
  },
  flex: {
    flexGrow: 2,
  },
  iconButton:{
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    marginLeft: 10,
    '&:hover': {
      backgroundColor: theme.palette.npd.darkBlue
    },
    '&:disabled':{
      backgroundColor: lighten(theme.palette.npd.gray, .7)
    }
  }
}));

export default function OdataCollectionToolbar(props) {
  const { view, setView, messageComponent,messageProps} = props;
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const appContext = React.useContext(ApplicationContext);
  
  useEffect(() => {
    if (location.search){
      const tab = query.parse(location.search);
      if (tab.v !== undefined && tab.v !==null && tab.v !== "null")
        setView(tab.v);
    }
  }, [location])

  const handleToggleButtonChange = (event, value) => {
    //Do not allow to untoggle
    //if (value !== null && value !== undefined)
      history.push('/collection?v='+value)
      //alert(messageProps + ' ' + view);
  }


  return (
    <div className={classes.toolbar} >
      {appContext.appInfo.user.hasFCAccess && 
        <ToggleButtonGroup
          value={view}
          exclusive
          onChange={handleToggleButtonChange}
          aria-label='toggle'
          size='small'
          classes={{ root: classes.toggleButtonGroupRoot }}
        >
          <ToggleButton classes={{ root: classes.buttonRoot, selected: classes.buttonSelected }} value='collection' aria-label='collection' >
            <span style={{ paddingLeft: 0 }}>Bundles</span>
          </ToggleButton>
          <ToggleButton classes={{ root: classes.buttonRoot, selected: classes.buttonSelected }} value='odata' aria-label='excelodata' >
            <span style={{ paddingLeft: 0 }}>Batch Reports</span>
          </ToggleButton>	          
         
        </ToggleButtonGroup>
    }
     {(view == 'collection' && messageProps) ? '' : messageComponent}
    </div>
    
  )
}
