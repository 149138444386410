//core components
import React, {Fragment, useState} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
//material ui components
import { makeStyles, lighten } from '@material-ui/core/styles';
import { Toolbar, Typography, Tooltip, IconButton, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, Grid } from '@material-ui/core';
import { GetApp as BatchIcon, Cached as RefreshIcon } from '@material-ui/icons';
//custom components
import { GridContext, SearchContext } from 'context';
import { useApi, SelectedSearches } from 'components';
import {CreateCollection} from 'views/Odata/Collections';

const useStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    flexGrow: 10,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    alignItems: 'flex-start',
    justifyContent: 'flex-end'
  },
  highlight: {
    color: theme.palette.primary.main,
    backgroundColor: lighten(theme.palette.secondary.light, 0.75),
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    alignItems: 'flex-start',
    justifyContent: 'flex-end'
  },
  icon: {
    padding: '1px 5px 1px 1px',
    //margin: '0px 3px 3px 3px',
    fontSize: '23px',
    //lineHeight: '24px'
  },
  title: {
    paddingRight: theme.spacing(3),
    fontSize: '12px',
    //flex: '1 1 100%',
  },
  alignRight: {
    textAlign: 'end'
  },
  alignLeft: {
    textAlign: 'start'
  },
}));

export default function EnhancedTableToolbar(props) {
  const classes = useStyles();
  const { multidownload, refresh, createCollection, commands = [] } = props;
  const [rowsSelected, setRowsSelected] = React.useState(0);
  const gridContext = React.useContext(GridContext)
  const searchContext = React.useContext(SearchContext)

  const [selectionModel, setSelectionModel] = useState({
    selected: [],
    selectedCount: 0
  });

  const GenerateSelectedSearches = () => {
    let searches = [];

    if (searchContext.reportName !== undefined && searchContext.reportName !== '')
      searches.push({ key: "Report Name", value: searchContext.reportName, handleDelete: () => { searchContext.setReportName(''); } });

    if (searchContext.folderName !== undefined && searchContext.folderName !== '')
      searches.push({ key: "Folder Name: ", value: searchContext.folderName, handleDelete: () => { searchContext.setFolderName(''); } });

    if (searchContext.batchStatus !== undefined && searchContext.batchStatus.scheduleStatus !== undefined && searchContext.batchStatus.scheduleStatus !== 0)
      searches.push({ key: "Status: ", value: searchContext.batchStatus.displayName, handleDelete: () => { searchContext.setBatchStatus({ scheduleStatus: 0, displayName: "" }); } });

    if (searchContext.batchedBy !== undefined && searchContext.batchedBy !== '')
      searches.push({ key: "Batched By: ", value: searchContext.batchedBy, handleDelete: () => { searchContext.setBatchedBy(''); } });

    if (searchContext.format !== undefined && searchContext.format.reportOutputTypeId !== undefined && searchContext.format.reportOutputTypeId !== 0)
      searches.push({ key: "Format: ", value: searchContext.format.reportOutputType, handleDelete: () => { searchContext.setFormat({ reportOutputTypeId: 0, reportOutputType: "" }); } });

    if (searchContext.batchEnddate !== undefined && searchContext.batchEnddate !== null)
      searches.push({ key: "Batch To: ", value: searchContext.batchEnddate.format("MM/DD/YYYY"), handleDelete: () => { searchContext.setBatchEnddate(null); } });

    if (searchContext.batchStartdate !== undefined && searchContext.batchStartdate !== null)
      searches.push({ key: "Batch From: ", value: searchContext.batchStartdate.format("MM/DD/YYYY"), handleDelete: () => { searchContext.setBatchStartdate(null); } });
    
    if (searchContext.lastModifiedEnddate !== undefined && searchContext.lastModifiedEnddate !== null)
      searches.push({ key: "Last Modified To: ", value: searchContext.lastModifiedEnddate.format("MM/DD/YYYY"), handleDelete: () => { searchContext.setLastModifiedEnddate(null); } });

    if (searchContext.lastModifiedStartdate !== undefined && searchContext.lastModifiedStartdate !== null)
      searches.push({ key: "Last Modified From: ", value: searchContext.lastModifiedStartdate.format("MM/DD/YYYY"), handleDelete: () => { searchContext.setLastModifiedStartdate(null); } });

    if (searchContext.createdByEnddate !== undefined && searchContext.createdByEnddate !== null)
      searches.push({ key: "Created By To: ", value: searchContext.createdByEnddate.format("MM/DD/YYYY"), handleDelete: () => { searchContext.setCreatedByEnddate(null); } });

    if (searchContext.createdByStartdate !== undefined && searchContext.createdByStartdate !== null)
      searches.push({ key: "Created By From: ", value: searchContext.createdByStartdate.format("MM/DD/YYYY"), handleDelete: () => { searchContext.setCreatedByStartdate(null); } });


    return searches;
  }
  
  React.useEffect(() => {
    setRowsSelected(gridContext.rowsSelected.length);
    setSelectionModel({
      selected: gridContext.rowsSelected,
      selectedCount: gridContext.rowsSelected.length
    });

  }, [gridContext.rowsSelected])

  const getCommandsWithModel = () => {
    return commands.map(c => {
      return React.cloneElement(c, {
        selectionModel: selectionModel
      });
    });
  }

  const onRefresh = () => 
  {
    gridContext.setRowsSelected([]);
    if (isFunction(refresh))
      refresh();
  }
  const showDownload = () => { 
    var result = true;
    if (commands.length > 0) {result = false;}
    if (createCollection === true) {result = false;}
     return !result;
  
  }
  
  const isFunction = functionToCheck => {
    return functionToCheck && {}.toString.call(functionToCheck) === '[object Function]';
  }

  return (
    <div
      className={clsx(classes.root, classes.highlight)}
      variant='dense'
    >
      <Grid container>

      <Grid item xs={1} className={classes.alignLeft}>
          {createCollection ? (
            <Typography className={classes.title} color='primary' variant="subtitle1">
              <CreateCollection selectionModel={selectionModel} />
            </Typography>
          ) : (
              <Typography className={classes.title} color='primary' variant="subtitle1">

              </Typography>
            )}
        {/* </Grid>

        <Grid item xs={1} className={classes.alignLeft}> */}
          {commands ? (
            <Typography className={classes.title} color='primary' variant="subtitle1">
              { 
                commands.map((command, index) => {
                  return <Fragment key={index}>{command} </Fragment>;
                })
              }
            </Typography>
          ) : (
              <Typography className={classes.title} color='primary' variant="subtitle1">

              </Typography>
            )}
        </Grid>

        <Grid item xs={7}>
          <SelectedSearches searches={GenerateSelectedSearches()}></SelectedSearches>
        </Grid>
        <Grid item xs={3} className={classes.alignRight}>
          {rowsSelected > 0 ? (
            <Typography className={classes.title} color='primary' variant="subtitle1">
              {rowsSelected} item(s) selected
            </Typography>
          ) : (
              <Typography className={classes.title} color='primary' variant="subtitle1">

              </Typography>
            )}
        </Grid>




        <Grid item xs={1} className={classes.alignRight}>
        {showDownload() ? (
              <span />
          ) : (
            <Tooltip title="Download Selected Files">
            <span >
              <IconButton className={classes.icon} onClick={() => multidownload()} aria-label="download" color="primary" disabled={rowsSelected === 0}>
                <BatchIcon className={classes.icon} />
              </IconButton>
            </span>
          </Tooltip>              
          )}          
          <Tooltip title="Refresh">
            <span>
              <IconButton className={classes.icon} onClick={onRefresh} aria-label="refresh" color="primary">
                <RefreshIcon className={classes.icon} />
              </IconButton>
            </span>
          </Tooltip>
        </Grid>
      </Grid>
    </div>
  );
};

EnhancedTableToolbar.propTypes = {
  refresh: PropTypes.func.isRequired,
  multidownload: PropTypes.func.isRequired
};